// Breadcrumbs.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from './routes';
import useBreadcrumbs from 'use-react-router-breadcrumbs'

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
  const customBreadcrumbs = lastBreadcrumb.match.route.breadcrumb?lastBreadcrumb.match.route.breadcrumb :[] ;
  return (
    <nav className=" ml-16 mt-3">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 ml-3 text-md">
        {customBreadcrumbs.map((breadcrumb, index) => (
          <li key={"BC_"+index}>
            {breadcrumb.path ? (
              <Link to={breadcrumb.path}  className="font-bold">{breadcrumb.name}</Link>
            ) : (
              <span className="white-text">{breadcrumb.name}</span>
            )}
            {index < customBreadcrumbs.length - 1 && <span className="mx-2">/</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;