import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import LocationContext from "../../context/LocationContext";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-js-dialog-box/dist/index.css";
import { apiService } from "../../Services/Services";
import Popup from "../Popup";
import { FaDownload, FaCopy } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { checkWeekStatus,checkScheduleValidation } from "../../Services/CommonFucntion";

const TableHeader = ({ breadcrumbs }) => {
  const {
    selectedLocation,
    selectedScheduleType,

    setLoading,

    selectedDate,

    hasSchedule,
    ScheduleStatusCheck,
    setScheduleStatusCheck,
    setLoaderMessage,
    setScheduleRefresh,
    setFacultyHrsUpdate,
    dateStatus,
    setDateStatus,
    setSelectedCheckboxes,
    selectedCheckboxes,
    setIsSwapSchedule,
    isSwapSchedule,
    tableData, setTableData

  } = useContext(LocationContext);
  
  let streamCode = "";

  if (selectedScheduleType === "jee/medical") {
    streamCode = "j/m";
  } else if (selectedScheduleType === "jee") {
    streamCode = "j";
  } else if (selectedScheduleType === "medical") {
    streamCode = "m";
  } else if (selectedScheduleType === "foundation") {
    streamCode = "f";
  }
  const [isOpenAutoSchedule, setIsOpenAutoSchedule] = useState(false);

  const openPopup = () => {
    setIsOpenAutoSchedule(true);
  };

  const closePopupAutoschedule = () => {
    setIsOpenAutoSchedule(false);
  };

  const [isOpenPublishSchedule, setIsOpenPublishSchedule] = useState(false);

  const openPopupPublishSchedule = () => {
    setIsOpenPublishSchedule(true);
  };
  const closePopupPublishSchedule = () => {
    setIsOpenPublishSchedule(false);
  };
  const [isOpenSwapSchedule, setIsOpenSwapSchedule] = useState(false);
  const [isOpenCopySchedule, setIsOpenCopySchedule] = useState(false);

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );

  const location = useLocation();
  const isFacultyListRoute = location.pathname === "/facultylist";
  const isSchedulepageRoute = location.pathname === "/";
  const isSchedulepageRoute1 = location.pathname === "/";
  const isSchedulepageRoute2 = location.pathname === "/";
  const isSchedulepageRoute3 = location.pathname === "/";
  const isBatchpageRoute = location.pathname === "/batchlist";
  const isLeavePageRoute = location.pathname === "/LeaveList";
  const isReportPageRoute = location.pathname === "/reports";

  const selectedDateArray = Array.isArray(selectedDate) ? selectedDate : [];
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });
  const startDate = formattedDates[0];
  const endDate = formattedDates[1];

  useEffect(() => {
    const checkDateIsCurrentorPastorPrevious = checkWeekStatus(
      startDate,
      endDate
    );
    if (checkDateIsCurrentorPastorPrevious == true) {
      setDateStatus(false);
    } else {
      setDateStatus(true);
    }
  }, [startDate, endDate]);

  function extractDates(input) {
    const startDate = new Date(input[0]);
    const endDate = new Date(input[1]);

    const startFormatted = startDate.toISOString().split("T")[0];
    const endFormatted = endDate.toISOString().split("T")[0];

    return { start: startFormatted, end: endFormatted };
  }

  useEffect(() => {}, [hasSchedule, ScheduleStatusCheck, startDate, endDate]);

  const exportToFaculty = async () => {
    try {
      const response = await apiService.getFile(
        `${process.env.REACT_APP_API_URL}/export-faculty/${selectedLocation}`
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "exported_sheet.xlsx";

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const exportReport = async () => {
    try {
      const response = await apiService.getFile(
        `${process.env.REACT_APP_API_URL}/export-report?location_id=${selectedLocation}&starting_date=$${startDate}&ending_date=${endDate}`
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "exported_sheet.xlsx";

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const exportToLeave = async () => {
    try {
      const response = await apiService.getFile(
        `${process.env.REACT_APP_API_URL}/export-leave?locationId=${selectedLocation}`
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "exported_sheet.xlsx";

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const exportToExcel = async () => {
   setOpen(false)

    try {
      let streamCode = "";

      if (selectedScheduleType === "jee/medical") {
        streamCode = "j/m";
      } else if (selectedScheduleType === "jee") {
        streamCode = "j";
      } else if (selectedScheduleType === "medical") {
        streamCode = "m";
      } else if (selectedScheduleType === "foundation") {
        streamCode = "f";
      }
      const response = await apiService.getFile(
        `${process.env.REACT_APP_API_URL}/export-schedule?batch_stream=${streamCode}&location_id=${selectedLocation}&start_date=${startDate}&end_date=${endDate}`
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "exported_sheet.xlsx";

      document.body.appendChild(link);
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const [autoScheduleInProgress, setAutoScheduleInProgress] = useState(false);

  const AutoSchedule = async () => {
    setIsOpenAutoSchedule(false);
    // setAutoScheduleInProgress(true);

    // let scheduleTypeMessage = hasSchedule
    //   ? "Reschedule"
    //   : "Automated Scheduling";
    // let autoMessage = `${scheduleTypeMessage} process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;

    // setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
    setLoading(true);
    let streamCode = "";

    if (selectedScheduleType === "jee/medical") {
      streamCode = "j/m";
    } else if (selectedScheduleType === "jee") {
      streamCode = "j";
    } else if (selectedScheduleType === "medical") {
      streamCode = "m";
    } else if (selectedScheduleType === "foundation") {
      streamCode = "f";
    }
    if (selectedDate && selectedDate[0] && selectedDate[1]) {
      const url = `${process.env.REACT_APP_API_URL}/sendAutoScheduleDataToApi?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}&batch_stream=${streamCode}`;

      try {
        // const response = await apiService.post(url);

        const  eventSource = new EventSource(url);
        eventSource.onmessage = (event) => {
            const eventData = JSON.parse(event.data);
            console.log('Received SSE message:', eventData);
            if(eventData && eventData['status'] =='STARTED'){
              toast.success(eventData.message, toastConfig);
              setScheduleRefresh(true);

              

            }else if(eventData && eventData['status'] =='COMPLETED'){
              setFacultyHrsUpdate(true);
              toast.success(eventData.message, toastConfig);
              setLoading(false);
              setScheduleRefresh(true);
              setScheduleStatusCheck(true);
              eventSource.close(); // Clean up event source on component unmount

            }
        };
        eventSource.onerror = (error) => {
          toast.error("Failed to AutoSchedule. Please try again.", toastConfig);
        };
      } catch (error) {
        setFacultyHrsUpdate(false);

        toast.error("Failed to AutoSchedule" + error, toastConfig);
      } finally {
        setAutoScheduleInProgress(false);
        setLoading(false);
        setFacultyHrsUpdate(false);
      }
    } else {
      console.error(
        "Invalid selectedDate array. Ensure both start and end dates are defined."
      );
      setLoading(false);
      setScheduleRefresh(false);
      setScheduleStatusCheck(false);
    }
  };

  const PublishSchedule = async () => {
    let autoMessage = ` process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;

    setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
    setLoading(true);
    setIsOpenPublishSchedule(false);
    if (selectedDate && selectedDate[0] && selectedDate[1]) {
      const url = `${process.env.REACT_APP_API_URL}/publishSchedule?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}&batch_stream=${streamCode}`;

      try {
        const response = await apiService.post(url).then((item) => {
          setLoading(false);

          setFacultyHrsUpdate(true);

          toast.success(
            "'All published schedules have been successfully emailed to all faculties. ",
            toastConfig
          );
          setScheduleStatusCheck(false);

          setScheduleRefresh(true);
        });
      } catch (error) {
        setFacultyHrsUpdate(false);

        console.error("publishing Schedule has error:", error);
        setLoading(false);
        setScheduleStatusCheck(false);

        setScheduleRefresh(false);
      }
    } else {
      setFacultyHrsUpdate(false);
      setLoading(false);
      setScheduleStatusCheck(false);

      setScheduleRefresh(false);

      console.error(
        "Invalid selectedDate array. Ensure both start and end dates are defined."
      );
    }
  };
  const exportToBatch = async () => {
    try {
      const response = await apiService.getFile(
        `${process.env.REACT_APP_API_URL}/export-batch/${selectedLocation}`
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "exported_sheet.xlsx";

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  const swapSchedule = async () => {
    console.log(selectedCheckboxes);
    const url = `${process.env.REACT_APP_API_URL}/swapSchedule?schedule1=${selectedCheckboxes[0]}&schedule2=${selectedCheckboxes[1]}`;

    try {
      await apiService.post(url).then((res) => {
        setLoading(false);
        toast.success(res.message, toastConfig);
        setIsOpenSwapSchedule(false);
        setSelectedCheckboxes([]);
        setScheduleRefresh(true);
        setIsSwapSchedule(false);
        let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
        setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
        setLoading(true);
        checkScheduleValidation(selectedLocation,selectedScheduleType,selectedDate).then(()=>{
          setLoading(false);
          setScheduleRefresh(true);
        }).catch(()=>{
          setLoading(false);
        });
      })
    } catch (error) {
      toast.error(error.response.data.message, toastConfig);
      setLoading(false);
    }
  };
  const copySchedule = async () => {
    const url = `${process.env.REACT_APP_API_URL}/copySchedule?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}`;

    try {
      await apiService.post(url).then((res) => {
        setLoading(false);
        toast.success(res.message, toastConfig);
        setIsOpenCopySchedule(false);
        setTableData([])
        setScheduleRefresh(true);
        let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
        setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
        setLoading(true);
        checkScheduleValidation(selectedLocation,selectedScheduleType,selectedDate).then(()=>{
          setLoading(false);
          setScheduleRefresh(true);
        }).catch(()=>{
          setLoading(false);
        });
        setFacultyHrsUpdate(true);
      });
    } catch (error) {
      toast.error(error.response.data.message, toastConfig);
      setFacultyHrsUpdate(false);
      setLoading(false);

    }
  };
  const [isOpen, setOpen] = useState(false);
  const handleDropDown = () => {
    setOpen(!isOpen);
  };
  return (
    <>
      <ToastContainer />

      <nav
        className="flex container-schedule h-[3rem] text-xl box-border border-b border-gray-400 border-solid overflow-auto"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3 ml-6 text-xl  font-serif">
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index}>
              {breadcrumb.path ? (
                <Link to={breadcrumb.path} className=" font-serif ">
                  <div className="flex items-center mt-3 font-serif">
                    {index !== 0 && (
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    )}
                    <span
                      className={`ml-1 text-xl font-medium md:ml-2 font-serif  ${
                        index !== 0
                          ? "dark:text-gray-400 text-green-800"
                          : "dark:text-gray-400 text-gray-500"
                      }`}
                    >
                      {breadcrumb.name}
                    </span>
                  </div>
                </Link>
              ) : (
                <div className="flex items-center mt-3 font-serif">
                  {index !== 0 && (
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                  <span
                    className={`ml-1 font-serif text-base md:text-base lg:text-xl xl:text-xl ${
                      index !== 0
                        ? "dark:text-gray-400 text-gray-500"
                        : "dark:text-gray-400 text-gray-500"
                    }`}
                  >
                    {breadcrumb.name}
                  </span>
                </div>
              )}
            </li>
          ))}
        </ol>
        <section className="notification flex justify-center bg-emerald-50 font-bold ml-4">
          {isSchedulepageRoute3 && (
            <div
              className="  ml-6 text-base flex  text-primaryColour  "
              style={{ marginTop: "14px" }}
            >
              {!hasSchedule && !ScheduleStatusCheck && (
                <div className="flex font-serif">
                  Please go ahead with auto-scheduling{" "}
                  <FaArrowRight className=" mt-1 ml-1" />{" "}
                </div>
              )}
              {hasSchedule && ScheduleStatusCheck && (
                <div className=" flex font-serif   ">
                  Schedule is in draft mode. Click "Publish" to finalize{" "}
                  <FaArrowRight className=" mt-1 ml-1" />
                </div>
              )}
              {hasSchedule && !ScheduleStatusCheck && (
                <div className=" font-serif">Schedule is Published.</div>
              )}
            </div>
          )}
        </section>

        <div className="absolute right-4 origin-center mt-2 flex space-x-2 ">
          <Popup
            isOpen={isOpenCopySchedule}
            heading={"Copy Schedules From Previous Week?"}
            onClose={() => setIsOpenCopySchedule(false)}
          >
            <h6 className="font-serif">
              {" "}
              Copy will replace if existing records. Do you want to continue ?
            </h6>
            <div className="button-container">
              <button
                className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                onClick={() => setIsOpenCopySchedule(false)}
              >
                No
              </button>
              <button
                className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                onClick={() => copySchedule()}
              >
                Yes
              </button>
            </div>
          </Popup>
          {isSchedulepageRoute2 && selectedCheckboxes?.length == 0 && (
            <button
              className={`bg-${
                ScheduleStatusCheck || hasSchedule
                  ? "primaryColour"
                  : "gray-400"
              } w-22 h-8 text-white text-sm font-bold font-serif py-2 px-3 md:px-4 inline-flex items-center`}
              onClick={() => setIsSwapSchedule(!isSwapSchedule)}
              disabled={!ScheduleStatusCheck && !hasSchedule}
            >
              <span className=" md:inline-block font-serif">
                Swap Schedule
              </span>
            </button>
          )}
          {isSchedulepageRoute2 && selectedCheckboxes?.length != 0 && (
            <button
              className={`bg-${
                selectedCheckboxes?.length == 2 ? "primaryColour" : "gray-400"
              } w-22 h-8 text-white text-sm font-bold font-serif py-2 px-3 md:px-4 inline-flex items-center`}
              onClick={() => setIsOpenSwapSchedule(true)}
              disabled={selectedCheckboxes?.length < 2}
            >
              <FaExchangeAlt />
              <span className=" md:inline-block font-serif ml-1">
                Swap It
              </span>
            </button>
          )}
          <Popup
            isOpen={isOpenSwapSchedule}
            heading={"Swap Schedule?"}
            onClose={() => setIsOpenSwapSchedule(false)}
          >
            <h6 className="font-serif">Do you want to continue ?</h6>
            <div className="button-container">
              <button
                className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                onClick={() => setIsOpenSwapSchedule(false)}
              >
                No
              </button>
              <button
                className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                onClick={() => swapSchedule()}
              >
                Yes
              </button>
            </div>
          </Popup>
          {isSchedulepageRoute2 && (
            <button
              className={`bg-${
                dateStatus ? "  bg-gray-400 " : "primaryColour"
              } w-22 h-8 text-white   text-sm font-bold py-2 px-3 md:px-4 inline-flex items-center`}
              onClick={() => openPopup()}
              disabled={autoScheduleInProgress || dateStatus}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6l6 6m0 0l-6 6m6-6H6"
              ></path>

              <span className="md:inline-block font-serif flex">
                <div className="flex">
                  {hasSchedule ? "Reschedule" : "Auto Schedule"}
                  <FaCalendarAlt className=" mt-1 ml-1" />
                </div>
              </span>
            </button>
          )}
          <Popup
            isOpen={isOpenAutoSchedule}
            onClose={closePopupAutoschedule}
            heading={hasSchedule ? "Reschedule?" : "Auto Schedule?"}
          >
            <h6 className="font-serif">
              {hasSchedule
                ? "Rescheduling will replace existing records. Do you want to continue ?"
                : "Do you want to continue ?"}
            </h6>
            <div className="button-container">
              <button
                className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                onClick={() => setIsOpenAutoSchedule(false)}
              >
                No
              </button>
              <button
                className=" text-white font-serif bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                onClick={() => AutoSchedule()}
              >
                Yes
              </button>
            </div>
          </Popup>

          {isSchedulepageRoute1 && (
            <button
              className={`bg-${
                ScheduleStatusCheck ? "primaryColour" : "gray-400"
              } w-22 h-8 text-white text-sm font-bold font-serif py-2 px-3 md:px-4 inline-flex items-center`}
              onClick={() => openPopupPublishSchedule()}
              disabled={!ScheduleStatusCheck || autoScheduleInProgress}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6l6 6m0 0l-6 6m6-6H6"
              ></path>

              <span className=" md:inline-block font-serif">
                Publish Schedule
              </span>
            </button>
          )}
          <Popup
            isOpen={isOpenPublishSchedule}
            onClose={closePopupPublishSchedule}
            heading={"Publish Schedule?"}
          >
            <h6 className="font-serif">Do you want to continue ?</h6>
            <div className="button-container">
              <button
                className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                onClick={() => setIsOpenPublishSchedule(false)}
              >
                No
              </button>
              <button
                className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                onClick={() => PublishSchedule()}
              >
                Yes
              </button>
            </div>
          </Popup>
          {isSchedulepageRoute && (
            <button
              className="flex items-center justify-center space-x-1 w-4 h-8 text-sm font-bold py-2 px-3 md:px-4 bg-primaryColour text-white hover:bg-primaryColour focus:outline-none focus:bg-primaryColour"
              onClick={() => handleDropDown()}
            >
              <span className="text-xl">⋮</span>
            </button>
          )}
          {isOpen && (
            <div className="absolute top-full right-0 mt-2 w-58 bg-white rounded-lg shadow-lg overflow-hidden z-50">
              <div className="divide-y divide-gray-200">
                <button
                  onClick={() =>{ setIsOpenCopySchedule(true); setOpen(false);}}
                  disabled={autoScheduleInProgress || dateStatus}
                  className={`flex ${
                    autoScheduleInProgress || dateStatus
                  ? "text-gray-400 hover:text-gray-400 "
                  : "text-black-900 hover:text-black-900 "} items-center px-4 py-3 text-sm font-bold hover:bg-gray-200  transition w-full duration-300`}
                >
                  <FaCopy className="mr-2" />
                  <span>Copy Schedule From Previous Week</span>
                </button>
                <button
                  disabled={autoScheduleInProgress}

                  onClick={exportToExcel}
                  className="flex items-center px-4 py-3 text-sm font-bold hover:bg-gray-200 hover:text-gray-900 transition  w-full  duration-300"
                >
                  <FaDownload className="mr-2" />
                  <span>Export Schedule to Excel</span>
                </button>
              </div>
            </div>
          )}

          {isFacultyListRoute && (
            <button
              className="bg-primaryColour font-serif w-22 h-8 text-white text-sm font-bold py-2 px-3 md:px-4 inline-flex items-center"
              onClick={exportToFaculty}
            >
              <FaDownload />

              <span className=" font-serif md:inline-block ml-1">
                Export
              </span>
            </button>
          )}
          {isBatchpageRoute && (
            <button
              className="bg-primaryColour font-serif w-22 h-8 text-white text-sm font-bold py-2 px-3 md:px-4 inline-flex items-center"
              onClick={exportToBatch}
            >
              <FaDownload />

              <span className="  font-serif md:inline-block  ml-1">
                Export
              </span>
            </button>
          )}

          {isLeavePageRoute && (
            <button
              className="bg-primaryColour font-serif w-22 h-8 text-white text-sm font-bold py-2 px-3 md:px-4 inline-flex items-center"
              onClick={exportToLeave}
            >
              <FaDownload />

              <span className=" font-serif md:inline-block  ml-1">
                Export
              </span>
            </button>
          )}

          {isReportPageRoute && (
            <button
              className="bg-primaryColour font-serif w-22 h-8 text-white text-sm font-bold py-2 px-3 md:px-4 inline-flex items-center"
              onClick={exportReport}
            >
              <FaDownload />

              <span className=" md:inline-block font-serif ml-1">
                Export
              </span>
            </button>
          )}
        </div>
      </nav>
    </>
  );
};

export default TableHeader;
