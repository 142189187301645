import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Loader from "../../components/Loader";
import {
  checkWeekStatus,
  calculateValueFromDates,
} from "../../Services/CommonFucntion";
import LocationContext from "../../context/LocationContext";
import { Modal } from "../../components/Modal/Modal";
import { apiService } from "../../Services/Services";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { EditSchedule } from "../../components/Table/EditSchedule";
import Select from "react-select";
import defaultImage from "../../utils/Images/defaultImage.jpg";
import { checkScheduleValidation } from "../../Services/CommonFucntion";
import {
  FaCopy,
  FaDownload,
  FaEllipsisV,
  FaExchangeAlt,
  FaFileExport,
  FaGripVertical,
  FaLocationArrow,
  FaPlus,
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaRegCalendarTimes,
} from "react-icons/fa";
import Location from "../../components/Dropdowns/Location/Location";
import Calender from "../../components/Dropdowns/Calender/Calender";
import ScheduleType from "../../components/Dropdowns/ScheduleType/scheduleType";
import moment from "moment";
import { eachDayOfInterval } from "date-fns";
import Popup from "../../components/Popup";
import AutoScheduleErrorTable from "../../components/Table/AutoScheduleErrorTable";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Switch from "../../components/switch/switch";

import * as _ from "lodash";

const SlotActionMenu = ({
  dataItem,
  isOpen,
  setIsOpen,
  handleOpenModal,
  setDeleteScheduleId,
}) => {
  const {
    setFacultyData,
    setTime,
    setSelectedFaculty,
    setSelectedSubjectid,
    setModalDate,
    modalOpen,
    setModalOpen,
    setSelectedTime,
    setSelectedSubject,
    setCreateScheduleData,
  } = useContext(LocationContext);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsActionOpen(!isActionOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsActionOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleOpenPopup = (id) => {
    setDeleteScheduleId(id);
    setIsOpen(true);
  };

  return (
    <>
      {/* <div className="absolute top-0 left-0  ml-1">
      <input
            type="checkbox"
            className="h-[14px] w-[14px] border border-gray-300 rounded-sm checked:bg-blue-600 checked:border-transparent focus:outline-none"
          />
      </div> */}
      <div className="absolute top-0 right-0 mt-1" ref={menuRef}>
        <div className="flex flex-col  ">
          <button
            onClick={toggleMenu}
            className="rounded-full hover:bg-gray-200 focus:outline-none"
          >
            {" "}
            <FaEllipsisV className="text-xs" />
          </button>
        </div>
        {isActionOpen && (
          <div className="absolute right-0 mt-2 w-28 bg-white border  border-gray-200 rounded-md shadow-lg z-10">
            <div className="py-1">
              <button
                className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                onClick={() => handleOpenModal(dataItem)}
              >
                Edit
              </button>
              <button
                onClick={() =>
                  handleOpenPopup(
                    dataItem.lesson.id,
                    dataItem.lesson.faculty.first_name +
                      " " +
                      dataItem.lesson.faculty.last_name
                  )
                }
                className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const ThreeDotMenu = ({
  setIsOpenCopySchedule,
  locationList,
  selectedLocation,
  setSelectedExportLocations,
  setIsOpenExportSchedule,
  setExportFacultyStatus,
  setExportStudentStatus,
  isOpenExportSchedule,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setExportFacultyStatus(false);
    setExportStudentStatus(false);
  };
  const ScheduleExporttogglePopup = () => {
    setSelectedExportLocations(locationList);
    setIsOpenExportSchedule(!isOpenExportSchedule);
  };
  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div ref={menuRef}>
      <div className="flex flex-col  ">
        {/* <input
          type="checkbox"
          className="h-[14px] w-[14px] border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
        /> */}
        <button
          onClick={toggleMenu}
          className="bg-primaryColour text-white h-9 px-1 rounded ml-2 flex items-center shadow-lg "
        >
          {" "}
          <FaEllipsisV className="text-lg" />
        </button>
      </div>
      {isOpen && (
        <div className="absolute right-0 m-2 w-auto z-30 bg-white border border-gray-200 rounded-md shadow-lg z-10">
          <div className="py-1 ">
            <button
              onClick={() => {
                setIsOpenCopySchedule(true);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaCopy className="mr-2" />
              Copy Schedule From Previous Week
            </button>

            <button
              onClick={() => {
                setExportFacultyStatus(true);
                ScheduleExporttogglePopup();
                setIsOpen(!isOpen);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaDownload className="mr-2" />
              Export Schedule for Faculty
            </button>
            <button
              onClick={() => {
                setExportStudentStatus(true);
                ScheduleExporttogglePopup();
                setIsOpen(!isOpen);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaDownload className="mr-2" />
              Export Schedule for Student
            </button>
            <Link
              to="/"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaLocationArrow className="mr-2" />
              Switch to Batch View
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
const FacultySchedulingPage = () => {
  const {
    loading,
    facultyData,
    setFacultyData,
    time,
    setTime,
    setSelectedFaculty,
    setSelectedSubjectid,
    selectedSubjectid,
    setModalDate,
    modalDate,
    selectedLocation,
    hasSchedule,
    ScheduleStatusCheck,
    modalOpen,
    setModalOpen,
    dateStatus,
    setDateStatus,
    selectedTime,
    setSelectedTime,
    selectedFaculty,
    selectedScheduleType,
    selectedDate,
    setScheduleStatusCheck,
    setFacultyHrsUpdate,
    setSelectedSubject,
    setScheduleRefresh,
    setHasSchedule,
    setSubjectList,
    loaderMessage,
    subjectList,
    createScheduleData,
    setCreateScheduleData,
    facultyPresent,
    setFacultyPresent,
    scheduleRefresh,
    facultyHrsUpdate,
    setLoaderMessage,
    setLoading,
    setSelectedCheckboxes,
    selectedCheckboxes,
    isSwapSchedule,
    setIsSwapSchedule,
    locationState,
  } = useContext(LocationContext);
  const [facultyCard, setFacultyCard] = useState([]);
  const [facultyList, setFacultyList] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [slotTime, setSlotTimes] = useState([]);
  const [deleteScheduleId, setDeleteScheduleId] = useState(null);
  const [allDetails, setAllDetails] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [isOpenExportSchedule, setIsOpenExportSchedule] = useState(false);

  const [selectedExportLocations, setSelectedExportLocations] = useState([]);
  const [calculateHrs, setCalculateHrs] = useState("");
  const [total, setTotal] = useState(0);
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const [schedule, setSchedule] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hardRuleError, setHardRuleError] = useState([]);
  const [isOpenAutoSchedule, setIsOpenAutoSchedule] = useState(false);
  const [autoScheduleInProgress, setAutoScheduleInProgress] = useState(false);
  const [isOpenPublishSchedule, setIsOpenPublishSchedule] = useState(false);
  const [isOpenSwapSchedule, setIsOpenSwapSchedule] = useState(false);
  const [isOpenCopySchedule, setIsOpenCopySchedule] = useState(false);
  const [isCompactView, setIsCompactView] = useState(false);
  const [exportFacultyStatus, setExportFacultyStatus] = useState(false);
  const [exportStudentStatus, setExportStudentStatus] = useState(false);

  const [selectedRescheduleLocations, setSelectedRescheduleLocations] =
    useState([]);
  const handleRescheduleLocationsChange = (selectedOptions) => {
    if (selectedOptions?.length == 0) {
      const location = _.find(locationList, { value: selectedLocation });
      setSelectedRescheduleLocations(location ? [location] : []);
    } else {
      setSelectedRescheduleLocations(selectedOptions);
    }
  };
  useEffect(() => {
    const mappingResponse = locationState.map((response) => {
      return {
        label: response.name,
        value: response.id,
      };
    });
    setLocationList(mappingResponse);
  }, [locationState]);
  const handleExportLocationsChange = (selectedOptions) => {
    if (selectedOptions?.length == 0) {
      const location = _.find(locationList, { value: selectedLocation });
      setSelectedExportLocations(location ? [location] : []);
    } else {
      setSelectedExportLocations(selectedOptions);
    }
  };
  const handleSwitchChange = (value) => {
    setIsCompactView(value);
  };
  const togglePopup = () => {
    setSelectedRescheduleLocations(locationList);
    setIsOpenAutoSchedule(!isOpenAutoSchedule);
  };

  let streamCode = "";
  if (selectedScheduleType === "jee/medical") {
    streamCode = "j/m";
  } else if (selectedScheduleType === "jee") {
    streamCode = "j";
  } else if (selectedScheduleType === "medical") {
    streamCode = "m";
  } else if (selectedScheduleType === "foundation") {
    streamCode = "f";
  }
  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const conversionRate = 3;
  const selectedDateArray = Array.isArray(selectedDate) ? selectedDate : [];
  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const calculatedValue = calculateValueFromDates(
    formattedDates[0],
    formattedDates[1],
    conversionRate
  );
  const startDate = formattedDates[0];
  const endDate = formattedDates[1];
  useEffect(() => {
    const checkDateIsCurrentorPastorPrevious = checkWeekStatus(
      startDate,
      endDate
    );
    if (checkDateIsCurrentorPastorPrevious == true) {
      setDateStatus(false);
    } else {
      setDateStatus(true);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (scheduleRefresh) {
      getSchedulingData();
      setScheduleRefresh(false);
    }
  }, [scheduleRefresh]);

  const scroll = (direction) => {
    const scrollAmount = scrollContainerRef.current.clientWidth;
    if (direction === "left") {
      scrollContainerRef.current.scrollLeft -= scrollAmount;
    } else if (direction === "right") {
      scrollContainerRef.current.scrollLeft += scrollAmount;
    }
  };
  const updateScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  };
  useEffect(() => {
    setAllDetails([]);
    if (batchList?.length > 0) {
      const batchSlots = _.flatMap(batchList, (batch) =>
        batch.batch_slots.map((slots) => slots.slot_times)
      ).flat();
      const bSlotTimes = _.uniq(batchSlots)
        .sort()
        .map((s) => ({ id: s, slot_time: s }));

      const scheduleSlotTimes = _.uniq([
        ...schedule.map((ele) => ele.slot_time),
      ]).sort();

      const finalSlotTimes = _.uniq([
        ...bSlotTimes.map((s) => s.slot_time),
        ...scheduleSlotTimes,
      ])
        .sort()
        .map((s) => ({ id: s, slot_time: s }));
      setSlotTimes(finalSlotTimes);
    }
  }, [schedule, batchList]);

  useEffect(() => {
    getSubjectData();
    getBatchLocationById();
    // getSlotTimes();

    getSchedulingData();
  }, [selectedScheduleType, selectedDate, selectedLocation]);
  useEffect(() => {
    getFacultyCard();
  }, [batchList]);

  useEffect(() => {
    updateScrollButtons();
    scrollContainerRef.current.addEventListener("scroll", updateScrollButtons);
    return () => {
      if (scrollContainerRef.current)
        scrollContainerRef.current.removeEventListener(
          "scroll",
          updateScrollButtons
        );
    };
  }, [facultyList]);
  const getSubjectData = async () => {
    const subjectURL = `${process.env.REACT_APP_API_URL}/getSubject?q=&limit=&page=`;
    const Response = await apiService.get(subjectURL);
    if (Response) {
      try {
        let mappingResponse = [];
        if (selectedScheduleType === "jee/medical") {
          Response.data.map((response) => {
            if (
              _.findIndex(
                response.batch_stream,
                (ele) => ele.stream_code === "J" || ele.stream_code === "M"
              ) >= 0
            ) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "jee") {
          Response.data.map((response) => {
            if (
              _.findIndex(
                response.batch_stream,
                (ele) => ele.stream_code === "J"
              ) >= 0
            ) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "medical") {
          Response.data.map((response) => {
            if (
              _.findIndex(
                response.batch_stream,
                (ele) => ele.stream_code === "M"
              ) >= 0
            ) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "foundation") {
          Response.data.map((response) => {
            if (response.batch_stream[0].stream_code == "F") {
              mappingResponse.push(response);
            }
          });
        }
        setSubjectList(mappingResponse);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleOpenModal = (slot) => {
    console.log(slot.lesson.batch.id);
    setCreateScheduleData([]);
    setSelectedFaculty("");
    setModalDate("");
    setSelectedSubjectid("");
    setSelectedTime("");
    setFacultyData([]);
    setTime([]);
    setSelectedSubject([]);
    let batch = batchList.filter(
      (batchl) => slot.lesson.batch.id == batchl.id
    )[0];
    console.log(batch, batchList);
    let data = {
      batch_code: slot.lesson.batch.batch_code,
      batch_id: slot.lesson.batch.id,
      date: slot.date,
      faculties: batch.faculties,
      time: slot.time,
      faculty_id: slot.lesson.faculty.id,
      subject: slot.lesson.subject,
      id: slot.lesson.id,
    };
    setModalOpen(!modalOpen);
    setCreateScheduleData(data);

    // setModalOpenEdit(true);
  };
  const handleOpenEditModal = (data) => {
    setIsDropdownOpen(false);
    let filteredDetails = "";
    allDetails.filter((detail) => {
      detail.lessons.forEach((lesson) => {
        lesson.forEach((slot) => {
          if (slot.lesson.id === data.id) {
            filteredDetails = slot;
          }
        });
      });
    });
    handleOpenModal(filteredDetails);
  };
  const getFacultyCard = async () => {
    if (selectedLocation) {
      // Check if selectedLocation is not empty

      let streamCode = "";

      if (selectedScheduleType === "jee/medical") {
        streamCode = "j/m";
      } else if (selectedScheduleType === "jee") {
        streamCode = "j";
      } else if (selectedScheduleType === "medical") {
        streamCode = "m";
      } else if (selectedScheduleType === "foundation") {
        streamCode = "f";
      }
      const url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&location_id=${selectedLocation}&sortBy=updated_at&sortOrder=ASC&stream_code=${streamCode}`;

      try {
        const res = await apiService.get(url);
        setFacultyCard(res);
        const mappingResponse = res.map((response) => {
          return {
            // label: <>{response.first_name + " " + response.last_name}</>,
            label: (
              <>
                <div className="flex">
                  {/* {response.image_url ? (
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        `/faculty/` +
                        response.id +
                        `/image`
                      }
                      onError={(e) => {
                        e.target.src = defaultImage;
                      }}
                      className="h-5 w-5 rounded-full mr-1"
                      alt={`Item Image` + response.id}
                    />
                  ) : null} */}
                  <div className="flex items-center justify-center w-8 h-8 bg-slate-100 text-sm rounded-full shadow-md">
                    {response.faculty_code}
                  </div>
                  {response.first_name + " " + response.last_name + " "}
                  {response.subject.map((subject, index) => (
                    <span
                      key={index}
                      style={{ color: "red" }}
                      className=" ml-1 "
                    >
                      ({subject.subject_name})
                    </span>
                  ))}
                </div>
              </>
            ),
            value: response.faculty_code,
            image: response.image,
            id: response.id,
          };
        });
        setFacultyData(mappingResponse);
        setTotal(res.total);
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    getFacultyHrs();
  }, [facultyCard]);

  useEffect(() => {
    generateData();
  }, [schedule, facultyList]);

  useEffect(() => {
    if (selectedLocation) {
      if (facultyHrsUpdate) {
        getFacultyHrs();
        setFacultyHrsUpdate(false);
      }
    }
  }, [facultyHrsUpdate]);
  const getFacultyHrs = async () => {
    if (selectedLocation) {
      const reportURL = `${process.env.REACT_APP_API_URL}/getFacultiesCount?&location_id=${selectedLocation}&starting_date=${formattedDates[0]}&ending_date=${formattedDates[1]}`;
      try {
        const res = await apiService.get(reportURL);
        setCalculateHrs(res);
      } catch (err) {
        setCalculateHrs(null);
      }
    }
  };

  const getSlotTimes = async () => {
    if (selectedLocation) {
      const reportURL = `${process.env.REACT_APP_API_URL}/getSlotTime`;
      try {
        const res = await apiService.get(reportURL);
        setSlotTimes(res);
      } catch (err) {
        setSlotTimes(null);
      }
    }
  };
  useEffect(() => {
    renderFacultyCards();
  }, [calculateHrs]);
  const renderFacultyCards = () => {
    let list = [];
    facultyCard.filter((faculty, index) => {
      let targetFacultyId = faculty?.id || null;
      const filteredData = calculateHrs.Faculties.filter(
        (item) => item?.faculty_id === targetFacultyId
      );
      const totaleCountOfSessionTaken = filteredData[0]?.count || null;
      const values =
        Math.floor((totaleCountOfSessionTaken / calculatedValue) * 100) || 0;
      facultyCard[index]["percentage"] = values;
      facultyCard[index]["totalSession"] = totaleCountOfSessionTaken;

      facultyCard[index]["getReportSessionCount"] = totaleCountOfSessionTaken;
      const completed = facultyCard[index]["percentage"] || 0;
      facultyCard[index]["bgColor"] = "#FFFF00";
      if (completed >= 70 && completed <= 100) {
        facultyCard[index]["bgColor"] = "#008000";
      } else if (completed >= 101) {
        facultyCard[index]["bgColor"] = "#FF0000";
      } else {
        facultyCard[index]["bgColor"] = "#F3C92C";
      }
      let batches = [];
      batchList.map((batch) => {
        batch.faculties.filter((f) => {
          if (f["id"] == facultyCard[index]["id"]) {
            batches.push(batch);
          }
        });
      });
      facultyCard[index]["batches"] = batches;
      console.log(facultyCard[index]["batches"]);
      list.push(faculty);
    });
    setFacultyList(list);
  };
  const getBatchLocationById = async () => {
    try {
      const response = await apiService.get(
        `${process.env.REACT_APP_API_URL}/getbatchdata?q=&limit=&page=&sortBy=id&sortOrder=ASC`
      );

      const batches = [];

      response.data.forEach((batch) => {
        const streamNames = batch.batch_stream.map(
          (stream) => stream.stream_names
        );
        if (streamCode == "j/m") {
          if (streamNames.includes("JEE") || streamNames.includes("Medical")) {
            batches.push(batch);
          }
        } else if (streamCode == "j") {
          if (streamNames.includes("JEE")) {
            batches.push(batch);
          }
        } else if (streamCode == "m") {
          if (streamNames.includes("Medical")) {
            batches.push(batch);
          }
        } else {
          if (streamNames.includes("Foundation")) {
            batches.push(batch);
          }
        }
      });
      setBatchList(batches);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  const getSchedulingData = async () => {
    try {
      const getScheduleURL = `${process.env.REACT_APP_API_URL}/getSchedule?starting_date=${formattedDates[0]}&ending_date=${formattedDates[1]}&stream_code=${streamCode}`;
      const Response = await apiService.get(getScheduleURL);
      if (Response) {
        setSchedule(Response);

        const hasDraft = Response.some((item) => item.status === "draft");

        setScheduleStatusCheck(hasDraft);
        setHasSchedule(true);
        setIsDropdownOpen(false);

        const errorMessages = Response.reduce((acc, item) => {
          if (item.error) {
            const [start, end] = item.slot_time.split("-");
            let time = `${moment(start, "HH:mm").format("h:mm A")} - ${moment(
              end,
              "HH:mm"
            ).format("h:mm A")}`;
            acc.push({
              message: item.error,
              id: item.id,
              time: time,
              batch_name: item.batch.batch_code,
              date: item.date,
              faculty_code: item.faculty.faculty_code,
              subject_name: item.subject.subject_name,
              faculty_name:
                item.faculty.first_name + " " + item.faculty.last_name[0],
            });
          }
          return acc;
        }, []);

        setHardRuleError(errorMessages);
      } else {
        setHardRuleError([]);
        generateData();
        setHasSchedule(false);
        setScheduleStatusCheck(false);
      }
    } catch (error) {
      setHardRuleError([]);
      generateData();
      setScheduleStatusCheck(false);
      setHasSchedule(false);
    }
  };
  const generateData = () => {
    const dates = eachDayOfInterval({
      start: selectedDate[0],
      end: selectedDate[1],
    });
    setAllDetails([]);

    const list = dates.map((d) => {
      const date = moment(d);
      const formattedDate = date.format("MMMM Do, dddd");
      const subSchedule = schedule.filter(
        (data) => moment(data.date).format("L") == date.format("L")
      );
      const times = slotTime.map((time) => {
        const [start, end] = time["slot_time"].split("-");
        return `${moment(start, "HH:mm").format("h:mm A")} - ${moment(
          end,
          "HH:mm"
        ).format("h:mm A")}`;
      });
      const displayTimes = slotTime.map((time) => {
        const [start, end] = time["slot_time"].split("-");
        return {
          startTime: moment(start, "hh:mm A"),
          endTime: moment(end, "hh:mm A"),
        };
      });
      const lessons = facultyList.map((faculty) => {
        return times.map((time) => {
          const [start, end] = time.split("-");
          let t = `${moment(start, "h:mm A").format("HH:mm")}-${moment(
            end,
            "h:mm A"
          ).format("HH:mm")}`;
          let scheduleData = subSchedule.filter(
            (data) => data.slot_time === t && faculty.id === data.faculty.id
          )[0];
          let batch_slots = [];
          if (faculty.batches.length > 0) {
            batch_slots = faculty.batches.map((batch) =>
              batch.batch_slots.map((slot) => slot.slot_times)
            );
            batch_slots = batch_slots.flat();
          }

          batch_slots = batch_slots.flat();
          const batchSlots = [...new Set(batch_slots)];

          return {
            time,
            time_old: t,
            display_time: {
              startTime: moment(start, "hh:mm A"),
              endTime: moment(end, "hh:mm A"),
            },
            date: date.format("L"),
            // batch_id: batch["id"],
            // batch_code: batch["batch_code"],
            faculty: faculty,
            lesson: scheduleData || {},
            allowToAddLesson: batchSlots.includes(t),
          };
        });
        // let batch_slots = batch.batch_slots.map((slot) => {
        //   return slot.slot_times;
        // });
        // batch_slots = batch_slots.flat();

        // return times.map((time) => {
        //   const [start, end] = time.split("-");
        //   let t = `${moment(start, "h:mm A").format("HH:mm")}-${moment(
        //     end,
        //     "h:mm A"
        //   ).format("HH:mm")}`;
        //   let scheduleData = subSchedule.filter(
        //     (data) => data.slot_time === t && batch.id === data.batch.id
        //   )[0];

        //   return {
        //     time,
        //     time_old: t,
        //     display_time: {
        //       startTime: moment(start, "hh:mm A"),
        //       endTime: moment(end, "hh:mm A"),
        //     },
        //     date: date.format("L"),
        //     batch_id: batch["id"],
        //     batch_code: batch["batch_code"],
        //     faculties: batch["faculties"],
        //     lesson: scheduleData || {},
        //     allowToAddLesson: batch_slots.includes(t),
        //   };
        // });
      });
      return {
        date: formattedDate,
        times,
        displayTimes,
        lessons,
      };
    });
    console.log(list);
    setAllDetails(list);
  };

  const handleDragStart = (event, batchData, lessonId = null) => {
    if (lessonId) {
      event.dataTransfer.setData("batchId", batchData.lesson.batch_id);
    } else {
      event.dataTransfer.setData("batchId", batchData.id);
    }
    event.dataTransfer.setData("lessonId", lessonId);
    event.dataTransfer.setData("batchData", JSON.stringify(batchData));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event, slot) => {
    event.preventDefault();
    const batch_id = event.dataTransfer.getData("batchId");
    const subjectId = event.dataTransfer.getData("subjectId");
    const lessonId = event.dataTransfer.getData("lessonId");
    const batchData = JSON.parse(event.dataTransfer.getData("batchData"));
    if (lessonId != null && slot.lesson.id != null) {
      console.log("can`t drag");
      return false;
    }
    // Perform actions with dropped data (facultyId, time, batchId)
    setCreateScheduleData([]);
    let post_data = {
      location_id: selectedLocation,
      batch_id: batch_id,
      faculty_id: slot.faculty.id,
      subject_id: slot.faculty.subject[0].id,
      slot_time: slot.time_old,
      date: moment(slot.date).format("YYYY-MM-DD"),
    };

    let isFacultyInBatch = "";
    if (lessonId != "null") {
      isFacultyInBatch = batchData.faculty.batches.some(
        (batches) => batches.id === batch_id
      );
    } else {
      isFacultyInBatch = batchData.faculties.some(
        (faculty) => faculty.id === slot.facultyId
      );
    }
    console.log(isFacultyInBatch);

    setFacultyPresent(isFacultyInBatch);
    setTimeout(async () => {
      await submitScheduling(post_data, lessonId);
    }, 200);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setCreateScheduleData([]);
    setSelectedFaculty("");
    setModalDate("");
    setSelectedSubjectid("");
    setSelectedTime("");
    setFacultyData([]);
    setTime([]);
    setSelectedSubject([]);
    setModalOpen(!isOpen);
  };

  const togglePublishSchedule = () => {
    setIsOpenPublishSchedule(!isOpenPublishSchedule);
  };
  const toggleSlotModal = (slot) => {
    setCreateScheduleData([]);
    setSelectedFaculty("");
    setModalDate("");
    setSelectedSubjectid("");
    setSelectedTime("");
    setFacultyData([]);
    setTime([]);
    setSelectedSubject([]);
    setCreateScheduleData(slot);
    setModalOpen(!isOpen);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const postData = {
    location_id: selectedLocation,
    batch_id: localStorage.getItem("item"),
    faculty_id: selectedFaculty.value,
    subject_id: selectedSubjectid,
    slot_time: selectedTime,
    date: modalDate,
  };

  let apiUrl;
  if (selectedScheduleType === "foundation") {
    apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule/foundation?isBatchFaculty=${facultyPresent}`;
  } else if (
    selectedScheduleType === "jee/medical" ||
    selectedScheduleType === "jee" ||
    selectedScheduleType === "medical"
  ) {
    apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule?isBatchFaculty=${facultyPresent}`;
  }
  const refreshData = () => {
    setFacultyHrsUpdate(true);
    setScheduleStatusCheck(true);
    // setScheduleRefresh(true);
  };
  const submitScheduling = async (post_data, lesson_id = null) => {
    let lessonId = createScheduleData.id;
    if (lesson_id) {
      lessonId = lesson_id;
    }
    const url = apiUrl;
    if (lessonId && lessonId !== "null") {
      let updateScheudleURL = `${process.env.REACT_APP_API_URL}/UpdateSchedule`;
      if (selectedScheduleType === "foundation") {
        updateScheudleURL += `/foundation/${lessonId}?isBatchFaculty=${facultyPresent}`;
      } else {
        updateScheudleURL += `/default/${lessonId}?isBatchFaculty=${facultyPresent}`;
      }
      const response = await apiService
        .patch(updateScheudleURL, post_data)
        .then((res) => {
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate,
            post_data.faculty_id,
            post_data.batch_id
          )
            .then((response) => {
              let mergedArray = _.map(schedule, (record) => {
                // Find the updated record by matching the 'id'
                const updatedRecord = _.find(response, { id: record.id });
                // If an updated record is found, merge it with the original record
                return updatedRecord
                  ? _.merge({}, record, updatedRecord)
                  : record;
              });
              // Find the records in updatedArray that are not in the original array and add them to the merged array
              const newRecords = _.filter(response, (updatedRecord) => {
                return !_.some(schedule, { id: updatedRecord.id });
              });

              // Combine the merged array with the new records
              mergedArray = [...mergedArray, ...newRecords];
              updateErrors(mergedArray);
              refreshData();
              // setScheduleRefresh(true);
              setModalOpen(false);
              let message = "Schedule Successfully Updated.. ";
              toast.success(message, toastConfig);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
          // setModalOpen(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastConfig);
        });
    } else {
      const response = await apiService
        .post(url, post_data)
        .then((res) => {
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate,
            post_data.faculty_id,
            post_data.batch_id
          )
            .then((response) => {
              let mergedArray = _.map(schedule, (record) => {
                // Find the updated record by matching the 'id'
                const updatedRecord = _.find(response, { id: record.id });
                // If an updated record is found, merge it with the original record
                return updatedRecord
                  ? _.merge({}, record, updatedRecord)
                  : record;
              });
              // Find the records in updatedArray that are not in the original array and add them to the merged array
              const newRecords = _.filter(response, (updatedRecord) => {
                return !_.some(schedule, { id: updatedRecord.id });
              });

              // Combine the merged array with the new records
              mergedArray = [...mergedArray, ...newRecords];
              updateErrors(mergedArray);
              refreshData();
              // setScheduleRefresh(true);
              setModalOpen(false);
              let message = "Schedule Successfully Created.. ";
              toast.success(message, toastConfig);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastConfig);
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitScheduling(postData);
  };
  const handleDeleteSchedule = () => {
    setIsOpen(false);
    if (deleteScheduleId !== null) {
      apiService
        .delete(
          `${process.env.REACT_APP_API_URL}/DeleteSchedule/${deleteScheduleId}`
        )
        .then(() => {
          toast.success("Schedule Successfully Deleted.. ", toastConfig);
          setFacultyHrsUpdate(true);
          getSchedulingData();
          let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
          setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
          setLoading(true);
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate
          )
            .then(() => {
              setLoading(false);
              setScheduleRefresh(true);
            })
            .catch(() => {
              setLoading(false);
            });
        });
    }
    if (deleteScheduleId) {
      setSchedule((prevSchedule) =>
        prevSchedule.filter((data) => data.id !== deleteScheduleId)
      );
    }
  };
  const AutoSchedule = async () => {
    setIsOpenAutoSchedule(false);
    setAutoScheduleInProgress(true);

    let scheduleTypeMessage = hasSchedule
      ? "Reschedule"
      : "Automated Scheduling";
    let autoMessage = `${scheduleTypeMessage} process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;

    setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
    setLoading(true);
    let streamCode = "";

    if (selectedScheduleType === "jee/medical") {
      streamCode = "j/m";
    } else if (selectedScheduleType === "jee") {
      streamCode = "j";
    } else if (selectedScheduleType === "medical") {
      streamCode = "m";
    } else if (selectedScheduleType === "foundation") {
      streamCode = "f";
    }
    const locationIds =
      selectedRescheduleLocations.map((option) => option.value).join(",") || "";
    const max_duration = selectedRescheduleLocations.length * 60;
    if (selectedDate && selectedDate[0] && selectedDate[1]) {
      const url = `${process.env.REACT_APP_API_URL}/sendAutoScheduleDataToApi?from_date=${startDate}&to_date=${endDate}&location_id=${locationIds}&max_duration=${max_duration}&batch_stream=${streamCode}`;

      try {
        const response = await apiService.get(url);
        setFacultyHrsUpdate(true);
        toast.success("Schedule Successfully Created", toastConfig);
        setLoading(false);
        setScheduleRefresh(true);
        setScheduleStatusCheck(true);
        // const eventSource = new EventSource(url);
        // eventSource.onmessage = (event) => {
        //   const eventData = JSON.parse(event.data);
        //   console.log("Received SSE message:", eventData);
        //   if (eventData && eventData["status"] == "STARTED") {
        //     toast.success(eventData.message, toastConfig);
        //   } else if (eventData && eventData["status"] == "COMPLETED") {
        //     setFacultyHrsUpdate(true);
        //     toast.success(eventData.message, toastConfig);
        //     setLoading(false);
        //     setScheduleRefresh(true);
        //     setScheduleStatusCheck(true);
        //     eventSource.close(); // Clean up event source on component unmount
        //   }
        // };
        // eventSource.onerror = (error) => {
        //   toast.error("Failed to AutoSchedule. Please try again.", toastConfig);
        // };
        // return () => {
        //   eventSource.close();
        // };
      } catch (error) {
        setFacultyHrsUpdate(false);

        toast.error("Failed to AutoSchedule" + error, toastConfig);
      } finally {
        setAutoScheduleInProgress(false);
        setLoading(false);
      }
    } else {
      console.error(
        "Invalid selectedDate array. Ensure both start and end dates are defined."
      );
      setLoading(false);
      setScheduleRefresh(false);
      setScheduleStatusCheck(false);
    }
  };
  const PublishSchedule = async () => {
    let autoMessage = ` process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;

    setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
    setLoading(true);
    setIsOpenPublishSchedule(false);
    if (selectedDate && selectedDate[0] && selectedDate[1]) {
      const url = `${process.env.REACT_APP_API_URL}/publishSchedule?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}&batch_stream=${streamCode}`;

      try {
        const response = await apiService.post(url).then((item) => {
          setLoading(false);

          setFacultyHrsUpdate(true);

          toast.success(
            "'All published schedules have been successfully emailed to all faculties. ",
            toastConfig
          );
          setScheduleStatusCheck(false);

          setScheduleRefresh(true);
        });
      } catch (error) {
        setFacultyHrsUpdate(false);

        console.error("publishing Schedule has error:", error);
        setLoading(false);
        setScheduleStatusCheck(false);

        setScheduleRefresh(false);
      }
    } else {
      setFacultyHrsUpdate(false);
      setLoading(false);
      setScheduleStatusCheck(false);

      setScheduleRefresh(false);

      console.error(
        "Invalid selectedDate array. Ensure both start and end dates are defined."
      );
    }
  };
  const swapSchedule = async () => {
    const url = `${process.env.REACT_APP_API_URL}/swapSchedule?schedule1=${selectedCheckboxes[0]}&schedule2=${selectedCheckboxes[1]}`;
    try {
      await apiService.post(url).then((res) => {
        setLoading(false);
        toast.success(res.message, toastConfig);
        setIsOpenSwapSchedule(false);
        setSelectedCheckboxes([]);
        setScheduleRefresh(true);
        setIsSwapSchedule(false);
        let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
        setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
        setLoading(true);
        checkScheduleValidation(
          selectedLocation,
          selectedScheduleType,
          selectedDate
        )
          .then(() => {
            setLoading(false);
            setScheduleRefresh(true);
          })
          .catch(() => {
            setLoading(false);
          });
      });
    } catch (error) {
      toast.error(error.response.data.message, toastConfig);
      setLoading(false);
      setIsOpenSwapSchedule(false);
    }
  };
  const copySchedule = async () => {
    const url = `${process.env.REACT_APP_API_URL}/copySchedule?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}`;

    try {
      await apiService.post(url).then((res) => {
        setLoading(false);
        toast.success(res.message, toastConfig);
        setIsOpenCopySchedule(false);
        setScheduleRefresh(true);
        let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
        setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
        setLoading(true);
        checkScheduleValidation(
          selectedLocation,
          selectedScheduleType,
          selectedDate
        )
          .then(() => {
            setLoading(false);
            setScheduleRefresh(true);
          })
          .catch(() => {
            setLoading(false);
          });
        setFacultyHrsUpdate(true);
      });
    } catch (error) {
      toast.error(error.response.data.message, toastConfig);
      setFacultyHrsUpdate(false);
      setLoading(false);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedCheckboxes((prevState) => {
      const index = prevState.indexOf(id);

      if (index === -1) {
        const updatedState = [...prevState, id].slice(-2);
        return updatedState;
      } else {
        // If exists, remove it from the array
        const updatedState = [
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1),
        ];
        return updatedState;
      }
    });
  };

  const exportLocationScheduleToExcel = async () => {
    const locationLabels = selectedExportLocations
      .map((option) => option.label)
      .join("-");
    const locationIds =
      selectedExportLocations.map((option) => option.value).join(",") || "";
    setIsOpenExportSchedule(false);

    setLoading(true);
    try {
      let streamCode = "";

      if (selectedScheduleType === "jee/medical") {
        streamCode = "j/m";
      } else if (selectedScheduleType === "jee") {
        streamCode = "j";
      } else if (selectedScheduleType === "medical") {
        streamCode = "m";
      } else if (selectedScheduleType === "foundation") {
        streamCode = "f";
      }

      const getExportUrl = () => {
        let url = `${process.env.REACT_APP_API_URL}/export-schedule?batch_stream=${streamCode}&location_id=${locationIds}&start_date=${startDate}&end_date=${endDate}`;

        if (exportFacultyStatus) {
          url += `&view=faculty`;
        } else if (exportStudentStatus) {
          url += `&view=student`;
        }

        return url;
      };

      const response = await apiService.getFile(getExportUrl());

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download =
        selectedExportLocations.length === 1
          ? `Schedule-${locationLabels}-${startDate} to ${endDate}.xlsx`
          : `Schedule-${startDate} to ${endDate}.xlsx`;
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setLoading(false);
      setExportStudentStatus(false);
      setExportFacultyStatus(false);
    } catch (error) {
      console.error("Error exporting data:", error);
      setLoading(false);
    }
  };
  const updateErrors = (Response) => {
    const hasDraft = Response.some(
      (item) => item.status === "draft" || item.status === "modified"
    );
    setScheduleStatusCheck(hasDraft);
    setHasSchedule(true);
    setIsDropdownOpen(false);
    setSchedule(Response);
    const errorMessages = Response.reduce((acc, item) => {
      if (item.error && selectedLocation === item.location_id) {
        if (item["error"] && item["error"][0]) {
          let errorArray = item["error"][0].split(":");
          if (
            errorArray[0] === "ERROR" ||
            (errorArray[0] !== "WARNING" && errorArray[0] !== "ERROR")
          ) {
            const [start, end] = item.slot_time.split("-");
            let time = `${moment(start, "HH:mm").format("h:mm A")} - ${moment(
              end,
              "HH:mm"
            ).format("h:mm A")}`;
            acc.push({
              message: errorArray[errorArray.length - 1],
              id: item.id,
              time: time,
              batch_name: item.batch.batch_code,
              date: item.date,
              faculty_code: item.faculty.faculty_code,
              subject_name: item.subject.subject_name,
              faculty_name:
                item.faculty.first_name + " " + item.faculty.last_name[0],
            });
          }
        }
      }
      return acc;
    }, []);
    setHardRuleError(errorMessages);
  };
  const handleSuggesionSwap = (res) => {
    checkScheduleValidation(
      selectedLocation,
      selectedScheduleType,
      selectedDate,
      "",
      createScheduleData.batch_id
    )
      .then((response) => {
        let mergedArray = _.map(schedule, (record) => {
          // Find the updated record by matching the 'id'
          const updatedRecord = _.find(response, { id: record.id });
          // If an updated record is found, merge it with the original record
          return updatedRecord ? _.merge({}, record, updatedRecord) : record;
        });
        // Find the records in updatedArray that are not in the original array and add them to the merged array
        const newRecords = _.filter(response, (updatedRecord) => {
          return !_.some(schedule, { id: updatedRecord.id });
        });

        // Combine the merged array with the new records
        mergedArray = [...mergedArray, ...newRecords];
        updateErrors(mergedArray);
        refreshData();
        // setScheduleRefresh(true);
        setModalOpen(false);
        toast.success(res.message, toastConfig);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <ToastContainer />
      <div>
        <div className="flex bg-white p-0.5 shadow-lg overflow-x-auto ">
          <div className="flex mt-0.5 mr-2 left-0">
            <Location />
          </div>
          <div className="flex mt-0.5  mr-2">
            <Calender />
          </div>
          <div className="flex mt-0.5">
            <ScheduleType />
          </div>
          <div className="flex  ml-auto">
            {selectedCheckboxes?.length == 0 && (
              <button
                disabled={
                  (!ScheduleStatusCheck && !hasSchedule) ||
                  batchList?.length == 0 ||
                  dateStatus
                }
                onClick={() => setIsSwapSchedule(!isSwapSchedule)}
                className="schedule-btn bg-primaryColour text-white  px-4 rounded ml-2 flex items-center shadow-lg "
              >
                <FaExchangeAlt className="mr-2" /> Swap
              </button>
            )}
            {selectedCheckboxes?.length != 0 && (
              <button
                className="schedule-btn bg-primaryColour text-white  px-4 rounded ml-2 flex items-center shadow-lg "
                onClick={() => setIsOpenSwapSchedule(true)}
                disabled={selectedCheckboxes?.length < 2}
              >
                <FaExchangeAlt />
                <span className=" md:inline-block font-serif ml-1">
                  Swap It
                </span>
              </button>
            )}
            <button
              onClick={() => togglePopup()}
              disabled={
                autoScheduleInProgress || dateStatus || batchList?.length == 0
              }
              className="schedule-btn bg-primaryColour text-white px-4 rounded ml-2 flex items-center shadow-lg "
            >
              <FaRegCalendarAlt className="mr-2" />{" "}
              {hasSchedule ? "Reschedule" : "Auto Schedule"}
            </button>
            <button
              disabled={!ScheduleStatusCheck || autoScheduleInProgress}
              onClick={() => togglePublishSchedule()}
              className="schedule-btn bg-primaryColour text-white  px-4 rounded ml-2 flex items-center shadow-lg "
            >
              <FaRegCalendarCheck className="mr-2" /> Publish
            </button>
            {batchList?.length != 0 && (
              <ThreeDotMenu
                setIsOpenCopySchedule={setIsOpenCopySchedule}
                // exportToExcel={exportToExcel}
                locationList={locationList}
                selectedLocation={selectedLocation}
                setIsOpenExportSchedule={setIsOpenExportSchedule}
                isOpenExportSchedule={isOpenExportSchedule}
                setSelectedExportLocations={setSelectedExportLocations}
                setExportFacultyStatus={setExportFacultyStatus}
                setExportStudentStatus={setExportStudentStatus}
              />
            )}
          </div>
        </div>
        <Popup
          isOpen={isOpenExportSchedule}
          onClose={() => setIsOpenExportSchedule(false)}
          heading={
            exportFacultyStatus
              ? "Export Schedule for Faculty"
              : exportStudentStatus
              ? "Export Schedule for Students"
              : "Export Schedule"
          }
        >
          <div className="flex flex-col m-2 mb-6 ">
            <label
              htmlFor="locationSelect"
              className="mb-2 text-sm font-medium text-gray-700"
            >
              Select Locations:
            </label>
            <Select
              id="locationSelect"
              autoFocus
              placeholder={`Select Location`}
              isMulti
              className="w-84 "
              options={locationList}
              closeMenuOnSelect={false} // Prevent menu from closing on select
              hideSelectedOptions={false} // Show selected options with checkboxes
              styles={{
                control: (base) => ({
                  ...base,
                  width: "30vw", // Fixed width for the select control
                }),
                menu: (base) => ({
                  ...base,
                  width: "34rem", // Fixed width for the dropdown menu
                }),
              }}
              value={selectedExportLocations}
              onChange={handleExportLocationsChange}
            />
          </div>

          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenExportSchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white font-serif bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => exportLocationScheduleToExcel()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenAutoSchedule}
          onClose={() => setIsOpenAutoSchedule(false)}
          heading={hasSchedule ? "Reschedule" : "Auto Schedule"}
        >
          <label
            htmlFor="locationSelect"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Select Location for {hasSchedule ? "Reschedule" : "Auto Schedule"}
          </label>
          <Select
            id="locationSelect"
            placeholder={`Select Location`}
            autoFocus
            isMulti
            className="w-84 "
            options={locationList}
            closeMenuOnSelect={false} // Prevent menu from closing on select
            hideSelectedOptions={false} // Show selected options with checkboxes
            styles={{
              control: (base) => ({
                ...base,
                width: "30vw", // Fixed width for the select control
              }),
              menu: (base) => ({
                ...base,
                width: "34rem", // Fixed width for the dropdown menu
              }),
            }}
            value={selectedRescheduleLocations}
            onChange={handleRescheduleLocationsChange}
          />
          <h6 className="font-serif mt-2">
            {hasSchedule
              ? "Rescheduling will replace existing records. Do you want to continue ?"
              : "Do you want to continue ?"}
          </h6>
          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenAutoSchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white font-serif bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => AutoSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenPublishSchedule}
          onClose={() => setIsOpenPublishSchedule(false)}
          heading={"Publish Schedule?"}
        >
          <h6 className="font-serif">Do you want to continue ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenPublishSchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => PublishSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenSwapSchedule}
          heading={"Swap Schedule"}
          onClose={() => setIsOpenSwapSchedule(false)}
        >
          <h6 className="font-serif">Do you want to continue ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenSwapSchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => swapSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenCopySchedule}
          heading={"Copy Schedules From Previous Week?"}
          onClose={() => setIsOpenCopySchedule(false)}
        >
          <h6 className="font-serif">
            {" "}
            Copy will replace if existing records. Do you want to continue ?
          </h6>
          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenCopySchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => copySchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>

        <div className="flex  p-0.5 items-center   shadow-lg">
          <button
            onClick={toggleModal}
            className={`bg-white text-gray-800 ${
              dateStatus ? "" : " hover:bg-primaryColour  hover:text-white"
            } rounded-md  text-2xl font-bold py-1 px-3 border border-gray-400 shadow`}
          >
            +
          </button>
          <button
            onClick={() => scroll("left")}
            className={`m-2 p-2  text-white h-2/5  rounded-md ${
              !canScrollLeft ? "bg-gray-400 cursor-not-allowed" : "bg-gray-800"
            }`}
            disabled={!canScrollLeft}
          >
            &lt;
          </button>
          <div
            className="overflow-x-auto w-auto max-w-4xl h-26 scrollbar-hide"
            ref={scrollContainerRef}
          >
            <div className="flex space-x-4 w-max p-2 px-0">
              {batchList.map((batch) => (
                <Link
                  to={`/batch-details/${batch.id}`}
                  key={batch.id}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    transition: "color 0.3s",
                  }}
                >
                  <div
                    key={`batch_` + batch.id}
                    draggable={true}
                    onDragStart={(event) => handleDragStart(event, batch)}
                    // style={{
                    //   backgroundColor: user?.subject[0]?.subject_color_code,
                    // }}
                    className="relative flex flex-col bg-white p-2 rounded-lg shadow-lg w-auto"
                  >
                    <div className="flex items-center">
                      <FaGripVertical className=" left-0 mr-2 w-2 text-gray-400 hover:text-gray-700 cursor-move" />
                      <span className=" text-sm">{batch.batch_code}</span>
                    </div>

                    {/* <div className="w-auto mt-2 flex items-center">
                    <span className="mr-2 text-sm text-primaryColour">
                      {batch.totalSession ? batch.totalSession : 0}/
                      {calculatedValue}
                    </span>
                    <div className="w-full bg-gray-300 rounded-full">
                      <div
                        className="text-sm text-blue-100 text-center p-0.5 leading-none rounded-full"
                        style={{
                          width: `${batch.percentage || 0}%`,
                          fontSize: "1px",
                          backgroundColor: `${batch.bgColor}`,
                        }}
                      >
                        {batch.percentage || 0}%
                      </div>
                    </div>
                  </div> */}
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <button
            onClick={() => scroll("right")}
            className={`m-2 p-2   text-white h-2/5  rounded-md ${
              !canScrollRight ? "bg-gray-400 cursor-not-allowed" : "bg-gray-800"
            }`}
            disabled={!canScrollRight}
          >
            &gt;
          </button>
          <div className=" flex overflow-x-auto flex-col ml-auto scrollbar-hide">
            <p className="flex mb-2 font-bold text-primaryColour ml-auto">
              {!hasSchedule && !ScheduleStatusCheck && (
                <p>Please go ahead with auto-scheduling</p>
              )}
              {hasSchedule &&
                ScheduleStatusCheck &&
                'Schedule is in draft mode. Click "Publish" to finalize'}
              {hasSchedule && !ScheduleStatusCheck && "Schedule is Published."}
            </p>
            <div className="flex ml-auto justify-center   space-x-1 w-max mr-3">
              {subjectList.map((subject, index) => (
                <div
                  key={`subject_` + subject.id}
                  className="flex items-center  p-0.5 rounded-lg w-22 mb-2"
                >
                  <div
                    className=" w-4 h-4 font-serif"
                    style={{
                      backgroundColor: subject?.subject_color_code || "white",
                    }}
                  ></div>
                  <span className="m-2 text-xs">{subject.subject_name}</span>
                </div>
              ))}
            </div>
            {/* <p className="flex mr-2 font-bold text-primaryColour ml-auto">
              Compact View
              <Switch checked={isCompactView} onChange={handleSwitchChange} />
            </p> */}
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          slotTime={slotTime}
          schedules={schedule}
          swapSubmit={handleSuggesionSwap}
        />
        <Popup
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          heading={"Delete Schedule"}
        >
          <h6 className="font-serif">Do you want to delete the Schedule ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900 font-serif  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpen(false)}
            >
              No
            </button>
            <button
              className=" font-serif text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => handleDeleteSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
      </div>
      <div className="relative overflow-x-auto overflow-y-auto height-lesson">
        {batchList?.length == 0 && (
          <div className="flex items-center justify-center h-full w-full">
            No Batches avaiable
          </div>
        )}
        {batchList?.length > 0 && (
          <div className="w-max h-full relative">
            <div className="inline-flex border p-2 pt-0 pl-0 bg-gray-100 relative">
              <div className="flex flex-col sticky top-0 left-0 z-20 bg-secondaryColour">
                <div className="flex flex-col sticky top-0 left-0 z-20 bg-secondaryColour text-white">
                  <div className="w-auto h-auto bg-primaryColour  border-2 border-neutral-300 m-0.5 p-0.5 text-center flex items-center justify-center">
                    Faculty
                  </div>
                  <div
                    className={` ${
                      isCompactView ? "h-12" : "h-auto"
                    } w-auto  p-0.5 text-primaryColour border-2 border-neutral-300 font-semibold text-xs m-0.5 flex items-center justify-center`}
                  >
                    Time
                  </div>
                </div>

                {facultyList.map((faculty) => (
                  <div
                    key={faculty.id}
                    style={{
                      backgroundColor: faculty.color_code || "white",
                    }}
                    className="w-auto h-12 border border-neutral-300 m-0.5 text-primaryColour  flex items-center  truncate"
                  >
                    {/* <img
                      src={
                        process.env.REACT_APP_API_URL +
                        `/faculty/` +
                        faculty.id +
                        `/image`
                      }
                      onError={(e) => {
                        e.target.src = defaultImage;
                      }}
                      className="w-6 h-6 mx-2 rounded-full"
                      alt={`images_` + faculty.id}
                    /> */}
                    <div
                      className="flex items-center justify-center h-8 font-semibold text-xs m-2"
                      data-tooltip-id={`tooltip-fa-${faculty.faculty_code}`}
                    >
                      {faculty.faculty_code} - {faculty.first_name}{" "}
                      {faculty.last_name[0]}.
                    </div>

                    <Tooltip
                      id={`tooltip-fa-${faculty.faculty_code}`}
                      place="right"
                      className="z-10"
                      content={
                        faculty.first_name + " " + faculty.last_name + "."
                      }
                      type="dark"
                      effect="float"
                    />
                  </div>
                ))}
              </div>
              <div className="flex">
                <div className="flex  h-full flex-col">
                  <div className="sticky top-0 z-10 bg-secondaryColour">
                    <div className="flex">
                      {allDetails.map((detail, detailIndex) => (
                        <div key={detailIndex} className="flex flex-col">
                          <div className="w-auto  bg-primaryColour text-white h-auto border-2 border-neutral-300 m-0.5 p-0.5 text-center flex items-center justify-center">
                            {detail.date}
                          </div>
                          <div className="flex text-primaryColour">
                            {detail.displayTimes.map((time, timeIndex) => (
                              <div
                                key={timeIndex}
                                className={`${
                                  isCompactView
                                    ? "w-14  h-12 flex-col"
                                    : "w-32  h-auto"
                                } p-0.5 border-2 border-neutral-300 m-0.5 text-xs flex font-semibold  items-center justify-center`}
                              >
                                <span className="block">
                                  {time["startTime"].format("hh:mm")}&nbsp;
                                  <small>{time["startTime"].format("A")}</small>
                                </span>
                                <span>&nbsp;-&nbsp;</span>
                                <span className="block">
                                  {time["endTime"].format("hh:mm")}&nbsp;
                                  <small>{time["endTime"].format("A")}</small>
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex  h-full overflow-x-auto">
                    {allDetails.map((detail, detailIndex) => (
                      <div key={detailIndex} className="flex flex-col">
                        {detail.lessons.map((lesson, lessonIndex) => (
                          <div
                            key={lessonIndex}
                            className="flex text-primaryColour"
                          >
                            {lesson.map((slot, slotIndex) => (
                              <div
                                key={slotIndex}
                                className={`relative ${
                                  isCompactView ? "w-14" : "w-32"
                                }  ${
                                  slot?.lesson?.error
                                    ? " border-4  border-red-600"
                                    : " border border-neutral-300"
                                }  h-12 text-primaryColour bg-white m-0.5 p-2 flex items-center justify-center`}
                                draggable={
                                  slot?.lesson?.faculty?.id ? true : false
                                }
                                onDragStart={(event) =>
                                  handleDragStart(event, slot, slot.lesson?.id)
                                }
                                data-tooltip-id={`tooltip-${slot?.lesson?.id}`}
                                onDragOver={(event) => handleDragOver(event)}
                                onDrop={(event) => handleDrop(event, slot)}
                              >
                                {slot.lesson.id && isCompactView && (
                                  <Tooltip
                                    id={`tooltip-${slot?.lesson?.id}`}
                                    place="right"
                                    className="z-10"
                                    content={
                                      slot.lesson.faculty.first_name +
                                      " " +
                                      slot.lesson.faculty.last_name[0] +
                                      "."
                                    }
                                    type="dark"
                                    effect="float"
                                  />
                                )}
                                {}

                                {slot?.lesson?.error
                                  ? slot?.lesson?.error.map((message) => (
                                      <Tooltip
                                        id={`tooltip-${slot?.lesson?.id}`}
                                        place="top"
                                        className="z-10"
                                        content={message}
                                        type="dark"
                                        effect="float"
                                      />
                                    ))
                                  : null}

                                {slot?.lesson?.faculty ? (
                                  <span className="w-full flex justify-center">
                                    <span>{slot.lesson.batch.batch_code}</span>
                                  </span>
                                ) : slot.allowToAddLesson ? (
                                  "-"
                                ) : (
                                  ""
                                )}
                                {!slot.lesson.faculty ? (
                                  slot.allowToAddLesson ? (
                                    !dateStatus ? (
                                      <div
                                        onClick={() => toggleSlotModal(slot)}
                                        className="absolute inset-0 flex items-center justify-center bg-primaryColour bg-opacity-100 opacity-0 hover:opacity-100 transition-opacity duration-200"
                                      >
                                        <FaPlus className="text-white" />
                                      </div>
                                    ) : null
                                  ) : null
                                ) : (
                                  <div>
                                    {isSwapSchedule && (
                                      <input
                                        className="absolute top-0 left-0 mt-1 ml-1"
                                        type="checkbox"
                                        checked={selectedCheckboxes.includes(
                                          slot.lesson.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(slot.lesson.id)
                                        }
                                        style={{
                                          width: "13px",
                                          height: "13px",
                                        }}
                                      />
                                    )}
                                    {!dateStatus ? (
                                      <SlotActionMenu
                                        dataItem={slot}
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        handleOpenModal={handleOpenModal}
                                        setDeleteScheduleId={
                                          setDeleteScheduleId
                                        }
                                      />
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {loading && <Loader message={loaderMessage} />}
      {/* <AutoScheduleErrorTable
        hardRuleError={hardRuleError}
        setIsDropdownOpen={setIsDropdownOpen}
        isDropdownOpen={isDropdownOpen}
        // formatDateddmmyy={formatDateddmmyy}
        handleEditErrorClick={handleOpenEditModal}
        // openPopup={openPopup}
        setDeleteScheduleId={setDeleteScheduleId}
        setIsOpen={setIsOpen}
      /> */}
    </div>
  );
};

export default FacultySchedulingPage;
