import React from "react";
import Select from "react-select";

const PopupForm = ({
  watch,
  SubjectCode,
  handleSubjectCode,
  setValue,
  key,
  register,
  isPopupOpen,
  handleSubmit,
  onSubmit,
  SubjectName,
  handleInputChange,
  batchStreamData,
  tempColor,
  handleColorChange,
  cancelPopup,
  errors,

  heading,
  popUpButton,
}) =>
  isPopupOpen && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black  bg-opacity-50" >

      <form key={key} onSubmit={handleSubmit(onSubmit)} className="relative bg-white w-full max-w-lg p-6 rounded shadow-xl">
      <h2 className="text-lg font-semibold ">{heading}</h2>
        <section className="mt-2">
          <hr />

          <div className="ml-5 ">
            <label>Subject Name :</label>
            <div>
              <input
                id="subjectName"
                type="text"
                style={{ height: "32px", outline: "none", width: "23rem" }}
                autoComplete="off"
                placeholder="Subject Name"

                autoFocus
                defaultValue={SubjectName}
                {...register("subjectName", {
                  required: "This is required",
                })}
                onChange={handleInputChange}
                className="border border-gray-400 mt-2 mb-2"
              />
              <div>

               {errors.subjectName && (
                        <span className="validationcolor">
                          {errors.subjectName.message}
                          {" *"}
                        </span>
                      )}
              </div>
            </div>

            <label>Subject Code:</label>

            <div>
              <input
                id="subjectcode"
                type="text"
                style={{ height: "32px", outline: "none", width: "23rem" }}
                autoComplete="off"
                placeholder="Subject Code"

                defaultValue={SubjectCode}
                {...register("subjectCode", {
                  required: "This is required",
                })}
                onChange={handleSubjectCode}
                className="border border-gray-400 mt-2 mb-2"
              />
              <div>

               {errors.subjectCode && (
                        <span className="validationcolor">
                          {errors.subjectCode.message}
                          {" *"}
                        </span>
                      )}
              </div>
            </div>
            <label htmlFor="" className=" ">
              Select Stream :
            </label>
            <div>
              <Select
                options={batchStreamData}
                isMulti
                value={watch("batchStream")}
                {...register("batchStream",  {
                  required: "This is required",
                })}
                onChange={(selectedOptions) => {
                  setValue("batchStream", selectedOptions);
                  // setSelectedLocationId(selectedOptions.id);
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "white",
                    borderRadius: 0,
                    // border: "none",
                    borderColor: "gray", // Set border color to gray

                    outline: "none",
                    boxShadow: "none",
                    height: "30px",
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
              <div>

               {errors.batchStream && (
                        <span className="validationcolor">
                          {errors.batchStream.message}
                          {" *"}
                        </span>
                      )}
              </div>
            </div>

            <div>
              <label htmlFor="">Color :</label>
              <div>
                <input
                  type="color"
                  {...register("colorcode", {
                    required: "This is required",
                  })}
                  value={tempColor}
                  onChange={handleColorChange}
                  style={{ cursor: "pointer", width: "4rem", height: "3rem" }}
                />
              </div>
            </div>
          </div>
          <div className="ml-5 mt-4">
          <button
              type="submit"
              className="text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2"
            >
              {popUpButton ? "Update" : "Create"}
            </button>
            <button
              className="text-gray-900 close-button bg-gray-50 border border-gray-300 hover:bg-gray-100 font-medium text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={cancelPopup}
            >
              Cancel
            </button>


          </div>
        </section>
      </form>
    </div>
  );

export default PopupForm;
