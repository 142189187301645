import { useContext } from "react";
import LocationContext from "../../context/LocationContext";
import { apiService } from "../../Services/Services";
import { FaDownload } from "react-icons/fa";
import Popup from "../Popup";

export const BatchExport = ({ exportType }) => {
  const {
    selectedLocation,
    selectedScheduleType,

    setLoading,

    selectedDate,

    hasSchedule,
    ScheduleStatusCheck,
    setScheduleStatusCheck,
    setLoaderMessage,
    setScheduleRefresh,
    setFacultyHrsUpdate,
    dateStatus,
    setDateStatus,
    setSelectedCheckboxes,
    selectedCheckboxes,
    setIsSwapSchedule,
    isSwapSchedule,
    tableData,
    setTableData,
  } = useContext(LocationContext);
  const exportToExcel = async () => {
    try {
      let apiUrl = `${process.env.REACT_APP_API_URL}/export-batch/${selectedLocation}`;
      const response = await apiService.getFile(apiUrl);

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "exported_sheet.xlsx";

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  return (
    <button
      onClick={exportToExcel}
      className="bg-primaryColour font-serif w-22 h-10 text-white text-sm font-bold p-4 mr-4 inline-flex items-center"
    >
      {" "}
      <FaDownload />
      <span className="  font-serif md:inline-block  ml-1">Export</span>
    </button>
  );
};

export const FacultyExports = ({
  exportType,
  Select,
  locationList,
  selectedExportLocations,
  handleExportLocationsChange,
  setIsOpenExportSchedule,
  ScheduleExporttogglePopup,
  isOpenExportSchedule,
}) => {
  const {
    selectedLocation,
    selectedScheduleType,

    setLoading,

    selectedDate,

    hasSchedule,
    ScheduleStatusCheck,
    setScheduleStatusCheck,
    setLoaderMessage,
    setScheduleRefresh,
    setFacultyHrsUpdate,
    dateStatus,
    setDateStatus,
    setSelectedCheckboxes,
    selectedCheckboxes,
    setIsSwapSchedule,
    isSwapSchedule,
    tableData,
    setTableData,
  } = useContext(LocationContext);
  const exportToExcel = async () => {
    const locationLabels = selectedExportLocations
      .map((option) => option.label)
      .join("-");
    const locationIds =
      selectedExportLocations.map((option) => option.value).join(",") || "";
    setIsOpenExportSchedule(false);

    setLoading(true);
    try {
      let apiUrl = `${process.env.REACT_APP_API_URL}/export-faculty?location_ids=${locationIds}`;
      const response = await apiService.getFile(apiUrl);

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download =
        selectedExportLocations.length === 1
          ? `Faculties-${locationLabels}.xlsx`
          : `Faculties.xlsx`;

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  return (
    <>
      <button
        onClick={() => {
          ScheduleExporttogglePopup();
        }}
        className="bg-primaryColour font-serif w-22 h-10 text-white text-sm font-bold p-4 mr-4 inline-flex items-center"
      >
        {" "}
        <FaDownload />
        <span className="  font-serif md:inline-block  ml-1">Export</span>
      </button>
      <Popup
        isOpen={isOpenExportSchedule}
        onClose={() => setIsOpenExportSchedule(false)}
        heading={"Export Faculties"}
      >
        <div className="flex flex-col m-2 mb-6 ">
          <label
            htmlFor="locationSelect"
            className="mb-2 text-sm font-medium text-gray-700"
          >
            Select Locations:
          </label>
          <Select
            id="locationSelect"
            placeholder={`Select Location`}
            isMulti
            className="w-84 "
            options={locationList}
            closeMenuOnSelect={false} // Prevent menu from closing on select
            hideSelectedOptions={false} // Show selected options with checkboxes
            styles={{
              control: (base) => ({
                ...base,
                width: "30vw", // Fixed width for the select control
              }),
              menu: (base) => ({
                ...base,
                width: "34rem", // Fixed width for the dropdown menu
              }),
            }}
            value={selectedExportLocations}
            onChange={handleExportLocationsChange}
          />
        </div>

        <div className="button-container">
          <button
            className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
            // onClick={() => setIsOpenExportSchedule(false)}
            onClick={() => {
              setIsOpenExportSchedule(false);
            }}
          >
            No
          </button>
          <button
            className=" text-white font-serif bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() => exportToExcel()}
          >
            Yes
          </button>
        </div>
      </Popup>
    </>
  );
};

export const LeaveExports = ({ exportType }) => {
  const {
    selectedLocation,
    selectedScheduleType,

    setLoading,

    selectedDate,

    hasSchedule,
    ScheduleStatusCheck,
    setScheduleStatusCheck,
    setLoaderMessage,
    setScheduleRefresh,
    setFacultyHrsUpdate,
    dateStatus,
    setDateStatus,
    setSelectedCheckboxes,
    selectedCheckboxes,
    setIsSwapSchedule,
    isSwapSchedule,
    tableData,
    setTableData,
    leaveExportDate,
  } = useContext(LocationContext);

  const selectedDateArray = Array.isArray(leaveExportDate)
    ? leaveExportDate
    : [];
  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const startDate = formattedDates[0] || ""; // Ensure there is a default value
  const endDate = formattedDates[1] || "";

  const exportToExcel = async () => {
    try {
      let apiUrl;

      if (selectedLocation === "null") {
        apiUrl = `${process.env.REACT_APP_API_URL}/export-leave?locationId=&start_date=${startDate}&end_date=${endDate}`;
      } else {
        apiUrl = `${process.env.REACT_APP_API_URL}/export-leave?locationId=${selectedLocation}&start_date=${startDate}&end_date=${endDate}`;
      }
      const response = await apiService.getFile(apiUrl);

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "exported_sheet.xlsx";

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  return (
    <button
      onClick={exportToExcel}
      className="bg-primaryColour font-serif w-22 h-10 text-white text-sm font-bold p-4 mr-4 inline-flex items-center"
    >
      {" "}
      <FaDownload />
      <span className="  font-serif md:inline-block  ml-1">Export</span>
    </button>
  );
};
