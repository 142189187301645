


import { IoIosPeople } from "react-icons/io";
import { MdDashboardCustomize, MdOutlineLogout } from "react-icons/md";
import { MdOutlineCalendarViewWeek, MdOutlineSchedule } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";
import { FiLogOut } from "react-icons/fi";
import { MdOutlinePeople } from "react-icons/md";

import { NavLink, useLocation } from "react-router-dom";
import { useContext, useEffect, useState, } from "react";
import "./Sidebar.css";
import logo from "./../../utils/Images/allen_logo.jpeg";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../Services/Services";
import LocationContext from "../../context/LocationContext";
import { FaTasks } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import Breadcrumbs from "../../breadcrumbs";
import { FaHistory } from 'react-icons/fa';

const Sidebar = () => {
  const { activeMenuStatus } = useContext(LocationContext);



  const navigate = useNavigate();
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState("");

  useEffect(() => {
    const storedActiveMenu = localStorage.getItem("activeMenu");
    if (storedActiveMenu) {
      setActiveMenu(storedActiveMenu);
    }
  }, []);

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location]);


  const isMenuActive = (menu) => {
    if (menu.children) {


      return activeMenu === menu.link || menu.children.some((child) => activeMenu === child.link);
    } else {
      return activeMenu === menu.link;

    }
  };

  const handleLogout = async () => {
    try {
      await apiService.post(`${process.env.REACT_APP_API_URL}/logout`);

      localStorage.removeItem("access_token");
      localStorage.removeItem("activeMenu");
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const Menus = [
    { title: "Dashboard", icon: <MdDashboardCustomize />, link: "/dashboard" },
    { title: "Schedule", icon: <MdOutlineSchedule />, link: "/" },
    {
      title: "Faculty",
      icon: <GiTeacher />,
      link: "/facultylist",
      children: [
        { link: "/facultycreate" },
        { title: "Faculty View", link: `/facultyview/${activeMenuStatus}` },
        { title: "Faculty Edit", link: `/facultyformedit/${activeMenuStatus}` }
      ]
    },
    {
      title: "Batches",
      icon: <IoIosPeople />,
      link: "/batchlist",
      children: [
        { link: "/batchcreate" },
        { title: "Batch Edit", link: `/batchformedit/${activeMenuStatus}` }
      ]
    },

    {
      title: "Leaves",
      icon: <MdOutlineCalendarViewWeek />,
      link: "/LeaveList",
      children: [
        { link: "/leavecreate" },
        { title: "leavedit", link: `/leaveEdit/${activeMenuStatus}` }
      ]
    },
    { title: "Users", icon: <MdOutlinePeople />, link: "/user", children: [
      { link: "/createUser" },
      { title: "UserEdit", link: `/userEdit/${activeMenuStatus}` }
    ] },
    {
      title: "LogHistory",
      icon: <FaHistory  />,
      link: "/loghistory",
      // children: [
      //   { link: "/batchcreate" },
      //   { title: "Batch Edit", link: `/batchformedit/${activeMenuStatus}` }
      // ]
    },

    { title: "Management", icon: <FaTasks />, link: "/management" },

  ];

  return (
    <div className="fixed  shadow-lg h-screen flex flex-col items-center font-serif  z-30">
      <div className="flex items-center  p-1 mb-8">
        <img
          src={logo}
          className="bg-slate-200 text-6xl rounded-full h-12 font-serif"
          alt="Logo"
        />
      </div>
      {/* <div className="sidebar overflow-y-auto" >
      {Menus.map((item, index) => (
        <div className="sidebar-item" key={index} data-tooltip-id={`tooltip-${index}`} data-tooltip-content={item.title}>
          {item.icon}
        </div>
      ))}
      {Menus.map((item, index) => (
        <Tooltip id={`tooltip-${index}`} key={`tooltip-${index}`} place="right" type="dark" effect="solid">
          {item.title}
        </Tooltip>
      ))}
    </div> */}
      <ul className="flex w-full flex-col items-center overflow-y-auto sidebar-menu">
        {Menus.map((menu, index) => (
          // <NavLink
          //   to={menu.link}
          //   className={`sidebar-item text-white text-lg flex  justify-center items-center cursor-pointer relative h-14	 w-full group hover:text-white focus:text-white `}
          //   key={index} data-tooltip-id={`tooltip-${index}`} data-tooltip-content={menu.title}
          // >
          //   <span className={`text-2xl `}>
          //     {menu.icon}
          //   </span>
          // </NavLink>
          <NavLink
  to={menu.link}
  className={`sidebar-item text-primaryColour text-lg flex  justify-center items-center cursor-pointer relative h-14 w-full group hover:text-white focus:text-white hover:bg-primaryColour ${
    isMenuActive(menu) ? "active" : ""
  }`}
  key={index}
  data-tooltip-id={`tooltip-${index}`}
  data-tooltip-content={menu.title}
>
  <span className={`text-2xl `}>
    {menu.icon}
  </span>
</NavLink>
        ))}
        {Menus.map((item, index) => (
          <Tooltip id={`tooltip-${index}`} key={`tooltip-${index}`} place="right" type="dark" effect="solid">
            {item.title}
          </Tooltip>
        ))}
        <span
          className={` text-lg flex rounded justify-center items-center cursor-pointer relative h-14	 w-full group hover:text-white hover:bg-primaryColour`}
          data-tooltip-id={`tooltip-logout`} data-tooltip-content="Logout"
        >
          <span className={`text-2xl`} onClick={handleLogout}>
            <MdOutlineLogout />
          </span>
        </span>
        <Tooltip id={`tooltip-logout`} key={`tooltip-logout`} place="right" type="dark" effect="solid">
          Logout
        </Tooltip>
      </ul>
    </div>
  );
};

export default Sidebar;
