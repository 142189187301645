import React, { useContext, useEffect, useState, useRef } from "react";
import LocationContext from "../../context/LocationContext";
import { DateRangePicker } from "rsuite";
import { useForm } from "react-hook-form";
import { apiService, getApiService } from "../../Services/Services";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import { Link, useNavigate, useParams } from "react-router-dom";
import TableHeader from "../../components/TableHeader/TableHeader";
import moment from "moment";

const LeaveEdit = ({
  setIsModalOpenEditModel,
  leaveApiData,
  UpdateId,
  setILeaveUpdateId,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [slotValidation, setSlotValidation] = useState("");

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const fetchBatchSlots = async () => {
      try {
        const response = await apiService.get(
          `${process.env.REACT_APP_API_URL}/getBatchslot`
        );
        const data = response;

        // Extract morning and afternoon IDs from the response data
        const morningId =
          data.find((slot) => slot.name === "Morning")?.id || "";
        const afternoonId =
          data.find((slot) => slot.name === "Afternoon")?.id || "";

        // Set options with morning and afternoon IDs
        setOptions([
          { value: morningId, label: "Morning" },
          { value: afternoonId, label: "Afternoon" },
        ]);
      } catch (error) {
        console.error("Error fetching batch slots:", error);
      }
    };

    fetchBatchSlots();
  }, []);

  const handleOptionChange = (value) => {
    setSelectedOptions(value);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedLocation, setActiveMenuStatus } = useContext(LocationContext);
  setActiveMenuStatus(UpdateId);

  const [date, setDate] = useState("");

  const leaveDataUrl = `  ${process.env.REACT_APP_API_URL}/leaveGetById/${UpdateId}
  `;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveData, setLeaveData] = useState([]);
  const fetchLeaveData = async () => {
    try {
      const response = await apiService.get(leaveDataUrl);
      if (response && response.data) {
        setLeaveData(response);

        if (response.data.dates && response.data.dates.length > 0) {
          if (response.data.dates.length > 1) {
            const startDate = response.data.dates[0];
            const endDate = response.data.dates[response.data.dates.length - 1];

            setStartDate(startDate);
            setEndDate(endDate);
          } else {
            const leaveDate = response.data.dates[0];
            setDate(leaveDate);
          }
        } else {
          console.log("No leave data available");
        }
      }
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  const getDataOfFacultyById = async () => {
    if (UpdateId) {
      fetchLeaveData();
    }
  };

  const styles = {
    width: 450,
    display: "block",
    height: "35px",
    outline: "none",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  //
  const [facultyData, setFacultyData] = useState([]);
  const facultyURL = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=1&gender=&sortBy=updated_at&sortOrder=DESC&location_id=`;
  const getFacutyData = async () => {
    const Response = await apiService.get(facultyURL);
    if (Response) {
      try {
        const mappingResponse = Response.map((response) => {
          // const locationNames = response.location.map(loc => loc.name).join(', ');

          return {
            // label: (
            //   <>
            //     {response.first_name + " " + response.last_name} ({locationNames})
            //   </>
            // ),
            label: [
              response.faculty_code +
                " - " +
                response.first_name +
                " " +
                response.last_name +
                response.subject
                  .map((subject) => ` (${subject.subject_name})`)
                  .join(", "),
            ],
            value: response.faculty_code,
            id: response.id,
          };
        });
        setFacultyData(mappingResponse);
      } catch (error) {
        return error;
      }
    }
  };

  const onSubmit = async (data) => {
    if (selectedOptions.length <= 0) {
      setSlotValidation(
        "Please select at least one option: 'Full Day', 'Morning', or 'Afternoon'."
      );
      return;
    }

    const dates = getAllDatesBetween(data.dateRange[0], data.dateRange[1]);

    const postData = {
      faculty_id: data.faculyData.id,
      dates: dates,
      batch_slot_name: selectedOptions,
    };
    try {
      const response = await apiService.patch(
        `${process.env.REACT_APP_API_URL}/updateLeave/${UpdateId}`,

        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Record Successfully Updated.. ", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
      });
      setSlotValidation("");
      setIsModalOpenEditModel(false);
      setILeaveUpdateId("");
      leaveApiData();
    } catch (error) {
      toast.error(error.response.data.error, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
      });
    }
  };

  useEffect(() => {
    if (leaveData && leaveData.data) {
      const slotName =
        leaveData.data?.batch_slot?.name || leaveData.data?.batch_slot_name;
      setSelectedOptions(slotName);
    }
  }, [leaveData]);

  useEffect(() => {
    getFacutyData();
  }, [selectedLocation]);

  useEffect(() => {
    const fetchData = async () => {
      await getDataOfFacultyById();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (facultyData.length > 0 && leaveData.data && leaveData.data.faculty_id) {
      const selectedFaculty = facultyData.find(
        (faculty) => faculty.id === leaveData.data.faculty_id
      );

      if (selectedFaculty) {
        setValue("faculyData", selectedFaculty);
      }
    }
  }, [facultyData, leaveData]);

  const formatDateToString = (dateString) => {
    const dateObject = new Date(dateString);

    if (!isNaN(dateObject.getTime())) {
      const formattedDateString = dateObject.toISOString().split("T")[0];
      return formattedDateString;
    } else {
      return "";
    }
  };

  const formattedDate = formatDateToString(date);
  const formattedStartDate = formatDateToString(startDate);
  const formattedEndDate = formatDateToString(endDate);

  useEffect(() => {
    if (formattedDate) {
      const dateStrings = [formattedDate, formattedDate];
      const dateObjects = dateStrings.map((dateString) => new Date(dateString));
      setValue("dateRange", dateObjects);
    } else if (formattedStartDate && formattedEndDate) {
      const dateStrings = [formattedStartDate, formattedEndDate];
      const dateObjects = dateStrings.map((dateString) => new Date(dateString));
      setValue("dateRange", dateObjects);
    } else {
      console.error(" formattedEndDate is empty or invalid.");
    }
  }, [formattedDate, formattedStartDate, formattedEndDate]);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset the time part for accurate comparison

  const disablePastDates = (date) => {
    return date < today;
  };
  const getAllDatesBetween = (startDate, endDate) => {
    let dates = [];
    let currentDate = moment(startDate).startOf("day");
    let end = moment(endDate).endOf("day");

    while (currentDate <= end) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "day");
    }

    return dates;
  };
  const dateRangePickerContainerRef = useRef(null);

  useEffect(() => {
    if (dateRangePickerContainerRef.current) {
      const inputElement =
        dateRangePickerContainerRef.current.querySelector("input");
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, []);
  return (
    <>
      <div className="  ">
        <>
          <ToastContainer />
        </>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className=" text-center">Update Leave</h4>

            <div>
              <div className="   ml-2 pt-5   ">
                <div className="  ">
                  <label
                    className=" float-left mb-2"
                    style={{ fontSize: "16px" }}
                  >
                    <span className="text-red-600">*</span>
                    Date:
                  </label>
                  <div
                    className="relative flex-grow "
                    ref={dateRangePickerContainerRef}
                  >
                    <DateRangePicker
                      showOneCalendar
                      // size="md"
                      value={watch("dateRange")}
                      placeholder="Calender"
                      format="dd/MM/yyyy" // Specify the date format here
                      {...register("dateRange", {
                        required: "This is required",
                        validate: (value) =>
                          (value && value[0] !== null && value[1] !== null) ||
                          "Please select a date range",
                      })}
                      isoWeek
                      onChange={(selectedOptions) => {
                        if (selectedOptions && selectedOptions.length >= 2) {
                          setValue("dateRange", selectedOptions);

                          const dates = getAllDatesBetween(
                            selectedOptions[0],
                            selectedOptions[1]
                          );
                        } else {
                          console.error(
                            "Invalid selectedDates:",
                            selectedOptions
                          );
                        }
                      }}
                      style={styles}
                      disabledDate={disablePastDates}
                    />
                  </div>
                  {errors.dateRange && (
                    <p className="error validationcolor">
                      {errors.dateRange.message}
                      {"*"}
                    </p>
                  )}
                </div>

                <div
                  className="  col-span-6  mt-10 "
                  style={{ width: "445px", cursor: "not-allowed" }}
                >
                  <label className=" float-left  " style={{ fontSize: "16px" }}>
                    <span className="text-red-600">*</span>
                    Faculty:
                  </label>
                  <br />

                  <div>
                    <Select
                      placeholder="select Faculty"
                      options={facultyData}
                      // isMulti
                      style={{
                        height: "35px",
                        borderRadius: "6px",
                      }}
                      {...register("faculyData", { required: true })}
                      onChange={(selectedOptions) => {
                        setValue("faculyData", selectedOptions);
                      }}
                      value={watch("faculyData")}
                      className="custom-select mt-2"
                      isDisabled={true} // This will disable the Select component
                      classNamePrefix="custom-select"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "white",
                          borderRadius: 0,
                          cursor: state.isDisabled ? "pointer" : "not-allowed", // Customize cursor

                          boxShadow: "none",
                          borderRadius: "6px",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />
                  </div>
                  <div>
                    {errors.faculyData && (
                      <p className="error validationcolor font-serif">
                        This is required {"*"}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-6 mt-3" style={{ width: "30vw" }}>
                  <div className="flex items-center">
                    <label className=" float-left" style={{ fontSize: "16px" }}>
                      <span className=" text-red-600 font-bold text-lg font-serif">
                        *
                      </span>
                      Select Slots:
                    </label>
                  </div>

                  <div className="flex  mt-2">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        // {...register(
                        //   "FullDay"

                        // )}
                        checked={selectedOptions.includes("FULLDAY")}
                        onChange={() => handleOptionChange("FULLDAY")}
                        className="  border border-gray-400 rounded-full  text-primary"
                      />
                      <span className="ml-2">Full Day</span>
                    </div>
                    {options.map((option) => (
                      <div key={option.value} className="flex items-center">
                        <input
                          type="checkbox"
                          value={option.value}
                          {...register("selectSlots")}
                          checked={selectedOptions.includes(option.label)}
                          onChange={() => handleOptionChange(option.label)}
                          // disabled
                          className=" text-primary border border-gray-400 rounded-full text-primary ml-4 "
                        />
                        <span className="ml-2 text-gray-500">
                          {option.label}
                        </span>
                      </div>
                    ))}
                  </div>

                  <div className="mt-2">
                    <p className=" text-red-600 ">{slotValidation}</p>
                  </div>
                </div>
              </div>

              <div className=" col-span-6 ml-2 flex mt-8 ">
                <div>
                  <button
                    className=" text-white bg-primaryColour hover:bg-primaryColour-1000  font-medium font-serif  text-sm px-5 py-2.5 me-2 "
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                </div>
                {/* <Link to={"/LeaveList"} style={{ color: "white" }}> */}
                <button
                  onClick={() => {
                    setIsModalOpenEditModel(false);
                    setILeaveUpdateId(""); // Reset the state variable
                  }}
                  className="text-gray-900   bg-gray-50 border border-gray-300  font-seriffocus:outline-none hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                >
                  Cancel
                </button>
                {/* </Link>{" "} */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default LeaveEdit;
