import React, { useState } from "react";
import "./popup.css";

const Popup = ({ isOpen, heading, onClose, children }) => {
  const closePopup = () => {
    onClose();
  };
  if (!isOpen) return null;

  return (
    <div className="popup-overlay z-50 bg-gray-900 bg-opacity-50">
      <div className="popup">
        <div className="header bg-primaryColour">
          <h3
            className=" text-lg bg-primaryColour text-white "
            style={{
              margin: "auto",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {heading}
          </h3>
          <button className="close-btn font-serif"  autoFocus onClick={closePopup}>
            X
          </button>
        </div>
        <div className="content font-serif ">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
