import { useEffect, useRef, useState , useContext} from "react";
import {
  FaBook,
  FaEllipsisV,
  FaInfo,
  FaInfoCircle,
  FaPlus,
} from "react-icons/fa";
import { IoIosFlag } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import defaultImage from "../../utils/Images/defaultImage.jpg";
import Popover from "../../components/popover/popover";
import RoundedProgressBar from "../../components/RoundedProgressBar";
import LocationContext from "../../context/LocationContext";
import moment from "moment";

const SlotActionMenu = ({
  dataItem,
  isOpen,
  setIsOpen,
  handleOpenModal,
  setDeleteScheduleId,
}) => {
  const [isActionOpen, setIsActionOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsActionOpen(!isActionOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsActionOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleOpenPopup = (id) => {
    setDeleteScheduleId(id);
    setIsOpen(true);
  };

  return (
    <>
      {/* <div className="absolute top-0 left-0  ml-1">
      <input
            type="checkbox"
            className="h-[14px] w-[14px] border border-gray-300 rounded-sm checked:bg-blue-600 checked:border-transparent focus:outline-none"
          />
      </div> */}
      <div className="absolute top-0 right-0 mt-1" ref={menuRef}>
        <div className="flex flex-col  ">
          <button
            onClick={toggleMenu}
            className="rounded-full hover:bg-gray-200 focus:outline-none"
          >
            {" "}
            <FaEllipsisV className="text-xs" />
          </button>
        </div>
        {isActionOpen && (
          <div className="absolute  z-[23] left-0 mt-2 w-28 bg-white border  border-gray-200 rounded-md shadow-lg z-10">
            <div className="py-1">
              <button
                className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                onClick={() => handleOpenModal(dataItem)}
              >
                Edit
              </button>
              <button
                onClick={() =>
                  handleOpenPopup(
                    dataItem.lesson.id,
                    dataItem.lesson.faculty.first_name +
                      " " +
                      dataItem.lesson.faculty.last_name
                  )
                }
                className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const ScheduleCard = ({
  type,
  isCompactView,
  batchTypeList,
  allDetails,
  handleDragStart,
  handleDragOver,
  handleDrop,
  hardRuleError,
  dateStatus,
  toggleSlotModal,
  isSwapSchedule,
  selectedCheckboxes,
  handleCheckboxChange,
  isOpen,
  setIsOpen,
  handleOpenModal,
  setDeleteScheduleId,
}) => {
  const {  selectedDate} =  useContext(LocationContext);
  return (
    <div className="inline-flex border p-2 pt-0 pl-0 bg-gray-100 relative">
      {batchTypeList && batchTypeList?.batch?.[type]?.length > 0 && (
        <>
          <div className="flex flex-col space-y-0.5  sticky top-0 left-0 z-20 bg-secondaryColour">
            <div className="flex flex-col sticky top-0 left-0 z-20 bg-secondaryColour text-white">
              <div className={ `${allDetails[0]?.extra?.[type]?.length == 0 && allDetails[0][type + "Lessons"].displayTimes?.length <3 ? allDetails[0][type + "Lessons"].displayTimes?.length == 2 ?'h-12':'h-6':' h-auto' } w-auto  bg-primaryColour  border-2 border-neutral-300 p-0.5 text-center flex items-center justify-center`}>
                Batch
              </div>
              <div
                className={` ${
                  isCompactView ? "h-auto" : "h-auto"
                } w-auto  p-0.5 text-primaryColour border-2 border-neutral-300 font-semibold  text-xs flex items-center justify-center`}
              >
                {/* <img src={morning} className="w-8 h-8" alt="morning"/> */}
                {type}
              </div>
            </div>

            {batchTypeList &&
              batchTypeList?.batch?.[type]?.map((batch) => (
                <div className={`w-32  ${type =='special' ? 'h-20':'h-12'}  border border-neutral-300 pl-1 text-xs text-primaryColour  flex items-center`}>
                  <a
                    href={`/batch-details/${batch.id}?date=${moment(selectedDate[0]).format('YYYY-MM-DD')}`}
                    target="_blank"
                    className=" text-primaryColour"
                    key={batch.batch_code}
                    rel="noreferrer"
                  >
                    {batch.batch_code}
                  </a>
                  <Popover
                    className={"ml-auto mr-1"}
                    content={
                      <>
                        {batch &&
                          batch?.subject_details?.map((subject) => (
                            <p>
                              {subject?.subject_name}
                              <RoundedProgressBar
                                completedSessions={subject.completed_sessions}
                                totalSessions={subject.total_session_of_year}
                              />
                            </p>
                          ))}
                      </>
                    }
                  >
                    <FaInfoCircle size={14} />
                  </Popover>
                </div>
              ))}
          </div>
          <div className="flex">
            <div className="flex  h-full flex-col ">
              <div className="sticky top-0 z-10 bg-secondaryColour">
                <div className="flex  space-x-1">
                  {allDetails?.map((detail, detailIndex) => (
                    <div
                      key={"DTL_A1_" + detailIndex}
                      className="flex flex-col  space-y-0.5"
                    >
                      <div className={`${detail?.extra?.[type]?.length == 0 && detail[type + "Lessons"].displayTimes?.length <3 ? detail[type + "Lessons"].displayTimes?.length == 2 ?'w-32':'w-16':' w-auto' } bg-primaryColour text-white h-auto border-2 border-neutral-300 p-0.5 text-center flex items-center justify-center`}>
                        {detail.date}
                      </div>
                      <div className="flex text-primaryColour  space-x-0.5 ">
                        {/* {detail.isHoliday &&(
                          <div className="flex justify-center items-center mr-2 ml-1" style={{width: `${detail?.css?.width}rem`, height: "100%"}}></div>
                        )} */}
                        {detail[type + "Lessons"].displayTimes.map(
                          (time, timeIndex) => (
                            <div
                              key={"SPEC_SLOT_A1_" + timeIndex}
                              className={`${
                                isCompactView
                                  ? "w-16  h-auto flex-col"
                                  : "w-32  h-auto"
                              } p-0.5 border-2 font-semibold border-neutral-300  text-xs flex  items-center justify-center`}
                            >
                              {type !== "special" ? (
                                isCompactView ? (
                                  <span
                                    className="block"
                                    data-tooltip-id={`tooltip-M-${detail.date}-${time["startTime"]}`}
                                  >
                                    {time["startTime"].format("hh:mm A")}
                                  </span>
                                ) : (
                                  <span className="block">
                                    {time["startTime"].format("hh:mm A")}-
                                    {time["endTime"].format("hh:mm A")}
                                  </span>
                                )
                              ) : (
                                <span className="text-neutral-300"> - </span>
                              )}
                              <Tooltip
                                id={`tooltip-M-${detail.date}-${time["startTime"]}`}
                                place="right"
                                className="z-10"
                                content={
                                  time["startTime"].format("hh:mm A") +
                                  " " +
                                  time["endTime"].format("hh:mm A")
                                }
                                type="dark"
                                effect="float"
                              />
                            </div>
                          )
                        )}
                        {detail?.extra?.[type]?.length > 0 &&
                          detail?.extra?.[type]?.map(() => (
                            <div
                              className={`relative ${
                                isCompactView ? "w-16  h-auto" : "w-32  h-auto"
                              } border border-neutral-300 text-primaryColour  flex items-center justify-center`}
                            >
                              <div></div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex space-x-1">
                  {allDetails &&
                    allDetails?.map((detail, detailIndex) => (
                      <div
                        key={"DTL_A2_" + detailIndex}
                        className="flex flex-col space-y-0.5"
                      >
                        {/* {detail.isHoliday && detail?.css?.width &&(
                            <div className="flex justify-center items-center mx-4 ml-1" style={{width: `${detail?.css?.width}rem`, height: "100%"}}>Holiday</div>
                          )} */}
                        {detail?.[type + "Lessons"].lessons.map(
                          (lesson, lessonIndex) => (
                            <div
                              key={
                                "SPEC_SLOT_A2_" +
                                detailIndex +
                                "_" +
                                lessonIndex
                              }
                              className="flex text-primaryColour  space-x-0.5"
                            >
                              {lesson.map((slot, slotIndex) => (
                                <div className="flex flex-col">
                                  {type == "special" && (
                                    <div
                                      className={`relative  ${
                                        isCompactView ? "w-16" : "w-32"
                                      } border h-8 font-semibold  bg-secondaryColour border-neutral-300 text-primaryColour  flex items-center justify-center`}
                                    >
                                      {
                                        slot?.lesson?.id ? (
                                          <><span className="text-xs"
                                            data-tooltip-id={`tooltip-M-${slot?.lesson?.id}`}
                                          >
                                            {moment(
                                              slot?.lesson?.slot_time?.split("-")[0]
                                            ,'HH:mm')?.format("hh:mm A")}
                                          </span><Tooltip
                                              id={`tooltip-M-${slot?.lesson?.id}`}
                                              place="right"
                                              className="z-10"
                                              content={moment(
                                                slot?.lesson?.slot_time?.split(
                                                  "-"
                                                )[0]
                                                ,'HH:mm')?.format("hh:mm A") +
                                                " - " +
                                                moment(
                                                  slot?.lesson?.slot_time?.split(
                                                    "-")[1]
                                                    ,'HH:mm')?.format("hh:mm A")}
                                              type="dark"
                                              effect="float" /></>
                                        ):(
                                          <></>
                                        )
                                      }
                                    
                                    </div>
                                  )}
                                  <div
                                    key={
                                      "B2_" +
                                      detailIndex +
                                      "_" +
                                      lessonIndex +
                                      "_" +
                                      slotIndex
                                    }
                                    className={`relative ${
                                      isCompactView ? "w-16" : "w-32"
                                    } ${slot?.lesson?.id ? "border-l-8" : ""} ${
                                      slot?.lesson?.displayError
                                        ? " border  border-red-600"
                                        : " border border-neutral-300"
                                    }  h-12  flex items-center justify-center`}
                                    style={{
                                      backgroundColor: slot.allowToAddLesson
                                        ? "white"
                                        : "",
                                      borderColor:
                                        slot.lesson?.faculty?.color_code,
                                    }}
                                    draggable={
                                      slot?.lesson?.faculty?.id ? true : false
                                    }
                                    onDragStart={(event) =>
                                      handleDragStart(
                                        event,
                                        slot.lesson?.faculty?.id,
                                        slot.lesson?.subject?.id,
                                        slot.lesson?.id
                                      )
                                    }
                                    data-tooltip-id={`tooltip-${slot?.lesson?.id}`}
                                    onDragOver={(event) =>
                                      handleDragOver(event)
                                    }
                                    onDrop={(event) => handleDrop(event, slot)}
                                  >
                                    {slot?.lesson?.displayError && (
                                      <IoIosFlag
                                        data-tooltip-id={`err-tooltip-${slot?.lesson?.id}`}
                                        style={{
                                          color:
                                            hardRuleError.length > 0
                                              ? "red"
                                              : "green",
                                        }}
                                        className="text-md absolute top-0 left-0 "
                                      />
                                    )}
                                    {slot.isHoliday && slot?.lesson?.id && (
                                      <IoIosFlag
                                        data-tooltip-id={`holiday-tooltip-${slot?.lesson?.id}`}
                                        style={{
                                          color:
                                            hardRuleError.length > 0
                                              ? "orange"
                                              : "",
                                        }}
                                        className="text-md absolute bottom-0 left-0 "
                                      />
                                    )}
                                    {slot.lesson.id && isCompactView && (
                                      <Tooltip
                                        id={`tooltip-${slot?.lesson?.id}`}
                                        place="right"
                                        className="z-10"
                                        content={
                                          slot.lesson.faculty.first_name +
                                          " " +
                                          slot.lesson.faculty.last_name[0] +
                                          "."
                                        }
                                        type="dark"
                                        effect="float"
                                      />
                                    )}

                                    {slot?.lesson?.displayError
                                      ? slot?.lesson?.error?.map((message) => (
                                          <Tooltip
                                            id={`err-tooltip-${slot?.lesson?.id}`}
                                            place="top"
                                            className="z-10"
                                            content={message}
                                            type="dark"
                                            effect="float"
                                          />
                                        ))
                                      : null}
                                    {slot.isHoliday && slot?.lesson?.id && (
                                      <Tooltip
                                        id={`holiday-tooltip-${slot?.lesson?.id}`}
                                        place="top"
                                        className="z-10"
                                        content={"Holiday"}
                                        type="dark"
                                        effect="float"
                                      />
                                    )}
                                    {slot?.lesson?.faculty ? (
                                      isCompactView ? (
                                        <div className="mix-blend-luminosity font-bold">
                                          <a
                                            href={`/facultyview/${slot.lesson.faculty.id}?date=${moment(selectedDate[0]).format('YYYY-MM-DD')}`}
                                            target="_blank"
                                            className=" text-primaryColour"
                                            key={slot.lesson.faculty.faculty_code}
                                            rel="noreferrer"
                                          >
                                            {slot.lesson.faculty.faculty_code}{" "}
                                          </a>
                                        </div>
                                      ) : (
                                        <span className="w-full flex justify-center">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_URL +
                                              `/faculty/` +
                                              slot.lesson.faculty.id +
                                              `/image`
                                            }
                                            onError={(e) => {
                                              e.target.src = defaultImage;
                                            }}
                                            className="h-8 w-8 rounded-full"
                                            alt={
                                              slot.lesson.faculty.first_name +
                                              " " +
                                              slot.lesson.faculty.last_name[0] +
                                              "."
                                            }
                                          />
                                          <span className="mt-1.5 mix-blend-luminosity font-bold">
                                            <a
                                              href={`/facultyview/${slot.lesson.faculty.id}?date=${moment(selectedDate[0]).format('YYYY-MM-DD')}`}
                                              target="_blank"
                                              className=" text-primaryColour"
                                              key={slot.lesson.faculty.faculty_code}
                                              rel="noreferrer"
                                            >
                                              {slot.lesson.faculty.faculty_code}{" "}
                                            </a>
                                          </span>
                                        </span>
                                      )
                                    ) : slot.isHoliday ? (
                                      <p className="text-gray-400">Holiday</p>
                                    ) : (
                                      "-"
                                    )}
                                    {!slot.lesson.faculty ? (
                                      !dateStatus ? (
                                        <div
                                          onClick={() => toggleSlotModal(slot)}
                                          className="absolute inset-0 flex items-center justify-center bg-primaryColour bg-opacity-100 opacity-0 hover:opacity-100 transition-opacity duration-200"
                                        >
                                          <FaPlus className="text-white" />
                                        </div>
                                      ) : null
                                    ) : (
                                      <div>
                                        {isSwapSchedule && (
                                          <input
                                            className="absolute top-0 left-0 mt-1 ml-1"
                                            type="checkbox"
                                            checked={selectedCheckboxes.includes(
                                              slot.lesson.id
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(
                                                slot.lesson.id
                                              )
                                            }
                                            style={{
                                              width: "13px",
                                              height: "13px",
                                            }}
                                          />
                                        )}
                                        {!dateStatus ? (
                                          <SlotActionMenu
                                            dataItem={slot}
                                            isOpen={isOpen}
                                            setIsOpen={setIsOpen}
                                            handleOpenModal={handleOpenModal}
                                            setDeleteScheduleId={
                                              setDeleteScheduleId
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                              {detail?.extra?.[type]?.length > 0 &&
                                detail?.extra?.[type]?.map(() => (
                                  <div
                                    className={`relative  ${
                                      isCompactView ? "w-16" : "w-32"
                                    } border border-neutral-300 text-primaryColour  flex items-center justify-center`}
                                  >
                                    <div></div>
                                  </div>
                                ))}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
