import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; 

function Accordion({ items, defaultOpenIndex, isPopupOpen }) {
  const [openIndex, setOpenIndex] = useState(defaultOpenIndex);

  const toggleSection = (index) => {
    setOpenIndex(openIndex === index ? null : index);
    isPopupOpen(false);
  
  };

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} style={{ padding:"20px", }} >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              // backgroundColor: index === openIndex ? '#f0f0f0' : 'transparent',
            }}
            onClick={() => toggleSection(index)}
          >
            <h4 style={{ marginRight: '10px' }}>{item.title}</h4>
            {index === openIndex ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {index === openIndex && (
            <div style={{ marginTop: '5px' }}>
              <p>{item.content}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Accordion;
