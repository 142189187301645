import { useContext, useEffect } from "react";
import LocationContext from "../../../context/LocationContext";
import { useSearchParams } from "react-router-dom";



const ScheduleType =()=>{
  const [searchParams, setSearchParams] = useSearchParams(); 

    const {
      selectedScheduleType,
      setselectedScheduleType,
    } = useContext(LocationContext);
    const batchType = searchParams.get('batchtype');

    const handleSelectChange = (event) => {
        //setselectedScheduleType(event.target.value);
        setSearchParams({
          ...Object.fromEntries(searchParams), // Spread the existing search params
          batchtype: event.target.value,
        });

      };
      useEffect(() => {
        if(batchType){
          setselectedScheduleType(batchType);
        }
      }, [batchType, setselectedScheduleType])
    return (
        <div className=" font-serif">
        <div>
          <select
                className="form-select px-4 bg-white border border-black rounded shadow-lg leading-4	 "
            placeholder="Select Schedule Type"
            value={selectedScheduleType}
            style={{ width: "14rem", height: "2.25rem" }}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              Select Schedule Type
            </option>
            <option value="jee/medical">JEE/Medical</option>
            <option value="jee">JEE</option>
            <option value="medical">Medical</option>
          </select>
        </div>
      </div>
    );
};
export default ScheduleType;