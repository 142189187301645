import React from "react";
import "./loader.css";

const Loader = ({ message, percentage = 0 }) => {
  return (
    <>
      <div className="loader-overlay  ">
        <div className=" mt-64  ">
          <div className="loader-container  ">
            <div className="loader font-serif"></div>
          </div>
          <div className="loader-message font-serif">
            {message}

            {percentage>0 && (<div className="w-full bg-white rounded-full">
              <div
                className="text-sm text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{
                  width: `${percentage > 100 ? 100 : percentage || 0}%`,
                  fontSize: "14px",
                  backgroundColor: `green`,
                }}
              >
                {percentage}%
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
