import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { endOfDay, sub, subDays } from "date-fns";
import { Link, useParams, useSearchParams } from "react-router-dom";
import LocationContext from "../../../context/LocationContext";
import TableHeader from "../../../components/TableHeader/TableHeader";
import defaultImage from "../../../utils/Images/defaultImage.jpg";
import { apiService } from "../../../Services/Services";
import "./batchView.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DatePicker } from "rsuite";
import RoundedProgressBar from "../../../components/RoundedProgressBar";
import CustomEventBatchView from "./CustomEventBatchView";
import Popup from "../../../components/Popup";
import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import { Modal } from "../../../components/Modal/Modal";
import { checkScheduleValidation } from "../../../Services/CommonFucntion";
import Loader from "../../../components/Loader";

moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

const localizer = momentLocalizer(moment);
const BatchView = () => {
  const { id } = useParams();
  const [scheduleEditId, setScheduleEditId] = useState("");
  const [scheduleDeleteId, setScheduleDeleteId] = useState("");
  const [scheduleDeleteisOpen, setScheduleDeleteisOpen] = useState(false);
  const [menuOpenId, setMenuOpenId] = useState(null);

  const batchId = id;
  const {
    selectedFacultyCalenderDate,
    setSelectedFacultyCalenderDate,
    modalOpen,
    setModalOpen,
    setCreateScheduleData,
    selectedLocation,
    setScheduleFacultyFieldValidation,
    setScheduleDateFieldValidation,
    setScheduleBatchFieldValidation,
    setScheduleSlotTimeFieldValidation,
    setScheduleSubjectFieldValidation,
    selectedFaculty,
    selectedSubjectid,
    selectedTime,
    modalDate,
    createScheduleData,
    selectedScheduleType,
    setLoading,
    setLoaderMessage,
    selectedDate,
    facultyPresent,
    loaderMessage,
    loading,
    loadingPercentage,
  } = useContext(LocationContext);
  const [batchProfileData, setBatchProfileData] = useState({});
  const [leaveData, setLeaveData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [locations, setLocations] = useState([]);
  const [events, setEvents] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [slotTimes, setSlotTimes] = useState([]);

  useEffect(() => {
    fetchSingleBatch();
    fetchFacultyLeaveData();
    fetchScheduleData();
    const dateParam = searchParams.get("date");
    if (dateParam) {
      setSelectedFacultyCalenderDate(new Date(dateParam));
    } else {
      setSelectedFacultyCalenderDate(new Date());
    }
    // setSelectedFacultyCalenderDate(new Date());
  }, []);
  console.log("selectedFacultyCalenderDate------", selectedFacultyCalenderDate);

  useEffect(() => {
    generateEvents();
    const scheduleSlotTimes = _.uniq([
      ...scheduleData.map((ele) => ele.slot_time),
    ]).sort();

    const finalSlotTimes = _.uniq(scheduleSlotTimes)
      .sort()
      .map((s) => ({ id: s, slot_time: s }));
    setSlotTimes(finalSlotTimes);
  }, [scheduleData, leaveData]);
  const [getBatchId, setBatchId] = useState([]);
  const [totalSubjectData, setTotalSubjectData] = useState([]);
  const fetchSingleBatch = async () => {
    try {
      const getBatchByIDURL = `${process.env.REACT_APP_API_URL}/showBatchById`;
      const url = `${getBatchByIDURL}`;
      const response = await apiService.get(`${url}/${batchId}`);
      if (response) {
        console.log(response);
        setBatchProfileData(response.data);
        setBatchId(response.data.id);
        setSubjects(response.data.batch_subjects);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFacultyLeaveData = () => {
    apiService
      .get(`${process.env.REACT_APP_API_URL}/getByBatchId/${batchId}`)
      .then((res) => {
        if (res && res.leave_records) {
          setLeaveData(res.leave_records);
        } else {
          setLeaveData();
        }
      })
      .catch((err) => {
        setLeaveData([]);
        console.log("Error in GET request:", err);
      });
  };
  const fetchScheduleData = () => {
    apiService
      .get(`${process.env.REACT_APP_API_URL}/getSchedule`, {
        batch_id: batchId,
      })
      .then((res) => {
        setScheduleData(res);
      })
      .catch((err) => {
        console.log(` error:`, err);
      });
  };
  // ---------------------------------------------------------------

  const fetchBatchSubjectTrack = () => {
    apiService
      .get(`${process.env.REACT_APP_API_URL}/getBatchDetails/${getBatchId}`)
      .then((res) => {
        console.log("res", res);

        if (res) {
          setTotalSubjectData(res);
        } else {
          setTotalSubjectData();
        }
      })
      .catch((err) => {
        setLeaveData([]);
        console.log("Error in GET request:", err);
      });
  };

  useEffect(() => {
    fetchBatchSubjectTrack();
  }, [getBatchId]);
  console.log("totalSubjectData", totalSubjectData);
  // ------------------------------------------------------------------------------------------
  const generateEvents = () => {
    let events = [];
    scheduleData.map((schedule) => {
      console.log("batchS", schedule);
      let scheduleEvent = {};
      const [start, end] = schedule.slot_time.split("-");
      scheduleEvent["id"] = schedule.id;
      scheduleEvent["hasSchedule"] = true; // Regular event
      scheduleEvent["scheduleEdit"] =
        new Date(schedule.date) >= new Date() ? true : false;
      scheduleEvent["start"] = new Date(schedule.date + " " + start);
      scheduleEvent["end"] = new Date(schedule.date + " " + end);
      scheduleEvent["color"] = schedule.faculty.color_code;
      let time = `${moment(start, "HH:mm").format("h:mm A")} - ${moment(
        end,
        "HH:mm"
      ).format("h:mm A")}`;
      // scheduleEvent["title"] =
      //   schedule.faculty.first_name + " " + schedule.faculty.last_name[0] + ".";
      scheduleEvent[
        "title"
      ] = `${schedule.faculty.first_name} ${schedule.faculty.last_name[0]}. (${schedule.faculty.faculty_code}) `;
      scheduleEvent["batch"] = schedule.batch.batch_code;
      events.push(scheduleEvent);
    });
    // leaveData.map((leave) => {
    //   console.log(leave);
    //   leave.dates.map((date) => {
    //     let leaveData = {};
    //     leaveData["start"] = new Date(date + " 00:00");
    //     leaveData["end"] = new Date(date + " 00:00");
    //     leaveData["title"] = leave.batch_slot.name + " Leave";
    //     events.push(leaveData);
    //   });
    // });
    console.log(events);
    setEvents(events);
  };
  const eventPropGetter = (event) => {
    const backgroundColor = event.color || "#dfdfdf";
    return { style: { backgroundColor, color: "#000" } };
  };
  const EventComponent = ({ event }) => (
    <span className="text-xs block">
      <strong>{event.title}</strong>
      <span className="float-right">
        {moment(event.start).format("HH:mm")} to{" "}
        {moment(event.end).format("HH:mm")}
      </span>
    </span>
  );
  const minTime = new Date();
  minTime.setHours(7, 0, 0, 0);
  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.5em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const handleDeleteSchedule = () => {
    setScheduleDeleteisOpen(false);
    if (scheduleDeleteId !== null) {
      apiService
        .delete(
          `${process.env.REACT_APP_API_URL}/DeleteSchedule/${scheduleDeleteId}`
        )
        .then(() => {
          toast.success("Schedule Successfully Deleted.. ", toastConfig);
          setScheduleDeleteId(null);
          setScheduleDeleteisOpen(false);
          fetchScheduleData();
        });
    }
  };
  const closeSchedulePopup = () => {
    setScheduleDeleteisOpen(false);
  };

  useEffect(() => {
    const slot = _.find(
      scheduleData,
      (schedule) => schedule.id === scheduleEditId
    );
    console.log(slot);
    if (slot) {
      let data = {
        batch_code: slot.batch.batch_code,
        batch_id: slot.batch.id,
        date: slot.date,
        // faculties: slot.faculties,
        time: slot.slot_time,
        faculty_id: slot.faculty.id,
        subject: slot.subject,
        id: slot.id,
        stream_code: slot.batch.batch_stream[0]["stream_code"],
      };
      setModalOpen(!modalOpen);
      setCreateScheduleData(data);
    }
  }, [scheduleEditId]);
  const handleCloseModal = () => {
    setScheduleEditId(null);
    setModalOpen(false);
    setScheduleFacultyFieldValidation("");
    setScheduleDateFieldValidation("");
    setScheduleBatchFieldValidation("");
    setScheduleSlotTimeFieldValidation("");
    setScheduleSubjectFieldValidation("");
  };
  const handleSubmitSchedule = async (e) => {
    e.preventDefault();

    let isValid = true; // Flag to track if all validations pass

    if (!selectedFaculty || selectedFaculty.length === 0) {
      setScheduleFacultyFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleFacultyFieldValidation("");
    }

    if (!selectedSubjectid || selectedSubjectid.length === 0) {
      setScheduleSubjectFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleSubjectFieldValidation("");
    }

    if (!selectedTime || selectedTime.length === 0) {
      setScheduleSlotTimeFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleSlotTimeFieldValidation("");
    }

    if (!modalDate || modalDate.length === 0) {
      setScheduleDateFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleDateFieldValidation("");
    }

    const localStorageItem = localStorage.getItem("item");
    if (!localStorageItem || localStorageItem.length === 0) {
      setScheduleBatchFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleBatchFieldValidation("");
    }
    if (!isValid) {
      return;
    }
    const postData = {
      location_id: selectedLocation,
      batch_id: localStorage.getItem("item"),
      faculty_id: selectedFaculty.value,
      subject_id: selectedSubjectid,
      slot_time: selectedTime,
      date: modalDate,
    };
    await submitScheduling(postData);
  };
  const submitScheduling = async (post_data, lesson_id = null) => {
    let lessonId = createScheduleData.id;
    if (lesson_id) {
      lessonId = lesson_id;
    }

    if (lessonId && lessonId !== "null") {
      let updateScheudleURL = `${process.env.REACT_APP_API_URL}/UpdateSchedule`;
      if (createScheduleData.stream_code === "F") {
        updateScheudleURL += `/foundation/${lessonId}?isBatchFaculty=${facultyPresent}`;
      } else {
        updateScheudleURL += `/default/${lessonId}?isBatchFaculty=${facultyPresent}`;
      }
      setLoading(true);
      const response = await apiService
        .patch(updateScheudleURL, post_data)
        .then((res) => {
          setScheduleFacultyFieldValidation("");
          setScheduleDateFieldValidation("");
          setScheduleBatchFieldValidation("");
          setScheduleSlotTimeFieldValidation("");
          setScheduleSubjectFieldValidation("");
          setLoading(true);
          setLoaderMessage("Please Wait");
          setScheduleEditId(null);
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate
          )
            .then(() => {
              setModalOpen(false);
              let message = "Schedule Successfully Updated.. ";
              toast.success(message, toastConfig);
              fetchScheduleData();
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });

          // setModalOpen(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastConfig);
          setLoading(false);
        });
    }
  };
  const handleViewChange = (newView) => {
    setMenuOpenId(null);
  };
  return (
    <div className="container  font-serif">
      <ToastContainer />
      {loading && (
        <Loader message={loaderMessage} percentage={loadingPercentage} />
      )}
      {selectedLocation && (
        <Modal
          isOpen={modalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmitSchedule}
          slotTime={slotTimes}
        />
      )}
      <Popup
        isOpen={scheduleDeleteisOpen}
        onClose={closeSchedulePopup}
        heading={"Delete Schedule "}
      >
        <h6>Do you want to delete the schedule ?</h6>
        <div className="button-container">
          <button
            className="text-gray-900  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
            onClick={() => {
              setScheduleDeleteisOpen(false);
              setMenuOpenId(null);
            }}
          >
            No
          </button>
          <button
            className=" text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() => handleDeleteSchedule()}
          >
            Yes
          </button>
        </div>
      </Popup>
      <div className="flex m-3">
        <div className="w-full md:w-1/3 lg:w-1/4 mr-3 custom-height overflow-auto scrollbar-hide bg-white p-4 rounded-lg shadow-lg">
          <div className="text-gray-900">
            <h4 className="text-primaryColour  mb-4">
              Details
              <Link
                to={`/batchformedit/${id}`}
                className="bg-primaryColour text-white float-right text-sm py-2 px-4 rounded hover:bg-opacity-90"
              >
                Edit
              </Link>
            </h4>

            <div className="space-y-2">
              <ProfileDetail
                label="Batch Code"
                value={batchProfileData?.batch_code}
              />
              <ProfileDetail
                label="Location"
                value={
                  batchProfileData?.locations
                    ?.map((loc) => loc.name)
                    .join(", ") || "N/A"
                }
              />
              <ProfileDetail
                label="Batch Type"
                value={batchProfileData?.batch_types?.[0]?.name}
              />
              <ProfileDetail
                label="Duration & Duration Type"
                value={
                  batchProfileData?.duration +
                  ` ` +
                  batchProfileData?.duration_type
                }
              />
              <ProfileDetail
                label="Stream"
                value={batchProfileData?.batch_stream?.map((streamnames) => (
                  <p>
                    {" "}
                    {streamnames.stream_names ? streamnames.stream_names : ""}
                  </p>
                ))}
              />

              <ProfileDetail
                label="Start Date"
                value={moment(batchProfileData?.starting_date).format("LL")}
              />
              <ProfileDetail
                label="Schedule Type"
                value={
                  batchProfileData?.selected_days_count ? "Flexible" : "Fixed"
                }
              />
              <ProfileDetail
                label="Selected Days"
                value={
                  batchProfileData?.selected_days_count
                    ? batchProfileData?.selected_days_count + " Days"
                    : batchProfileData.selected_days
                        ?.map((days) => days)
                        .join(", ")
                }
              />
            </div>
          </div>
          <hr className="my-2" />

          {/* <div className="text-gray-900">
            <h4 className="text-primaryColour text-xl mb-4">Faculty Details</h4>
            <div className="space-y-2">
              {batchProfileData?.faculties?.map((faculty, index) => (
                <ProfileDetail
                  label={faculty.first_name + " " + faculty.last_name}
                  key={faculty.id}
                  type="faculty"
                  value={
                    faculty.subject
                      ?.map((sub) => sub.subject_name)
                      .join(", ") || "N/A"
                  }
                />
              ))}
            </div>
          </div>
          <hr className="my-2" />

  <div className="text-gray-900">
  <h4 className="text-primaryColour text-xl mb-4">Subject Details</h4>
  <div className="space-y-4">
    {batchProfileData?.batch_subjects?.map((subject, index) => {
      const progressData = totalSubjectData?.subjects?.find(
        (data) => data.subject_id === subject.subject.id
      );
      return (
        <div
          key={subject.id}
          className="flex items-center bg-white p-4 shadow-md rounded-lg space-x-4"
        >
          <div className="flex-1">
            <h2 className="text-lg font-semibold">{subject.subject.subject_name}</h2>
            <p>
              Weekly Session: <b>{subject.session_per_weeks}</b>
              <br />
              Total Session: <b>{subject.total_session_of_year}</b>
            </p>
          </div>
          {progressData && (
            <div className="flex-1">
              <RoundedProgressBar
                completedSessions={progressData.completed_sessions}
                totalSessions={progressData.total_session_of_year}
              />
            </div>
          )}
        </div>
      );
    })}
  </div>
</div> */}
          <div className="text-gray-900">
            <h4 className="text-primaryColour text-xl mb-4">Subject Details</h4>
            <div className="space-y-4">
              {batchProfileData?.batch_subjects?.map((subject, index) => {
                const progressData = totalSubjectData?.subjects?.find(
                  (data) => data.subject_id === subject.subject.id
                );
                console.log("batchProfileData---------", batchProfileData);

                // Find faculty name for the subject
                // const facultyName = batchProfileData?.faculties?.find(
                //   (faculty) => faculty.subject.some((sub) => sub.id === subject.subject.id)
                // );
                // const facultyName = batchProfileData?.faculties

                // ?.filter((faculty) => faculty.subject.some((sub) => sub.id === subject.subject.id))
                // ?.map((faculty) => `${faculty.first_name} ${faculty.last_name}`)
                // ?.join(', ');
                const facultyNames = batchProfileData?.faculties
                  ?.filter((faculty) =>
                    faculty.subject.some((sub) => sub.id === subject.subject.id)
                  )
                  ?.map(
                    (faculty) =>
                      `${faculty.faculty_code} - ${faculty.first_name} ${faculty.last_name}`
                  );

                // console.log("facultyName", facultyName)
                return (
                  <div
                    key={subject.id}
                    className="flex items-center  p-1  rounded-lg space-x-2"
                  >
                    <div className="flex-1">
                      <h2 className="text-base font-semibold">
                        {subject.subject.subject_name}
                      </h2>
                      <div className="text-sm mt-2">
                        {/* Display faculty names or "N/A" */}
                        {facultyNames && facultyNames.length > 0
                          ? facultyNames.map((name, idx) => (
                              <span key={idx}>
                                {name}
                                <br /> {/* New line after each faculty name */}
                              </span>
                            ))
                          : "N/A"}
                      </div>
                    </div>
                    {progressData && (
                      <div className="flex-1">
                        <RoundedProgressBar
                          completedSessions={progressData.completed_sessions}
                          totalSessions={progressData.total_session_of_year}
                          subjectColorCode={subject.subject.subject_color_code} // Pass the color code here
                        />
                        <div className="mt-1">
                          Weekly Session: <b>{subject.session_per_weeks}</b>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3 lg:w-3/4 custom-height overflow-auto bg-white rounded-lg shadow-lg">
          <div className="flex ml-auto justify-center   space-x-1 w-max mr-3">
            {subjects.map((subject, index) => (
              <div
                key={`subject_` + subject.subject.id}
                className="flex items-center rounded-lg w-22"
              >
                <div
                  className=" w-4 h-4 font-serif"
                  style={{
                    backgroundColor:
                      subject?.subject.subject_color_code || "white",
                  }}
                ></div>
                <span className="m-2 text-xs">
                  {subject.subject.subject_name}
                </span>
              </div>
            ))}
          </div>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            className="p-4"
            style={{ height: "93%" }}
            eventPropGetter={eventPropGetter}
            step={60}
            timeslots={1}
            popup // Enable popup for events
            showMultiDayTimes // Show times for multi-day events
            defaultView="week" // Set the default view to "week"
            // scrollToTime={new Date(2024, 1, 1, 7, 0, 0)} // Scroll to 7 AM by default
            min={minTime} // Set the start time to 7 AM
            views={["month", "week", "day", "agenda"]}
            components={{
              month: {
                event: EventComponent,
                // toolbar: (props) => <CustomToolbar view="month" {...props} />,
                toolbar: (props) => (
                  <CustomToolbar
                    view="month"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
              week: {
                event: (props) => (
                  <CustomEventBatchView
                    {...props}
                    setScheduleEditId={setScheduleEditId}
                    setScheduleDeleteId={setScheduleDeleteId}
                    setScheduleDeleteisOpen={setScheduleDeleteisOpen}
                    menuOpenId={menuOpenId}
                    setMenuOpenId={setMenuOpenId}
                  />
                ),
                // toolbar: (props) => <CustomToolbar view="week" {...props} />,
                toolbar: (props) => (
                  <CustomToolbar
                    view="week"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
              day: {
                // toolbar: (props) => <CustomToolbar view="day" {...props} />,
                event: (props) => (
                  <CustomEventBatchView
                    {...props}
                    setScheduleEditId={setScheduleEditId}
                    setScheduleDeleteId={setScheduleDeleteId}
                    setScheduleDeleteisOpen={setScheduleDeleteisOpen}
                    menuOpenId={menuOpenId}
                    setMenuOpenId={setMenuOpenId}
                  />
                ),
                toolbar: (props) => (
                  <CustomToolbar
                    view="day"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
              agenda: {
                // toolbar: (props) => <CustomToolbar view="agenda" {...props} />,
                event: (props) => (
                  <CustomEventBatchView
                    {...props}
                    setScheduleEditId={setScheduleEditId}
                    setScheduleDeleteId={setScheduleDeleteId}
                    setScheduleDeleteisOpen={setScheduleDeleteisOpen}
                    menuOpenId={menuOpenId}
                    setMenuOpenId={setMenuOpenId}
                  />
                ),
                toolbar: (props) => (
                  <CustomToolbar
                    view="agenda"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
            }}
            onView={handleViewChange}
          />
        </div>
      </div>
    </div>
  );
};
const ProfileDetail = ({ label, value, type = null }) => {
  return type === "faculty" ? (
    <div className="flex">
      <span>{label}:</span>
      <span className="overflow-auto ml-2">{value}</span>
    </div>
  ) : (
    <div className="flex justify-between">
      <span>{label}:</span>
      <span className="overflow-auto">{value}</span>
    </div>
  );
};
const CustomToolbar = ({ view, date, onNavigate, onView, selectedDate }) => {
  const { selectedFacultyCalenderDate, setSelectedFacultyCalenderDate } =
    useContext(LocationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    renderTitle();
  }, [view, date]);
  useEffect(() => {
    onNavigate("DATE", selectedDate);
  }, [selectedDate]);
  const handleDateChange = (date) => {
    setSearchParams({
      ...Object.fromEntries(searchParams), // Spread the existing search params
      date: moment(date).format("YYYY-MM-DD"),
    });
    setSelectedFacultyCalenderDate(date);
    onNavigate("DATE", date);
  };
  const handleNavigate = (action) => {
    let newDate = date;
    if (action === "PREV") {
      newDate = moment(date).subtract(1, view).toDate();
    } else if (action === "NEXT") {
      newDate = moment(date).add(1, view).toDate();
    } else if (action === "TODAY") {
      newDate = new Date();
    }
    setSelectedFacultyCalenderDate(newDate);
    onNavigate(action, newDate);
    setSearchParams({
      ...Object.fromEntries(searchParams), // Spread the existing search params
      date: moment(newDate).format("YYYY-MM-DD"),
    });
  };

  const renderTitle = () => {
    switch (view) {
      case "month":
        setTitle(moment(date).format("MMMM YYYY"));
        break;
      case "week":
        setTitle(
          `${moment(date).startOf("week").format("MMMM D, YYYY")} - ${moment(
            date
          )
            .endOf("week")
            .format("MMMM D, YYYY")}`
        );
        break;
      case "day":
        setTitle(moment(date).format("MMMM D, YYYY"));
        break;
      case "agenda":
        setTitle(
          `${moment(date).format("MMMM D, YYYY")} - ${moment(date)
            .add(1, "months")
            .format("MMMM D, YYYY")}`
        );
        break;
      default:
        setTitle("");
    }
  };
  return (
    <div className="rbc-toolbar">
      <div className="rbc-toolbar-label relative">
        {/* Hidden date picker */}
        {/* <button type="button" onClick={() => onNavigate("PREV")}> */}
        <button type="button" onClick={() => handleNavigate("PREV")}>
          {"<"}
        </button>
        <DatePicker
          value={selectedFacultyCalenderDate}
          selected={selectedFacultyCalenderDate}
          onClose={handleToggle}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
          open={isOpen}
          onToggle={handleToggle}
          className="absolute top-8 left-0 opacity-0 h-0 w-0 pointer-events-none"
          isoWeek
        />
        {/* Label styled as button */}
        <label
          className="inline-block py-2 px-4 font-bold rounded cursor-pointer"
          onClick={handleToggle}
        >
          {title}
        </label>
        {/* <button type="button" onClick={() => onNavigate("NEXT")}> */}
        <button type="button" onClick={() => handleNavigate("NEXT")}>
          {">"}
        </button>
      </div>
      <span className="rbc-btn-group float-right">
        <button
          type="button"
          onClick={() => onView("month")}
          disabled={view === "month"}
        >
          Month
        </button>
        <button
          type="button"
          onClick={() => onView("week")}
          disabled={view === "week"}
        >
          Week
        </button>
        <button
          type="button"
          onClick={() => onView("day")}
          disabled={view === "day"}
        >
          Day
        </button>
        <button
          type="button"
          onClick={() => onView("agenda")}
          disabled={view === "agenda"}
        >
          Agenda
        </button>
        {/* <button type="button" onClick={() => onNavigate("TODAY")}> */}
        <button type="button" onClick={() => handleNavigate("TODAY")}>
          Today
        </button>
      </span>
    </div>
  );
};

export default BatchView;
