import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { endOfDay, subDays } from "date-fns";
import { Link, useParams, useSearchParams } from "react-router-dom";
import LocationContext from "../../../context/LocationContext";
import TableHeader from "../../../components/TableHeader/TableHeader";
import defaultImage from "../../../utils/Images/defaultImage.jpg";
import { apiService } from "../../../Services/Services";
import "./facultyView.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DatePicker } from "rsuite";
import FacultyAccordion from "./FacultyAccordion"; // Import the Accordion component
import RoundedProgressBar from "../../../components/RoundedProgressBar";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { DateRangePicker } from "rsuite";
import _ from "lodash";
import CustomEvent from "./CustomEvent";
import Popup from "../../../components/Popup";
import { Modal } from "../../../components/Modal/Modal";
import { checkScheduleValidation } from "../../../Services/CommonFucntion";
import Loader from "../../../components/Loader";
import LeaveDialogue from "../../Leave/LeaveDialogue";
import LeaveUpdate from "../../../pages/Leave/LeaveEdit";
import { MdClose } from 'react-icons/md'; // Import the close icon from react-icons

moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

const localizer = momentLocalizer(moment);
const FacultyViewNew = () => {
  const { setActiveMenuStatus } = useContext(LocationContext);
  const [menuOpenId, setMenuOpenId] = useState(null);

  const { id } = useParams();
  setActiveMenuStatus(id);
  const facultyId = id;
  const {
    selectedFacultyCalenderDate,
    setSelectedFacultyCalenderDate,
    modalOpen,
    setModalOpen,
    setCreateScheduleData,
    setScheduleFacultyFieldValidation,
    setScheduleDateFieldValidation,
    setScheduleBatchFieldValidation,
    setScheduleSlotTimeFieldValidation,
    setScheduleSubjectFieldValidation,
    selectedLocation,
    selectedFaculty,
    selectedSubjectid,
    selectedTime,
    loaderMessage,
    loading,
    loadingPercentage,
    modalDate,
    createScheduleData,
    selectedScheduleType,
    facultyPresent,
    setLoading,
    setLoaderMessage,
    selectedDate,
  } = useContext(LocationContext);
  const [facultyProfileData, setFacultyProfileData] = useState({});
  const [leaveData, setLeaveData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [locations, setLocations] = useState([]);
  const [events, setEvents] = useState([]);
  const [slotTimes, setSlotTimes] = useState([]);
  const [leaveUpdateData, setLeaveUpdateData] = useState([]);
  const [eventLeaveDate, SetEventLeaveDate] = useState([]);
  const [slotValidation, setSlotValidation] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm();
  const [options, setOptions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    fetchFacultyData();

    fetchFacultyLeaveData();
    fetchScheduleData();
    const dateParam = searchParams.get("date");
    if (dateParam) {
      setSelectedFacultyCalenderDate(new Date(dateParam));
    } else {
      setSelectedFacultyCalenderDate(new Date());
    }
  }, []);

  useEffect(() => {
    generateEvents();
    const scheduleSlotTimes = _.uniq([
      ...scheduleData.map((ele) => ele.slot_time),
    ]).sort();

    const finalSlotTimes = _.uniq(scheduleSlotTimes)
      .sort()
      .map((s) => ({ id: s, slot_time: s }));
    setSlotTimes(finalSlotTimes);
  }, [scheduleData, leaveData]);
  const fetchFacultyData = () => {
    apiService
      .get(`${process.env.REACT_APP_API_URL}/showfacultyById/${facultyId}`)
      .then((res) => {
        setFacultyProfileData(res.data);
        const subjectIds = res.data.subject.map((subject) => subject.id);
        setSubjectIds(subjectIds);

        // Set faculty ID
        setFacultyId(res.data.id);
        if (res.data.subject) {
          setSubjects(res.data.subject);
        }
        if (res.data.location) {
          setLocations(res.data.location);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ----------------------------------------------------------------
  const [subjectIds, setSubjectIds] = useState([]);
  const [getByfacultyId, setFacultyId] = useState("");
  const [totalSessionData, setTotalSessionsOfYear] = useState([]);

  const fetchFacultyTotalSessionData = () => {
    if (getByfacultyId !== "" && subjectIds?.length > 0) {
      apiService
        .get(
          `${process.env.REACT_APP_API_URL}/getTotalSessionsOfYear/${getByfacultyId}`
        )
        .then((res) => {
          if (res && res.length > 0) {
            setTotalSessionsOfYear(res);
          } else {
            setTotalSessionsOfYear([]);
          }
        })
        .catch((err) => {
          setLeaveData([]);
          console.log("Error in GET request:", err);
        });
    }
  };

  useEffect(() => {
    fetchFacultyTotalSessionData();
  }, [getByfacultyId, subjectIds]);
  // ---------------------------------------------------------------------------------

  const fetchFacultyLeaveData = () => {
    apiService
      .get(`${process.env.REACT_APP_API_URL}/getById/${facultyId}`)
      .then((res) => {
        if (res && res.leave_records) {
          setLeaveData(res.leave_records);
        } else {
          setLeaveData();
        }
      })
      .catch((err) => {
        setLeaveData([]);
        console.log("Error in GET request:", err);
      });
  };
  const fetchScheduleData = () => {
    apiService
      .get(`${process.env.REACT_APP_API_URL}/getSchedule`, {
        faculty_id: facultyId,
      })
      .then((res) => {
        setScheduleData(res);
      })
      .catch((err) => {
        console.log(` error:`, err);
      });
  };
  let allDates = []; // Track all unique dates for which events exist
  const formatDate = (date) => date.toISOString().split("T")[0];

  const generateEvents = () => {
    let events = [];
    scheduleData.map((schedule) => {
      let scheduleEvent = {};
      const [start, end] = schedule.slot_time.split("-");
      scheduleEvent["id"] = schedule.id;
      scheduleEvent["hasSchedule"] = true; // Regular event
      scheduleEvent["scheduleEdit"] =
        new Date(schedule.date) >= new Date() ? true : false;
      scheduleEvent["start"] = new Date(schedule.date + " " + start);
      scheduleEvent["end"] = new Date(schedule.date + " " + end);
      scheduleEvent["color"] = schedule.subject.subject_color_code;
      let time = `${moment(start, "HH:mm").format("h:mm A")} - ${moment(
        end,
        "HH:mm"
      ).format("h:mm A")}`;
      scheduleEvent[
        "title"
      ] = `${schedule.batch.batch_code}  (${schedule.location.name})`;
      events.push(scheduleEvent);
    });

    leaveData.map((leave) => {
      leave.dates.map((date) => {
        allDates.push(formatDate(new Date(date))); // Add date to the set
      });

      let leaveData = {};
      leaveData["id"] = leave.id; // Add unique ID for each event
      leaveData["hasLeave"] = true; // Regular event

      leaveData["start"] = new Date(leave.dates[0] + " 00:00");

      // Set end date to midnight of the next day after the last date
      const lastDate = new Date(leave.dates[leave.dates.length - 1] + " 00:00");
      lastDate.setDate(lastDate.getDate() + 1);
      leaveData["end"] = lastDate;

      leaveData["title"] = leave?.batch_slot_name + " Leave";
      events.push(leaveData);
    });

    // Ensure one event per day
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    const todayDate = new Date(); // January 1st
    const yearEnd = new Date(new Date().getFullYear(), 11, 31); // December 31st
    // Helper function to format date as YYYY-MM-DD
    const leaveDateCounts = _.countBy(allDates);

    for (
      let date = todayDate;
      date <= yearEnd;
      date = new Date(date.getTime() + oneDay)
    ) {
      const dateString = formatDate(date);
      if (!leaveDateCounts[dateString] || leaveDateCounts[dateString] <= 1) {
        const newEvent = {
          start: new Date(dateString + " 00:00"), // Default start time
          end: new Date(dateString + " 00:00"), // Default end time
          title: "+", // Default title
          // backgroundColor: "	#0000FF", // Background color for "Create Leave" events (Blue color)
          isCreateLeave: true, // Flag for plus icon

          color: "#ffffff", // Text color (White)
        };
        events.push(newEvent);
      }
    }
    setEvents(events);
  };

  const [showModal, setShowModal] = useState(false);
  const [createLeaveEventData, setCreateLeaveEventData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [LeaveId, setLeaveId] = useState(null);
  const [scheduleEditId, setScheduleEditId] = useState("");
  const [leaveEditId, setleaveEditId] = useState("");
  const [leaveEditisOpen, setLeaveEditisOpen] = useState(false);
  const [scheduleDeleteId, setScheduleDeleteId] = useState("");
  const [leaveDeleteisOpen, setLeaveDeleteisOpen] = useState(false);
  const [scheduleDeleteisOpen, setScheduleDeleteisOpen] = useState(false);

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.5em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );

  const closeLeavePopup = () => {
    setLeaveDeleteisOpen(false);
    setMenuOpenId(null); // Close the menu if it's already open
  };
  const closeSchedulePopup = () => {
    setScheduleDeleteisOpen(false);
  };

  const handleDeleteLeave = async () => {
    if (LeaveId !== null) {
      setLeaveDeleteisOpen(false);

      try {
        await apiService.delete(
          `${process.env.REACT_APP_API_URL}/deleteLeave/${LeaveId}`
        );
        toast.success("Leave successfully deleted.", toastConfig);
      } catch (error) {
        toast.error("Error deleting leave.", toastConfig);
        // }
      }

      setLeaveId("");
      setLeaveDeleteisOpen(false);
      fetchFacultyLeaveData();
    }
  };

  const handleDeleteSchedule = () => {
    setScheduleDeleteisOpen(false);
    if (scheduleDeleteId !== null) {
      apiService
        .delete(
          `${process.env.REACT_APP_API_URL}/DeleteSchedule/${scheduleDeleteId}`
        )
        .then(() => {
          toast.success("Schedule Successfully Deleted.. ", toastConfig);
          setScheduleDeleteId(null);
          setScheduleDeleteisOpen(false);
          fetchScheduleData();
        });
    }
  };
  const handleSelectEvent = (event) => {
    if (event.isCreateLeave) {
      const startDate = event?.start;
      const endDate = event?.end;

      setStartDate(startDate);
      setEndDate(endDate);
      setShowModal(true);
      setMenuOpenId(null);
    } else {
      console.log("Event title:", event.title);
    }
  };

  const formatDateToString = (date) => {
    // Check if the input is a valid Date object
    if (date instanceof Date && !isNaN(date.getTime())) {
      // Convert the Date object to YYYY-MM-DD format
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const formattedStartDate = formatDateToString(startDate);
  const formattedEndDate = formatDateToString(endDate);

  useEffect(() => {
    if (formattedStartDate && formattedEndDate) {
      const dateStrings = [formattedStartDate, formattedEndDate];
      const dateObjects = dateStrings.map((dateString) => new Date(dateString));

      setValue("dateRange", dateObjects);
    } else {
      console.error(" formattedEndDate is empty or invalid.");
    }
  }, [formattedStartDate, formattedEndDate, setValue, showModal]);

  useEffect(() => {
    const fetchBatchSlots = async () => {
      try {
        const response = await apiService.get(
          `${process.env.REACT_APP_API_URL}/getBatchslot`
        );
        const data = response;

        const morningId =
          data.find((slot) => slot.name === "Morning")?.id || "";
        const afternoonId =
          data.find((slot) => slot.name === "Afternoon")?.id || "";

        setOptions([
          { value: morningId, label: "Morning" },
          { value: afternoonId, label: "Afternoon" },
        ]);
      } catch (error) {
        console.error("Error fetching batch slots:", error);
      }
    };

    fetchBatchSlots();
  }, []);
  const styles = {
    width: 400,
    display: "block",
    height: "35px",
    // outline: "none",
    zIndex: showModal ? 1080 : "auto", // Adjust zIndex when showModal is true
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const disablePastDates = (date) => {
    return date < today;
  };

  const getAllDatesBetween = (startDate, endDate) => {
    let dates = [];
    let currentDate = moment(startDate).startOf("day");
    let end = moment(endDate).endOf("day");

    while (currentDate <= end) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "day");
    }

    return dates;
  };
  const handleOptionChange = (value) => {
    let updatedOptions;

    if (value === "FULLDAY") {
      // If "Full Day" is selected, uncheck "Morning" and "Afternoon"
      updatedOptions = selectedOptions.includes("FULLDAY")
        ? selectedOptions.filter((option) => option !== "FULLDAY")
        : ["FULLDAY"];
    } else {
      // If "Morning" or "Afternoon" is selected, uncheck "Full Day"
      updatedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((option) => option !== value)
        : [...selectedOptions.filter((option) => option !== "FULLDAY"), value];
    }

    setSelectedOptions(updatedOptions);
  };
  const onSubmit = async (data) => {
    if (selectedOptions.length <= 0) {
      setSlotValidation(
        "Please select at least one option: 'Full Day', 'Morning', or 'Afternoon'."
      );
      return;
    }
    const dates = getAllDatesBetween(data.dateRange[0], data.dateRange[1]);

    const postData = {
      faculty_id: [facultyId],
      dates: dates,

      batch_slot_name: selectedOptions,
    };
    console.log("PostData", postData);

    try {
      const response = await apiService.post(
        `${process.env.REACT_APP_API_URL}/createLeave`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setStartDate("");
      setEndDate("");
      setValue("dateRange", "");
      setSlotValidation("");

      toast.success("Record Successfully Created.. ", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
      });
      fetchFacultyLeaveData();
      setShowModal(false);
    } catch (error) {
      toast.error(error.response.data.error, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
      });
    }
    // setShowModal(false);
    setCreateLeaveEventData(null);
    // reset();
  };

  // const onSubmit = (data) => {
  //   console.log("data", data)
  //   console.log("createLeaveEventData", createLeaveEventData)
  //   // handleSave(data);
  //   setShowModal(false);
  //   setCreateLeaveEventData(null);
  //   reset(); // Reset form values
  // };

  const handleCancel = () => {
    setShowModal(false);
    setCreateLeaveEventData(null);

    setSelectedOptions([]);
    setSlotValidation('')
  };
  useEffect(() => {
    if (showModal) {
      setSelectedOptions([]);

      // setCreateLeaveEventData([])
    }
  }, [showModal]);
  useEffect(() => {
    const slot = _.find(
      scheduleData,
      (schedule) => schedule.id === scheduleEditId
    );
    console.log(slot);
    if (slot) {
      let data = {
        batch_code: slot.batch.batch_code,
        batch_id: slot.batch.id,
        date: slot.date,
        // faculties: slot.faculties,
        time: slot.slot_time,
        faculty_id: slot.faculty.id,
        subject: slot.subject,
        id: slot.id,
        stream_code: slot.batch.batch_stream[0]["stream_code"],
      };
      setModalOpen(!modalOpen);
      setCreateScheduleData(data);
    }
  }, [scheduleEditId]);
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    backgroundColor: "white",
    border: "1px solid #ccc",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    zIndex: 20, // Adjust zIndex when showModal is true
  };
  const eventPropGetter = (event) => {
    const backgroundColor = event.color || "#dfdfdf";
    return { style: { backgroundColor, color: "#000" } };
  };
  const EventComponent = ({ event }) => {
    if (event.isCreateLeave) return <span></span>;
    return (
      <span className="text-xs block">
        <strong>{event.title}</strong>
        <span className="float-right">
          {moment(event.start).format("HH:mm")} to{" "}
          {moment(event.end).format("HH:mm")}
        </span>
      </span>
    );
  };
  const handleCloseModal = () => {
    setScheduleEditId(null);
    setModalOpen(false);
    setScheduleFacultyFieldValidation("");
    setScheduleDateFieldValidation("");
    setScheduleBatchFieldValidation("");
    setScheduleSlotTimeFieldValidation("");
    setScheduleSubjectFieldValidation("");
  };

  const handleSubmitSchedule = async (e) => {
    e.preventDefault();

    let isValid = true; // Flag to track if all validations pass

    if (!selectedFaculty || selectedFaculty.length === 0) {
      setScheduleFacultyFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleFacultyFieldValidation("");
    }

    if (!selectedSubjectid || selectedSubjectid.length === 0) {
      setScheduleSubjectFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleSubjectFieldValidation("");
    }

    if (!selectedTime || selectedTime.length === 0) {
      setScheduleSlotTimeFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleSlotTimeFieldValidation("");
    }

    if (!modalDate || modalDate.length === 0) {
      setScheduleDateFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleDateFieldValidation("");
    }

    const localStorageItem = localStorage.getItem("item");
    if (!localStorageItem || localStorageItem.length === 0) {
      setScheduleBatchFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleBatchFieldValidation("");
    }
    if (!isValid) {
      return;
    }
    const postData = {
      location_id: selectedLocation,
      batch_id: localStorage.getItem("item"),
      faculty_id: selectedFaculty.value,
      subject_id: selectedSubjectid,
      slot_time: selectedTime,
      date: modalDate,
    };
    await submitScheduling(postData);
  };
  const submitScheduling = async (post_data, lesson_id = null) => {
    let lessonId = createScheduleData.id;
    if (lesson_id) {
      lessonId = lesson_id;
    }

    if (lessonId && lessonId !== "null") {
      let updateScheudleURL = `${process.env.REACT_APP_API_URL}/UpdateSchedule`;
      if (createScheduleData.stream_code === "F") {
        updateScheudleURL += `/foundation/${lessonId}?isBatchFaculty=${facultyPresent}`;
      } else {
        updateScheudleURL += `/default/${lessonId}?isBatchFaculty=${facultyPresent}`;
      }
      setLoading(true);
      const response = await apiService
        .patch(updateScheudleURL, post_data)
        .then((res) => {
          setScheduleFacultyFieldValidation("");
          setScheduleDateFieldValidation("");
          setScheduleBatchFieldValidation("");
          setScheduleSlotTimeFieldValidation("");
          setScheduleSubjectFieldValidation("");
          setLoading(true);
          setLoaderMessage("Please Wait");
          setScheduleEditId(null);
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate
          )
            .then(() => {
              setModalOpen(false);
              let message = "Schedule Successfully Updated.. ";
              toast.success(message, toastConfig);
              fetchScheduleData();
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });

          // setModalOpen(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastConfig);
          setLoading(false);
        });
    }
  };

  const handleSuggesionSwap = (res) => {
    checkScheduleValidation(
      selectedLocation,
      selectedScheduleType,
      selectedDate,
      "",
      createScheduleData.batch_id
    )
      .then((response) => {
        // Combine the merged array with the new records
        fetchScheduleData();
        // setScheduleRefresh(true);
        setModalOpen(false);
        toast.success(res.message, toastConfig);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const minTime = new Date();
  minTime.setHours(7, 0, 0, 0);

  useEffect(() => {
    if (menuOpenId && typeof menuOpenId === "string") {
      const [menuId, menuDate] = menuOpenId.split("date"); // Extract the ID and date

      // Trim and log menuId and menuDate
      const trimmedMenuId = menuId.trim();
      const trimmedMenuDate = menuDate.trim();

      // Set the date state only if necessary
      SetEventLeaveDate(trimmedMenuDate);

      if (trimmedMenuId && trimmedMenuDate) {
        // Find the corresponding batch in leaveData
        const matchingBatch = leaveData.find(
          (leave) => leave.id === trimmedMenuId
        );

        if (matchingBatch) {
          // Set the batch state only if necessary
          setLeaveUpdateData(matchingBatch);
        } else {
          console.log("No matching batch found or dates is not an array.");
        }
      } else {
        console.log("Invalid trimmedMenuId or trimmedMenuDate extracted.");
      }
    } else {
      console.log("Invalid menuOpenId format.");
    }
  }, [menuOpenId, leaveData]); // Dependencies
  console.log("leaveUpdateData", leaveUpdateData);
  const handleViewChange = (newView) => {
    setMenuOpenId(null);
  };
  return (
    <div className="container font-serif">
      <>
        <ToastContainer />
        {loading && (
          <Loader message={loaderMessage} percentage={loadingPercentage} />
        )}
        {selectedLocation && (
          <Modal
            isOpen={modalOpen}
            onClose={handleCloseModal}
            onSubmit={handleSubmitSchedule}
            slotTime={slotTimes}
            schedules={scheduleData}
            swapSubmit={handleSuggesionSwap}
          />
        )}
        <LeaveDialogue
          isOpen={leaveEditisOpen}
          setIsModalOpen={setLeaveEditisOpen}
        >
          <LeaveUpdate
            setIsModalOpenEditModel={setLeaveEditisOpen}
            leaveApiData={fetchFacultyLeaveData}
            UpdateId={leaveEditId}
            setILeaveUpdateId={setleaveEditId}
          />
        </LeaveDialogue>
        <Popup
          isOpen={leaveDeleteisOpen}
          onClose={closeLeavePopup}
          heading={"Delete Leave?"}
        >
          <h6>Do you want to delete the leave ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => {
                setLeaveDeleteisOpen(false);
                setMenuOpenId(null); // Close the menu if it's already open
              }}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => handleDeleteLeave()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={scheduleDeleteisOpen}
          onClose={closeSchedulePopup}
          heading={"Delete Schedule?"}
        >
          <h6>Do you want to delete the schedule ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => {
                setScheduleDeleteisOpen(false);
                setMenuOpenId(null);
              }}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => handleDeleteSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        {/* <Modal
          // isOpen={modalOpen}
          // onClose={handleCloseModal}
          onSubmit={handleSubmit}
        /> */}
      </>
      <div className="flex m-3">
        <div className="w-full md:w-1/3 lg:w-1/4 mr-3 custom-height overflow-auto scrollbar-hide bg-white p-4 rounded-lg shadow-lg">
          <div className="text-center mb-6">
            <img
              src={
                process.env.REACT_APP_API_URL +
                  `/faculty/` +
                  facultyProfileData.id +
                  `/image` || defaultImage
              }
              onError={(e) => {
                e.target.src = defaultImage;
              }}
              className="w-32 h-32 mx-auto rounded-full shadow-lg"
              alt="Faculty"
            />
          </div>
          <div className="text-center mb-6">
            <Link
              to={`/facultyformedit/${facultyProfileData.id}`}
              className="bg-primaryColour text-white py-2 px-4 rounded-full hover:bg-opacity-90"
            >
              Edit
            </Link>
          </div>
          <div className="text-gray-900">
            <h4 className="text-primaryColour text-xl mb-4">Profile</h4>
            <div className="space-y-2">
              <ProfileDetail
                label="Faculty Code"
                value={facultyProfileData?.faculty_code}
              />
              <ProfileDetail
                label="Name"
                value={`${facultyProfileData?.first_name || ""} ${
                  facultyProfileData?.last_name || ""
                }`}
              />
              <ProfileDetail label="Email" value={facultyProfileData?.mail} />
              <ProfileDetail label="Phone" value={facultyProfileData?.phone} />
            </div>
          </div>
          <div className="space-y-2 mt-4">
            <ProfileDetail
              label="Location(s)"
              value={locations?.map((loc) => loc.name).join(", ") || "N/A"}
            />
            <ProfileDetail
              label="Subject(s)"
              value={
                subjects?.map((sub) => sub.subject_name).join(", ") || "N/A"
              }
            />
          </div>
          <hr className="my-2" />
          <FacultyAccordion title="More Details">
            <div className="space-y-2 mt-4">
              <ProfileDetail
                label="Gender"
                value={
                  facultyProfileData?.gender
                    ? facultyProfileData.gender.charAt(0).toUpperCase() +
                      facultyProfileData.gender.slice(1)
                    : ""
                }
              />
              <ProfileDetail label="Age" value={facultyProfileData?.age} />
              <ProfileDetail
                label="Experience"
                value={facultyProfileData?.experience}
              />
              <ProfileDetail
                label="Address"
                value={facultyProfileData?.address}
              />
            </div>
          </FacultyAccordion>
          <hr className="my-2" />
          {/* <FacultyAccordion title="Academic Details"> */}

          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      {totalSessionData.map((data, index) => (
        <div key={data.batch_id} className="bg-white p-4  shadow-md">
          <h2 className=" text-base font-semibold">{data.batch_code}</h2>
          <p className="text-sm text-gray-500">{data.subject_name}</p>
          <div style={{marginTop:"12px"}}>

          <RoundedProgressBar
            totalSessions={data.total_sessions}
            completedSessions={data.completed_sessions}
          />
          </div>
        </div>
      ))}
    </div> */}
          <div>
            {totalSessionData?.map((data, index) => (
              <div
                key={data?.batch_id}
                className="flex items-center bg-white p-2 shadow-md"
              >
                <div className="flex-1">
                  <h2 className=" text-sm font-semibold">{data?.batch_code}</h2>
                  {/* <p className="text-sm text-gray-500">{data.subject_name}</p> */}
                </div>
                <div className="ml-4 flex-1">
                  <RoundedProgressBar
                    totalSessions={data?.total_sessions}
                    completedSessions={data?.completed_sessions}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full md:w-2/3 lg:w-3/4 custom-height overflow-auto bg-white rounded-lg shadow-lg">
          <div className="flex ml-auto justify-center space-x-1 w-max mr-3">
            {subjects.map((subject) => (
              <div
                key={`subject_` + subject.id}
                className="flex items-center rounded-lg w-22"
              >
                <div
                  className="w-4 h-4"
                  style={{
                    backgroundColor: subject?.subject_color_code || "white",
                  }}
                ></div>
                <span className="m-2 text-xs">{subject.subject_name}</span>
              </div>
            ))}
          </div>

          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            className="p-4"
            style={{ height: "93%" }}
            eventPropGetter={eventPropGetter}
            step={60}
            timeslots={1}
            selectable
            // onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent} // Handle event clicks
            popup // Enable popup for events
            showMultiDayTimes // Show times for multi-day events
            views={["month", "week", "day", "agenda"]}
            defaultView="week" // Set the default view to "week"
            min={minTime} // Set the start time to 7 AM
            components={{
              // event: (props) => <CustomEvent {...props} setLeaveId={setLeaveId} setScheduleEditId= {setScheduleEditId}  setScheduleDeleteId= {setScheduleDeleteId} setLeaveDeleteisOpen={setLeaveDeleteisOpen} setScheduleDeleteisOpen={setScheduleDeleteisOpen}/>,

              // event: CustomEvent,
              month: {
                event: EventComponent,
                toolbar: (props) => (
                  <CustomToolbar
                    view="month"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
              // week: {
              //   toolbar: (props) => <CustomToolbar view="week" {...props} />,
              // },
              week: {
                event: (props) => (
                  <CustomEvent
                    {...props}
                    setLeaveId={setLeaveId}
                    setScheduleEditId={setScheduleEditId}
                    setleaveEditId={setleaveEditId}
                    setScheduleDeleteId={setScheduleDeleteId}
                    setLeaveDeleteisOpen={setLeaveDeleteisOpen}
                    setLeaveEditisOpen={setLeaveEditisOpen}
                    setScheduleDeleteisOpen={setScheduleDeleteisOpen}
                    menuOpenId={menuOpenId}
                    setMenuOpenId={setMenuOpenId}
                    setShowModal={setShowModal}
                  />
                ),
                toolbar: (props) => (
                  <CustomToolbar
                    view="week"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
              day: {
                event: (props) => (
                  <CustomEvent
                    {...props}
                    setLeaveId={setLeaveId}
                    setScheduleEditId={setScheduleEditId}
                    setleaveEditId={setleaveEditId}
                    setScheduleDeleteId={setScheduleDeleteId}
                    setLeaveDeleteisOpen={setLeaveDeleteisOpen}
                    setLeaveEditisOpen={setLeaveEditisOpen}
                    setScheduleDeleteisOpen={setScheduleDeleteisOpen}
                    menuOpenId={menuOpenId}
                    setMenuOpenId={setMenuOpenId}
                    setShowModal={setShowModal}
                  />
                ),
                toolbar: (props) => (
                  <CustomToolbar
                    view="day"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
              agenda: {
                event: (props) => (
                  <CustomEvent
                    {...props}
                    setLeaveId={setLeaveId}
                    setScheduleEditId={setScheduleEditId}
                    setleaveEditId={setleaveEditId}
                    setScheduleDeleteId={setScheduleDeleteId}
                    setLeaveDeleteisOpen={setLeaveDeleteisOpen}
                    setLeaveEditisOpen={setLeaveEditisOpen}
                    setScheduleDeleteisOpen={setScheduleDeleteisOpen}
                    menuOpenId={menuOpenId}
                    setMenuOpenId={setMenuOpenId}
                    setShowModal={setShowModal}
                  />
                ),
                toolbar: (props) => (
                  <CustomToolbar
                    view="agenda"
                    selectedDate={selectedFacultyCalenderDate}
                    {...props}
                  />
                ),
              },
            }}
            onView={handleViewChange}
          />
          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="relative bg-white w-full max-w-lg p-6 rounded shadow-xl">
              <button
            onClick={handleCancel}
            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
            style={{ background: "transparent", border: "none" }}
          >
            <MdClose size={24} /> {/* React icon with size */}
          </button>
                <h4 className=" text-center ">Create Leave</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="  ">
                    <label className="text-base mb-1 mt-3 text-primary">
                      <span className=" text-red-600 font-bold text-lg font-serif ">
                        *
                      </span>
                      Date:
                    </label>
                    <div className=" flex-grow ">
                      <DateRangePicker
                        showOneCalendar
                        // size="md"
                        placeholder="Calender"
                        value={watch("dateRange")}
                        {...register("dateRange", {
                          required: "This is required",
                          validate: (value) =>
                            (value && value[0] !== null && value[1] !== null) ||
                            "Please select a date range",
                        })}
                        onChange={(selectedOptions) => {
                          setValue("dateRange", selectedOptions);
                        }}
                        style={styles}
                        // className="w-300px" // Tailwind CSS utility class for width

                        disabledDate={disablePastDates}
                      />
                    </div>
                    {errors.dateRange && (
                      <p className="error validationcolor">
                        {errors.dateRange.message}
                        {"*"}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 mt-2">
                    <span className=" text-red-600 font-bold text-lg font-serif ">
                        *
                      </span>
                      Select Slots
                    </label>
                    <div className="flex ">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          // value="fullDay"

                          checked={selectedOptions.includes("FULLDAY")}
                          onChange={() => handleOptionChange("FULLDAY")}
                          className="  border border-gray-400 rounded-full  text-primary"
                        />
                        <span className="ml-2">Full Day</span>
                      </div>
                      {options.map((option) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            type="checkbox"
                            value={option.value}
                            checked={selectedOptions.includes(option.label)}
                            onChange={() => handleOptionChange(option.label)}
                            className=" text-primary border border-gray-400 rounded-full text-primary ml-4 "
                          />
                          <label className="ml-2 text-gray-700 text-sm font-medium">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="mt-2">
                      <p className=" text-red-600 ">{slotValidation}</p>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className=" text-white bg-primaryColour hover:bg-primaryColour-1000  font-medium  text-sm px-5 py-2.5 me-2 font-serif "
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="text-gray-900  font-serif bg-gray-50 border border-gray-300 focus:outline-none hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ProfileDetail = ({ label, value }) => (
  <div className="flex justify-between">
    <span className="font-bold text-black">{label}:</span>
    <span className="overflow-auto">{value}</span>
  </div>
);

const CustomToolbar = ({ view, date, onNavigate, onView, selectedDate }) => {
  const { selectedFacultyCalenderDate, setSelectedFacultyCalenderDate } =
    useContext(LocationContext);
  console.log(date);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    renderTitle();
  }, [view, date]);
  useEffect(() => {
    onNavigate("DATE", selectedDate);
  }, [selectedDate]);
  const handleDateChange = (date) => {
    setSelectedFacultyCalenderDate(date);
    onNavigate("DATE", date);
    setSearchParams({
      ...Object.fromEntries(searchParams), // Spread the existing search params
      date: moment(date).format("YYYY-MM-DD"),
    });
  };
  const handleNavigate = (action) => {
    let newDate = date;
    if (action === "PREV") {
      newDate = moment(date).subtract(1, view).toDate();
    } else if (action === "NEXT") {
      newDate = moment(date).add(1, view).toDate();
    } else if (action === "TODAY") {
      newDate = new Date();
    }
    setSelectedFacultyCalenderDate(newDate);
    onNavigate(action, newDate);
    setSearchParams({
      ...Object.fromEntries(searchParams), // Spread the existing search params
      date: moment(newDate).format("YYYY-MM-DD"),
    });
  };
  const renderTitle = () => {
    switch (view) {
      case "month":
        setTitle(moment(date).format("MMMM YYYY"));
        break;
      case "week":
        setTitle(
          `${moment(date).startOf("week").format("MMMM D, YYYY")} - ${moment(
            date
          )
            .endOf("week")
            .format("MMMM D, YYYY")}`
        );
        break;
      case "day":
        setTitle(moment(date).format("MMMM D, YYYY"));
        break;
      case "agenda":
        setTitle(
          `${moment(date).format("MMMM D, YYYY")} - ${moment(date)
            .add(1, "months")
            .format("MMMM D, YYYY")}`
        );
        break;
      default:
        setTitle("");
    }
  };
  return (
    <div className="rbc-toolbar">
      <div className="rbc-toolbar-label relative">
        {/* Hidden date picker */}
        <button type="button" onClick={() => handleNavigate("PREV")}>
          {"<"}
        </button>
        <DatePicker
          value={selectedFacultyCalenderDate}
          selected={selectedFacultyCalenderDate}
          onClose={handleToggle}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
          open={isOpen}
          onToggle={handleToggle}
          className="absolute top-8 left-0 opacity-0 h-0 w-0 pointer-events-none"
          isoWeek
        />
        {/* Label styled as button */}
        <label
          className="inline-block py-2 px-4 font-bold rounded cursor-pointer"
          onClick={handleToggle}
        >
          {title}
        </label>
        <button type="button" onClick={() => handleNavigate("NEXT")}>
          {">"}
        </button>
      </div>
      <span className="rbc-btn-group float-right">
        <button
          type="button"
          onClick={() => onView("month")}
          disabled={view === "month"}
        >
          Month
        </button>
        <button
          type="button"
          onClick={() => onView("week")}
          disabled={view === "week"}
        >
          Week
        </button>
        <button
          type="button"
          onClick={() => onView("day")}
          disabled={view === "day"}
        >
          Day
        </button>
        <button
          type="button"
          onClick={() => onView("agenda")}
          disabled={view === "agenda"}
        >
          Agenda
        </button>
        <button type="button" onClick={() => handleNavigate("TODAY")}>
          Today
        </button>
      </span>
    </div>
  );
};

export default FacultyViewNew;
