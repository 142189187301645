import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import LocationContext from "../../context/LocationContext";
import { apiService } from "../../Services/Services";
import {
  checkScheduleValidation,
  filterAndSortDataByWeek,
} from "../../Services/CommonFucntion";
import { ToastContainer, toast } from "react-toastify";
import { useDrop } from "react-dnd";
import { FaSearch } from 'react-icons/fa'; // Assuming you're using react-icons for the search icon

import "react-js-dialog-box/dist/index.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import { FaEllipsisV } from "react-icons/fa";
import "./table.css";
import Select, { components } from "react-select";

import defaultImage from "../../utils/Images/defaultImage.jpg";
import Popup from "../Popup";
import TableHeading from "./TableHeading";
import TableBody from "./TableBody";
import AutoScheduleErrorTable from "./AutoScheduleErrorTable";

const Humberger = ({
  dataItem,
  isOpens,
  toggleMenu,
  isCompactSIze,
  setSelectedCheckboxes,
  selectedCheckboxes,
  isSwapSchedule,
  onOpenPopup,
}) => {
  //

  const handleCheckboxChange = (id) => {
    setSelectedCheckboxes((prevState) => {
      const index = prevState.indexOf(id);

      if (index === -1) {
        const updatedState = [...prevState, id].slice(-2);
        return updatedState;
      } else {
        // If exists, remove it from the array
        const updatedState = [
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1),
        ];
        return updatedState;
      }
    });
  };
  const opendialogue = (id, facultyName) => {
    onOpenPopup({ id, isOpen: true, facultyName });

    toggleMenu(false);
  };

  const { setModalOpenEdit, setEditItemId } = useContext(LocationContext);
  const handleOpenModal = (id) => {
    setEditItemId(id);
    setModalOpenEdit(true);
    toggleMenu(false);
  };

  const styles = isCompactSIze
    ? { cursor: "pointer", position: "absolute", right: 0, top: 0 }
    : { cursor: "pointer", position: "absolute", right: 0, top: 0 };
  return (
    <div claonOpenPopupssName="">
      <div className={`hamburger ${isOpens ? "open" : ""}`}>
        <div
          className=" mt-1 dots-icon flex flex-col right-0   "
          style={styles}
        >
          <div>
            {isSwapSchedule && (
              <input
                type="checkbox"
                checked={selectedCheckboxes.includes(dataItem.id)}
                onChange={() => handleCheckboxChange(dataItem.id)}
                style={{
                  width: "15px",
                  height: "15px",
                  position: "relative",
                  right: "3px",
                }}
              />
            )}
            <FaEllipsisV
              onClick={toggleMenu}
              className={`${isCompactSIze ? "text-xm" : "text-xl"}`}
            />
          </div>
        </div>
      </div>

      {isOpens && (
        <div className="relative bg-white">
          <div
            className="menu-box absolute top-full"
            style={{
              right: isCompactSIze ? "1px" : "20px",
              marginTop: "20px",
              padding: isCompactSIze ? "4px" : "8px",
            }}
          >
            <div className="  custom-button">
              <button
                onClick={() => handleOpenModal(dataItem.id)}
                className="flex "
              >
                <AiFillEdit className="text-lg text-green-700 cursor-pointer " />
                <p className=" ml-1 "> Edit</p>
              </button>
            </div>

            <div className="  custom-button">
              <button
                className="mt-2 flex"
                onClick={() =>
                  opendialogue(
                    dataItem.id,
                    dataItem.faculty.first_name +
                      " " +
                      dataItem.faculty.last_name
                  )
                }
              >
                <RiDeleteBin6Line className="text-red-700 text-lg cursor-pointer " />
                <p className="  ml-1">Delete</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const Table = () => {
  const {
    selectedDate,
    selectedLocation,
    compactSizeTable,
    setScheduleStatusCheck,
    hasSchedule,
    setHasSchedule,
    selectedScheduleType,
    scheduleRefresh,
    setScheduleRefresh,
    setFacultyHrsUpdate,
    dateStatus,
    setSelectedCheckboxes,
    selectedCheckboxes,
    isSwapSchedule,
    setIsSwapSchedule,
    tableData,
    setTableData,
    setLoaderMessage,
    setLoading,
    subjectList,
  } = useContext(LocationContext);
  let streamCode = "";

  if (selectedScheduleType === "jee/medical") {
    streamCode = "j/m";
  } else if (selectedScheduleType === "jee") {
    streamCode = "j";
  } else if (selectedScheduleType === "medical") {
    streamCode = "m";
  } else if (selectedScheduleType === "foundation") {
    streamCode = "f";
  }
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setModalOpenEdit, setEditItemId } = useContext(LocationContext);
  const handleEditErrorClick = (id) => {
    setIsDropdownOpen(!isDropdownOpen);
    setEditItemId(id);
    setModalOpenEdit(true);
  };

  const [schedule, setSchedule] = useState([]);
  const [dropFacutly, setDropFaculty] = useState([]);
  const [batch, setBatch] = useState([]);
  const [foundationBatch, setfoundationBatch] = useState([]);
  const [openColumns, setOpenColumns] = useState({});
  const [state, setState] = useState([]);

  const [state1, setState1] = useState([]);
  const [state2, setState2] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  const [deleteFacultyname, setDeleteFacultyname] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [checkValidationPopUp, setcheckValidationPopUp] = useState(false);
  const [deleteScheduleId, setDeleteScheduleId] = useState(null);

  const closePopupcheckValidationPopUp = () => {
    setcheckValidationPopUp(false);
    setConfirmationStatus(false);
  };
  const closePopup = () => {
    setIsOpen(false);
    setDeleteScheduleId("");

    toggleMenu(false); // Close the menu
    setDeleteFacultyname("");
  };

  useEffect(() => {
    if (isSwapSchedule === true) {
      toggleMenu(false);
    }
  }, [isSwapSchedule]);
  const handleOpenPopup = ({ id, isOpen, facultyName }) => {
    setDeleteScheduleId(id);

    setIsOpen(isOpen);
    setDeleteFacultyname(facultyName);
  };

  const handleDeleteSchedule = () => {
    setIsOpen(false);
    if (deleteScheduleId !== null) {
      apiService
        .delete(
          `${process.env.REACT_APP_API_URL}/DeleteSchedule/${deleteScheduleId}`
        )
        .then(() => {
          toast.success("Schedule Successfully Deleted.. ", toastConfig);
          setFacultyHrsUpdate(true);
          getSchedulingData();
          let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
          setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
          setLoading(true);
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate
          )
            .then(() => {
              setLoading(false);
              setScheduleRefresh(true);
            })
            .catch(() => {
              setLoading(false);
            });
        });
    }
    if (deleteScheduleId) {
      setSchedule((prevSchedule) =>
        prevSchedule.filter((data) => data.id !== deleteScheduleId)
      );
    }
  };

  const selectedDateArray = Array.isArray(selectedDate) ? selectedDate : [];
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });
  const startDate = formattedDates[0];
  const endDate = formattedDates[1];

  const getBatchLocationById = async () => {
    try {
      const response = await apiService.get(
        `${process.env.REACT_APP_API_URL}/getbatchdata?q=&limit=&page=&sortBy=id&sortOrder=ASC&location_id=${selectedLocation}`
      );

      const jeeMedicalBatches = [];
      const foundationBatches = [];

      response.data.forEach((batch) => {
        const streamNames = batch.batch_stream.map(
          (stream) => stream.stream_names
        );

        if (streamNames.includes("JEE") || streamNames.includes("Medical")) {
          jeeMedicalBatches.push(batch);
        }

        if (streamNames.includes("Foundation")) {
          foundationBatches.push(batch);
        }
      });

      setBatch(jeeMedicalBatches);
      setfoundationBatch(foundationBatches);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  const [hardRuleError, setHardRuleError] = useState([]);

  const getScheduleURL = `${process.env.REACT_APP_API_URL}/getSchedule?starting_date=${formattedDates[0]}&ending_date=${formattedDates[1]}&location_id=${selectedLocation}&stream_code=${streamCode}`;
  const getSchedulingData = async () => {
    try {
      const Response = await apiService.get(getScheduleURL);
      if (Response) {
        setSchedule(Response);

        const hasDraft = Response.some((item) => item.status === "draft");

        setScheduleStatusCheck(hasDraft);
        setHasSchedule(true);
        setIsDropdownOpen(false);

        const errorMessages = Response.reduce((acc, item) => {
          if (item.error) {
            acc.push({
              message: item.error,
              id: item.id,
              time: item.slot_time,
              batch_name: item.batch.batch_code,
              date: item.date,
              faculty_name: item.faculty.first_name + item.faculty.last_name,
            });
          }
          return acc;
        }, []);

        setHardRuleError(errorMessages);
      } else {
        setHardRuleError([]);

        setHasSchedule(false);
        setScheduleStatusCheck(false);
      }
    } catch (error) {
      setHardRuleError([]);

      setScheduleStatusCheck(false);
      setHasSchedule(false);
    }
  };

  useEffect(() => {}, [hasSchedule]);

  const getfetchData = useRef(true);

  useEffect(() => {
    setSelectedCheckboxes([]);
    setIsSwapSchedule(false);
    const fetchData = async () => {
      if (!getfetchData.current && selectedLocation) {
        await getBatchLocationById();
        getSchedulingData();
        getfetchData.current = false;
      } else {
        console.log("Location not selected.");
      }
    };

    fetchData();
  }, [selectedDate, getfetchData,selectedLocation, selectedScheduleType]);

  getfetchData.current = false;
  useEffect(() => {
    getfetchData.current = false;
  }, [selectedDate, selectedLocation, selectedScheduleType]);

  useEffect(() => {
    if (scheduleRefresh) {
      getSchedulingData();
      setScheduleRefresh(false);
    }
  }, [scheduleRefresh]);

  function getDayNameFromDate(dateString) {
    const dateObj = new Date(dateString);
    const dayOfWeek = dateObj.getDay();
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return dayNames[dayOfWeek];
  }

  useEffect(() => {
    const updatedData = schedule.map((data) => ({
      ...data,
      dayOfWeek: getDayNameFromDate(data.date),
    }));

    setTableData(updatedData);
  }, [schedule]);
  useEffect(() => {}, [tableData]);

  function compareTimeRanges(a, b) {
    const timeA = a.split(" - ")[0];
    const timeB = b.split(" - ")[0];

    // Compare the start times
    if (timeA < timeB) {
      return -1;
    }
    if (timeA > timeB) {
      return 1;
    }
    return 0;
  }
  const allFilteredAndSortedData = [];

  function processBatch(batchToProcess) {
    batchToProcess.map((i) => {
      const morningSlotTimes =
        i.batch_slots.find((slot) => slot.slot === "morning")?.slot_times || [];
      const afternoonSlotTimes =
        i.batch_slots.find((slot) => slot.slot === "afternoon")?.slot_times ||
        [];
      morningSlotTimes.sort(compareTimeRanges);
      afternoonSlotTimes.sort(compareTimeRanges);

      const hasMorningSlots = morningSlotTimes.length > 0;
      const hasAfternoonSlots = afternoonSlotTimes.length > 0;

      const filteredAndSortedDataByWeek = filterAndSortDataByWeek(
        tableData,
        startDate,
        endDate,
        i.id,
        i.batch_code,
        hasMorningSlots,
        hasAfternoonSlots,
        morningSlotTimes,
        afternoonSlotTimes,
        i
      );

      allFilteredAndSortedData.push(filteredAndSortedDataByWeek);
    });
  }

  // function processBatchIds() {
  //   if (selectedScheduleType === "foundation") {
  //     processBatch(foundationBatch);
  //   } else if (selectedScheduleType === "jee/medical") {
  //     processBatch(batch);
  //   }
  // }



  // Search Functionality on Batch Code---------------------------
  const [searchTerm, setSearchTerm] = useState('');

  const processBatchIds = () => {
    const filteredFoundationBatch = foundationBatch.filter(batch =>
      batch.batch_code.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredBatch = batch.filter(batch =>
      batch.batch_code.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (selectedScheduleType === "foundation") {
      processBatch(filteredFoundationBatch);
    } else if (selectedScheduleType === "jee/medical" || selectedScheduleType === "jee" || selectedScheduleType === "medical") {
      processBatch(filteredBatch);
    }
  };
  processBatchIds();

  
  console.log("batch",batch)

  let subject_id = null;

  dropFacutly?.facultydata?.subject?.forEach((dropSubject) => {
    subjectList.forEach((subject) => {
      if (dropSubject.subject_name === subject.subject_name) {
        subject_id = subject.id;
        return; // Exit the loop once a match is found
      }
    });
  });

  const [confirmationStatus, setConfirmationStatus] = useState(false); // Initialize with false
  const handleConfirmSchedule = () => {
    setConfirmationStatus(true);
  };

  const [validationFacultyData, setvalidationFacultyData] = useState("");
  const [validationBatchData, setvalidationBatchData] = useState("");

  const createScheduling = async () => {
    if (dateStatus) {
      toast.error(
        "Scheduling is restricted for both past dates and the current week.",
        toastConfig
      );
    } else {
      if (state2 && state1 && dropFacutly && state) {
        setvalidationBatchData(state2.batch_code);
        setvalidationFacultyData(
          dropFacutly.facultydata.first_name +
            " " +
            dropFacutly.facultydata.last_name
        );
        let isFacultyInBatch = true;
        if (
          state2 &&
          state2.faculties &&
          dropFacutly &&
          dropFacutly.facultydata
        ) {
          isFacultyInBatch =
            Array.isArray(state2.faculties) &&
            state2.faculties.some(
              (faculty) => faculty.id === dropFacutly.facultydata.id
            );
        }

        if (isFacultyInBatch) {
          const preparePostData = {
            location_id:
              state2?.locations && state2.locations.length > 0
                ? state2.locations[0].id
                : null,
            batch_id: state2?.id || null,
            date: state1?.date || null,
            faculty_id: dropFacutly?.facultydata?.id || null,
            slot_time: state?.[0] || null,
            subject_id: subject_id || null, // Assign the found subject_id or null if not found
          };

          let apiUrl;
          if (selectedScheduleType === "foundation") {
            apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule/foundation?isBatchFaculty=${isFacultyInBatch}`;
          } else if (selectedScheduleType === "jee/medical" 
                      || selectedScheduleType === "jee" 
                      || selectedScheduleType === "medical") {
            apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule?isBatchFaculty=${isFacultyInBatch}`;
          }

          try {
            const response = await apiService.post(apiUrl, preparePostData);

            if (response.data) {
              toast.success("Schedule Successfully Created..", toastConfig);
              setScheduleStatusCheck(true);
              setFacultyHrsUpdate(true);
              setDropFaculty([]);
              getSchedulingData();
            } else {
              toast.error(
                "Schedule creation failed. Please try again.",
                toastConfig
              );
              setFacultyHrsUpdate(false);

              getSchedulingData();
              setDropFaculty([]);
            }
          } catch (error) {
            toast.error(error.response.data.message, toastConfig);
            setFacultyHrsUpdate(false);

            setDropFaculty([]);
          }
        } else {
          setcheckValidationPopUp(true);

          if (confirmationStatus) {
            const prepareData = {
              location_id:
                state2?.locations && state2.locations.length > 0
                  ? state2.locations[0].id
                  : null,
              batch_id: state2?.id || null,
              date: state1?.date || null,
              faculty_id: dropFacutly?.facultydata?.id || null,
              slot_time: state?.[0] || null,
              subject_id: subject_id || null, // Assign the found subject_id or null if not found
            };

            let Url;
            if (selectedScheduleType === "foundation") {
              Url = `${process.env.REACT_APP_API_URL}/createSchedule/foundation?isBatchFaculty=${isFacultyInBatch}`;
            } else if (selectedScheduleType === "jee/medical"
                      || selectedScheduleType === "jee" 
                      || selectedScheduleType === "medical") {
              Url = `${process.env.REACT_APP_API_URL}/createSchedule?isBatchFaculty=${isFacultyInBatch}`;
            }

            try {
              const response = await apiService.post(Url, prepareData);
              if (response.data) {
                toast.success("Schedule Successfully Created..", toastConfig);
                setScheduleStatusCheck(true);
                setcheckValidationPopUp(false);
                setConfirmationStatus(false);

                setFacultyHrsUpdate(true);
                let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
                setLoaderMessage(
                  <pre className=" font-serif ">{autoMessage}</pre>
                );
                setLoading(true);
                checkScheduleValidation(
                  selectedLocation,
                  selectedScheduleType,
                  selectedDate
                )
                  .then(() => {
                    setLoading(false);
                    setScheduleRefresh(true);
                  })
                  .catch(() => {
                    setLoading(false);
                  });
                setDropFaculty([]);
                getSchedulingData();
              } else {
                toast.error(
                  "Schedule creation failed. Please try again.",
                  toastConfig
                );
                setFacultyHrsUpdate(false);
                getSchedulingData();
                setDropFaculty([]);
              }
            } catch (error) {
              toast.error(error.response.data.message, toastConfig);
              setFacultyHrsUpdate(false);
              setConfirmationStatus(false);
              setDropFaculty([]);
            }
          } else {
            console.log("User cancelled the action");
          }
        }
      } else {
        console.log("incomplete data");
      }
    }
  };

  const toggleMenu = (columnIndex, dataItemId) => {
    setIsDropdownOpen(false);
    setOpenColumns((prevOpenItems) => {
      const isCurrentlyOpen = prevOpenItems[columnIndex]?.[dataItemId];

      // Close previously opened menu in the same row and previous column
      let closePreviousMenu = {};
      Object.keys(prevOpenItems).forEach((prevColumnIndex) => {
        closePreviousMenu[prevColumnIndex] = {
          ...prevOpenItems[prevColumnIndex],
        };
        if (prevColumnIndex !== columnIndex) {
          Object.keys(prevOpenItems[prevColumnIndex]).forEach(
            (prevDataItemId) => {
              closePreviousMenu[prevColumnIndex][prevDataItemId] = false;
            }
          );
        }
      });

      // Toggle the current menu
      const updatedOpenItems = {
        ...closePreviousMenu,
        [columnIndex]: {
          ...closePreviousMenu[columnIndex],
          [dataItemId]: !isCurrentlyOpen,
        },
      };

      return updatedOpenItems;
    });
  };

  const handleDrop = (date, time, batchDetails) => {
    const extractedData = {
      index: 0,
      message: "Data not found",
      slotTime: time,
    };

    const result = {
      index: extractedData.index,
      message: extractedData.message,
      slotTime: extractedData.slotTime,
    };

    let combinedTimeSlots = [];

    if (
      batchDetails.mornning_time !== "null" &&
      batchDetails.mornning_time.length > 0
    ) {
      combinedTimeSlots.push(...batchDetails.mornning_time);
    }

    if (
      batchDetails.afternoon_time !== "null" &&
      batchDetails.afternoon_time.length > 0
    ) {
      combinedTimeSlots.push(...batchDetails.afternoon_time);
    }

    if (batchDetails.bathchId.selected_days !== null) {
      const checkScheduleDayIsValid =
        batchDetails.bathchId.selected_days.includes(date.day);

      if (!checkScheduleDayIsValid) {
        const errorMessage = "Schedule day is not valid.";
        const warnMessage = `${batchDetails.bathchId.batch_code} is valid for ${batchDetails.bathchId.selected_days}`;
        toast.warn(warnMessage, toastConfig);
      }
    } else {
      console.log("selected_days is null day count is present ");
    }

    if (result?.slotTime === undefined) {
      const slotTimeToFind = result?.slotTime || result?.slotTime;
      const findSlotTime = combinedTimeSlots.filter((slot) => {
        return slot === slotTimeToFind;
      });
      setState(findSlotTime);
    } else {
      const slotTimeToFind = result?.slotTime || result?.slotTime;
      const findSlotTime = combinedTimeSlots.filter((slot) => {
        return slot === slotTimeToFind;
      });

      setState(findSlotTime);
    }

    setState1(date);
    setState2(batchDetails.bathchId);
  };

  const [, drop] = useDrop({
    accept: "CARD",
    drop: (item, monitor) => {
      setDropFaculty(item);
    },
  });

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );

  useEffect(() => {
    if (dropFacutly.length !== 0) {
      createScheduling();
    }
  }, [dropFacutly]);
  useEffect(() => {
    if (confirmationStatus) {
      createScheduling();
    }
  }, [confirmationStatus]);
  useEffect(() => {
    setcheckValidationPopUp(false);
  }, []);

  useEffect(() => {
    if (dropFacutly) {
      setIsMounted(true);
    }
  }, [dropFacutly]);

  function formatDateddmmyy(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day}-${month}-${year}`;
  }
  const [isOpenCreateSchedule, setIsOpenCreateSchedule] = useState(false);

  const [facultyData, setFacultyData] = useState([]);

  const facultyURL = `${
    process.env.REACT_APP_API_URL
  }/getfacultydata?q=&limit=${100}&page=&gender=&sortBy=updated_at&sortOrder=DESC&location_id=${selectedLocation}&stream_code=${streamCode}`;

  const [facultyAssignCheckMessage, setfacultyAssignCheckMessage] =
    useState("");

  const getFacutyData = async () => {
    const Response = await apiService.get(facultyURL);
    if (Response) {
      try {
        const mappingResponse = Response.data.map((response) => {
          const subjectData = response.subject.map((subject) => ({
            id: subject.id,
            code: subject.subject_name,
            label: subject.subject_name,
          }));

          return {
            label: (
              <>
                <div className="flex">
                  {response.image_url ? (
                    <img
                      src={response.image_url}
                      onError={(e) => {
                        e.target.src = defaultImage;
                      }}
                      className="h-8 w-8 rounded-full mr-1"
                      alt="Item Image"
                    />
                  ) : (
                    <img
                      src={defaultImage}
                      className="h-8 w-8 rounded-full "
                      alt="Default Image"
                    />
                  )}

                  {response.first_name + " " + response.last_name + " "}
                  {subjectData.map((subject, index) => (
                    <span
                      key={index}
                      style={{ color: "red" }}
                      className=" ml-1 "
                    >
                      ({subject.code})
                    </span>
                  ))}
                </div>
              </>
            ),
            value: response.faculty_code,
            facultyId: response.id,
            subjectData: subjectData,
          };
        });

        setFacultyData(mappingResponse);
      } catch (error) {
        console.error("Error mapping faculty data:", error);
      }
    }
  };

  useEffect(() => {
    if (isOpenCreateSchedule) {
      getFacutyData();
    }
  }, [isOpenCreateSchedule]);

  const [selectedSubjectData, setSelectedSubjectData] = useState([]);

  const closePopupCreateSchedule = () => {
    setIsOpenCreateSchedule(false);
    setSelectedSubjectData([]);
    setSubjectId([]);
    setfacultyAssignCheckMessage("");
  };

  const [createScheduleDate, setCreateScheduleDate] = useState([]);
  const [createScheduleTime, setCreateScheduleTime] = useState([]);
  const [createScheduleBatchDetails, setCreateScheduleBatchDetails] = useState(
    []
  );
  const [batchNameHeadingForPop, setbatchNameHeadingForPop] = useState([]);

  const [FacultyDetails, setFacultyDetails] = useState([]);

  const [selectTimePerticulatBatchData, setselectTimePerticulatBatchData] =
    useState([]);

  const handleTimeClick = (item, time, group) => {
    setselectTimePerticulatBatchData(group.bathchId);
    setIsDropdownOpen(false);

    if (dateStatus) {
      toast.error(
        "Scheduling is restricted for both past dates and the current week.",
        toastConfig
      );
    } else {
      setCreateScheduleDate(item);
      setCreateScheduleTime(time);
      setbatchNameHeadingForPop(group.bathchId.batch_code);
      setCreateScheduleBatchDetails(group);

      setIsOpenCreateSchedule(true);
      toggleMenu(false);
    }
  };

  const [SubjectId, setSubjectId] = useState([]);

  let isFacultyInBatchData = true;
  useEffect(() => {
    if (FacultyDetails) {
      isFacultyInBatchData =
        Array.isArray(selectTimePerticulatBatchData.faculties) &&
        selectTimePerticulatBatchData.faculties.some(
          (faculty) => faculty.id === FacultyDetails.facultyId
        );
      const message = isFacultyInBatchData
        ? ""
        : "Faculty not assigned to batch. Are you sure you want to continue?";
      setfacultyAssignCheckMessage(message);
    }
  }, [selectTimePerticulatBatchData, FacultyDetails]);

  const handleCreateSchedule = async () => {
    const preparePostData = {
      location_id: createScheduleBatchDetails.bathchId.locations[0].id,

      batch_id: createScheduleBatchDetails.bathchId?.id || null,
      date: createScheduleDate?.date || null,
      faculty_id: FacultyDetails?.facultyId || null,
      slot_time: createScheduleTime || null,
      subject_id: SubjectId || null,
    };

    let apiUrl;
    if (selectedScheduleType === "foundation") {
      apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule/foundation?isBatchFaculty=${isFacultyInBatchData}`;
    } else if (selectedScheduleType === "jee/medical"
                      || selectedScheduleType === "jee" 
                      || selectedScheduleType === "medical") {
      apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule?isBatchFaculty=${isFacultyInBatchData}`;
    }
    try {
      const response = await apiService.post(apiUrl, preparePostData);

      if (response.data) {
        setIsOpenCreateSchedule(false);
        setSelectedSubjectData([]);
        setSubjectId([]);
        setFacultyDetails([]);
        let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
        setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
        setLoading(true);
        checkScheduleValidation(
          selectedLocation,
          selectedScheduleType,
          selectedDate
        )
          .then(() => {
            setLoading(false);
            setScheduleRefresh(true);
          })
          .catch(() => {
            setLoading(false);
          });
        toast.success("Schedule Successfully Created..", toastConfig);
        setScheduleStatusCheck(true);
        setFacultyHrsUpdate(true);
        getSchedulingData();
      } else {
        toast.error("Schedule creation failed. Please try again.", toastConfig);
        setFacultyHrsUpdate(false);

        getSchedulingData();
      }
    } catch (error) {
      toast.error(error.response.data.message, toastConfig);
      setFacultyHrsUpdate(false);
    }
  };

  const openPopup = (id, faculty_name) => {
    setIsDropdownOpen(!isDropdownOpen);

    setDeleteScheduleId(id);
    setDeleteFacultyname(faculty_name);

    setIsOpen(true);
  };

  return (
    <>
      <ToastContainer />
   
      <div className="mt-4 ml-5" style={{ width: '28vw' }}>
     
        <div className="relative">
          <input
            type="text"
            id="batchSearch"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by batch code"
            className="px-3 py-2 border border-gray-300 focus:outline-none focus:ring-green-800 focus:border-green-800 mb-4 w-full pl-10"
            autoComplete="off"
          />
          <FaSearch className="absolute left-3 top-3 text-gray-500" />
        </div>
      </div>

     


    
      {allFilteredAndSortedData.length > 0 ? (
        <div ref={drop} className="ml-4 font-serif customHeight">
          <table className="bg-secondaryColour ">
            <Popup
              isOpen={checkValidationPopUp}
              onClose={closePopupcheckValidationPopUp}
              heading={`CreateSchedule (${validationBatchData})`}
            >
              <h6 className="font-serif text-red-500">
                Faculty <u>{validationFacultyData}</u> is not assigned for this
                batch. Are you sure you want to continue?
              </h6>
              <div className="button-container">
                <button
                  className="text-gray-900 font-serif  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                  onClick={closePopupcheckValidationPopUp}
                >
                  No
                </button>
                <button
                  className=" font-serif text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  onClick={() => handleConfirmSchedule()}
                >
                  Yes
                </button>
              </div>
            </Popup>
            <Popup
              isOpen={isOpen}
              onClose={closePopup}
              heading={deleteFacultyname}
            >
              <h6 className="font-serif">
                Do you want to delete the Schedule ?
              </h6>
              <div className="button-container">
                <button
                  className="text-gray-900 font-serif  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                  onClick={closePopup}
                >
                  No
                </button>
                <button
                  className=" font-serif text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  onClick={() => handleDeleteSchedule()}
                >
                  Yes
                </button>
              </div>
            </Popup>

            <Popup
              isOpen={isOpenCreateSchedule}
              onClose={closePopupCreateSchedule}
              heading={`Create Schedule (${batchNameHeadingForPop} ${createScheduleTime})`}
            >
              <div className="  col-span-6 mt-1 " style={{ width: "24vw" }}>
                <Select
                  placeholder="Select Faculty"
                  options={facultyData}
                  onChange={(selectedOption) => {
                    const subjectData = selectedOption?.subjectData || [];
                    setSelectedSubjectData(subjectData);

                    setFacultyDetails(selectedOption);

                    if (subjectData.length === 1) {
                      setSubjectId(subjectData[0].id);
                    }
                  }}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 0,
                      border: "none",
                      outline: "2px solid green",
                      boxShadow: "none",
                      fontSize: "0.9vw",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                  className="custom-select"
                  classNamePrefix="custom-select"
                  isMulti={false}
                  components={{
                    ClearIndicator: () => null,
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    SingleValue: (props) => (
                      <components.SingleValue
                        {...props}
                        className="flex justify-center"
                      >
                        {props.data.label}
                        {props.data.value && (
                          <div
                            onClick={() => {
                              props.clearValue(); // Clear the selected value
                              setfacultyAssignCheckMessage(""); // Set facultyAssignCheckMessage to an empty string
                            }}
                            // onClick={props.clearValue}
                            style={{ marginLeft: "20px" }}
                            className=" h-14 w-8  cursor-pointer"
                          >
                            <span
                              className=" cursor-pointer "
                              style={{ fontSize: "20px" }}
                            >
                              &times;
                            </span>
                          </div>
                        )}
                      </components.SingleValue>
                    ),
                  }}
                />
              </div>

              <div className=" mt-2">
                {selectedSubjectData.length > 0 && (
                  <>
                    <div className=" flex ">
                      <label
                        className="subject-label text-lg font-bold"
                        htmlFor=""
                      >
                        Select Subject:
                      </label>
                      {selectedSubjectData.map((subject, index) => (
                        <div key={subject.id} className="ml-2">
                          <input
                            className=" mr-1 "
                            type="radio"
                            id={subject.id}
                            name="selectedSubject"
                            value={subject.id}
                            checked={SubjectId === subject.id}
                            onChange={() => {
                              setSubjectId(subject.id);
                            }}
                          />

                          <label htmlFor={subject.id} className="text-lg mr-2 ">
                            {subject.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <p style={{ color: "red" }}>{facultyAssignCheckMessage}</p>
                  </>
                )}
              </div>

              <div className="button-container">
                <button
                  className="text-gray-900  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                  onClick={() => {
                    setIsOpenCreateSchedule(false);
                    setSelectedSubjectData([]);
                    setSubjectId([]);

                    setFacultyDetails([]);
                  }}
                >
                  Cancel
                </button>
                <button
                  className=" text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  onClick={() => handleCreateSchedule()}
                  disabled={
                    !FacultyDetails ||
                    (FacultyDetails.length === 0 && !SubjectId) ||
                    typeof SubjectId !== "string" ||
                    SubjectId.trim() === "" ||
                    dateStatus
                  }
                >
                  Create
                </button>
              </div>
            </Popup>
            <div>
     
    </div>
    
            <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
              {" "}
              <tr
                className={` ${
                  compactSizeTable ? "text-xs" : "text-sm"
                } font-semibold text-left font-serif  uppercase border-b border-black bg-primaryColour text-white p-auto`}
              >
                <th class=" px-12 py-1 font-serif text-center border border-white ">
                  Batch Codes
                </th>

                <TableHeading
                  data={allFilteredAndSortedData[0].data}
                  formatDateddmmyy={formatDateddmmyy}
                />
              </tr>
            </thead>
            <tbody>
              <TableBody
                data={allFilteredAndSortedData}
                compactSizeTable={compactSizeTable}
                handleDrop={handleDrop}
                toggleMenu={toggleMenu}
                handleTimeClick={handleTimeClick}
                getSchedulingData={getSchedulingData}
                openColumns={openColumns}
                Humberger={Humberger}
                setScheduleRefresh={setScheduleRefresh}
                setSelectedCheckboxes={setSelectedCheckboxes}
                selectedCheckboxes={selectedCheckboxes}
                isSwapSchedule={isSwapSchedule}
                handleOpenPopup={handleOpenPopup}
              />
            </tbody>
          </table>
          <div
            className=" mt-14"
            style={{ position: " sticky ", bottom: 0, left: 0, right: 0 }}
          ></div>
        </div>
      ) : (
        <p className=" font-serif " style={{ textAlign: "center" }}>
          No Batch Available this location
        </p>
      )}
      {allFilteredAndSortedData.length > 0 && (
        <AutoScheduleErrorTable
          hardRuleError={hardRuleError}
          setIsDropdownOpen={setIsDropdownOpen}
          isDropdownOpen={isDropdownOpen}
          formatDateddmmyy={formatDateddmmyy}
          handleEditErrorClick={handleEditErrorClick}
          openPopup={openPopup}
        />
      )}
    </>
  );
};

export default Table;
