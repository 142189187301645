import React from "react";

const LeaveDialogue = ({ isOpen, setIsModalOpen, children }) => {
  if (!isOpen) return null;
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black  bg-opacity-50">
      <div className="relative bg-white w-full max-w-lg p-6 rounded shadow-xl">
        <button
          className="absolute top-2 right-2 text-2xl text-gray-700 hover:text-gray-900 font-bold focus:outline-none"
          onClick={handleCloseModal}
          aria-label="Close"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default LeaveDialogue;
