import React, { useEffect, useState, useContext } from "react";
import { IoIosClose, IoIosFlag } from "react-icons/io";
import { AiFillEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import LocationContext from "../../context/LocationContext";

const AutoScheduleErrorTable = ({
  openErrorsDialog,
  setOpenErrorsDialog,
  hardRuleError,
  setHardRuleError,
  facultyRuleError,
  setFacultyRuleError,
  setIsDropdownOpen,
  isDropdownOpen,
  handleEditErrorClick,
  setDeleteScheduleId,
  setIsOpen,
  searchError,
  setSearchError,
}) => {
  const { selectedDate } = useContext(LocationContext);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [activeTab, setActiveTab] = useState(0);

  const handleOpenPopup = (id) => {
    setDeleteScheduleId(id);
    setIsOpen(true);
    setIsDropdownOpen(false);
  };

  const toggleContainerVisibility = () => {
    if (
      Math.round(hardRuleError.length) === 0 &&
      Math.round(facultyRuleError.length) !== 0
    ) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
    setSearchError("");
    setIsDropdownOpen(!isDropdownOpen);
    setIsOverlayOpen(!isOverlayOpen);
    setOpenErrorsDialog(false);
  };

  const handleCloseOverlay = (e) => {
    if (e.target.id === "overlay") {
      setIsOverlayOpen(false);
      setIsDropdownOpen(false);
      setOpenErrorsDialog(false);
    }
  };

  const sortData = (key) => {
    let direction = "descending";
    if (sortConfig.key === key && sortConfig.direction === "descending") {
      direction = "ascending";
    }

    const sortedData = [
      ...(activeTab === 0 ? hardRuleError : facultyRuleError),
    ].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    activeTab === 0
      ? setHardRuleError(sortedData)
      : setFacultyRuleError(sortedData);
  };

  const getSortIcon = (key) => {
    if (key === "#") return "";

    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "↑" : "↓";
    }

    return "↑";
  };
  const [headerState, setHeaderState] = useState({
    date: "Date",
    faculty_code: "Faculty Code",
    faculty_name: "Faculty Name",
    message: "Message",
    batch_name: "Batch Name",
    time: "Time",
    subject_name: "Subject Name",
  });
  const formatHeader = (key) => {
    return headerState[key];
  };
  const filterData = (data) => {
    if (!searchError) return data;
    return data.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchError.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (openErrorsDialog === true) {
      setActiveTab(1);
      setIsDropdownOpen(true);
      setIsOverlayOpen(true);
    }
  }, [openErrorsDialog]);
  return (
    <>
      <div className="absolute right-6 bottom-12 rounded-r-full shadow-md">
        <div
          className="flex items-center justify-between  bg-white  p-2 border rounded-full border-primaryColour cursor-pointer relative"
          onClick={toggleContainerVisibility}
          id="chatbot-toggle"
        >
          <div className="flex items-center">
            <IoIosFlag
              style={{
                color: Math.round(hardRuleError.length) > 0 ? "red" : "green",
              }}
              className="text-xl"
            />
            <span
              className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 px-2 rounded-full text-white"
              style={{
                backgroundColor:
                  Math.round(hardRuleError.length) > 0 ? "red" : "green",
              }}
            >
              {Math.round(hardRuleError.length)}
            </span>
            <span
              className="absolute top-[30px] right-0 transform translate-x-1/2 -translate-y-1/2 px-2 rounded-full text-white"
              style={{
                backgroundColor:
                  Math.round(facultyRuleError.length) > 0 ? "orange" : "green",
              }}
            >
              {Math.round(facultyRuleError.length)}
            </span>
          </div>
        </div>
      </div>

      {isOverlayOpen && (
        <div
          id="overlay"
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCloseOverlay}
        >
          <div
            className="chatDiv bg-white p-4 pt-2 rounded-lg shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-end bg-white sticky top-0 z-10">
              <button onClick={toggleContainerVisibility} className="text-2xl">
                <IoIosClose />
              </button>
            </div>
            <Tabs
              selectedIndex={activeTab}
              onSelect={(index) => setActiveTab(index)}
            >
              <TabList>
                <Tab>
                  <h6 className="font-normal">
                    Schedules Errors ({hardRuleError?.length})
                  </h6>
                </Tab>
                <Tab>
                  <h6 className="font-normal">
                    Faculties Errors ({facultyRuleError?.length})
                  </h6>
                </Tab>
              </TabList>
              <input
                type="text"
                placeholder="Search..."
                value={searchError}
                onChange={(e) => setSearchError(e.target.value)}
                className="flex mt-2 mb-4 p-2 ml-auto border border-gray-300 rounded"
              />
              <TabPanel>
                <div className="h-[500px] w-[94rem] overflow-auto">
                  <table className=" w-full divide-y divide-gray-200 bg-white">
                    <thead className="bg-primaryColour text-white sticky top-0">
                      <tr>
                        {[
                          "#",
                          "batch_name",
                          "date",
                          "time",
                          "subject_name",
                          "faculty_code",
                          "faculty_name",
                          "message",
                        ].map((key) => (
                          <th
                            key={key}
                            onClick={() => sortData(key)}
                            className="px-4 py-3 text-left text-base font-bold text-primary font-serif cursor-pointer"
                          >
                            {/* {key.split("_").join(" ")} {getSortIcon(key)} */}
                            {formatHeader(key)} {getSortIcon(key)}

                          </th>
                        ))}
                        <th className="px-4 py-3 text-left text-base font-bold text-primary font-serif">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {filterData(hardRuleError).length === 0 ? (
                        <tr>
                          <td
                            colSpan="9"
                            className="text-center py-4 text-gray-800 font-serif"
                          >
                            No Errors available
                          </td>
                        </tr>
                      ) : (
                        filterData(hardRuleError).map((item, i) => (
                          <tr className="hover:bg-gray-100" key={i}>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {i + 1}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.batch_name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {moment(item.date).format("LL")}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.time}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.subject_name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.faculty_code}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.faculty_name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.message}
                            </td>
                            <td className="px-4 py-4 flex items-center space-x-3 text-sm text-gray-800 font-serif">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleContainerVisibility();
                                  handleEditErrorClick(item);
                                }}
                                className="text-xl text-green-700"
                              >
                                <AiFillEdit />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleContainerVisibility();
                                  handleOpenPopup(item.id);
                                }}
                                className="text-xl text-red-700"
                              >
                                <RiDeleteBin6Line />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="h-[500px] w-[94rem] overflow-auto">
                  <table className=" w-full divide-y divide-gray-200 bg-white">
                    <thead className="bg-primaryColour text-white sticky top-0">
                      <tr>
                        {[
                          "#",
                          "date",
                          "faculty_code",
                          "faculty_name",
                          "message",
                        ].map((key) => (
                          <th
                            key={key}
                            onClick={() => sortData(key)}
                            className="px-4 py-3 text-left text-base font-bold text-primary font-serif cursor-pointer"
                          >
                            {formatHeader(key)} {getSortIcon(key)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {filterData(facultyRuleError).length === 0 ? (
                        <tr>
                          <td
                            colSpan="8"
                            className="text-center py-4 text-gray-800 font-serif"
                          >
                            No Errors available
                          </td>
                        </tr>
                      ) : (
                        filterData(facultyRuleError).map((item, i) => (
                          <tr className="hover:bg-gray-100" key={i}>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {i + 1}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {moment(item.date).format("LL")}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              <a
                                href={`/facultyview/${
                                  item.faculty_id
                                }?date=${moment(item.date).format(
                                  "YYYY-MM-DD"
                                )}`}
                                target="_blank"
                                key={item.id}
                                style={{
                                  color: "black",
                                  transition: "color 0.3s",
                                }}
                                rel="noreferrer"
                              >
                                {item.faculty_code}
                              </a>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.faculty_name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-800 font-serif">
                              {item.message}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};

export default AutoScheduleErrorTable;
