import React from "react";
import noSchedulePic from "../../utils/Images/nophoto2i.png";
import ByDefaultImage from "../../utils/Images/defaultImage.jpg";

const SlotComponent = ({
  slotType,
  group,
  compactSizeTable,
  IoIosFlag,
  Humberger,
  AfterNoonIMG,
  MorningIMG,
  toggleMenu,
  openColumns,
  getSchedulingData,
  setScheduleRefresh,
  setSelectedCheckboxes,
  selectedCheckboxes,
  isSwapSchedule,
  handleOpenPopup,
  defaultImage,
  handleTimeClick,
  handleDrop,
  nophoto,
}) => {
  const timeArray = [...group?.mornning_time, ...group?.afternoon_time];

  const imageSource = slotType === "morning" ? MorningIMG : AfterNoonIMG;

  return (
    <>
      <tr key={group.batchid}>
        <td className="border font-serif  border-gray-400 text-center  gap-4  tooltip ">
          <div className={`${compactSizeTable ? "text-xs" : "text-sm"}`}>
            <img
              src={imageSource}
              alt=""
              style={{
                width: compactSizeTable ? "2vw" : "3vw",

                margin: "auto",
              }}
            />
            <span class="tooltiptext font-serif ">{group.batch_code}</span>
            {compactSizeTable
              ? group.batch_code.substring(0, 20) + "..."
              : group.batch_code.length > 15
              ? `${group.batch_code.substring(0, 20)}...`
              : group.batch_code}
          </div>
        </td>

        {group.data.map((item) => (
          <td key={item.date} class="border font-serif border-gray-400  ">
            {item.data.length > 0 ? (
              <div
                className=" grid-cols-1  flex "
                style={{
                  position: "relative",
                }}
              >
                {timeArray.map((time, columnIndex) => {
                  const matchingDataItems = item.data.filter((dataItem) => {
                    const slotTimeParts = dataItem.slot_time
                      .split("-")[0]
                      .split(":");
                    const slotHour = parseInt(slotTimeParts[0], 10);
                    const slotMinute = parseInt(slotTimeParts[1], 10);

                    const morningTimeParts = time.split("-")[0].split(":");
                    const morningHour = parseInt(morningTimeParts[0], 10);
                    const morningMinute = parseInt(morningTimeParts[1], 10);

                    return (
                      slotHour === morningHour && slotMinute === morningMinute
                    );
                  });

                  return matchingDataItems.length > 0 ? (
                    matchingDataItems.map((dataItem, index) => (
                      <div
                        key={dataItem.date}
                        className=" text-center font-serif tooltip "
                        style={{
                          width: compactSizeTable ? "74px" : "10vw",
                          height: compactSizeTable ? "86px" : "9vw", //
                          alignItems: "center",
                          justifyContent: "center",
                          border:
                            dataItem.error && dataItem.error.length > 0
                              ? "4px solid red"
                              : dataItem.status === "draft"
                              ? "2px solid black"
                              : "none",
                          boxShadow:
                            dataItem.status === "draft"
                              ? "0px 0px 10px rgba(0, 0, 0, 0.5)"
                              : "none",
                          backgroundColor:
                            dataItem.subject.subject_color_code ||
                            "transparent",
                        }}
                      >
                        {dataItem.error && dataItem.error.length > 0 && (
                          <span className="tooltiptext font-serif">
                            {dataItem.error[0]}
                          </span>
                        )}
                        <div key={`${dataItem.id}_${index}`}>
                          <IoIosFlag
                            className={
                              compactSizeTable
                                ? "text-red-500 text-base font-bold"
                                : "text-red-500 text-2xl font-bold"
                            }
                            style={{
                              margin: "3%",
                              opacity:
                                dataItem.error && dataItem.error.length > 0
                                  ? 1
                                  : 0,
                            }}
                          />
                          <Humberger
                            key={dataItem.id}
                            dataItem={dataItem}
                            isOpens={openColumns[columnIndex]?.[dataItem.id]}
                            toggleMenu={() =>
                              toggleMenu(columnIndex, dataItem.id)
                            }
                            getSchedulingData={getSchedulingData}
                            isCompactSIze={compactSizeTable}
                            setScheduleRefresh={setScheduleRefresh}
                            setSelectedCheckboxes={setSelectedCheckboxes}
                            selectedCheckboxes={selectedCheckboxes}
                            isSwapSchedule={isSwapSchedule}
                            onOpenPopup={handleOpenPopup}
                          />
                        </div>
                        <div
                          style={{
                            display: "grid ",
                            placeItems: "center",
                          }}
                        >
                          <div
                            className=" "
                            style={{
                              marginLeft: compactSizeTable ? "40px" : "9.7vw",

                              display: "flex",
                            }}
                          ></div>
                          {dataItem.faculty && !compactSizeTable && (
                            <th>
                              {dataItem.faculty.image_url ? (
                                <img
                                  src={dataItem.faculty.image_url}
                                  onError={(e) => {
                                    e.target.src = ByDefaultImage;
                                  }}
                                  style={{
                                    width: "3.2vw",
                                    height: "3.7vw",
                                  }}
                                  className="rounded-full"
                                />
                              ) : (
                                <img
                                  src={ByDefaultImage}
                                  alt="Default Faculty Image"
                                  style={{
                                    width: "3.2vw",
                                    height: "3.7vw",
                                  }}
                                  className="rounded-full"
                                />
                              )}
                            </th>
                          )}

                          <p
                            className=" text-center  text-xs font-serif"
                            style={{
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            {" "}
                            {dataItem.subject.subject_code +
                              group.bathchId.batch_stream[0].stream_code +
                              dataItem.faculty.faculty_code}
                          </p>
                          <span
                            className=" px-2 text-xs font-serif"
                            style={{
                              color: "black",
                            }}
                          >
                            {dataItem.slot_time}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      key={time}
                      className={
                        compactSizeTable
                          ? "py-3  text-center cursor-pointer"
                          : " text-center cursor-pointer"
                      }
                      style={{
                        width: compactSizeTable ? "74px" : "10vw",
                        height: compactSizeTable ? "" : "6vw", //

                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => handleTimeClick(item, time, group)}
                      onDrop={(event) => {
                        handleDrop(
                          item, //date
                          time, //drop time
                          group //batchDetails
                        );
                      }}
                    >
                      <div
                        className={
                          compactSizeTable
                            ? " font-serif cursor-pointer relative "
                            : "px-8 font-serif cursor-pointer relative"
                        }
                        style={{
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        {!compactSizeTable && (
                          <img
                            src={noSchedulePic}
                            alt="Faculty Image"
                            style={{
                              width: "3.5vw",
                              height: "3.7vw",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="time-text"
                          />
                        )}
                        <p
                          className={
                            compactSizeTable ? "p-2 font-serif" : " font-serif"
                          }
                        >
                          <div>
                            <p
                              className={
                                compactSizeTable
                                  ? "font-serif cursor-pointer relative"
                                  : "font-serif cursor-pointer relative"
                              }
                              style={{ position: "relative" }}
                            >
                              {compactSizeTable && (
                                <button className="plus-icon bg-white hover:bg-gray-100 text-gray-800 text-2xl font-bold py-1 px-3 border border-gray-400 shadow hidden">
                                  +
                                </button>
                              )}

                              {!compactSizeTable && (
                                <button className="plus-icon bg-white hover:bg-gray-100 text-gray-800 text-2xl font-bold py-4 px-6 mt-2 border border-gray-400 shadow hidden">
                                  +
                                </button>
                              )}
                              <div
                                className={compactSizeTable ? "time-text" : ""}
                              >
                                {time}
                              </div>
                            </p>
                          </div>
                        </p>

                        {compactSizeTable ? null : (
                          <span className="font-serif time-text">
                            No Schedule
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className=" grid-cols-1  flex  font-serif  "
                style={{ display: "flex" }}
              >
                {timeArray.map((time, index) => (
                  <div
                    key={index}
                    onDrop={(event) => {
                      handleDrop(
                        item, //date
                        time, //drop time
                        group //batchDetails
                      );
                    }}
                    className=" font-serif cursor-pointer relative  "
                    onClick={() => handleTimeClick(item, time, group)}
                    style={{
                      width: compactSizeTable ? "74px" : "10vw",
                      height: compactSizeTable ? "" : "8vw", //

                      paddingLeft: compactSizeTable ? "0.5vw" : "2.5vw",
                    }}
                  >
                    {!compactSizeTable && (
                      <img
                        src={noSchedulePic}
                        alt="Faculty Image"
                        style={{
                          width: "3.5vw",
                          height: "3.7vw",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="time-text"
                      />
                    )}
                    <p
                      className={
                        compactSizeTable
                          ? "pl-2 font-serif cursor-pointer relative"
                          : "font-serif cursor-pointer relative"
                      }
                      style={{ position: "relative" }}
                    >
                      {compactSizeTable && (
                        <button className="plus-icon bg-white hover:bg-gray-100 text-gray-800 text-2xl font-bold py-1 px-3 border border-gray-400 shadow hidden">
                          +
                        </button>
                      )}

                      {!compactSizeTable && (
                        <button className="plus-icon bg-white hover:bg-gray-100 text-gray-800 text-2xl font-bold py-4 px-6 mt-2 border border-gray-400 shadow hidden">
                          +
                        </button>
                      )}
                      <div className={compactSizeTable ? "time-text" : ""}>
                        {time}
                      </div>
                    </p>

                    {compactSizeTable ? null : (
                      <span className="font-serif time-text">No Schedule</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </td>
        ))}
      </tr>
    </>
  );
};

export default SlotComponent;
