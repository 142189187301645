
import React from 'react';


const TableHeading = ({ data , formatDateddmmyy}) => {
  return (
    <>
      {data.map((item) => (
        <th
          key={item.date}
          style={{
            height: "0.6vw",
            width: "12vw",
          }}
          className="text-center border border-white font-serif"
        >
          {formatDateddmmyy(item.date)} - {item.day}
        </th>
      ))}
    </>
  );
};

export default TableHeading;


