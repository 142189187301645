import React, { useState } from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

const TimePopup = ({
  errorMessage,
  startTime,
  endTime,
  heading,
  handleSubmit,
  onSubmit,
  handleEndTimeChange,
  cancelPopup,
  popUpButton,
  handleStartTimeChange,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black  bg-opacity-50">
      <form onSubmit={handleSubmit(onSubmit)} className="relative bg-white w-full max-w-lg p-6 rounded shadow-xl">
      <h2 className="text-lg font-semibold">{heading}</h2>
        <section className="mt-2">
          <hr />
          <div className="flex justify-between items-center">
            <TimePicker
              showSecond={false}
              onChange={handleStartTimeChange}
              format="HH:mm"
              use12Hours
              inputReadOnly
              placeholder="Start Time"
              value={startTime}
            />
            <span className="mx-4">-</span>
            <TimePicker
              showSecond={false}
              className="xxx"
              onChange={handleEndTimeChange}
              format="HH:mm"
              use12Hours
              inputReadOnly
              placeholder="End Time"
              value={endTime}
            />
          </div>
          <p className="  text-red-700" >{errorMessage}</p>
        </section>
        <div className=" mt-4">
        <button
            type="submit"
            className="text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2"
          >
            {popUpButton ? "Update" : "Create"}
          </button>
          <button
            className="text-gray-900 close-button bg-gray-50 border border-gray-300 hover:bg-gray-100 font-medium text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
            onClick={cancelPopup}
          >
            Cancel
          </button>

        </div>
      </form>
    </div>
  );
};

export default TimePopup;
