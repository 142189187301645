import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { apiService } from "../../../Services/Services";
import { RiDeleteBin6Line } from "react-icons/ri";
import "../BatchCreate/BatchCreate.css";
import LocationContext from "../../../context/LocationContext";
import { FaPlus } from "react-icons/fa";

const BatchEdit = () => {
  const [fixedChecked, setFixedChecked] = useState(false);
  const [flexibleChecked, setFlexibleChecked] = useState(false);
  const [slotTimeError, setslotTimeError] = useState("");
  const [sessionValues, setSessionValues] = useState([]);
  const [specialBatchChecked, setSpecialBatchChecked] = useState(false);
  const handleSpecialBatchChecked = () => {
    console.log(specialBatchChecked);
    setSpecialBatchChecked(!specialBatchChecked);
  };
  const handleFixedCheckboxChange = () => {
    setFixedChecked(!fixedChecked);
    setFlexibleChecked(false);
  };

  const handleFlexibleCheckboxChange = () => {
    setFlexibleChecked(!flexibleChecked); //

    setFixedChecked(false);
  };
  useEffect(() => {
    if (flexibleChecked) {
      setSelectedOptions([]);
      console.log("fixedChecked");
    }
  }, [fixedChecked, flexibleChecked]);

  const { id } = useParams();
  const { selectedLocation, setActiveMenuStatus } = useContext(LocationContext);
  setActiveMenuStatus(id);

  const [isEditMode, setIsEditMode] = useState(false);

  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [startTime, setStartTime] = useState({
    hour: "12",
    minute: "00",
    meridiem: "AM",
  });
  const [endTime, setEndTime] = useState({
    hour: "12",
    minute: "00",
    meridiem: "AM",
  });
  const [timeRanges, setTimeRanges] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTimeChange = (time, setTime) => {
    setTime(time);
  };
  // const customFilterOption = (option, searchText) => {
  //   const label = option.label.props.children.reduce((acc, child) => {
  //     if (typeof child === "string") {
  //       return acc + child;
  //     } else if (child.type === "span") {
  //       return acc + child.props.children;
  //     }
  //     return acc;
  //   }, "");

  //   return label.toLowerCase().includes(searchText.toLowerCase());
  // };
  const handleAddTimeRange = () => {
    if (
      !startTime.hour ||
      !startTime.minute ||
      !startTime.meridiem ||
      !endTime.hour ||
      !endTime.minute ||
      !endTime.meridiem
    ) {
      setErrorMessage("Please select both start and end time.");
      return;
    }

    let startTotalMinutes =
      ((parseInt(startTime.hour, 10) % 12) +
        (startTime.meridiem === "PM" ? 12 : 0)) *
        60 +
      parseInt(startTime.minute, 10);

    let endTotalMinutes =
      ((parseInt(endTime.hour, 10) % 12) +
        (endTime.meridiem === "PM" ? 12 : 0)) *
        60 +
      parseInt(endTime.minute, 10);

    if (endTotalMinutes < startTotalMinutes) {
      endTotalMinutes += 24 * 60; // Add 24 hours worth of minutes
    }

    if (startTotalMinutes < endTotalMinutes) {
      const newTimeRangeString = `${startTime.hour}:${startTime.minute} ${startTime.meridiem} - ${endTime.hour}:${endTime.minute} ${endTime.meridiem}`;

      const isOverlap = timeRanges.some((existingRange) => {
        const [existingStart, existingEnd] = existingRange.split(" - ");
        const [existingStartHour, existingStartMinute, existingStartMeridiem] =
          existingStart.split(/:|\s/);
        const [existingEndHour, existingEndMinute, existingEndMeridiem] =
          existingEnd.split(/:|\s/);

        // Convert existing time range to total minutes since midnight
        let existingStartTotalMinutes =
          ((parseInt(existingStartHour, 10) % 12) +
            (existingStartMeridiem === "PM" ? 12 : 0)) *
            60 +
          parseInt(existingStartMinute, 10);
        let existingEndTotalMinutes =
          ((parseInt(existingEndHour, 10) % 12) +
            (existingEndMeridiem === "PM" ? 12 : 0)) *
            60 +
          parseInt(existingEndMinute, 10);

        // Adjust for midnight (12 AM)
        if (
          parseInt(existingStartHour, 10) === 12 &&
          existingStartMeridiem === "AM"
        ) {
          existingStartTotalMinutes = 0;
        }
        if (
          parseInt(existingEndHour, 10) === 12 &&
          existingEndMeridiem === "AM"
        ) {
          existingEndTotalMinutes = 0;
        }

        // Check for overlap
        return (
          startTotalMinutes < existingEndTotalMinutes &&
          endTotalMinutes > existingStartTotalMinutes
        );
      });

      // If there's an overlap, set an error message
      if (isOverlap) {
        setErrorMessage(
          "New time range overlaps with an existing range. Please select a different time range."
        );
      } else {
        // If there's no overlap, add the new time range
        setTimeRanges([...timeRanges, newTimeRangeString]);
        setErrorMessage("");
      }
    } else if (startTotalMinutes === endTotalMinutes) {
      setErrorMessage("Start time should not be equal to end time.");
    } else {
      setErrorMessage("Start time should be less than end time.");
    }
  };

  const handleDeleteTimeRange = (index) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges.splice(index, 1);
    setTimeRanges(newTimeRanges);
  };
  const [facultyData, setFacultyData] = useState([]);
  const [batchData, setBatchData] = useState({});
  const [batchTypeData, setBatchTypeData] = useState([]);
  const [batchStreamData, setBatchStreamData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [durationType, setDurationType] = useState("");
  const [duration, setDuration] = useState("");

  const [batchCode, setBatchCode] = useState("");
  const options = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },

    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (value) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(value)) {
        return prevSelectedOptions.filter((option) => option !== value);
      } else {
        return [...prevSelectedOptions, value];
      }
    });
  };

  const locationURL = `${process.env.REACT_APP_API_URL}/getlocation?q=&limit=&page=&name=&sortBy=name&sortOrder=DESC`;
  const batchStreamURL = `${process.env.REACT_APP_API_URL}/getBatchStream`;
  const batchSlotURL = `${process.env.REACT_APP_API_URL}/getBatchslot?q=&limit=&page=&name=&sortBy=name&sortOrder=DESC`;
  const batchDataURL = `${process.env.REACT_APP_API_URL}/showBatchById/${id}`;
  const batchTypeURL = `${process.env.REACT_APP_API_URL}/getBatchtype`;
  const facultyURL = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&sortBy=updated_at&sortOrder=DESC&location_id=${selectedLocationId}`;
  const getSlotTime = `${process.env.REACT_APP_API_URL}/getSlotTime`;

  const fetchBatchData = async () => {
    try {
      const Response = await apiService.get(batchDataURL);

      if (Response) {
        const defaultFaculty = Response.data.faculties[0];
        if (defaultFaculty) {
          const facultyData = [];

          Response.data.faculties.forEach((faculty) => {
            facultyData.push({
              label: [
                faculty.faculty_code +
                  " - " +
                  faculty.first_name +
                  " " +
                  faculty.last_name +
                  faculty.subject
                    .map((subject) => ` (${subject.subject_name})`)
                    .join(", "),
              ],
              value: faculty.id,
              id: faculty.id,
            });
          });

          setValue("faculyData", facultyData);
        }
        setBatchData(Response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const getDataOfBatchById = async () => {
    if (id) {
      setIsEditMode(true);
      fetchBatchData();
    }
  };
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [Times, setTimes] = useState([]);

  const getLocationData = async () => {
    const Response = await apiService.get(locationURL);
    if (Response) {
      try {
        const mappingResponse = Response.map((response) => {
          return { label: response.name, value: response.id };
        });
        setLocationData(mappingResponse);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getBatchStreamData = async () => {
    const Response = await apiService.get(batchStreamURL);

    if (Response) {
      try {
        const mappingResponse = Response.map((response) => {
          const subjects = response.subject || [];

          const mappedSubjects = subjects.map((subject) => {
            return {
              label: subject.subject_name,
              value: subject.subject_code,
              id: subject.id,
            };
          });

          return {
            id: response.id,
            label: response.stream_names,
            value: response.stream_names,
            stream_code: response.stream_code,
            subjects: mappedSubjects,
          };
        });

        setBatchStreamData(mappingResponse);
      } catch (error) {
        console.error("Error mapping subjects:", error);
      }
    }
  };
  const [selectedStream, setSelectedStream] = useState({
    value: "",
    label: "",
    stream_code: "",
  });
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedLabel = event.target.selectedOptions[0].textContent;

    const selectedStreamId = event.target.value;
    const selectedStream = batchStreamData.find(
      (option) => option.id === selectedStreamId
    );

    if (selectedStream) {
      const selectedOptions = {
        value: selectedValue,
        label: selectedLabel,
        stream_code: selectedStream["stream_code"],
      };
      setSelectedStream(selectedOptions);
      setValue("faculyData", []);
      const subjects = selectedStream.subjects.map((subject) => ({
        id: subject.id,
        label: subject.label,
      }));
      setSelectedSubjects(subjects);
    }
    setSessionValues({}); // Clear session values when stream changes
  };

  // const handleSessionChange = (index, value) => {
  //   const updatedSessionValues = sessionValues;
  //   updatedSessionValues[index] = value;
  //   setSessionValues(updatedSessionValues);
  // };

  // const handleSessionChange = (id, value, type) => {
  //   const updatedSessionValues = { ...sessionValues };
  //   if (!updatedSessionValues[id]) {
  //     updatedSessionValues[id] = { lecturePerWeek: '', totalSubject: '' };
  //   }
  //   updatedSessionValues[id][type] = value;
  //       console.log("updatedSessionValues", updatedSessionValues)

  //   setSessionValues(updatedSessionValues);
  // };

  // const handleSessionChange = (id, value, field) => {
  //   const updatedSessions = { ...sessionValues };
  //   if (!updatedSessions[id]) {
  //     updatedSessions[id] = { lecturePerWeek: "", totalSubject: "" };
  //   }
  //   updatedSessions[id][field] = value;
  //   setSessionValues(updatedSessions);
  //   console.log("updatedSessionValues", updatedSessions);

  //   setValue(field, value, { shouldValidate: true });
  // };
  const getFacutyData = async () => {
    let streamCode = "";
    let url;

    if (
      selectedStream &&
      (selectedStream.stream_code === "J" || selectedStream.stream_code === "M")
    ) {
      streamCode = "j/m";
      url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&sortBy=updated_at&sortOrder=DESC&location_id=${selectedLocationId}&stream_code=${streamCode}`;
    } else if (selectedStream && selectedStream.stream_code === "F") {
      streamCode = "f";
      url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&location_id=${selectedLocationId}&sortBy=updated_at&sortOrder=ASC&stream_code=${streamCode}`;
    }

    try {
      const Response = await apiService.get(url);
      const mappingResponse = Response.map((response) => {
        const subjectCodes = response.subject.map((subject, index) => (
          <span key={index} style={{ color: "red" }}>
            {subject.subject_code}
          </span>
        ));

        return {
          label: [
            response.faculty_code +
              " - " +
              response.first_name +
              " " +
              response.last_name +
              response.subject
                .map((subject) => ` (${subject.subject_name})`)
                .join(", "),
          ],
          value: response.id,
          // value: response.faculty_code,
          id: response.id,
        };
      });
      setFacultyData(mappingResponse);
    } catch (error) {
      return error;
    }
  };

  const getBatchTypeData = async () => {
    const Response = await apiService.get(batchTypeURL);
    if (Response) {
      try {
        const mappingResponse = Response.map((response) => {
          return {
            label: response.name,
            value: response.id,
          };
        });
        setBatchTypeData(mappingResponse);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchFormattedStartingDate = () => {
    if (batchData.starting_date) {
      const dateParts = batchData.starting_date.split("-");
      const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      return formattedDate;
    }
    return "";
  };

  const [startingDate, setStartingDate] = useState(
    fetchFormattedStartingDate()
  );

  useEffect(() => {
    getFacutyData();
  }, [selectedLocation, selectedLocationId, selectedStream]);
  const getfetchData = useRef(true);

  useEffect(() => {
    if (
      isEditMode &&
      batchData.locations &&
      locationData &&
      locationData.length > 0
    ) {
      // Execute the code block only if both batchData.locations and locationData are not empty
      const selectedLocationOptions = locationData.filter((option) =>
        batchData.locations.find(
          (location) => location.pivot.location_id === option.value
        )
      );
      setValue("locations", selectedLocationOptions);
      setSelectedLocationId(selectedLocationOptions[0].value);
    }
  }, [isEditMode, batchData.locations, locationData]);

  const [defaultStream, setDefaultStream] = useState("");

  useEffect(() => {
    if (isEditMode && batchData.batch_stream) {
      const selectedBatchStreamOptions = batchStreamData.filter((option) =>
        batchData.batch_stream.find(
          (batchstream) => batchstream.pivot.batch_stream_id === option.id
        )
      );

      if (selectedBatchStreamOptions.length > 0) {
        const firstSelectedOption = selectedBatchStreamOptions[0];

        const subjectsLabel = firstSelectedOption.subjects
          ? `(${firstSelectedOption.subjects
              .map((subject) => subject.label)
              .join(", ")})`
          : "";

        setValue("batchStream", firstSelectedOption.id);
        setSelectedStream({
          value: firstSelectedOption.id,
          label: `${firstSelectedOption.label} ${subjectsLabel}`,
          stream_code: firstSelectedOption.stream_code,
        });
        setDefaultStream({
          value: firstSelectedOption.id,
          label: `${firstSelectedOption.label} ${subjectsLabel}`,
          stream_code: firstSelectedOption.stream_code,
        });
        const selectedStreamId = firstSelectedOption.id;
        const selectedStream = batchStreamData.find(
          (option) => option.id === selectedStreamId
        );

        if (selectedStream) {
          // Extracting subjects from the selected stream with IDs
          const subjects = selectedStream.subjects.map((subject) => ({
            id: subject.id,
            label: subject.label,
          }));
          console.log("selectedStreamId", selectedStreamId);
          console.log("batchStreamData--------", batchStreamData);
          console.log("subjects--------", subjects);

          setSelectedSubjects(subjects);

          let updatedSessionValues = {};
          batchData.batch_subjects.forEach((subject) => {
            updatedSessionValues[subject.subject_id] = {
              lecturePerWeek: subject.session_per_weeks,
              totalSubject: subject.total_session_of_year !== undefined ? subject.total_session_of_year : "",
            };
          });
          console.log("updatedSessionValues", updatedSessionValues);
          setSessionValues(updatedSessionValues);
          const combinedSubjects = [
            ...subjects,
            ...Object.keys(updatedSessionValues)
              .filter((id) => !subjects.some((subject) => subject.id === id))
              .map((id) => ({
                id,
                label:
                  subjectData.find((subject) => subject.value === id)?.label ||
                  "Unknown Subject",

                // label: firstSelectedOption.subjects.find(subject => subject.id === id)?.label || 'Unknown Subject',
              })),
          ];

          setSelectedSubjects(combinedSubjects);
        }
      }
    }
  }, [isEditMode, batchData.batch_stream, batchStreamData]);

  console.log("selectedSubjects", selectedSubjects);
  console.log("sessionValues", sessionValues);


  useEffect(() => {
    if (isEditMode && batchData.batch_types) {
      const selectedBatchStreamOptions = batchTypeData.filter((option) =>
        batchData.batch_types.find(
          (batchtype) => batchtype.pivot.batch_type_id === option.value
        )
      );
      setValue("batchtype", selectedBatchStreamOptions);
    }
  }, [isEditMode, batchData.batch_types, batchTypeData]);

  useEffect(() => {
    if (batchData.selected_days && batchData.selected_days.length > 0) {
      setValue("selectdays", batchData.selected_days);
      setFixedChecked(true);
      setSelectedOptions(batchData.selected_days);
      console.log(batchData.selected_days);
      if (flexibleChecked) {
        setValue((prevValue) => ({
          ...prevValue,
          selectdays: "",
        }));
      }
    }
  }, [batchData.selected_days]);
  useEffect(() => {
    if (batchData.selected_days_count) {
      setFlexibleChecked(true);
      setValue("dayCount", batchData.selected_days_count);
    }
  }, [batchData.selected_days_count]);
  useEffect(() => {
    if (batchData.special_batch) {
      console.log(batchData.special_batch);
      setSpecialBatchChecked(batchData.special_batch);
    }
  }, [batchData.special_batch]);

  const [selectedTimes, setSelectedTimes] = useState([]);

  // const handleCardClick = (value) => {
  //   setSelectedTimes((prevSelected) => {
  //     if (prevSelected.includes(value)) {
  //       return prevSelected.filter((timeValue) => timeValue !== value);
  //     } else {
  //       return [...prevSelected, value];
  //     }
  //   });
  // };
  const handleCardClick = (value) => {
    setSelectedTimes((prevSelected) => {
      const newSelectedTimes = prevSelected.includes(value)
        ? prevSelected.filter((timeValue) => timeValue !== value)
        : [...prevSelected, value];

      setValue("times", newSelectedTimes);
      return newSelectedTimes;
    });
  };
  const getSlotTimeData = async () => {
    const Response = await apiService.get(getSlotTime);
    try {
      const mappingResponse = Response.map((response) => {
        return {
          label: response.slot_time,
          value: response.slot_time,
          id: response.id,
        };
      });
      setTimes(mappingResponse);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      getDataOfBatchById();
      if (!getfetchData.current) {
        // await getDataOfBatchById();
        await getLocationData();
        await getSlotTimeData();
        await getBatchTypeData();
        await getBatchStreamData();
        getfetchData.current = false;
      }
    };

    fetchData();
  }, []);
  getfetchData.current = false;

  const formatTo12HourFormat = (timeRange) => {
    const [startTime, endTime] = timeRange.split("-").map((time) => {
      const [hours, minutes, seconds] = time.split(":");
      const parsedHours = parseInt(hours, 10);
      const period = parsedHours >= 12 ? "PM" : "AM";

      const formattedHours = (parsedHours % 12 || 12)
        .toString()
        .padStart(2, "0");

      return `${formattedHours}:${minutes} ${period}`;
    });

    return `${startTime} - ${endTime}`;
  };
  //time--------------------------------------
  useEffect(() => {
    const isFoundationStream = selectedStreams.some(
      (stream) => stream.label === "Foundation"
    );
    const batchSlots = batchData?.batch_slots || [];

    console.log("batchSlots", batchSlots);

    if (isFoundationStream) {
      const slotTimesFoundations = batchData?.slot_times_foundations || [];

      if (slotTimesFoundations.length > 0) {
        const morningSlot =
          batchSlots[0]?.slot_times?.map((time) => time) || [];
        const afternoonSlot =
          batchSlots[1]?.slot_times?.map((time) => time) || [];

        const combinedSlots = [...morningSlot, ...afternoonSlot];

        setSelectedTimes(combinedSlots);
        setValue("times", combinedSlots);
      }
    }
    if (batchSlots && batchSlots.length > 0) {
      const morningSlot = batchSlots[0]?.slot_times?.map((time) => time) || [];
      const afternoonSlot =
        batchSlots[1]?.slot_times?.map((time) => time) || [];

      const combinedSlots = [...morningSlot, ...afternoonSlot];

      setSelectedTimes(combinedSlots);
      setValue("times", combinedSlots);
    }
  }, [selectedStreams, batchData]);

  console.log("SelecetdTime", selectedTimes);

  useEffect(() => {
    if (isEditMode) {
      setDurationType(isEditMode ? batchData.duration_type : "");
      setDuration(isEditMode ? batchData.duration : "");
      setStartingDate(isEditMode ? batchData.starting_date : "");
      setBatchCode(isEditMode ? batchData.batch_code : "");
    }
  }, [
    isEditMode,
    batchData.duration_type,
    batchData.duration,
    batchData.starting_date,
    batchData.duration_type,
    batchData.batchCode,
  ]);

  useEffect(() => {
    setValue("durationType", durationType);
    setValue("batchCode", batchCode);
    setValue("duration", duration);
    setValue("startingDate", startingDate);
  }, [durationType, duration, startingDate, batchData, batchCode]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase();
    setBatchCode(inputValue);
  };

  const convertTo24HourFormat = (time) => {
    const [hours, minutes] = time.split(/:|\s/);
    let formattedHours = parseInt(hours, 10);

    if (time.includes("PM") && formattedHours !== 12) {
      formattedHours += 12;
    }

    const paddedHours = formattedHours.toString().padStart(2, "0");
    const paddedMinutes = minutes.padStart(2, "0");

    return `${paddedHours}:${paddedMinutes}`;
  };

  function formatTo24Hour(timeString) {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let formattedHours = hours;

    if (period === "PM" && hours !== 12) {
      formattedHours += 12;
    }

    const paddedHours = formattedHours.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");

    const formattedTime = `${paddedHours}:${paddedMinutes}`;
    return {
      time: formattedTime,
      period: period,
    };
  }

  const onSubmit = async (data) => {
    if (!selectedTimes || selectedTimes.length === 0) {
      setslotTimeError("field is required*");
    } else {
      const morning = [];
      const afternoon = [];
      const timeData = [];

      selectedTimes.forEach((timeInterval) => {
        const [startTime] = timeInterval.split("-");
        const [hours] = startTime.split(":");
        const startHour = parseInt(hours, 10);

        if (startHour < 12) {
          morning.push(timeInterval);
        } else {
          afternoon.push(timeInterval);
        }
      });

      // return { morning, afternoon };

      console.log("morning", morning);
      console.log("afternoon", afternoon);
      if (morning.length > 0) {
        timeData.push({
          slot: "morning",
          slot_times: morning,
        });
      }

      if (afternoon.length > 0) {
        timeData.push({
          slot: "afternoon",
          slot_times: afternoon,
        });
      }
      const facultyIds = data.faculyData?.map((item) => item.id) || [];

      // -------------------------------------------------------------------------
      // const SubjectSessionData = selectedSubjects.map((subject, index) => ({
      //   subject_id: subject.id,
      //   session_per_weeks: parseInt(sessionValues[subject.id] || 0, 10), // parse to integer
      // }));
      // const SubjectSessionData = selectedSubjects.map((subject, index) => ({
      //   // subject_id: subject.id,
      //   // session_per_weeks: parseInt(sessionValues[subject.id] || 0, 10), // parse to integer
      //   subject_id: subject.id,
      //   session_per_weeks:
      //     parseInt(sessionValues[subject.id].lecturePerWeek) || 0,
      //   total_sessions_of_year:
      //     parseInt(sessionValues[subject.id].totalSubject) || 0,
      // }));
      const SubjectSessionData = Object.entries(formattedSessionValues).map(
        ([subject_id, details]) => ({
          subject_id: subject_id,
          session_per_weeks: details.session_per_weeks,
          total_sessions_of_year: details.total_sessions_of_year,
        })
      );
      console.log(
        "SubjectSessionData==================================",
        SubjectSessionData
      );

      const postData = {
        location_id: data.locations?.[0]?.value ?? data.locations?.value,

        batch_code: data.batchCode,
        batch_type_id: Array.isArray(data.batchtype)
          ? data.batchtype.map((item) => item.value)
          : [data.batchtype.value],
        batch_stream_id: data.batchStream,
        faculty_id: facultyIds,
        starting_date: data.startingDate,
        duration: data.duration,
        subject_sessions: SubjectSessionData,
        duration_type: data.durationType,
        slot: timeData,
        ...(data.selectdays ? { selected_days: data.selectdays } : {}),

        ...(data.dayCount && !data.selectdays
          ? { selected_days_count: data.dayCount }
          : {}),
        special_batch: specialBatchChecked,
      };
      console.log("postDAta", postData);
      apiService
        .patch(`${process.env.REACT_APP_API_URL}/update/${id}`, postData)
        .then((res) => {
          toast.success("Record Successfully Updated.. ", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              marginBottom: "4vw",
              fontSize: "1.2em",
              width: "400px",
              padding: "10px",
            },
          });
          setTimeout(() => {
            navigate("/batchlist");
          }, 3000);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              marginBottom: "4vw",
              fontSize: "1.2em",
              width: "400px",
              padding: "10px",
            },
          });
        });
    }
  };

  useEffect(() => {
    if (flexibleChecked) {
      setValue("selectdays", "");
    }
  }, [flexibleChecked]);
  const [subjectIds, setSubjectIds] = useState({});
  const [additionalRows, setAdditionalRows] = useState([]);
  const [filteredSubjectData, setFilteredSubjectData] = useState([]);

  const handleSessionChange = (subjectId, value, field) => {
    setSessionValues((prevSessions) => {
      const updatedSessions = { ...prevSessions };
      if (subjectId) {
        updatedSessions[subjectId] = updatedSessions[subjectId] || {};
        updatedSessions[subjectId][field] = value;
      }
      return updatedSessions;
    });
    setValue(field, value, { shouldValidate: true });
  };

  console.log("sessionValues----------------------", sessionValues);
  const handleSubjectChange = (rowId, selectedOptions) => {
    console.log("selectedOptions===========", selectedOptions);

    const newSubjectId = selectedOptions.value;
    const previousSubjectId = subjectIds[rowId];

    setSubjectIds((prev) => {
      const updated = { ...prev, [rowId]: newSubjectId };
      if (previousSubjectId && sessionValues[previousSubjectId]) {
        const updatedSessions = { ...sessionValues };
        updatedSessions[newSubjectId] = updatedSessions[previousSubjectId];
        delete updatedSessions[previousSubjectId];
        setSessionValues(updatedSessions);
      }
      return updated;
    });

    setValue(`ExtaraSubjectName${newSubjectId}`, selectedOptions);
  };

  console.log("Subjecty All", selectedSubjects);
  const addRow = () => {
    const newRowId = Date.now();
    setAdditionalRows((prev) => [
      ...prev,
      { id: newRowId, lecturePerWeek: "", totalSubject: "" },
    ]);
    setSubjectIds((prev) => ({ ...prev, [newRowId]: null }));
  };

  const removeRow = (rowId) => {
    setAdditionalRows((prev) => prev.filter((row) => row.id !== rowId));

    // Remove related session values and subject IDs
    setSessionValues((prevSessions) => {
      const updatedSessions = { ...prevSessions };
      const subjectId = subjectIds[rowId];
      if (subjectId) {
        delete updatedSessions[subjectId];
      }
      return updatedSessions;
    });

    setSubjectIds((prev) => {
      const updated = { ...prev };
      delete updated[rowId];
      return updated;
    });
  };

  const formatSessionValues = (sessionValues) => {
    return Object.keys(sessionValues).reduce((acc, subjectId) => {
      const { lecturePerWeek, totalSubject } = sessionValues[subjectId];
      acc[subjectId] = {
        subject_id: subjectId || "",
        session_per_weeks: parseInt(lecturePerWeek, 10) || 0,
        total_sessions_of_year: parseInt(totalSubject, 10) || 0,
      };
      return acc;
    }, {});
  };

  const formattedSessionValues = formatSessionValues(sessionValues);
  console.log("formatSessionValues", formattedSessionValues);
  console.log("formattedSessionValues", formattedSessionValues);
  const [subjectData, setSubjectData] = useState([]);
  const fetchSubjectData = async () => {
    try {
      const response = await apiService.get(
        `${process.env.REACT_APP_API_URL}/getSubject?q=&limit=20&page=1&sort_by=updated_at&sort_order=desc`
      );
      console.log("responsesubject", response);
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.subject_name,
      }));
      console.log("options", options);

      console.log("selectedSubjects", selectedSubjects);
      console.log("subjectIds", subjectIds);

      // // Extract IDs from selectedSubjects
      setSubjectData(options);
      const selectedSubjectIds = selectedSubjects
        .map((subject) => subject.id)
        .filter((id) => id); // Remove null or undefined values
      console.log("selectedSubjectIds", selectedSubjectIds);

      // Extract IDs from subjectIds
      const subjectIdValues = Object.values(subjectIds).filter((id) => id); // Remove null or undefined values
      console.log("subjectIdValues", subjectIdValues);

      // Combine both lists of IDs to be excluded
      const excludedValues = new Set([
        ...selectedSubjectIds,
        ...subjectIdValues,
      ]);
      console.log("excludedValues", excludedValues);

      // Filter out options from subjectData where ID is in excludedValues
      const filteredOptions = options.filter(
        (option) => !excludedValues.has(option.value)
      );
      console.log("filteredOptions", filteredOptions);

      setFilteredSubjectData(filteredOptions);
    } catch (error) {
      console.error("Error fetching subject data:", error);
    }
  };
  console.log("selectedSubjects---------------------------", selectedSubjects);

  // ---------------------------------------------------------------------------------
  useEffect(() => {
    fetchSubjectData();
  }, [subjectIds]);
  // const combinedSubjects = [
  //   ...selectedSubjects,
  //   ...Object.keys(sessionValues).filter(id => !selectedSubjects.some(subject => subject.id === id)).map(id => ({
  //     id,
  //     label: subjectData.find(subject => subject.value === id)?.label || 'Unknown Subject',
  //   })),
  // ];

  // console.log("combinedSubjects", combinedSubjects)
  return (
    <div>
      <>
        <ToastContainer />
      </>
      <div className=" bg-secondaryColour ml-6 mr-4 h-auto pb-6 font-serif mb-4 overflow-y-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="cuPageHeight">
          <div className=" mt-1 ">
            <div className="  grid grid-cols-12 ml-6 pt-2    ">
              <div className=" col-span-6 ">
                <label className="text-sm mb-1 mt-3 text-primary">
                  {" "}
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Code:
                </label>
                <div>
                  <input
                    id="batchcode"
                    defaultValue={batchCode}
                    autoFocus
                    type="text"
                    style={{ height: "40px", outline: "none" }}
                    placeholder="Batch Code"
                    {...register("batchCode", {
                      required: "This field is required",
                    })}
                    className="input text-sm "
                    autoComplete="off"
                    onChange={handleInputChange}
                  />
                  <div>
                    {errors.batchCode && (
                      <span className="validationcolor">This is required</span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className=" col-span-6 "
                style={{ width: "30vw", outline: "none" }}
              >
                <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Location:
                </label>
                <Select
                  options={locationData}
                  className=" text-sm"
                  {...register("locations", { required: true })}
                  onChange={(selectedOptions) => {
                    setValue("locations", selectedOptions);
                    setSelectedLocationId(selectedOptions.value);
                  }}
                  value={watch("locations")}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      height: "24px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
                <div>
                  {errors.locations && (
                    <p className="error validationcolor">This is required</p>
                  )}
                </div>
              </div>

              <div className=" col-span-6 " style={{ width: "30vw" }}>
                <label className="text-sm mb-1 mt-3 text-primary">
                  {" "}
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Type:
                </label>
                <Select
                  options={batchTypeData}
                  value={watch("batchtype")}
                  style={{ outline: "none", border: "none" }}
                  {...register("batchtype", { required: true })}
                  onChange={(selectedOptions) => {
                    setValue("batchtype", selectedOptions);
                  }}
                  className="custom-select text-sm"
                  classNamePrefix="custom-select"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      height: "30px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
                <div>
                  {errors.batchType && (
                    <p className="error validationcolor">This is required</p>
                  )}
                </div>
              </div>
              <div className=" col-span-6 ">
                <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Duration & Duration Type
                </label>
                <div className="flex">
                  <div className="">
                    <div>
                      <input
                        id="duration"
                        type="number"
                        defaultValue={isEditMode ? batchData.duration : ""}
                        style={{
                          height: "40px",

                          width: "16vw",
                          outline: "none",
                        }}
                        placeholder="Duration"
                        {...register("duration", {
                          required: "This is required",
                        })}
                        className=" text-sm "
                      />
                      <div>
                        {errors.duration && (
                          <span className="validationcolor">
                            {errors.duration.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="" style={{ marginLeft: "0.1vw" }}>
                      <select
                        {...register("durationType", { required: true })}
                        defaultValue={durationType}
                        style={{
                          height: "40px",
                          width: "14vw",
                        }}
                        className=" bg-white  text-sm "
                        onChange={(e) => setDurationType(e.target.value)}
                        placeholder="Duration"
                      >
                        <option value="" disabled selected>
                          Select Duration Type
                        </option>
                        <option value="Days">Days</option>
                        <option value="Weeks">Weeks</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                      </select>

                      <div>
                        {errors.durationtype &&
                          errors.durationtype.type === "required" && (
                            <span className="validationcolor">
                              This is required
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6 mt-1" style={{ width: "30vw" }}>
                <label className="text-base mb-1 text-primary font-serif">
                  <span className="text-red-600 font-bold text-lg">*</span>
                  Stream:
                </label>
                <div>
                  <select
                    {...register("batchStream", { required: true })}
                    style={{ height: "40px", width: "30vw" }}
                    className="bg-white font-serif"
                    // onChange={handleChange}
                    onChange={(e) => {
                      handleChange(e);
                      setDefaultStream({
                        label: e.target.selectedOptions[0].text,
                        value: e.target.value,
                      });
                      setValue("batchStream", {
                        label: e.target.selectedOptions[0].text,
                        value: e.target.value,
                      });
                    }}
                    value={defaultStream.value || ""}
                  >
                    {batchStreamData.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        label={`${option.label} (${option.subjects
                          .map((subject) => subject.label)
                          .join(", ")})`}
                      >
                        {`${option.label} (${option.subjects
                          .map((subject) => subject.label)
                          .join(", ")})`}
                      </option>
                    ))}
                  </select>
                  <div class="">
                    {errors.batchStream && (
                      <p className="error validationcolor">
                        This is required {" *"}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className=" col-span-6 ">
                <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Start Date:
                </label>
                <div>
                  <input
                    id="date"
                    type="date"
                    defaultValue={startingDate}
                    style={{ height: "40px" }}
                    placeholder="Starting Date"
                    onChange={(e) => setStartingDate(e.target.value)}
                    {...register("startingDate", {
                      required: "This field is required",
                    })}
                    className="input text-sm "
                  />
                  <div>
                    {errors.startingDate && (
                      <span className="validationcolor">
                        {errors.startingDate.message}{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="  col-span-6  " style={{ width: "30vw" }}>
                <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Faculty:
                </label>
                <Select
                  placeholder="select Faculty"
                  isMulti
                  options={facultyData}
                  // filterOption={customFilterOption}
                  style={{ outline: "none", border: "none" }}
                  {...register("faculyData", { required: true })}
                  onChange={(selectedOptions) => {
                    setValue("faculyData", selectedOptions);
                  }}
                  value={watch("faculyData")}
                  className="custom-select text-xs"
                  classNamePrefix="custom-select"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      height: "px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
                <div>
                  {errors.faculyData && (
                    <p className="error validationcolor">This is required</p>
                  )}
                </div>
              </div>

              <div>
                <label className="text-sm mb-1  mt-8 text-primary">
                  <span className="text-red-600 font-bold text-lg">*</span>
                  Select Time:
                </label>
                <div className="space-y-4">
                  {Array.from({ length: Math.ceil(Times.length / 6) }).map(
                    (_, index) => (
                      <div key={index} className="flex space-x-8 mt-2">
                        {Times.slice(index * 6, index * 6 + 6).map((item) => (
                          <div
                            {...register("times", {
                              required: "This is required",
                            })}
                            key={item.id}
                            onClick={() => handleCardClick(item.value)}
                            className={`bg-white p-2 shadow-lg w-42 cursor-pointer ${
                              selectedTimes.includes(item.value)
                                ? "border-2 border-blue-500"
                                : ""
                            }`}
                          >
                            <p>{item.label}</p>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
              {selectedSubjects.length > 0 && (
                <div className="col-span-6 mt-4" style={{ width: "20vw" }}>
                  <input
                    type="checkbox"
                    value="sunday-to-monday"
                    checked={specialBatchChecked}
                    onChange={handleSpecialBatchChecked}
                    // disabled
                    className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                  />
                  <span className="ml-2">Is Special Batch?</span>
                  <div style={{ justifyContent: "flex-end" }} className="mt-4">
                    <button
                      onClick={addRow}
                      disabled={selectedSubjects.length === 0} // Disable if no subjects are selected
                      type="button" // Corrected type attribute
                      style={{
                        // marginBottom: "1rem",
                        padding: "0.5rem 1rem",
                        // backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        cursor: "pointer",
                        justifyContent: "flex-end",
                        backgroundColor:
                          selectedSubjects.length === 0 ? "gray" : "#007bff",
                        cursor:
                          selectedSubjects.length === 0
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <FaPlus />
                    </button>
                  </div>
                  <table
                    style={{
                      marginTop: "1rem",
                      borderCollapse: "collapse",
                      border: "1px solid black",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            // padding: "0.5rem 4rem",
                            border: "1px solid black",
                            textAlign: "center",
                            width: "2vw",
                          }}
                        >
                          Subject
                        </th>
                        <th
                          style={{
                            // padding: "0.5rem 4rem",
                            border: "1px solid black",
                            textAlign: "center",
                            width: "8vw",
                          }}
                        >
                          Lecture Per Week
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "8vw",
                          }}
                        >
                          Total Lecture
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* {selectedSubjects.length === 0 ? (
                        <tr>
                          <td
                            colSpan="3"
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Please select a stream first
                          </td>
                        </tr>
                      ) : (
                        selectedSubjects.map((subject, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {subject.label}
                            </td>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="text"
                                className="input"
                                placeholder="Enter Number"
                                {...register(`lecturePerWeek${subject.id}`, {
                                  required: "This is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                // defaultValue={sessionValues[subject.id]}
                                defaultValue={
                                  sessionValues[subject.id]?.lecturePerWeek ||
                                  ""
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value >= 0 || value === "") {
                                    handleSessionChange(
                                      subject.id,
                                      value,
                                      "lecturePerWeek"
                                    );
                                  }
                                }}
                                onKeyPress={(e) => {
                                  // Prevent typing the minus sign (-)
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "2rem",
                                  width: "10vw",
                                  outline: "none",
                                  border: "1px solid black",
                                }}
                              />
                              {errors[`lecturePerWeek${subject.id}`] && (
                                <span className="validationcolor">
                                  {
                                    errors[`lecturePerWeek${subject.id}`]
                                      .message
                                  }
                                </span>
                              )}
                            </td>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="text"
                                className="input"
                                placeholder="Enter Number"
                                {...register(`totalSubject${subject.id}`, {
                                  required: "This is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                defaultValue={
                                  sessionValues[subject.id]?.totalSubject || ""
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value >= 0 || value === "") {
                                    handleSessionChange(
                                      subject.id,
                                      value,
                                      "totalSubject"
                                    );
                                  }
                                }}
                                onKeyPress={(e) => {
                                  // Prevent typing the minus sign (-)
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "2rem",
                                  width: "10vw",
                                  outline: "none",
                                  border: "1px solid black",
                                }}
                              />
                              {errors[`totalSubject${subject.id}`] && (
                                <span className="validationcolor">
                                  {errors[`totalSubject${subject.id}`].message}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      )} */}
                      {selectedSubjects.length === 0 ? (
                        <tr>
                          <td
                            colSpan="3"
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Please select a stream first
                          </td>
                        </tr>
                      ) : (
                        selectedSubjects.map((subject, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {subject.label}
                            </td>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="text"
                                className="input"
                                placeholder="Enter Number"
                                {...register(`lecturePerWeek${subject.id}`, {
                                  required: "This is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                // value={
                                //   sessionValues[subject.id]?.lecturePerWeek ||
                                //   ""
                                // }
                                value={
                                  sessionValues[subject.id]?.lecturePerWeek !== undefined
                                    ? sessionValues[subject.id].lecturePerWeek
                                    : ""
                                }
                                // defaultValue={sessionValues[subject.id]?.lecturePerWeek || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value >= 0 || value === "") {
                                    handleSessionChange(
                                      subject.id,
                                      value,
                                      "lecturePerWeek"
                                    );
                                  }
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "2rem",
                                  width: "10vw",
                                  outline: "none",
                                  border: "1px solid black",
                                }}
                              />
                              {errors[`lecturePerWeek${subject.id}`] && (
                                <span className="validationcolor">
                                  {
                                    errors[`lecturePerWeek${subject.id}`]
                                      .message
                                  }
                                </span>
                              )}
                            </td>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="text"
                                className="input"
                                placeholder="Enter Number"
                                {...register(`totalSubject${subject.id}`, {
                                  required: "This is required",
                                  min: {
                                    value: -1,
                                    message: "Must be a positive number",
                                  },
                                })}
                                // defaultValue={sessionValues[subject.id]?.totalSubject || ""}
                                // value={
                                //   sessionValues[subject.id]?.totalSubject || ""
                                // }
                                value={
                                  sessionValues[subject.id]?.totalSubject !== undefined
                                    ? sessionValues[subject.id].totalSubject
                                    : ""
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value >= 0 || value === "") {
                                    handleSessionChange(
                                      subject.id,
                                      value,
                                      "totalSubject"
                                    );
                                  }
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === "-") {
                                    e.preventDefault();
                                  }
                                }}
                                style={{
                                  height: "2rem",
                                  width: "10vw",
                                  outline: "none",
                                  border: "1px solid black",
                                }}
                              />
                              {errors[`totalSubject${subject.id}`] && (
                                <span className="validationcolor">
                                  {errors[`totalSubject${subject.id}`].message}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                      {additionalRows.map((row) => {
                        const selectedSubjectId = subjectIds[row.id];
                        const isDisabled = !selectedSubjectId;

                        return (
                          <tr key={row.id}>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              <Select
                                placeholder="Select Subject"
                                options={filteredSubjectData}
                                style={{ outline: "none", border: "none" }}
                                onChange={(selectedOptions) =>
                                  handleSubjectChange(row.id, selectedOptions)
                                }
                                className="custom-select"
                                classNamePrefix="custom-select"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor: "white",
                                    borderRadius: 0,
                                    // border: "none",
                                    // outline: "none",
                                    boxShadow: "none",
                                    width: "8vw",
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    color: "black",
                                  }),
                                }}
                              />
                              {errors.facultyData && (
                                <p className="error validationcolor">
                                  This is required {" *"}
                                </p>
                              )}
                            </td>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="text"
                                className="input"
                                placeholder={
                                  isDisabled
                                    ? "First Select Subject"
                                    : "Enter Number"
                                }
                                {...register(`lecturePerWeek${row.id}`, {
                                  required: "This is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                onChange={(e) =>
                                  handleSessionChange(
                                    selectedSubjectId,
                                    e.target.value,
                                    "lecturePerWeek"
                                  )
                                }
                                onKeyPress={(e) =>
                                  e.key === "-" && e.preventDefault()
                                }
                                style={{
                                  height: "2rem",
                                  width: "10vw",
                                  // outline: "none",
                                  // border: "1px solid black",
                                  backgroundColor: isDisabled
                                    ? "#e0e0e0"
                                    : "white", // Gray background for disabled
                                  cursor: isDisabled ? "not-allowed" : "auto",
                                }}
                                disabled={isDisabled}
                              />
                              {errors[`lecturePerWeek${row.id}`] && (
                                <span className="validationcolor">
                                  {errors[`lecturePerWeek${row.id}`].message}
                                </span>
                              )}
                            </td>
                            <td
                              style={{
                                padding: "0.5rem 1rem",
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="text"
                                className="input"
                                {...register(`totalSubject${row.id}`, {
                                  required: "This is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                placeholder={
                                  isDisabled
                                    ? "First Select Subject"
                                    : "Enter Number"
                                }
                                onChange={(e) =>
                                  handleSessionChange(
                                    selectedSubjectId,
                                    e.target.value,
                                    "totalSubject"
                                  )
                                }
                                onKeyPress={(e) =>
                                  e.key === "-" && e.preventDefault()
                                }
                                style={{
                                  height: "2rem",
                                  width: "10vw",
                                  // outline: "none",
                                  // border: "1px solid black",
                                  backgroundColor: isDisabled
                                    ? "#e0e0e0"
                                    : "white", // Gray background for disabled
                                  cursor: isDisabled ? "not-allowed" : "auto",
                                }}
                                disabled={isDisabled}
                              />
                              {errors[`totalSubject${row.id}`] && (
                                <span className="validationcolor">
                                  {errors[`totalSubject${row.id}`].message}
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                onClick={() => removeRow(row.id)}
                                style={{
                                  padding: "0.5rem",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <RiDeleteBin6Line className="text-red-700 text-xl mr-3" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* )} */}
                </div>
              )}
              <div className="col-span-6 mt-1" style={{ width: "30vw" }}>
                <div className="flex items-center ">
                  <label className="text-sm mb-1  text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Select Days:
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    value="sunday-to-monday"
                    checked={fixedChecked}
                    onChange={handleFixedCheckboxChange}
                    // disabled
                    className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                  />
                  <span className="ml-2">Fixed</span>

                  <input
                    type="checkbox"
                    value="sunday-to-monday"
                    checked={flexibleChecked}
                    onChange={handleFlexibleCheckboxChange}
                    className="form-checkbox-square ml-4 h-4 w-4 text-primary border border-gray-400 rounded-none"
                  />
                  <span className="ml-2">Flexible</span>
                </div>
                <div className=" mt-2">
                  <select
                    {...(flexibleChecked
                      ? register("dayCount", { required: "This is required" })
                      : {})}
                    style={{
                      height: "40px",
                      width: "30vw",
                    }}
                    value={fixedChecked ? "" : undefined}
                    className={fixedChecked ? "bg-gray-200" : "bg-white"}
                    disabled={fixedChecked || !flexibleChecked}
                    placeholder="Duration"
                  >
                    <option value="" disabled selected>
                      Select Days Count
                    </option>
                    {[1, 2, 3, 4, 5, 6, 7].map((dayCount) => (
                      <option key={dayCount} value={dayCount}>
                        {dayCount}
                      </option>
                    ))}
                  </select>
                  <div>
                    {flexibleChecked && errors.dayCount && (
                      <span className="validationcolor">
                        {errors.dayCount.message}
                        {" *"}
                      </span>
                    )}
                  </div>
                  <div></div>
                </div>

                <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-2">
                  {options.map((option) => (
                    <div key={option.value} className="flex items-center">
                      <input
                        type="checkbox"
                        value={option.value}
                        {...(fixedChecked
                          ? register("selectdays", {
                              required: "This is required",
                            })
                          : {})}
                        checked={
                          !flexibleChecked &&
                          selectedOptions.includes(option.value)
                        }
                        onChange={() => handleOptionChange(option.value)}
                        disabled={!fixedChecked || flexibleChecked}
                        className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                      />
                      <span className="ml-2">{option.label}</span>
                    </div>
                  ))}
                  <div>
                    {fixedChecked && errors.selectdays && (
                      <span className="validationcolor">
                        {errors.selectdays.message}
                        {" *"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-span-6 flex mt-4  	ml-6 ">
              <div>
                <button className=" text-white bg-primaryColour hover:bg-primaryColour-1000  font-medium  text-sm px-5 py-2.5 me-2 ">
                  Update
                </button>
              </div>
              <Link to={"/batchlist"} style={{ color: "white" }}>
                <button className="text-gray-900   bg-gray-50 border border-gray-300 focus:outline-none hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800">
                  Cancel
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BatchEdit;
