import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Loader from "../../components/Loader";
import {
  checkWeekStatus,
  calculateValueFromDates,
} from "../../Services/CommonFucntion";
import LocationContext from "../../context/LocationContext";
import { Modal } from "../../components/Modal/Modal";
import { apiService } from "../../Services/Services";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import * as _ from "lodash";
import Select from "react-select";

import { checkScheduleValidation } from "../../Services/CommonFucntion";
import {
  FaChevronRight,
  FaCopy,
  FaDownload,
  FaEllipsisV,
  FaExchangeAlt,
  FaExternalLinkAlt,
  FaExternalLinkSquareAlt,
  FaFileExport,
  FaGripVertical,
  FaLocationArrow,
  FaPlus,
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaRegCalendarTimes,
  FaSync,
} from "react-icons/fa";
import Location from "../../components/Dropdowns/Location/Location";
import Calender from "../../components/Dropdowns/Calender/Calender";
import ScheduleType from "../../components/Dropdowns/ScheduleType/scheduleType";
import moment from "moment";
import { eachDayOfInterval } from "date-fns";
import Popup from "../../components/Popup";
import AutoScheduleErrorTable from "../../components/Table/AutoScheduleErrorTable";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Switch from "../../components/switch/switch";
import { IoIosFlag } from "react-icons/io";
import { ScheduleCard } from "./ScheduleCard";
import Timer from "../../components/timer";

const ThreeDotMenu = ({
  setIsOpenCopySchedule,

  locationList,
  selectedLocation,
  setSelectedExportLocations,
  setIsOpenExportSchedule,
  isOpenExportSchedule,
  setExportFacultyStatus,
  setExportStudentStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setExportFacultyStatus(false);
    setExportStudentStatus(false);
  };

  const ScheduleExporttogglePopup = () => {
    // const location = _.find(locationList, { value: selectedLocation });
    // setSelectedExportLocations(location ? [location] : []);

    setSelectedExportLocations(locationList);
    setIsOpenExportSchedule(!isOpenExportSchedule);
  };
  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div ref={menuRef}>
      <div className="flex flex-col  ">
        {/* <input
          type="checkbox"
          className="h-[14px] w-[14px] border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
        /> */}
        <button
          onClick={toggleMenu}
          className="bg-primaryColour text-white h-9 px-1 rounded ml-2 flex items-center shadow-lg "
        >
          {" "}
          <FaEllipsisV className="text-lg" />
        </button>
      </div>
      {isOpen && (
        <div className="absolute right-0 m-2 z-30 w-auto bg-white border border-gray-200 rounded-md shadow-lg z-10">
          <div className="py-1 ">
            <button
              onClick={() => {
                setIsOpenCopySchedule(true);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaCopy className="mr-2" />
              Copy Schedule From Previous Week
            </button>


            <button
              onClick={() => {
                setExportFacultyStatus(true);
                ScheduleExporttogglePopup();
                setIsOpen(!isOpen);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaDownload className="mr-2" />
              Export Schedule for Faculty
            </button>
            <button
              onClick={() => {
                setExportStudentStatus(true);
                ScheduleExporttogglePopup();
                setIsOpen(!isOpen);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaDownload className="mr-2" />
              Export Schedule for Student
            </button>

            <Link
              to="/faculty-view"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="flex px-4 py-2 text-sm items-center text-gray-700 hover:bg-gray-100"
            >
              <FaLocationArrow className="mr-2" />
              Switch to Faculty View
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
const SchedulingNewPage = () => {
  const {
    loading,
    facultyData,
    setFacultyData,
    time,
    setTime,
    setSelectedFaculty,
    setSelectedSubjectid,
    selectedSubjectid,
    setModalDate,
    modalDate,
    selectedLocation,
    hasSchedule,
    ScheduleStatusCheck,
    modalOpen,
    setModalOpen,
    dateStatus,
    setDateStatus,
    selectedTime,
    setSelectedTime,
    selectedFaculty,
    selectedScheduleType,
    selectedDate,
    setScheduleStatusCheck,
    setFacultyHrsUpdate,
    setSelectedSubject,
    setScheduleRefresh,
    setHasSchedule,
    setSubjectList,
    subjectList,
    createScheduleData,
    setCreateScheduleData,
    facultyPresent,
    setFacultyPresent,
    scheduleRefresh,
    facultyHrsUpdate,
    loadingPercentage,
    setLoadingPercentage,
    setLoaderMessage,
    loaderMessage,
    setLoading,
    setSelectedCheckboxes,
    selectedCheckboxes,
    isSwapSchedule,
    setIsSwapSchedule,
    setScheduleFacultyFieldValidation,
    setScheduleDateFieldValidation,
    setScheduleBatchFieldValidation,
    setScheduleSlotTimeFieldValidation,
    setScheduleSubjectFieldValidation,
    exportLocationName,
    locationState,
    setLocationState,
  } = useContext(LocationContext);
  const [facultyCard, setFacultyCard] = useState([]);
  const [facultyList, setFacultyList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [selectedRescheduleLocations, setSelectedRescheduleLocations] =
    useState([]);
  const [selectedExportLocations, setSelectedExportLocations] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [batchTypeList, setBatchTypeList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);

  const [slotTime, setSlotTimes] = useState([]);
  const [deleteScheduleId, setDeleteScheduleId] = useState(null);
  const [allDetails, setAllDetails] = useState([]);

  const [calculateHrs, setCalculateHrs] = useState("");
  const [total, setTotal] = useState(0);
  const scrollContainerRef = useRef(null);
  const [autoScheduleStatus, setAutoScheduleStatus] = useState(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const [schedule, setSchedule] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [hardRuleError, setHardRuleError] = useState([]);
  const [facultyRuleError, setFacultyRuleError] = useState([]);
  const [searchError, setSearchError] = useState("");
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);

  const [isOpenAutoSchedule, setIsOpenAutoSchedule] = useState(false);
  const [isOpenExportSchedule, setIsOpenExportSchedule] = useState(false);
  const [exportFacultyStatus, setExportFacultyStatus] = useState(false);
  const [exportStudentStatus, setExportStudentStatus] = useState(false);

  const [autoScheduleInProgress, setAutoScheduleInProgress] = useState(false);
  const [isOpenPublishSchedule, setIsOpenPublishSchedule] = useState(false);
  const [isOpenSwapSchedule, setIsOpenSwapSchedule] = useState(false);
  const [isOpenCopySchedule, setIsOpenCopySchedule] = useState(false);
  const [isCompactView, setIsCompactView] = useState(true);

  // console.log("exportLocationName", exportLocationName)
  const handleSwitchChange = (value) => {
    setIsCompactView(value);
  };
  const togglePopup = () => {
    //const location = _.find(locationList, { value: selectedLocation });

    setSelectedRescheduleLocations(locationList);
    setIsOpenAutoSchedule(!isOpenAutoSchedule);
  };

  let streamCode = "";
  if (selectedScheduleType === "jee/medical") {
    streamCode = "j/m";
  } else if (selectedScheduleType === "jee") {
    streamCode = "j";
  } else if (selectedScheduleType === "medical") {
    streamCode = "m";
  } else if (selectedScheduleType === "foundation") {
    streamCode = "f";
  }
  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const conversionRate = 3;
  const selectedDateArray = Array.isArray(selectedDate) ? selectedDate : [];
  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const calculatedValue = calculateValueFromDates(
    formattedDates[0],
    formattedDates[1],
    conversionRate
  );
  const startDate = formattedDates[0];
  const endDate = formattedDates[1];
  useEffect(() => {
    const checkDateIsCurrentorPastorPrevious = checkWeekStatus(
      startDate,
      endDate
    );
    if (checkDateIsCurrentorPastorPrevious == true) {
      setDateStatus(false);
    } else {
      setDateStatus(true);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const mappingResponse = locationState.map((response) => {
      return {
        label: response.name,
        value: response.id,
      };
    });
    setLocationList(mappingResponse);
  }, [locationState]);

  useEffect(() => {
    if (scheduleRefresh) {
      getSchedulingData();
      setScheduleRefresh(false);
    }
  }, [scheduleRefresh]);

  const scroll = (direction) => {
    const scrollAmount = scrollContainerRef.current.clientWidth;
    if (direction === "left") {
      scrollContainerRef.current.scrollLeft -= scrollAmount;
    } else if (direction === "right") {
      scrollContainerRef.current.scrollLeft += scrollAmount;
    }
  };
  const updateScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  };
  useEffect(() => {
    setLoading(true);
    setLoaderMessage("Please Wait");
    // getSchedulingData();
    getBatchLocationById();
    getAutoScheduleStatus();
  }, [selectedDate]);
  useEffect(() => {
    if (selectedLocation && selectedLocation !== "") {
      setLoading(true);
      setLoaderMessage("Please Wait");
      getSubjectData();
      getFacultyCard();
      getBatchLocationById();
      //getSlotTimes();
      // getSchedulingData();
      getAutoScheduleStatus();
      holidayApiData();
    }
  }, [selectedScheduleType, selectedLocation]);
  useEffect(() => {
    updateScrollButtons();
    scrollContainerRef.current.addEventListener("scroll", updateScrollButtons);
    return () => {
      if (scrollContainerRef.current)
        scrollContainerRef.current.removeEventListener(
          "scroll",
          updateScrollButtons
        );
    };
  }, [facultyList]);
  const getSubjectData = async () => {
    const subjectURL = `${process.env.REACT_APP_API_URL}/getSubject?q=&limit=&page=`;
    const Response = await apiService.get(subjectURL);
    if (Response) {
      try {
        let mappingResponse = [];
        if (selectedScheduleType === "jee/medical") {
          Response.data.map((response) => {
            if (
              _.findIndex(
                response.batch_stream,
                (ele) => ele.stream_code === "J" || ele.stream_code === "M"
              ) >= 0
            ) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "jee") {
          Response.data.map((response) => {
            if (
              _.findIndex(
                response.batch_stream,
                (ele) => ele.stream_code === "J"
              ) >= 0
            ) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "medical") {
          Response.data.map((response) => {
            if (
              _.findIndex(
                response.batch_stream,
                (ele) => ele.stream_code === "M"
              ) >= 0
            ) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "foundation") {
          Response.data.map((response) => {
            if (response.batch_stream[0].stream_code == "F") {
              mappingResponse.push(response);
            }
          });
        }
        setSubjectList(mappingResponse);
      } catch (error) {}
    }
  };
  const holidayApiData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/holidays`;
    try {
      const response = await apiService.get(url);
      if (response) {
        const responseData = await response;
        const { data, length } = responseData;
        setHolidayList(response);
      } else {
        console.error("Error fetching batch data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };
  const getAutoScheduleStatus = async () => {
    let streamCode = "";
    if (selectedScheduleType === "jee/medical") {
      streamCode = "j/m";
    } else if (selectedScheduleType === "jee") {
      streamCode = "j";
    } else if (selectedScheduleType === "medical") {
      streamCode = "m";
    } else if (selectedScheduleType === "foundation") {
      streamCode = "f";
    }
    if(startDate && endDate){
      setLoading(true);
      const oldStatus = autoScheduleStatus ? autoScheduleStatus.status: '';
      const url = `${process.env.REACT_APP_API_URL}/getScheduleStatus?from_date=${startDate}&to_date=${endDate}&batch_stream=${streamCode}`;
      try {
        const response = await apiService.get(url);
        if (response) {
          const status =  _.some(response.data, (location) => location.location_id === selectedLocation);
          const record =  _.filter(response.data, (location) => location.location_id === selectedLocation);
          console.log(record);
          const statusData = record.length !== 0 ? record[0] : null;
          setAutoScheduleStatus(statusData);
          if(oldStatus !== status && status == false) {
            setLoading(true);
            setScheduleRefresh(true);
          }
        } else {
          console.error("Error fetching batch data:", response.status);
        }
      setLoading(false);
      } catch (error) {
      setLoading(false);
        console.error("Error fetching batch data:", error);
      }
    }
   
  };
  const handleOpenModal = (slot) => {
    setCreateScheduleData([]);
    setSelectedFaculty("");
    setModalDate("");
    setSelectedSubjectid("");
    setSelectedTime("");
    setTime([]);
    setSelectedSubject([]);
    setScheduleFacultyFieldValidation("");
    setScheduleDateFieldValidation("");
    setScheduleBatchFieldValidation("");
    setScheduleSlotTimeFieldValidation("");
    setScheduleSubjectFieldValidation("");
    let data = {
      batch_code: slot.batch_code,
      batch_id: slot.batch_id,
      date: slot.date,
      // faculties: slot.faculties,
      time: slot.lesson.slot_time,
      faculty_id: slot.lesson.faculty.id,
      subject: slot.lesson.subject,
      id: slot.lesson.id,
    };
    setModalOpen(!modalOpen);
    setCreateScheduleData(data);

    // setModalOpenEdit(true);
  };
  const findLessonById = (id, schedules) => {
    for (const schedule of schedules) {
      const { morningLessons, afternoonLessons } = schedule;

      // Helper function to search lessons array
      const searchLessons = (lessons) => {
        for (const lessonGroup of lessons.lessons) {
          for (const lessonData of lessonGroup) {
            if (lessonData.lesson.id === id) {
              return lessonData;
            } else {
              const lesson = lessonData.allLesson.filter((l) => l.id === id)[0];
              if (lesson) {
                let data = lessonData;
                data["lesson"] = lesson;
                return data;
              }
            }
          }
        }
      };

      // Search in both morning and afternoon lessons
      const foundLesson =
        searchLessons(morningLessons) || searchLessons(afternoonLessons);
      if (foundLesson) return foundLesson;
    }
    return null;
  };

  const handleOpenEditModal = (data) => {
    setIsDropdownOpen(false);
    const lesson = findLessonById(data.id, allDetails);
    handleOpenModal(lesson);
    setScheduleFacultyFieldValidation("");
    setScheduleDateFieldValidation("");
    setScheduleBatchFieldValidation("");
    setScheduleSlotTimeFieldValidation("");
    setScheduleSubjectFieldValidation("");
  };
  const getFacultyCard = async () => {
    if (selectedLocation) {
      // Check if selectedLocation is not empty

      let streamCode = "";

      if (selectedScheduleType === "jee/medical") {
        streamCode = "j/m";
      } else if (selectedScheduleType === "jee") {
        streamCode = "j";
      } else if (selectedScheduleType === "medical") {
        streamCode = "m";
      } else if (selectedScheduleType === "foundation") {
        streamCode = "f";
      }
      const url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&location_id=${selectedLocation}&sortBy=updated_at&sortOrder=ASC&stream_code=${streamCode}`;

      try {
        const res = await apiService.get(url);
        setFacultyCard(res);
        // const mappingResponse = res.data.map((response) => {
        //   return {
        //     // label: <>{response.first_name + " " + response.last_name}</>,
        //     label: (
        //       <>
        //         <div className="flex">
        //           {response.image_url ? (
        //             <img
        //               src={
        //                 process.env.REACT_APP_API_URL +
        //                 `/faculty/` +
        //                 response.id +
        //                 `/image`
        //               }
        //               onError={(e) => {
        //                 e.target.src = defaultImage;
        //               }}
        //               className="h-5 w-5 rounded-full mr-1"
        //               alt={`Item Image` + response.id}
        //             />
        //           ) : null}

        //           {response.first_name + " " + response.last_name + " "}
        //           {response.subject.map((subject, index) => (
        //             <span
        //               key={index}
        //               style={{ color: "red" }}
        //               className=" ml-1 "
        //             >
        //               ({subject.subject_name})
        //             </span>
        //           ))}
        //         </div>
        //       </>
        //     ),
        //     value: response.faculty_code,
        //     image: response.image,
        //     id: response.id,
        //   };
        // });
        // setFacultyData(mappingResponse);
        setTotal(res.total);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      getFacultyHrs();
      setFacultyHrsUpdate(false);
    }
  }, [schedule, facultyCard]);
  const getFacultyHrs = async () => {
    let faculties = [];
    if (facultyCard && facultyCard?.length > 0 && schedule) {
      facultyCard.filter((faculty, index) => {
        let targetFacultyId = faculty?.id || null;
        const filteredData = schedule.filter(
          (item) => item?.faculty_id === targetFacultyId
        );
        faculty["count"] = filteredData?.length || 0;
        faculties.push({
          count: faculty["count"],
          faculty_id: faculty["id"],
          faculty_name: faculty["first_name"] + " " + faculty["last_name"],
        });
      });
      setCalculateHrs({ Faculties: faculties });
    }

    // if (selectedLocation) {
    //   const reportURL = `${process.env.REACT_APP_API_URL}/getFacultiesCount?&location_id=${selectedLocation}&starting_date=${formattedDates[0]}&ending_date=${formattedDates[1]}`;
    //   try {
    //     const res = await apiService.get(reportURL);
    //     setCalculateHrs(res);
    //   } catch (err) {
    //     setCalculateHrs(null);
    //   }
    // }
  };

  useEffect(() => {
    renderFacultyCards();
  }, [calculateHrs]);
  const renderFacultyCards = () => {
    let list = [];
    if (facultyCard && facultyCard?.length > 0) {
      facultyCard.filter((faculty, index) => {
        // const filteredData = calculateHrs.Faculties.filter(
        //   (item) => item?.faculty_id === targetFacultyId
        // );
        const totaleCountOfSessionTaken = faculty?.count || null;
        const values =
          Math.floor((totaleCountOfSessionTaken / calculatedValue) * 100) || 0;
        facultyCard[index]["percentage"] = values;
        facultyCard[index]["totalSession"] = totaleCountOfSessionTaken;
        facultyCard[index]["getReportSessionCount"] = totaleCountOfSessionTaken;
        const completed = facultyCard[index]["percentage"] || 0;
        facultyCard[index]["bgColor"] = "#FFFF00";
        if (completed >= 70 && completed <= 100) {
          facultyCard[index]["bgColor"] = "#008000";
        } else if (completed >= 101) {
          facultyCard[index]["bgColor"] = "#FF0000";
        } else {
          facultyCard[index]["bgColor"] = "#F3C92C";
        }
        list.push(faculty);
      });
    }
    setFacultyList(list);
  };
  const getBatchLocationById = async () => {
    try {
      if (selectedLocation !== "") {
        const response = await apiService.get(
          `${process.env.REACT_APP_API_URL}/getbatchdata?q=&limit=&page=&sortBy=created_at&sortOrder=DESC&subject_details=true&location_id=${selectedLocation}&date=${startDate}`
        );

        const batches = [];
        const batchSlots = [];
        response.data.forEach((batch) => {
          const streamNames = batch.batch_stream.map(
            (stream) => stream.stream_names
          );
          if (streamCode == "j/m") {
            if (
              streamNames.includes("JEE") ||
              streamNames.includes("Medical")
            ) {
              batches.push(batch);
            }
          } else if (streamCode == "j") {
            if (streamNames.includes("JEE")) {
              batches.push(batch);
            }
          } else if (streamCode == "m") {
            if (streamNames.includes("Medical")) {
              batches.push(batch);
            }
          } else {
            if (streamNames.includes("Foundation")) {
              batches.push(batch);
            }
          }
        });
        setTimeout(() => {
          setBatchList(batches);
        }, 0);
      }
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };
  const calculateFacultyHoursAndLectures = (records) => {
    // Group records by date and faculty_id
    const filterRecords = _.filter(records, (data) => {
      return _.some(data.faculty.location, (l) => l.id === selectedLocation);
    });
    const groupedRecords = _.groupBy(
      filterRecords,
      (record) => `${record.date}_${record.faculty_id}`
    );

    return _.map(groupedRecords, (records, key) => {
      const faculty = records[0].faculty;
      // Calculate the earliest start time and latest end time for the faculty on this date
      const times = _.flatMap(records, (record) => record.slot_time.split("-"));
      const minTime = _.min(times.map((time) => moment(time, "HH:mm")));
      const maxTime = _.max(times.map((time) => moment(time, "HH:mm")));

      const totalHours = moment.duration(maxTime.diff(minTime)).asHours();
      const roundedTotalHours = Math.round(totalHours);

      // Calculate the total number of lectures
      const totalLectures = records.length;

      // Determine if there is an error
      let error = null;
      if (records[0]["error"] && records[0]["error"][0]) {
        let errorArray = records[0]["error"][0].split(":");
        if (errorArray[0] === "WARNING") {
          error = errorArray[errorArray.length - 1];
        }
      }
      if (totalLectures > 3) {
        error = error
          ? `${error} & Total lectures exceed 3`
          : "Total lectures exceed 3";
      }
      if (roundedTotalHours > 10) {
        error = error
          ? `${error} & total hours exceed 10`
          : "Total hours exceed 10";
      }
      return {
        date: records[0].date,
        location_id: records[0]["location_id"],
        faculty_name: `${faculty.first_name} ${faculty.last_name}`,
        faculty_id: records[0].faculty.id,
        faculty_code: faculty.faculty_code,
        totalHours: roundedTotalHours,
        totalLectures,
        message: error,
      };
    });
  };
  // const updateRecordsWithErrors = (originalRecords, calculatedData) => {
  //   const updatedRecords = originalRecords.map(record => {
  //     const recordKey = `${record.date}_${record.faculty_id}`;
  //     const data = calculatedData.find(d => `${d.records[0].date}_${d.records[0].faculty_id}` === recordKey);
  //     if (data && data.error) {
  //       return {
  //         ...record,
  //         error: record.error ? [...record.error, data.error] : [data.error]
  //       };
  //     }
  //     return record;
  //   });

  //   return updatedRecords;
  // };
  const getSchedulingData = async (locationUpdate = false) => {
    try {
      if (selectedLocation && selectedLocation !== "") {
        let getScheduleURL = `${process.env.REACT_APP_API_URL}/getSchedule?starting_date=${formattedDates[0]}&ending_date=${formattedDates[1]}&stream_code=${streamCode}`;
        if (locationUpdate === true) {
          getScheduleURL = `${process.env.REACT_APP_API_URL}/getSchedule?location_id=${selectedLocation}&starting_date=${formattedDates[0]}&ending_date=${formattedDates[1]}&stream_code=${streamCode}`;
        }
        let Response = await apiService.get(getScheduleURL);
        if (Response) {
          updateErrors(Response);
        } else {
          setHardRuleError([]);
          setFacultyRuleError([]);
          generateData1();
          setHasSchedule(false);
          setScheduleStatusCheck(false);
          setSchedule([]);
        }
      }
    } catch (error) {
      setHardRuleError([]);
      setFacultyRuleError([]);
      generateData1();
      setScheduleStatusCheck(false);
      setHasSchedule(false);
      setSchedule([]);
    }
  };

  const updateErrors = (Response) => {
    const hasDraft = Response.some(
      (item) => item.status === "draft" || item.status === "modified"
    );
    const locationSchedules = Response.some(
      (item) => item.location_id === selectedLocation
    );
    setHasSchedule(locationSchedules)
    setScheduleStatusCheck(hasDraft);
    setIsDropdownOpen(false);
    const facultyError = calculateFacultyHoursAndLectures(Response);
    // const updated = updateRecordsWithErrors(Response, hours);
    const filteredData = _.filter(
      facultyError,
      (record) => record.message && record.message !== null
    );
    setFacultyRuleError(filteredData);
    setSchedule(Response);
    const errorMessages = Response.reduce((acc, item) => {
      if (item.error && selectedLocation === item.location_id) {
        if (item["error"] && item["error"][0]) {
          let errorArray = item["error"][0].split(":");
          if (
            errorArray[0] === "ERROR" ||
            (errorArray[0] !== "WARNING" && errorArray[0] !== "ERROR")
          ) {
            const [start, end] = item.slot_time.split("-");
            let time = `${moment(start, "HH:mm").format("h:mm A")} - ${moment(
              end,
              "HH:mm"
            ).format("h:mm A")}`;
            acc.push({
              message: errorArray[errorArray.length - 1],
              id: item.id,
              time: time,
              batch_name: item.batch.batch_code,
              date: item.date,
              faculty_code: item.faculty.faculty_code,
              subject_name: item.subject.subject_name,
              faculty_name:
                item.faculty.first_name + " " + item.faculty.last_name[0],
            });
          }
        }
      }
      return acc;
    }, []);
    setHardRuleError(errorMessages);
  };
  const isHolidayForLesson = (holidayList, batchType, batch, location) => {
    return _.some(holidayList, (holiday) => {
      const appliesToAll =
        _.isEmpty(holiday.locations) &&
        _.isEmpty(holiday.batch_types) &&
        _.isEmpty(holiday.batches);
      const locationMatch =
        _.isEmpty(holiday.locations) ||
        _.some(holiday.locations, ({ id }) => id === location.id);
      const batchTypeMatch =
        _.isEmpty(holiday.batch_types) ||
        _.some(holiday.batch_types, ({ id }) => id === batchType.id);
      const batchesMatch =
        _.isEmpty(holiday.batches) ||
        _.some(holiday.batches, ({ id }) => id === batch.id);
      return appliesToAll || (locationMatch && batchTypeMatch && batchesMatch);
    });
  };
  const generateLessons = (
    slots,
    batches,
    subSchedule,
    date,
    holiday,
    isSpecial = false
  ) => {
    const formatTime = (time) => moment(time, "HH:mm").format("h:mm A");
    const times = _.map(slots, ({ slot_time }) => {
      const [start, end] = slot_time.split("-") || ["", ""];
      return `${formatTime(start)} - ${formatTime(end)}`;
    });

    const displayTimes = _.map(slots, ({ slot_time }) => {
      const [start, end] = slot_time.split("-");
      return {
        startTime: moment(start, "hh:mm A"),
        endTime: moment(end, "hh:mm A"),
      };
    });

    const lessons = _.map(
      batches,
      ({ id, batch_code, faculties, batch_slots, locations, batch_types }) => {
        const flatSlots = _.flatMap(batch_slots, "slot_times");

        return _.map(times, (time, timeIndex) => {
          const [start, end] = time.split(" - ");
          const formattedTime = `${moment(start, "h:mm A").format(
            "HH:mm"
          )}-${moment(end, "h:mm A").format("HH:mm")}`;
          let scheduleData = [];
          if (!isSpecial) {
            scheduleData = _.filter(
              subSchedule,
              ({ slot_time, batch }, index) =>
                (isSpecial ? "" : slot_time === formattedTime) &&
                batch.id === id
            );
          } else {
            const scheduleData1 = _.filter(
              subSchedule,
              ({ slot_time, batch }, index) => batch.id === id
            );
            // Sort the schedules by the start time of slot_time
            scheduleData = scheduleData1.sort((a, b) => {
              const [hoursA, minutesA] = getStartTime(a.slot_time)
                .split(":")
                .map(Number);
              const [hoursB, minutesB] = getStartTime(b.slot_time)
                .split(":")
                .map(Number);
              const timeA = hoursA * 60 + minutesA;
              const timeB = hoursB * 60 + minutesB;
              return timeA - timeB;
            });
          }
          let schedule = isSpecial
            ? scheduleData[timeIndex] || {}
            : _.head(scheduleData) || {};
          schedule["displayError"] = false;
          if (schedule["error"] && schedule["error"][0]) {
            schedule["displayError"] = true;
            let errorArray = schedule["error"][0].split(":");
            if (errorArray[0] === "WARNING") {
              schedule["displayError"] = false;
            }
            schedule["error"] =
              errorArray[0] === "WARNING" || errorArray[0] === "ERROR"
                ? [errorArray[1]]
                : [errorArray[0]];
          }
          const isHoliday =
            holiday &&
            holiday?.length > 0 &&
            isHolidayForLesson(holiday, batch_types[0], { id }, locations[0]);
          return {
            time,
            time_old: formattedTime,
            display_time: {
              startTime: moment(start, "hh:mm A"),
              endTime: moment(end, "hh:mm A"),
            },
            date: date.format("L"),
            batch_id: id,
            batch_code,
            faculties,
            isHoliday,
            lesson: schedule,
            allLesson: scheduleData,
            allowToAddLesson: _.includes(flatSlots, formattedTime),
          };
        });
      }
    );
    return {
      times,
      displayTimes,
      lessons,
    };
  };
  const getStartTime = (slot_time) => {
    return slot_time.split("-")[0]; // Extracts the start time (e.g., '08:00')
  };
  const isTimeInRange = (time, start, end) => {
    const [hour, minute] = time.split(":").map(Number);
    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    const totalMinutes = hour * 60 + minute;
    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;

    return totalMinutes >= startMinutes && totalMinutes <= endMinutes;
  };

  const isSlotInRange = (slotTimes, start, end) => {
    return _.some(slotTimes, (slotTime) => {
      const [startSlotTime, endSlotTime] = slotTime.split("-");
      return (
        isTimeInRange(startSlotTime, start, end) ||
        isTimeInRange(endSlotTime, start, end)
      );
    });
  };

  const categorizeBatches = () => {
    const timeRanges = {
      morning: { start: "00:00", end: "13:00" },
      afternoon: { start: "13:00", end: "23:59" },
    };

    const specialBatches = _.filter(
      batchList,
      (batch) => batch.special_batch === 1
    );
    const morningBatches = _.filter(
      batchList,
      (batch) =>
        batch.special_batch === 0 &&
        _.some(batch.batch_slots, (slot) =>
          isSlotInRange(
            slot.slot_times,
            timeRanges.morning.start,
            timeRanges.morning.end
          )
        )
    );

    const afternoonBatches = _.filter(
      batchList,
      (batch) =>
        batch.special_batch === 0 &&
        !_.some(batch.batch_slots, (slot) =>
          isSlotInRange(
            slot.slot_times,
            timeRanges.morning.start,
            timeRanges.morning.end
          )
        )
    );

    const getUniqueSlotTimes = (batches, isSpecial = false) => {
      const batchId = _.flatMap(batches, (batch) => batch.id);
      if (isSpecial) {
        const filteredSchedules = schedule.filter((sched) =>
          batchId.includes(sched.batch_id)
        );
        const groupedSchedules = _(filteredSchedules)
          .groupBy((sched) => `${sched.batch_id}_${sched.date}`)
          .mapValues((group) => group.length)
          .value();
        // Find the maximum length
        const maxLength = _.max(_.values(groupedSchedules));

        const arrayWithMaxLength = Array.from(
          { length: maxLength },
          () => "00:00-00:00"
        );
        return arrayWithMaxLength.map((data) => ({
          id: data,
          slot_time: data,
        }));
      } else {
        const allSlotTimes = _.flatMap(batches, (batch) =>
          _.flatMap(batch.batch_slots, "slot_times")
        );
        const scheduleSlotTimes = _.chain(schedule)
          .groupBy("batch_id")
          .pick(batchId) // Filter the groups by the specified batch_id(s)
          .mapValues((batch) =>
            _.uniq(batch.map((ele) => ele.slot_time)).sort()
          )
          .value();
        const uniqueSlotTimes = _.chain(scheduleSlotTimes)
          .flatMap()
          .uniq()
          .sort()
          .value();
        return _.uniq([...allSlotTimes, ...uniqueSlotTimes]).map(
          (slotTime) => ({
            id: slotTime,
            slot_time: slotTime,
          })
        );
      }
    };
    const parseTime = (timeStr) => {
      const [startTime] = timeStr.split("-");
      return moment(startTime, "HH:mm").format("HH:mm");
    };

    const sortSlotTimes = (slots) => {
      return _.sortBy(slots, (slot) => parseTime(slot.slot_time));
    };

    const morningSlots = sortSlotTimes(getUniqueSlotTimes(morningBatches));
    const afternoonSlots = sortSlotTimes(getUniqueSlotTimes(afternoonBatches));
    const specialSlots = getUniqueSlotTimes(specialBatches, true);

    return {
      morningBatches,
      afternoonBatches,
      specialBatches,
      morningSlots,
      afternoonSlots,
      specialSlots,
    };
  };
  const generateData1 = () => {
    const dates = eachDayOfInterval({
      start: selectedDate[0],
      end: selectedDate[1],
    });

    const {
      morningBatches,
      afternoonBatches,
      specialBatches,
      morningSlots,
      afternoonSlots,
      specialSlots,
    } = categorizeBatches();
    const list = dates.map((d) => {
      const date = moment(d);
      const formattedDate = date.format("MMMM Do, dddd");
      const subSchedule = _.filter(schedule, ({ date: schedDate }) =>
        moment(schedDate).isSame(date, "day")
      );
      const isHoliday = _.some(holidayList, ({ dates }) =>
        _.some(dates, (holidayDate) => moment(holidayDate).isSame(date, "day"))
      );
      const holiday = _.filter(holidayList, ({ dates }) =>
        _.some(dates, (holidayDate) => moment(holidayDate).isSame(date, "day"))
      );
      const morningLessons = generateLessons(
        morningSlots,
        morningBatches,
        subSchedule,
        date,
        holiday
      );
      const afternoonLessons = generateLessons(
        afternoonSlots,
        afternoonBatches,
        subSchedule,
        date,
        holiday
      );
      const specialLessons = generateLessons(
        specialSlots,
        specialBatches,
        subSchedule,
        date,
        holiday,
        true
      );
      const calculateWidth = (slots) => slots.length; // Slot width in rem

      const morningWidth = calculateWidth(morningSlots);
      const afternoonWidth = calculateWidth(afternoonSlots);
      const specialWidth = calculateWidth(specialSlots);
      const containerWidthRem = Math.max(
        morningWidth,
        afternoonWidth,
        specialWidth
      );
      const remainingWidthMorning = containerWidthRem - morningWidth;
      const remainingWidthAfternoon = containerWidthRem - afternoonWidth;
      const remainingWidthSpecial = containerWidthRem - specialWidth;
      return {
        date: formattedDate,
        isHoliday,
        extra: {
          morning: Array.from(
            {
              length:
                morningWidth !== containerWidthRem ? remainingWidthMorning : 0,
            },
            (_, index) => index
          ),
          afternoon: Array.from(
            {
              length:
                afternoonWidth !== containerWidthRem
                  ? remainingWidthAfternoon
                  : 0,
            },
            (_, index) => index
          ),
          special: Array.from(
            {
              length:
                specialWidth !== containerWidthRem ? remainingWidthSpecial : 0,
            },
            (_, index) => index
          ),
        },
        morningLessons,
        afternoonLessons,
        specialLessons,
      };
    });

    setBatchTypeList({
      batch: {
        morning: morningBatches,
        afternoon: afternoonBatches,
        special: specialBatches,
      },
      slot: {
        morning: morningSlots,
        afternoon: afternoonSlots,
        special: specialSlots,
      },
    });
    setAllDetails(list);
  };
  useEffect(() => {
    generateData1();
  }, [schedule]);
  useEffect(() => {
    setLoading(false);
  }, [allDetails]);
  const handleDragStart = (event, facultyId, subjectId, lessonId = null) => {
    event.dataTransfer.setData("facultyId", facultyId);
    event.dataTransfer.setData("subjectId", subjectId);
    event.dataTransfer.setData("lessonId", lessonId);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event, slot) => {
    event.preventDefault();
    const facultyId = event.dataTransfer.getData("facultyId");
    const subjectId = event.dataTransfer.getData("subjectId");
    const lessonId = event.dataTransfer.getData("lessonId");

    if (lessonId != null && slot.lesson.id != null) {
      return false;
    }
    // Perform actions with dropped data (facultyId, time, batchId)
    setCreateScheduleData([]);
    let post_data = {
      location_id: selectedLocation,
      batch_id: slot.batch_id,
      faculty_id: facultyId,
      subject_id: subjectId,
      slot_time: slot.time_old,
      date: moment(slot.date).format("YYYY-MM-DD"),
    };
    let isFacultyInBatch = slot.faculties.some(
      (faculty) => faculty.id === facultyId
    );
    setFacultyPresent(isFacultyInBatch);
    setTimeout(async () => {
      await submitScheduling(post_data, lessonId);
    }, 200);
  };
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setAllDetails([]);
    if (batchList?.length > 0) {
      const batchSlots = _.flatMap(batchList, (batch) =>
        batch.batch_slots.map((slots) => slots.slot_times)
      ).flat();
      const bSlotTimes = _.uniq(batchSlots)
        .sort()
        .map((s) => ({ id: s, slot_time: s }));

      const scheduleSlotTimes = _.uniq([
        ...schedule.map((ele) => ele.slot_time),
      ]).sort();

      const finalSlotTimes = _.uniq([
        ...bSlotTimes.map((s) => s.slot_time),
        ...scheduleSlotTimes,
      ])
        .sort()
        .map((s) => ({ id: s, slot_time: s }));
      setSlotTimes(finalSlotTimes);
    }
  }, [schedule, batchList]);

  useEffect(() => {
    generateData1();
  }, [slotTime]);
  const toggleModal = () => {
    setCreateScheduleData([]);
    setSelectedFaculty("");
    setModalDate("");
    setSelectedSubjectid("");
    setSelectedTime("");
    setTime([]);
    setSelectedSubject([]);
    setModalOpen(!isOpen);
  };

  const togglePublishSchedule = () => {
    setIsOpenPublishSchedule(!isOpenPublishSchedule);
  };
  const toggleSlotModal = (slot) => {
    setFacultyPresent(true);
    setCreateScheduleData(slot);
    setModalOpen(!isOpen);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setScheduleFacultyFieldValidation("");
    setScheduleDateFieldValidation("");
    setScheduleBatchFieldValidation("");
    setScheduleSlotTimeFieldValidation("");
    setScheduleSubjectFieldValidation("");
  };
  const postData = {
    location_id: selectedLocation,
    batch_id: localStorage.getItem("item"),
    faculty_id: selectedFaculty.value,
    subject_id: selectedSubjectid,
    slot_time: selectedTime,
    date: modalDate,
  };

  let apiUrl;
  if (selectedScheduleType === "foundation") {
    apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule/foundation?isBatchFaculty=${facultyPresent}`;
  } else if (
    selectedScheduleType === "jee/medical" ||
    selectedScheduleType === "jee" ||
    selectedScheduleType === "medical"
  ) {
    apiUrl = `${process.env.REACT_APP_API_URL}/createSchedule?isBatchFaculty=${facultyPresent}`;
  }
  const refreshData = () => {
    setFacultyHrsUpdate(true);
    setScheduleStatusCheck(true);
    // setScheduleRefresh(true);
  };
  const submitScheduling = async (post_data, lesson_id = null) => {
    let lessonId = createScheduleData.id;
    if (lesson_id) {
      lessonId = lesson_id;
    }
    const url = apiUrl;
    if (lessonId && lessonId !== "null") {
      let updateScheudleURL = `${process.env.REACT_APP_API_URL}/UpdateSchedule`;
      if (selectedScheduleType === "foundation") {
        updateScheudleURL += `/foundation/${lessonId}?isBatchFaculty=${facultyPresent}`;
      } else {
        updateScheudleURL += `/default/${lessonId}?isBatchFaculty=${facultyPresent}`;
      }
      setLoading(true);
      const response = await apiService
        .patch(updateScheudleURL, post_data)
        .then((res) => {
          setScheduleFacultyFieldValidation("");
          setScheduleDateFieldValidation("");
          setScheduleBatchFieldValidation("");
          setScheduleSlotTimeFieldValidation("");
          setScheduleSubjectFieldValidation("");
          setLoading(true);
          setLoaderMessage("Please Wait");
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate,
            post_data.faculty_id,
            post_data.batch_id
          )
            .then((response) => {
              let mergedArray = _.map(schedule, (record) => {
                // Find the updated record by matching the 'id'
                const updatedRecord = _.find(response, { id: record.id });
                // If an updated record is found, merge it with the original record
                return updatedRecord
                  ? _.merge({}, record, updatedRecord)
                  : record;
              });
              // Find the records in updatedArray that are not in the original array and add them to the merged array
              const newRecords = _.filter(response, (updatedRecord) => {
                return !_.some(schedule, { id: updatedRecord.id });
              });

              // Combine the merged array with the new records
              mergedArray = [...mergedArray, ...newRecords];
              updateErrors(mergedArray);
              refreshData();
              // setScheduleRefresh(true);
              setModalOpen(false);
              let message = "Schedule Successfully Updated.. ";
              toast.success(message, toastConfig);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });

          // setModalOpen(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastConfig);
          setLoading(false);
        });
    } else {
      setLoading(true);
      const response = await apiService
        .post(url, post_data)
        .then((res) => {
          setScheduleFacultyFieldValidation("");
          setScheduleDateFieldValidation("");
          setScheduleBatchFieldValidation("");
          setScheduleSlotTimeFieldValidation("");
          setScheduleSubjectFieldValidation("");
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate,
            post_data.faculty_id,
            post_data.batch_id
          )
            .then((response) => {
              let mergedArray = _.map(schedule, (record) => {
                // Find the updated record by matching the 'id'
                const updatedRecord = _.find(response, { id: record.id });
                // If an updated record is found, merge it with the original record
                return updatedRecord
                  ? _.merge({}, record, updatedRecord)
                  : record;
              });
              // Find the records in updatedArray that are not in the original array and add them to the merged array
              const newRecords = _.filter(response, (updatedRecord) => {
                return !_.some(schedule, { id: updatedRecord.id });
              });

              // Combine the merged array with the new records
              mergedArray = [...mergedArray, ...newRecords];
              updateErrors(mergedArray);
              refreshData();
              // setScheduleRefresh(true);
              setModalOpen(false);
              let message = "Schedule Successfully Created.. ";
              toast.success(message, toastConfig);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastConfig);
          setLoading(false);
        });
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    let isValid = true; // Flag to track if all validations pass

    if (!selectedFaculty || selectedFaculty.length === 0) {
      setScheduleFacultyFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleFacultyFieldValidation("");
    }

    if (!selectedSubjectid || selectedSubjectid.length === 0) {
      setScheduleSubjectFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleSubjectFieldValidation("");
    }

    if (!selectedTime || selectedTime.length === 0) {
      setScheduleSlotTimeFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleSlotTimeFieldValidation("");
    }

    if (!modalDate || modalDate.length === 0) {
      setScheduleDateFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleDateFieldValidation("");
    }

    const localStorageItem = localStorage.getItem("item");
    if (!localStorageItem || localStorageItem.length === 0) {
      setScheduleBatchFieldValidation("Field is required*");
      isValid = false;
    } else {
      setScheduleBatchFieldValidation("");
    }
    if (!isValid) {
      return;
    }
    await submitScheduling(postData);
  };
  const handleDeleteSchedule = () => {
    setIsOpen(false);
    if (deleteScheduleId !== null) {
      const schedule1 = _.filter(schedule, ({ id }) => id === deleteScheduleId);
      apiService
        .delete(
          `${process.env.REACT_APP_API_URL}/DeleteSchedule/${deleteScheduleId}`
        )
        .then(() => {
          toast.success("Schedule Successfully Deleted.. ", toastConfig);
          setFacultyHrsUpdate(true);
          let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
          setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
          setLoading(true);
          checkScheduleValidation(
            selectedLocation,
            selectedScheduleType,
            selectedDate,
            schedule1[0].faculty_id,
            schedule1[0].batch_id
          )
            .then((response) => {
              let mergedArray = _.map(schedule, (record) => {
                // Find the updated record by matching the 'id'
                const updatedRecord = _.find(response, { id: record.id });
                // If an updated record is found, merge it with the original record
                return updatedRecord
                  ? _.merge({}, record, updatedRecord)
                  : record;
              });
              // Find the records in updatedArray that are not in the original array and add them to the merged array
              const newRecords = _.filter(response, (updatedRecord) => {
                return !_.some(schedule, { id: updatedRecord.id });
              });

              // Combine the merged array with the new records
              mergedArray = [...mergedArray, ...newRecords];
              const removeDeletedSchedule = _.filter(
                mergedArray,
                ({ id }) => id !== deleteScheduleId
              );
              updateErrors(removeDeletedSchedule);
              refreshData();
            })
            .catch(() => {
              setLoading(false);
            });
        });
    }
  };
  const AutoSchedule = async () => {
    setIsOpenAutoSchedule(false);
    setAutoScheduleInProgress(true);

    let scheduleTypeMessage = hasSchedule
      ? "Reschedule"
      : "Automated Scheduling";
    let autoMessage = `${scheduleTypeMessage} process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;

    setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
    setLoading(true);
    let streamCode = "";

    if (selectedScheduleType === "jee/medical") {
      streamCode = "j/m";
    } else if (selectedScheduleType === "jee") {
      streamCode = "j";
    } else if (selectedScheduleType === "medical") {
      streamCode = "m";
    } else if (selectedScheduleType === "foundation") {
      streamCode = "f";
    }
    const locationIds =
      selectedRescheduleLocations.map((option) => option.value).join(",") || "";
    if (selectedDate && selectedDate[0] && selectedDate[1]) {
      const url = `${process.env.REACT_APP_API_URL}/sendAutoScheduleDataToApi?from_date=${startDate}&to_date=${endDate}&location_id=${locationIds}&batch_stream=${streamCode}`;
      try {
        const response = await apiService.get(url);
        getAutoScheduleStatus();
        setLoadingPercentage(0);
        setFacultyHrsUpdate(true);
        toast.success(response.message, toastConfig);
        setLoading(false);
        // setScheduleRefresh(true);
        setScheduleStatusCheck(true);
        // setLoading(true);
        // const eventSource = new EventSource(url);
        // eventSource.onmessage = (event) => {
        //   const eventData = JSON.parse(event.data);
        //   console.log("Received SSE message:", eventData);
        //   if (eventData && eventData["status"] == "STARTED") {
        //     toast.success(eventData.message, toastConfig);
        //     setLoading(true);
        //   } else if (eventData && eventData["status"] == "COMPLETED") {
        //     setFacultyHrsUpdate(true);
        //     toast.success(eventData.message, toastConfig);
        //     setLoading(false);
        //     setScheduleRefresh(true);
        //     setScheduleStatusCheck(true);
        //     clearInterval(interval);
        //     setLoadingPercentage(0);

        //     eventSource.close(); // Clean up event source on component unmount
        //   }
        // };
        // eventSource.onerror = (error) => {
        //   toast.error("Failed to AutoSchedule. Please try again.", toastConfig);
        // };
        // return () => {
        //   eventSource.close();
        // };
      } catch (error) {
        setFacultyHrsUpdate(false);

        toast.error("Failed to AutoSchedule" + error, toastConfig);
      } finally {
        setAutoScheduleInProgress(false);
        setLoading(false);
      }
    } else {
      console.error(
        "Invalid selectedDate array. Ensure both start and end dates are defined."
      );
      setLoading(false);
      setScheduleRefresh(false);
      setScheduleStatusCheck(false);
    }
  };
  const PublishSchedule = async () => {
    let autoMessage = ` process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;

    setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
    setLoading(true);
    setIsOpenPublishSchedule(false);
    if (selectedDate && selectedDate[0] && selectedDate[1]) {
      const url = `${process.env.REACT_APP_API_URL}/publishSchedule?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}&batch_stream=${streamCode}`;

      try {
        const response = await apiService.post(url).then((item) => {
          setLoading(false);

          setFacultyHrsUpdate(true);

          toast.success(
            "'All published schedules have been successfully emailed to all faculties. ",
            toastConfig
          );
          setScheduleStatusCheck(false);

          setScheduleRefresh(true);
        });
      } catch (error) {
        setFacultyHrsUpdate(false);

        console.error("publishing Schedule has error:", error);
        setLoading(false);
        setScheduleStatusCheck(false);

        setScheduleRefresh(false);
      }
    } else {
      setFacultyHrsUpdate(false);
      setLoading(false);
      setScheduleStatusCheck(false);

      setScheduleRefresh(false);

      console.error(
        "Invalid selectedDate array. Ensure both start and end dates are defined."
      );
    }
  };
  const swapSchedule = async () => {
    const schedule1 = _.filter(
      schedule,
      ({ id }) => id === selectedCheckboxes[0]
    );
    const schedule2 = _.filter(
      schedule,
      ({ id }) => id === selectedCheckboxes[1]
    );
    const url = `${process.env.REACT_APP_API_URL}/swapSchedule?schedule1=${selectedCheckboxes[0]}&schedule2=${selectedCheckboxes[1]}`;
    try {
      await apiService.post(url).then((res) => {
        toast.success(res.message, toastConfig);
        setIsOpenSwapSchedule(false);
        setSelectedCheckboxes([]);
        setIsSwapSchedule(false);
        let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
        setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
        setLoading(true);
        checkScheduleValidation(
          selectedLocation,
          selectedScheduleType,
          selectedDate,
          `${schedule1[0].faculty_id},${schedule2[0].faculty_id}`,
          `${schedule1[0].batch_id},${schedule2[0].batch_id}`
        )
          .then((response) => {
            let mergedArray = _.map(schedule, (record) => {
              // Find the updated record by matching the 'id'
              const updatedRecord = _.find(response, { id: record.id });
              // If an updated record is found, merge it with the original record
              return updatedRecord
                ? _.merge({}, record, updatedRecord)
                : record;
            });
            // Find the records in updatedArray that are not in the original array and add them to the merged array
            const newRecords = _.filter(response, (updatedRecord) => {
              return !_.some(schedule, { id: updatedRecord.id });
            });

            // Combine the merged array with the new records
            mergedArray = [...mergedArray, ...newRecords];
            updateErrors(mergedArray);
            refreshData();
          })
          .catch(() => {
            setLoading(false);
          });
      });
    } catch (error) {
      toast.error(error.response.data.message, toastConfig);
      setLoading(false);
      setIsOpenSwapSchedule(false);
    }
  };
  const copySchedule = async () => {
    const url = `${process.env.REACT_APP_API_URL}/copySchedule?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}`;

    try {
      await apiService.post(url).then((res) => {
        setLoading(false);
        toast.success(res.message, toastConfig);
        setIsOpenCopySchedule(false);
        setScheduleRefresh(true);
        let autoMessage = `Schedule Verification  process is currently underway. Avoid refreshing the page as it may take a few minutes to complete.`;
        setLoaderMessage(<pre className=" font-serif ">{autoMessage}</pre>);
        setLoading(true);
        checkScheduleValidation(
          selectedLocation,
          selectedScheduleType,
          selectedDate
        )
          .then((response) => {
            let mergedArray = _.map(schedule, (record) => {
              // Find the updated record by matching the 'id'
              const updatedRecord = _.find(response, { id: record.id });
              // If an updated record is found, merge it with the original record
              return updatedRecord
                ? _.merge({}, record, updatedRecord)
                : record;
            });
            // Find the records in updatedArray that are not in the original array and add them to the merged array
            const newRecords = _.filter(response, (updatedRecord) => {
              return !_.some(schedule, { id: updatedRecord.id });
            });

            // Combine the merged array with the new records
            mergedArray = [...mergedArray, ...newRecords];
            updateErrors(mergedArray);
            refreshData();
          })
          .catch(() => {
            setLoading(false);
          });
        setFacultyHrsUpdate(true);
      });
    } catch (error) {
      toast.error(error.response.data.message, toastConfig);
      setFacultyHrsUpdate(false);
      setLoading(false);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedCheckboxes((prevState) => {
      const index = prevState.indexOf(id);

      if (index === -1) {
        const updatedState = [...prevState, id].slice(-2);
        return updatedState;
      } else {
        // If exists, remove it from the array
        const updatedState = [
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1),
        ];
        return updatedState;
      }
    });
  };
  const exportLocationScheduleToExcel = async () => {
    const locationLabels = selectedExportLocations
      .map((option) => option.label)
      .join("-");
    const locationIds =
      selectedExportLocations.map((option) => option.value).join(",") || "";
    setIsOpenExportSchedule(false);

    setLoading(true);

    try {
      let streamCode = "";

      if (selectedScheduleType === "jee/medical") {
        streamCode = "j/m";
      } else if (selectedScheduleType === "jee") {
        streamCode = "j";
      } else if (selectedScheduleType === "medical") {
        streamCode = "m";
      } else if (selectedScheduleType === "foundation") {
        streamCode = "f";
      }

      const getExportUrl = () => {
        let url = `${process.env.REACT_APP_API_URL}/export-schedule?batch_stream=${streamCode}&location_id=${locationIds}&start_date=${startDate}&end_date=${endDate}`;

        if (exportFacultyStatus) {
          url += `&view=faculty`;
        } else if (exportStudentStatus) {
          url += `&view=student`;
        }

        return url;
      };

      const response = await apiService.getFile(getExportUrl());
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download =
        selectedExportLocations.length === 1
          ? `Schedule-${locationLabels}-${startDate} to ${endDate}.xlsx`
          : `Schedule-${startDate} to ${endDate}.xlsx`;
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setLoading(false);
      setExportFacultyStatus(false);
      setExportStudentStatus(false);
    } catch (error) {
      console.error("Error exporting data:", error);
      setLoading(false);
    }
  };
  function checkFacultyError(id) {
    return _.some(facultyRuleError, (faculty) => faculty.faculty_id === id);
  }
  const handleRescheduleLocationsChange = (selectedOptions) => {
    if (selectedOptions?.length == 0) {
      const location = _.find(locationList, { value: selectedLocation });
      setSelectedRescheduleLocations(location ? [location] : []);
    } else {
      setSelectedRescheduleLocations(selectedOptions);
    }
  };
  const handleExportLocationsChange = (selectedOptions) => {
    if (selectedOptions?.length == 0) {
      const location = _.find(locationList, { value: selectedLocation });
      setSelectedExportLocations(location ? [location] : []);
    } else {
      setSelectedExportLocations(selectedOptions);
    }
  };
  const checkValidations = () => {
    setLoading(true);
    checkScheduleValidation(
      selectedLocation,
      selectedScheduleType,
      selectedDate
    ).then(() => {
      refreshData();
      setLoading(false);
    });
  };

  const handleSuggesionSwap = (res) => {
    checkScheduleValidation(
      selectedLocation,
      selectedScheduleType,
      selectedDate,
      "",
      createScheduleData.batch_id
    )
      .then((response) => {
        let mergedArray = _.map(schedule, (record) => {
          // Find the updated record by matching the 'id'
          const updatedRecord = _.find(response, { id: record.id });
          // If an updated record is found, merge it with the original record
          return updatedRecord ? _.merge({}, record, updatedRecord) : record;
        });
        // Find the records in updatedArray that are not in the original array and add them to the merged array
        const newRecords = _.filter(response, (updatedRecord) => {
          return !_.some(schedule, { id: updatedRecord.id });
        });

        // Combine the merged array with the new records
        mergedArray = [...mergedArray, ...newRecords];
        updateErrors(mergedArray);
        refreshData();
        // setScheduleRefresh(true);
        setModalOpen(false);
        toast.success(res.message, toastConfig);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <ToastContainer />
      <div>
        <div className="flex bg-white p-0.5 shadow-lg overflow-x-auto ">
          <div className="flex mt-0.5 mr-2 left-0">
            <Location />
          </div>
          <div className="flex mt-0.5  mr-2">
            <Calender />
          </div>
          <div className="flex mt-0.5">
            <ScheduleType />
          </div>
          <div className="flex  ml-auto">
            
            {!autoScheduleStatus ? (
              <>
              <p className="flex mt-2 ml-auto">
                {hasSchedule && ScheduleStatusCheck && (
                  <span>
                    Status:{" "}
                    <span className=" font-bold text-primaryColour">Draft</span>
                  </span>
                )}
                {hasSchedule && !ScheduleStatusCheck && (
                  <span>
                    Status:{" "}
                    <span className=" font-bold text-primaryColour">Publish</span>
                  </span>
                )}
              </p>
                {selectedCheckboxes?.length === 0 ? (
                  <button
                    disabled={
                      (!ScheduleStatusCheck && !hasSchedule) ||
                      batchList?.length === 0 ||
                      dateStatus
                    }
                    onClick={() => setIsSwapSchedule(!isSwapSchedule)}
                    className="schedule-btn bg-primaryColour text-white px-4 rounded ml-2 flex items-center shadow-lg"
                  >
                    <FaExchangeAlt className="mr-2" /> Swap
                  </button>
                ) : (
                  <button
                    className="schedule-btn bg-primaryColour text-white px-4 rounded ml-2 flex items-center shadow-lg"
                    onClick={() => setIsOpenSwapSchedule(true)}
                    disabled={selectedCheckboxes?.length < 2}
                  >
                    <FaExchangeAlt />
                    <span className="md:inline-block font-serif ml-1">Swap It</span>
                  </button>
                )}
                
                <button
                  onClick={() => checkValidations()}
                  disabled={
                    autoScheduleInProgress || dateStatus || batchList?.length === 0
                  }
                  className="schedule-btn bg-primaryColour text-white px-4 rounded ml-2 flex items-center shadow-lg"
                >
                  <FaSync className="mr-2" /> Validations
                </button>
                
                <button
                  onClick={() => togglePopup()}
                  disabled={
                    autoScheduleInProgress || dateStatus || batchList?.length === 0
                  }
                  className="schedule-btn bg-primaryColour text-white px-4 rounded ml-2 flex items-center shadow-lg"
                >
                  <FaRegCalendarAlt className="mr-2" />{" "}
                  {hasSchedule ? "Reschedule" : "Auto Schedule"}
                </button>
                
                <button
                  disabled={!ScheduleStatusCheck || autoScheduleInProgress}
                  onClick={() => togglePublishSchedule()}
                  className="schedule-btn bg-primaryColour text-white px-4 rounded ml-2 flex items-center shadow-lg"
                >
                  <FaRegCalendarCheck className="mr-2" /> Publish
                </button>
              </>
            ):
            <>
             <p className="flex ml-auto">
              <span className="mt-2">
                Status: 
                <span className="font-bold  text-primaryColour">Auto scheduling in progress. Please wait...</span>
              </span>
              <button className="schedule-btn bg-primaryColour text-white px-4 rounded ml-2 flex items-center shadow-lg" onClick={getAutoScheduleStatus}>
                <Timer duration={(parseInt(autoScheduleStatus.duration)+9)} createdAt={autoScheduleStatus.created_at} />
              </button>
            </p>
            </>}

           
            {batchList?.length != 0 && (
              <ThreeDotMenu
                setIsOpenCopySchedule={setIsOpenCopySchedule}
                locationList={locationList}
                selectedLocation={selectedLocation}
                setIsOpenExportSchedule={setIsOpenExportSchedule}
                isOpenExportSchedule={isOpenExportSchedule}
                setSelectedExportLocations={setSelectedExportLocations}
                setExportFacultyStatus={setExportFacultyStatus}
                setExportStudentStatus={setExportStudentStatus}
              />
            )}
          </div>
        </div>
        <Popup
          isOpen={isOpenExportSchedule}
          onClose={() => setIsOpenExportSchedule(false)}
          heading={
            exportFacultyStatus
              ? "Export Schedule for Faculty"
              : exportStudentStatus
              ? "Export Schedule for Students"
              : "Export Schedule"
          }
        >
          <div className="flex flex-col m-2 mb-6 ">
            <label
              htmlFor="locationSelect"
              className="mb-2 text-sm font-medium text-gray-700"
            >
              Select Locations:
            </label>
            <Select
              id="locationSelect"
              autoFocus
              placeholder={`Select Location`}
              isMulti
              className="w-84 "
              options={locationList}
              closeMenuOnSelect={false} // Prevent menu from closing on select
              hideSelectedOptions={false} // Show selected options with checkboxes
              styles={{
                control: (base) => ({
                  ...base,
                  width: "30vw", // Fixed width for the select control
                }),
                menu: (base) => ({
                  ...base,
                  width: "34rem", // Fixed width for the dropdown menu
                }),
              }}
              value={selectedExportLocations}
              onChange={handleExportLocationsChange}
            />
          </div>

          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              // onClick={() => setIsOpenExportSchedule(false)}
              onClick={() => {
                setIsOpenExportSchedule(false);
                setExportFacultyStatus(false);
                setExportStudentStatus(false);
              }}
            >
              No
            </button>
            <button
              className=" text-white font-serif bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => exportLocationScheduleToExcel()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenAutoSchedule}
          onClose={() => setIsOpenAutoSchedule(false)}
          heading={hasSchedule ? "Reschedule" : "Auto Schedule"}
        >
          <div className="flex flex-col m-2 mb-6 ">
            <label
              htmlFor="locationSelect"
              className="mb-2 text-sm font-medium text-gray-700"
            >
              Select Location for {hasSchedule ? "Reschedule" : "Auto Schedule"}
            </label>
            <Select
              id="locationSelect"
              placeholder={`Select Location`}
              autoFocus
              isMulti
              className="w-84 "
              options={locationList}
              closeMenuOnSelect={false} // Prevent menu from closing on select
              hideSelectedOptions={false} // Show selected options with checkboxes
              styles={{
                control: (base) => ({
                  ...base,
                  width: "34rem", // Fixed width for the select control
                }),
                menu: (base) => ({
                  ...base,
                  width: "34rem", // Fixed width for the dropdown menu
                }),
              }}
              value={selectedRescheduleLocations}
              onChange={handleRescheduleLocationsChange}
            />
          </div>
          <h6 className="font-serif">
            {hasSchedule
              ? "Rescheduling will replace existing records. Do you want to continue ?"
              : "Do you want to continue ?"}
          </h6>

          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenAutoSchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white font-serif bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => AutoSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenPublishSchedule}
          onClose={() => setIsOpenPublishSchedule(false)}
          heading={"Publish Schedule?"}
        >
          <h6 className="font-serif">Do you want to continue ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenPublishSchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => PublishSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenSwapSchedule}
          heading={"Swap Schedule"}
          onClose={() => setIsOpenSwapSchedule(false)}
        >
          <h6 className="font-serif">Do you want to continue ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenSwapSchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => swapSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <Popup
          isOpen={isOpenCopySchedule}
          heading={"Copy Schedules From Previous Week?"}
          onClose={() => setIsOpenCopySchedule(false)}
        >
          <h6 className="font-serif">
            {" "}
            Copy will replace if existing records. Do you want to continue ?
          </h6>
          <div className="button-container">
            <button
              className="text-gray-900  font-serif bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpenCopySchedule(false)}
            >
              No
            </button>
            <button
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => copySchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <div className="flex  p-0.5 items-center   shadow-lg">
          <button
            onClick={toggleModal}
            className={`bg-white text-gray-800 ${
              dateStatus ? "" : " hover:bg-primaryColour  hover:text-white"
            } rounded-md  text-2xl font-bold py-1 px-3 border border-gray-400 shadow`}
          >
            +
          </button>
          <button
            onClick={() => scroll("left")}
            className={`m-2 p-2  text-white h-2/5  rounded-md ${
              !canScrollLeft ? "bg-gray-400 cursor-not-allowed" : "bg-gray-800"
            }`}
            disabled={!canScrollLeft}
          >
            &lt;
          </button>
          <div
            className="overflow-x-auto w-auto max-w-4xl h-26 scrollbar-hide"
            ref={scrollContainerRef}
          >
            <div className="flex space-x-4 w-max p-2 px-0">
              {facultyList.map((user) => (
                <div
                  key={`user_` + user.id}
                  draggable={true}
                  onDragStart={(event) =>
                    handleDragStart(event, user.id, user?.subject[0]?.id)
                  }
                  style={{
                    borderColor: user?.color_code,
                  }}
                  className="relative border-2 	border-l-4 flex flex bg-white rounded-lg shadow-lg w-auto"
                >
                  <div
                    style={{
                      backgroundColor: user?.color_code,
                    }}
                    className="h-18 flex mr-1 w-6  justify-center items-center "
                  >
                    <FaGripVertical className=" text-gray-600 hover:text-gray-700 cursor-move" />
                  </div>
                  <div className="min-w-32 p-1">
                    <div className="flex">
                      <div className="flex w-full font-semibold  justify-center text-sm p-1">
                        {checkFacultyError(user.id) && (
                          <IoIosFlag
                            size={18}
                            style={{ color: "red" }}
                            className="text-md  absolute  top-1 left-6 cursor-pointer "
                            onClick={() => {
                              setSearchError(user.faculty_code);
                              setOpenErrorsDialog(true);
                            }}
                          />
                        )}
                        <a
                          href={`/facultyview/${user.id}?date=${moment(
                            selectedDate[0]
                          ).format("YYYY-MM-DD")}`}
                          target="_blank"
                          key={user.id}
                          style={{
                            textDecoration: "none",
                            color: "black",
                            transition: "color 0.3s",
                          }}
                          rel="noreferrer"
                        >
                          <FaExternalLinkSquareAlt className="text-md text-primaryColour absolute top-1 right-2 " />
                        </a>

                        {user.faculty_code}
                      </div>
                    </div>
                    <span className="text-sm mix-blend-luminosity justify-center">
                      {user.first_name} {user.last_name}
                    </span>
                    <div className="w-auto mt-2 flex items-center">
                      <div className="w-full  bg-gray-300 rounded-full">
                        <div
                          className="text-sm text-blue-100 text-center p-0.5 leading-none rounded-full"
                          style={{
                            width: `${
                              user.percentage > 100 ? 100 : user.percentage || 0
                            }%`,
                            fontSize: "1px",
                            backgroundColor: `${user.bgColor}`,
                          }}
                        >
                          {user.percentage}%
                        </div>
                      </div>
                      <span className="ml-2 mix-blend-luminosity text-sm text-primaryColour">
                        {user.totalSession ? user.totalSession : 0}/
                        {calculatedValue}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => scroll("right")}
            className={`m-2 p-2   text-white h-2/5  rounded-md ${
              !canScrollRight ? "bg-gray-400 cursor-not-allowed" : "bg-gray-800"
            }`}
            disabled={!canScrollRight}
          >
            &gt;
          </button>
          <div className=" flex overflow-x-auto flex-col ml-auto scrollbar-hide">
            <p className="flex mb-2 font-bold text-primaryColour ml-auto">
              {!hasSchedule && !ScheduleStatusCheck && (
                <p>Please go ahead with auto-scheduling</p>
              )}
              {hasSchedule &&
                ScheduleStatusCheck &&
                'Schedule is in draft mode. Click "Publish" to finalize'}
              {hasSchedule && !ScheduleStatusCheck && "Schedule is Published."}
            </p>
            <div className="flex ml-auto justify-center   space-x-1 w-max mr-3">
              {subjectList.map((subject, index) => (
                <div
                  key={`subject_` + subject.id}
                  className="flex items-center  p-0.5 rounded-lg w-22 mb-2"
                >
                  <div
                    className=" w-4 h-4 font-serif"
                    style={{
                      backgroundColor: subject?.subject_color_code || "white",
                    }}
                  ></div>
                  <span className="m-2 text-xs">{subject.subject_name}</span>
                </div>
              ))}
            </div>
            <p className="flex mr-2 font-bold text-primaryColour ml-auto">
              Compact View
              <Switch checked={isCompactView} onChange={handleSwitchChange} />
            </p>
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          slotTime={slotTime}
          schedules={schedule}
          swapSubmit={handleSuggesionSwap}
        />
        <Popup
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          heading={"Delete Schedule"}
        >
          <h6 className="font-serif">Do you want to delete the Schedule ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900 font-serif  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpen(false)}
            >
              No
            </button>
            <button
              className=" font-serif text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => handleDeleteSchedule()}
            >
              Yes
            </button>
          </div>
        </Popup>
      </div>
      <div className="relative overflow-x-auto overflow-y-auto h-[calc(100vh-13rem)] ">
        {batchList?.length == 0 && (
          <div className="flex items-center justify-center h-full w-full">
            No Batches avaiable
          </div>
        )}
        {batchList?.length > 0 && (
          <div className="w-max h-full relative">
            <ScheduleCard
              type={"morning"}
              isCompactView={isCompactView}
              batchTypeList={batchTypeList}
              allDetails={allDetails}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              hardRuleError={hardRuleError}
              dateStatus={dateStatus}
              toggleSlotModal={toggleSlotModal}
              isSwapSchedule={isSwapSchedule}
              selectedCheckboxes={selectedCheckboxes}
              handleCheckboxChange={handleCheckboxChange}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleOpenModal={handleOpenModal}
              setDeleteScheduleId={setDeleteScheduleId}
            />

            <div>&nbsp;</div>
            <ScheduleCard
              type={"afternoon"}
              isCompactView={isCompactView}
              batchTypeList={batchTypeList}
              allDetails={allDetails}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              hardRuleError={hardRuleError}
              dateStatus={dateStatus}
              toggleSlotModal={toggleSlotModal}
              isSwapSchedule={isSwapSchedule}
              selectedCheckboxes={selectedCheckboxes}
              handleCheckboxChange={handleCheckboxChange}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleOpenModal={handleOpenModal}
              setDeleteScheduleId={setDeleteScheduleId}
            />
            <div>&nbsp;</div>
            <ScheduleCard
              type={"special"}
              isCompactView={isCompactView}
              batchTypeList={batchTypeList}
              allDetails={allDetails}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              hardRuleError={hardRuleError}
              dateStatus={dateStatus}
              toggleSlotModal={toggleSlotModal}
              isSwapSchedule={isSwapSchedule}
              selectedCheckboxes={selectedCheckboxes}
              handleCheckboxChange={handleCheckboxChange}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleOpenModal={handleOpenModal}
              setDeleteScheduleId={setDeleteScheduleId}
            />
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
        )}
      </div>
      {loading && (
        <Loader message={loaderMessage} percentage={loadingPercentage} />
      )}
      <AutoScheduleErrorTable
        hardRuleError={hardRuleError}
        setHardRuleError={setHardRuleError}
        facultyRuleError={facultyRuleError}
        setFacultyRuleError={setFacultyRuleError}
        setIsDropdownOpen={setIsDropdownOpen}
        isDropdownOpen={isDropdownOpen}
        // formatDateddmmyy={formatDateddmmyy}
        handleEditErrorClick={handleOpenEditModal}
        // openPopup={openPopup}
        setDeleteScheduleId={setDeleteScheduleId}
        setIsOpen={setIsOpen}
        searchError={searchError}
        setSearchError={setSearchError}
        openErrorsDialog={openErrorsDialog}
        setOpenErrorsDialog={setOpenErrorsDialog}
      />
    </div>
  );
};

export default SchedulingNewPage;
