import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { apiService } from "../../Services/Services";
import Accordion from "./Accordion";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationContext from "../../context/LocationContext";

// import "./management.css";
import { useContext } from "react";
import Popup from "../../components/Popup";
import PopupForm from "./SubjectPopupForm";
const batchStreamURL = `${process.env.REACT_APP_API_URL}/getBatchStream`;

const SubjectList = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm();

  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const [itemToDelete, setItemToDelete] = useState(null);

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = (id, batchCode) => {
    setItemToDelete(id);

    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
    clearErrors(); // Clears all validation errors
  };
  const handleDeleteSubject = () => {
    if (itemToDelete !== null) {
      setIsOpen(false);
      apiService
        .delete(
          `${process.env.REACT_APP_API_URL}/DeleteSubject/${itemToDelete}`
        )
        .then(() => {
          toast.success("Subject Successfully deleted.. ", toastConfig);

          subjectApiData();
        });
    }
  };

  const { selectedLocation } = useContext(LocationContext);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 400); // Adjust the delay as needed (300ms in this example)

    // Cleanup function to clear the timeout if searchTerm changes within the delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalRecords, setTotalRecords] = useState("");
  const [subjectData, setSubjectData] = useState([]);
  const noOfPages = Math.ceil(totalRecords / limit);

  const subjectApiData = async () => {
    if (selectedLocation) {
      const sortOrder = sortConfig.direction === "asc" ? "asc" : "desc";
      const sortBy = sortConfig.key ? sortConfig.key : "updated_at";
      const url = `${process.env.REACT_APP_API_URL}/getSubject?q=${debouncedTerm}&limit=${limit}&page=${currentPage}&sort_by=${sortBy}&sort_order=${sortOrder}`;

      try {
        const response = await apiService.get(url);
        if (response) {
          const responseData = await response;
          const { data, total } = responseData;
          setSubjectData(data);

          setTotalRecords(total);
        } else {
          console.error("Error fetching batch data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleLimitChange = (e) => {
    setLimit(e);
    setCurrentPage(0);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getfetchData = useRef(true);

  useEffect(() => {
    if (selectedLocation) {
      if (!getfetchData.current) {
        subjectApiData();
        getfetchData.current = false;
      }
    }
  }, [debouncedTerm, currentPage, sortConfig, selectedLocation, limit]);

  getfetchData.current = false;

  function getRandomColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    const color = `#${red.toString(16).padStart(2, "0")}${green
      .toString(16)
      .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;

    return color;
  }

  const [color, setColor] = useState(getRandomColor());

  const [tempColor, setTempColor] = useState(color);
  const [showPicker, setShowPicker] = useState(true);
  const colorPickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleColorChange = (event) => {
    setTempColor(event.target.value);
  };

  const [SubjectName, setSubjectName] = useState("");
  const [SubjectCode, setSubjectCode] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const capitalizedInput =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);

    setSubjectName(capitalizedInput);
    setValue("subjectName", capitalizedInput);
  };

  const handleSubjectCode = (event) => {
    const inputValue = event.target.value;
    const capitalizedInput = inputValue.toUpperCase();
    setSubjectCode(capitalizedInput);
    setValue("subjectCode", capitalizedInput);
  };
  const [batchStreamData, setBatchStreamData] = useState([]);
  const getBatchStreamData = async () => {
    try {
      const Response = await apiService.get(batchStreamURL);
      const mappingResponse = Response.map((response) => {
        const subjects = response.subject || [];

        const mappedSubjects = subjects.map((subject) => {
          return {
            label: subject.subject_name,
            value: subject.subject_code,
            id: subject.id,
          };
        });

        return {
          id: response.id,
          label: response.stream_names,
          value: response.stream_names,
          subjects: mappedSubjects,
        };
      });

      setBatchStreamData(mappingResponse);
    } catch (error) {
      console.error("Error mapping subjects:", error);
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [subjectGetById, setsubjectGetById] = useState([]);
  const [popUpButton, setPopUpButton] = useState(false);
  const [popUpHeading, setPopUpHeading] = useState("");

  const togglePopup = () => {
    setTempColor(color);
    setColor(getRandomColor());
    setIsPopupOpen(true);
    setPopUpButton(false);
  };

  const cancelPopup = () => {
    setSubjectName("");
    setSubjectCode("");
    setValue("subjectName", "");
    setValue("batchStream", "");
    setValue("subjectCode", "");
    clearErrors(); // Clears all validation errors

    setIsPopupOpen(false);
    setValue("colorcode", color);
    setsubjectGetById("");
    setTempColor("#ffffff");
    setColor(getRandomColor()); // Set a new random color

    setPopUpButton(false);
    setPopUpHeading("Create Subject");
  };

  const handleEditSubject = (id) => {
    setIsPopupOpen(true);
    setsubjectGetById(id);
  };

  const subjectGetByIdUrl = `${process.env.REACT_APP_API_URL}/showByIDSubject/${subjectGetById}`;
  const [getByIdData, setGetByIdData] = useState([]);
  const fetchSubjectGetByData = async () => {
    try {
      const Response = await apiService.get(subjectGetByIdUrl);
      if (Response) {
        setGetByIdData(Response);
        if (Response && Response && Response.subject_name) {
          setSubjectName(Response.subject_name);
          setValue("subjectName", Response.subject_name);
        }
        if (Response && Response && Response.subject_code) {
          setSubjectCode(Response.subject_code);
          setValue("subjectCode", Response.subject_code);
        }

        if (Response && Response && Response?.subject_color_code) {
          // setSubjectName(Response.subject_name);
          setValue("colorcode", Response?.subject_color_code);
          setTempColor(Response?.subject_color_code);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (subjectGetById && getByIdData && getByIdData.batch_stream) {
      const selectedLocationOptions = batchStreamData.filter((option) =>
        getByIdData.batch_stream?.find(
          (stream) => stream.pivot.batch_stream_id === option.id
        )
      );
      setValue("batchStream", selectedLocationOptions);
    }
  }, [subjectGetById, getByIdData]);

  useEffect(() => {
    if (subjectGetById) {
      fetchSubjectGetByData();
    }
  }, [subjectGetById]);

  useEffect(() => {
    if (subjectGetById.length === 0) {
      if (Array.isArray(subjectGetById) && subjectGetById.length === 0) {
        setPopUpHeading("Create Subject");
        setPopUpButton(false);
      }
    } else {
      setPopUpHeading("Update Subject");
      setPopUpButton(true);
    }
  }, [subjectGetById, popUpHeading]);

  const onSubmit = async (data) => {
    const straemIds = data.batchStream.map((item) => item.id);

    const postData = {
      subject_name: data.subjectName,
      subject_code: data.subjectCode,
      batch_stream_id: straemIds,
      subject_color_code: data?.colorcode,
    };

    if (subjectGetById.length === 0) {
      try {
        const response = await apiService
          .post(`${process.env.REACT_APP_API_URL}/createSubject`, postData)
          .then(() => {
            setValue("subjectName", "");
            setValue("batchStream", "");
            setValue("colorcode", color);
            setTempColor("#ffffff");
            setColor(getRandomColor()); // Set a new random color
            setsubjectGetById("");
            setIsPopupOpen(false);
            toast.success("Record Successfully Created.. ", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
            subjectApiData();
          })
          .catch((err) => {
            setTempColor("#ffffff");
            setColor(getRandomColor()); // Set a new random color
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
          });
      } catch (error) {
        setTempColor("#ffffff");
        setColor(getRandomColor()); // Set a new random color

        console.error("Error:", error.message);
      }
    } else {
      try {
        const response = await apiService
          .patch(
            `${process.env.REACT_APP_API_URL}/UpdateSubject/${subjectGetById}`,
            postData
          )
          .then(() => {
            setsubjectGetById("");
            setValue("subjectName", "");
            setValue("batchStream", "");
            setValue("colorcode", color);
            // setTempColor("#ffffff");
            setColor(getRandomColor()); // Set a new random color

            setIsPopupOpen(false);
            toast.success("Record Successfully Updated.. ", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
            subjectApiData();
          })
          .catch((err) => {
            setColor(getRandomColor()); // Set a new random color

            toast.error(err, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
          });
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };
  const accordionItems = [
    {
      title: "Subject",
      content: (
        <>
          <div class="flex justify-end items-center m-2">
            <input
              value={searchTerm}
              onChange={handleChange}
              defaultValue={SubjectName}
              class="bg-white border border-gray-500 m-1 py-2 px-2 focus:outline-none mr-2"
              placeholder="Search....."
            />
            <button
              onClick={togglePopup}
              class="bg-white hover:bg-gray-100 text-gray-800 font-serif text-md font-bold py-2 px-3 border border-gray-400 shadow mr-2"
            >
              +
            </button>
          </div>
          <section className="h-full mb-8 font-serif bg-white  ">
            <div className="acpageHeight">
              <div className="min-w-full inline-block align-middle">
                <div className="">
                  <table className="min-w-full divide-y divide-gray-400 ">
                    <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      <tr className=" bg-primaryColour text-white">
                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                          onClick={() => handleSort("subject_name")}
                        >
                          Subject Name{" "}
                          {sortConfig.key === "subject_name" ? (
                            sortConfig.direction === "asc" ? (
                              <span>▲</span>
                            ) : (
                              <span>▼</span>
                            )
                          ) : (
                            <span>▲</span>
                          )}
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                        >
                          Stream
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                        >
                          {" "}
                          Color Code
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-400 dark:divide-gray-700">
                      {subjectData.length === 0 ? (
                        <tr>
                          <td
                            colSpan="9"
                            className="text-center py-4 text-gray-800 font-serif"
                          >
                            No batch available
                          </td>
                        </tr>
                      ) : (
                        subjectData.map((item, i) => (
                          <tr className="hover:bg-gray-100" key={i}>
                            <td className="px-8 py-3 whitespace-nowrap text-sm  text-gray-800  font-serif  text-center ">
                              {/* {item.batch_code ? item.batch_code : ""} */}
                              {item.subject_name}
                            </td>
                            <td className="px-8 py-3 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
                              {item.batch_stream.map((streamItem, index) => (
                                <p key={streamItem.id}>
                                  {streamItem?.stream_names}
                                  {index !== item.batch_stream.length - 1 &&
                                    ","}{" "}
                                  {/* Add comma if not the last item */}
                                </p>
                              ))}
                            </td>

                            <td className="px-8 py-3 whitespace-nowrap text-sm  text-gray-800  font-serif  text-center ">
                              {/* {item.subject_color_code} */}
                              <div
                                style={{
                                  backgroundColor: item?.subject_color_code,
                                  height: "20px",
                                  width: "30px",
                                  margin: "auto",
                                }}
                                className="  "
                              ></div>{" "}
                            </td>

                            <td
                              className="px-8 py-3 flex whitespace-nowrap text-sm  text-gray-800  font-serif  text-center "
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className=" text-center    "
                              >
                                <button
                                  className="button muted-button "
                                  onClick={() => handleEditSubject(item.id)}
                                >
                                  {/* <Link to={`/batchformedit/${item.id}`}> */}
                                  <AiFillEdit className="text-xl mr-3 text-green-700" />
                                  {/* </Link> */}
                                </button>
                              </div>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <button onClick={() => openPopup(item.id)}>
                                  <RiDeleteBin6Line className="text-red-700 text-xl mr-3" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className=" mt-3" style={{ float: "right" }}>
              <div className="flex">
                <p className=" ">Items per page:</p>

                <div className="relative inline-block">
                  <button
                    className="me-3 mb-3 md:mb-0 text-balck  border       font-medium text-sm px-3 py-1 text-center inline-flex items-center  "
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    type="button"
                    style={{ border: " 2px solid balck" }}
                  >
                    {limit}
                    <svg
                      className={`w-2.5 h-2.5 ms-3 transition-transform transform ${
                        isDropdownOpen ? "rotate-180" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>

                  <div
                    className={`z-10 ${
                      isDropdownOpen ? "" : "hidden"
                    } absolute bottom-0 mb-6 bg-white divide-y divide-gray-100 rounded-lg   w-16 dark:bg-gray-700`}
                  >
                    <ul className="py-2text-base text-gray-700 dark:text-gray-200">
                      <li>
                        <button
                          onClick={() => handleLimitChange(6)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          6
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(10)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          10
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(20)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          20
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(50)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          50
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  pageCount={noOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                />
              </div>
            </div>
          </section>
        </>
      ),
    },
  ];
  useEffect(() => {
    if (isPopupOpen === true) {
      getBatchStreamData();
    }
  }, [isPopupOpen]);

  return (
    <>
      <Popup isOpen={isOpen} onClose={closePopup}>
        <h6>Do you want to delete the subject ?</h6>
        <div className="button-container">
          <button
            className="text-gray-900  bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
            onClick={() => setIsOpen(false)}
          >
            No
          </button>
          <button
            className=" text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() => handleDeleteSubject()}
          >
            Yes
          </button>
        </div>
      </Popup>
      <div className=" ml-6 pb-6 font-serif mb-4">
        <div className="mt-6 ml-8 mr-8  bg-secondaryColour">
          <Accordion
            items={accordionItems}
            defaultOpenIndex={0}
            isPopupOpen={setIsPopupOpen}
          />
        </div>
        <div>
          {isPopupOpen && (
            <PopupForm
              watch={watch}
              errors={errors}
              SubjectCode={SubjectCode}
              handleSubjectCode={handleSubjectCode}
              setValue={setValue}
              register={register}
              isPopupOpen={isPopupOpen}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              SubjectName={SubjectName}
              handleInputChange={handleInputChange}
              batchStreamData={batchStreamData}
              tempColor={tempColor}
              handleColorChange={handleColorChange}
              cancelPopup={cancelPopup}
              subjectGetById={subjectGetById}
              heading={popUpHeading}
              popUpButton={popUpButton}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SubjectList;
