import axios from "axios";

export const getApiService = async (url, method = "GET", data = null) => {
  try {
    const config = {
      method,
      url,
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getDataById = async (url, id) => {
  const apiUrl = `${url}/${id}`;
  return await getApiService(apiUrl);
};

export const updateData = async (url, id, data) => {
  const apiUrl = `${url}/${id}`;
  return await getApiService(apiUrl, "PUT", data);
};

export const deleteData = async (url, id) => {
  const apiUrl = `${url}/${id}`;
  return await getApiService(apiUrl, "DELETE");
};

export const createData = async (url, data) => {
  return await getApiService(url, "POST", data);
};

// Common service for making HTTP requests
export const apiService = {
  // Function for sending a request
  request: async (url, method, data = {}) => {
    try {
      const token = localStorage.getItem("access_token"); // Assuming the token is stored in localStorage
      const headers = {
        Authorization: `Bearer ${token}`, // Include the authorization token in the header
      };
      const response = await axios({
        method,
        url,
        data,
        headers,
      });

      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.clear();
        window.location.replace("/");
      }
      console.error(`Error in ${method.toUpperCase()} request:`, error);
      throw error;
    }
  },
  // Function for making a GET request
  get: async (url, params = {}) => {
    if (Object.keys(params).length != 0) {
      const queryString = new URLSearchParams(params).toString();
      url = `${url}?${queryString}`;
    }
    return await apiService.request(url, "GET", params);
  },

  // Function for making a POST request
  post: async (url, data = {}) => {
    return await apiService.request(url, "POST", data);
  },

  // Function for making a PUT request
  put: async (url, data = {}) => {
    return await apiService.request(url, "PUT", data);
  },

  // Function for making a PATCH request
  patch: async (url, data = {}) => {
    return await apiService.request(url, "PATCH", data);
  },

  // Function for making a DELETE request
  delete: async (url) => {
    return await apiService.request(url, "DELETE");
  },
  getFile: async (url, data = {}) => {
    try {
      const token = localStorage.getItem("access_token"); // Assuming the token is stored in localStorage
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { responseType: "blob", headers });
      return response;
    } catch (error) {
      console.error(`Error in request:`, error);
      throw error;
    }
  },
};
