import { apiService } from "./Services";
import { toast } from "react-toastify";

export function filterDataBySlot(data, slotName) {
  if (Array.isArray(data)) {
    return data.filter((item) =>
      item?.batch?.batch_slots.some((slot) => slot.name === slotName)
    );
  } else {
    return [];
  }
}

export function calculatePercentage(startDate, endDate, hoursWorkedPerDay) {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  // Calculate the difference in milliseconds between the two dates
  const dateDifference = endDate - startDate + oneDayInMilliseconds;
  const numberOfDays = dateDifference / oneDayInMilliseconds;

  const maxPossibleHours = numberOfDays * 10;

  const percentage = (hoursWorkedPerDay / maxPossibleHours) * 100;

  const formattedPercentage = percentage.toFixed(1);

  return formattedPercentage;
}

export function filterAndSortDataByWeek(
  data,
  startDate,
  endDate,
  batch_id,
  batch_code,
  morningSlots,
  afternoonSlots,
  mornning_time,
  afternoon_time,
  i
) {
  const filteredAndSortedDataByWeek = [];
  const currentDate = new Date(startDate);
  const lastDate = new Date(endDate);

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  while (currentDate <= lastDate) {
    const formattedDate = formatDate(currentDate);
    const dayOfWeek = currentDate.toLocaleDateString("en-US", {
      weekday: "long",
    });

    const filteredData = data.filter(
      (item) => item.date === formattedDate && item.batch_id === batch_id
    );

    filteredAndSortedDataByWeek.push({
      date: formattedDate,
      day: dayOfWeek,
      data: filteredData,
    });

    // Move to the next date
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const result = {
    batchid: batch_id,
    batch_code: batch_code,
    mornning_Slots: morningSlots,
    afternoonSlots: afternoonSlots,
    data: filteredAndSortedDataByWeek,
    mornning_time: mornning_time ? mornning_time : " null",
    afternoon_time: afternoon_time ? afternoon_time : " null",
    bathchId: i,
  };

  return result;
}

export function addDataNotFoundMessage(
  filteredAndSortedDataByWeek,
  missingSlotTimes
) {
  const updatedData = filteredAndSortedDataByWeek.map((item, outerIndex) => {
    if (item.data.length === 0) {
      return {
        date: item.date,
        day: item.day,
        data: missingSlotTimes.map((ele, innerIndex) => {
          return {
            index: innerIndex, // Use the innerIndex here
            message: "Data not found",
            slotTime: ele,
            // batchDetails: locationNew[outerIndex],
          };
        }),
      };
    } else {
      const slotTimes = item.data.map(
        (dataItem) => dataItem.slotTime.slot_time
      );
      const missingTimes = missingSlotTimes.filter(
        (time) => !slotTimes.includes(time)
      );

      const newData = [...item.data];
      missingTimes.forEach((time) => {
        newData.push({
          slotTime: { slot_time: time },
          message: "Data not found",
        });
      });

      return {
        date: item.date,
        day: item.day,
        data: newData.sort((a, b) => {
          const timeA = parseInt(a.slotTime.slot_time.replace(":", ""));
          const timeB = parseInt(b.slotTime.slot_time.replace(":", ""));
          return timeA - timeB;
        }),
      };
    }
  });

  return updatedData;
}

export function filterDataByBatchId(data, batchId) {
  const filteredData = data.filter((item) => {
    if (item.batch_id === batchId) {
      return true;
    } else if (Array.isArray(item.data) && item.data.length > 0) {
      return item.data.some((nestedItem) => nestedItem.batch_id === batchId);
    }
    return false;
  });

  return filteredData;
}

export function groupDataByBatchId(data) {
  const groupedData = [];

  data.forEach((item) => {
    const existingGroup = groupedData.find(
      (group) => group.batch_id === item.batch_id
    );
    if (existingGroup) {
      existingGroup.data.push(item);
    } else {
      groupedData.push({ batch_id: item.batch_id, data: [item] });
    }
  });

  return groupedData;
}
//

export function getDateRangeWithSlots(
  startDateString,
  endDateString,
  slotTimes
) {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

  const dateArray = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const date = currentDate.toISOString().slice(0, 10); // Get the date in 'YYYY-MM-DD' format
    const day = getDayOfWeek(currentDate);
    const slot = slotTimes.map((time) => time); // Clone the array of slotTimes

    dateArray.push({ date, day, slot });

    currentDate = new Date(currentDate.getTime() + oneDay);
  }

  return dateArray;
}

function getDayOfWeek(date) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return daysOfWeek[date.getDay()];
}
export function addReportsToFacultyCard(facultyCardState, calculateState) {
  if (!calculateState) {
    return facultyCardState;
  }
  const updatedFacultyCardState = facultyCardState.map((faculty) => {
    const calculateFaculty = calculateState.find(
      (calc) => calc && calc.id === faculty.id
    );
    if (calculateFaculty) {
      faculty.week_hours = calculateFaculty.week_hours;
      faculty.month_hours = calculateFaculty.month_hours;
      faculty.year_hours = calculateFaculty.year_hours;
    }
    return faculty;
  });

  return updatedFacultyCardState;
}

export function calculateValueFromDates(
  startDate,
  endDate,
  conversionRatePerDay
) {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  let daysDifference = 0;

  for (
    let date = startDateObj;
    date <= endDateObj;
    date.setDate(date.getDate() + 1)
  ) {
    // Check if the current day is not Sunday (0 index for Sunday)
    if (date.getDay() !== 0) {
      daysDifference++;
    }
  }

  // Calculate the value based on the remaining weekdays and conversion rate per day
  const calculatedValue = daysDifference * conversionRatePerDay;
  return calculatedValue;
}

export function sortDates(dateArray) {
  const data = dateArray?.leave_records[0].dates;
  return data.sort((a, b) => new Date(a) - new Date(b));
}

export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function checkWeekStatus(startDate, endDate) {
  const today = new Date();
  const startOfCurrentWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay()
  ); // Get Monday of current week
  const endOfPreviousWeek = new Date(startOfCurrentWeek);
  endOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 1); // Get Sunday of previous week
  const startOfPreviousWeek = new Date(endOfPreviousWeek);
  startOfPreviousWeek.setDate(endOfPreviousWeek.getDate() - 6); // Get Monday of previous week
  
  let showButton = false;
  // Convert string dates to Date objects
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  // Check if the start date is after the end of the previous week
  if (startDate > endOfPreviousWeek) {
    showButton = true;
    return showButton; // Start date is after the previous week
  }

  // Check if the end date is before the start of the previous week
  if (endDate < startOfPreviousWeek) {
    return showButton; // End date is before the previous week
  }

  // Otherwise, the start and/or end date is within the previous week
  return showButton; // Start or end date is within the previous week
}

export async function checkScheduleValidation(
  selectedLocation,
  selectedScheduleType,
  selectedDate,
  facultyIds='',
  batchIds=''
) {
  const toastConfig = {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      marginBottom: "4vw",
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.5em",
        width: "400px",
        padding: "10px",
      },
      fontSize: "1.2em",
      width: "400px",
      padding: "10px",
    },
  };
  const selectedDateArray = Array.isArray(selectedDate) ? selectedDate : [];
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });
  const startDate = formattedDates[0];
  const endDate = formattedDates[1];
  let streamCode = "";

  if (selectedScheduleType === "jee/medical") {
    streamCode = "j/m";
  } else if (selectedScheduleType === "jee") {
    streamCode = "j";
  } else if (selectedScheduleType === "medical") {
    streamCode = "m";
  } else if (selectedScheduleType === "foundation") {
    streamCode = "f";
  }
  if (selectedDate && selectedDate[0] && selectedDate[1]) {
    const url = `${process.env.REACT_APP_API_URL}/checkAutoScheduleDataToApi?from_date=${startDate}&to_date=${endDate}&location_id=${selectedLocation}&batch_stream=${streamCode}&facultyIds=${facultyIds}&batchIds=${batchIds}`;
    try {
      const response = await apiService.post(url);

      if (response.response) {
        return response.response;
      } else {
        toast.error(
          "Failed to Verified Schedule . Please try again.",
          toastConfig
        );
      }
    } catch (error) {
      toast.error("Failed to Verified Schedule" + error, toastConfig);
    } finally {
    }
  } else {
    console.error(
      "Invalid selectedDate array. Ensure both start and end dates are defined."
    );
  }
}
