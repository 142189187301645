import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import "./FacultyCreate.css";
import { toast } from "react-toastify";
import { apiService } from "../../../Services/Services";
import defaultImage from "../../../utils/Images/defaultImage.jpg";
import LocationContext from "../../../context/LocationContext";
import { ChromePicker } from 'react-color';

const locationURL = `${process.env.REACT_APP_API_URL}/getlocation?q=&limit=2&page=1&name=1&sortBy=name&sortOrder=DESC`;
const batchSloteURL = `${process.env.REACT_APP_API_URL}/getBatchslot?q=&limit=5&page=1&name=1&sortBy=name&sortOrder=DESC`;
const subjectURL = `${process.env.REACT_APP_API_URL}/getSubject`;

const FacultyCreate = () => {
  const { selectedLocation } = useContext(LocationContext);

  const [defaultBatchSlot, setDefaultBatchSlot] = useState(null);

  const [locationData, setlocationData] = useState([]);
  const [batchSlotData, setBatchSlotData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLatName] = useState("");
  const [address, setAddress] = useState("");

  // ------------------------------------------------------------------
  function getRandomColorFaculty() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    const color = `#${red.toString(16).padStart(2, "0")}${green
      .toString(16)
      .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;

    return color;
  }

  const [color, setColor] = useState(getRandomColorFaculty());
  // const [color, setColor] = useState('#ffffff');

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    setValue('colorCode', newColor.hex, { shouldValidate: true });
  };
// -----------------------------------------------------------------------


  const [facultyCode, setFacultyCode] = useState("");

  const handleFacultyCodeChange = (e) => {
    const capitalizedValue = e.target.value.toUpperCase();
    setFacultyCode(capitalizedValue);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(URL.createObjectURL(file));
  };

  const handleFirstNameChange = (event) => {
    const words = event.target.value.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        const lowercaseWord = word.toLowerCase();
        return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(" ");
    setFirstName(capitalizedValue);
  };
  const handleLastNameChange = (event) => {
    const words = event.target.value.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        const lowercaseWord = word.toLowerCase();
        return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(" ");
    setLatName(capitalizedValue);
  };

  const handleAddressChange = (event) => {
    const words = event.target.value.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        const lowercaseWord = word.toLowerCase();
        return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(" ");
    setAddress(capitalizedValue);
  };

  const getLocationData = async () => {
    const Response = await apiService.get(locationURL);
    if (Response) {
      try {
        const mappingResponse = Response.map((response) => {
          return {
            label: response.name,
            value: response.name,
            id: response.id,
          };
        });
        setlocationData(mappingResponse);
      } catch (error) {
        return error;
      }
    }
  };

  const getBatchSlotData = async () => {
    const Response = await apiService.get(batchSloteURL);
    if (Response) {
      try {
        const mappingResponse = Response.map((response) => {
          return {
            label: response.name,
            value: response.name,
            id: response.id,
          };
        });
        setDefaultBatchSlot(mappingResponse);
        setBatchSlotData(mappingResponse);
      } catch (error) {
        return error;
      }
    }
  };

  const getSubjectData = async () => {
    const Response = await apiService.get(subjectURL);
    if (Response) {
      try {
        const mappingResponse = Response.data.map((response) => {
          return {
            label: response.subject_name,
            value: response.subject_name,
            id: response.id,
          };
        });

        setSubjectData(mappingResponse);
      } catch (error) {
        return error;
      }
    }
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    async function fetchData() {
      await getLocationData();
      await getBatchSlotData();
      await getSubjectData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (defaultBatchSlot) {
      setValue("batchSlot", [defaultBatchSlot]);
    }
  }, [defaultBatchSlot, setValue]);

  const isBatchSlotDataLoaded = batchSlotData.length > 0;
  const defaultBatchSlotValues = isBatchSlotDataLoaded
    ? [batchSlotData[0], batchSlotData[1]] // Pass an array of selected options
    : null;

  useEffect(() => {
    const defaultOption = locationData.find(
      (option) => option.id === selectedLocation
    );
    if (defaultOption) {
      setValue("locations", [defaultOption]);
    }
  }, [locationData, selectedLocation]);

  const onSubmit = async (data) => {
    console.log("data", data)
    const subjectIds = data.subject.map((item) => item.id);
    const locationIds = data.locations.map((item) => item.id);
    const batchSlotIds = data.batchSlot.flatMap((nestedArray) =>
      nestedArray.map((item) => item.id)
    );

    const profilePicFile = data.profilePic[0];

    const formData = new FormData();
    formData.append("first_name", data.firstname);
    formData.append("last_name", data.lastname);

    formData.append("mail", data.email);
    formData.append("phone", data.phoneNumber);
    formData.append("address", data.address);
    formData.append("gender", data.gender);
    formData.append("faculty_code", data.facultyCode);
    subjectIds.forEach((id) => formData.append("subject_id[]", id));
    formData.append("age", data.age);
    formData.append("experience", data.experience);
    formData.append("color_code", data.colorcode);

    formData.append("image", profilePicFile);
    locationIds.forEach((id) => formData.append("location_id[]", id));
    batchSlotIds.forEach((id) => formData.append("batch_slot_id[]", id));
    try {
      const response = await apiService
        .post(`${process.env.REACT_APP_API_URL}/createfaculty`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast.success("Record Successfully Created.. ", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              marginBottom: "4vw",
              fontSize: "1.2em",
              width: "400px",
              padding: "10px",
            },
          });
          setTimeout(() => {
            navigate("/facultylist");
          }, 3000);
        })
        .catch((err) => {
          const mailErrorMessage =
            err.response.data.errors && err.response.data.errors.mail
              ? err.response.data.errors.mail[0]
              : "Unknown error";

          toast.error(mailErrorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              marginBottom: "4vw",
              fontSize: "1.2em",
              width: "400px",
              padding: "10px",
            },
          });
        });
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <div className="  ">
        <>
          <ToastContainer />
        </>

        <div className=" bg-secondaryColour ml-6 mr-4 h-auto pb-6 font-serif mb-4 ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            // style={{ height: "70vh" }}
            className="   overflow-y-auto bg-secondaryColour cuPageHeight"
          >
            <div className=" mt-2 ">
              <div className="  grid grid-cols-12 ml-6 pt-3  font-serif ">
                <div className=" col-span-6">
                  <label className=" font-serif text-base  mb-1 mt-3 text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    First Name:
                  </label>
                  <div>
                    <input
                      id="firstname"
                      type="text"
                      autoFocus
                      placeholder="First Name"
                      style={{ height: "40px", outline: "none" }}
                      {...register("firstname", {
                        required: "This is required",

                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "This is not a valid First Name",
                        },
                        validate: {
                          startsWithLetter: (value) =>
                            /^[A-Za-z][^\s]*$/.test(value) ||
                            "First character should be a letter and spaces are not allowed as the first character",
                        },

                        minLength: {
                          value: 3,
                          message:
                            "First Name must be at least 3 characters long",
                        },
                        maxLength: {
                          value: 15,
                          message:
                            "First Name can be at most 10 characters long",
                        },
                      })}
                      className="input   "
                      onChange={handleFirstNameChange}
                      value={firstName}
                      autoComplete="off"
                    />
                    <div>
                      {errors.firstname && (
                        <span className="validationcolor">
                          {errors.firstname.message}
                          {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-span-6 mt-1 ">
                  <label className="text-base font-serif  mb-1 mt-3 text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Last Name:
                  </label>
                  <div>
                    <input
                      id="lastname"
                      type="text"
                      style={{ height: "40px", outline: "none" }}
                      placeholder="Last Name"
                      autoComplete="off"
                      {...register("lastname", {
                        required: "This is required",

                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message: "This is not a valid First Name",
                        },
                        validate: {
                          startsWithLetter: (value) =>
                            /^[A-Za-z][^\s]*$/.test(value) ||
                            "First character should be a letter and spaces are not allowed as the first character",
                        },

                        minLength: {
                          value: 3,
                          message:
                            "Last Name must be at least 3 characters long",
                        },
                        maxLength: {
                          value: 15,
                          message:
                            "Last Name can be at most 10 characters long",
                        },
                      })}
                      className="input  "
                      onChange={handleLastNameChange}
                      value={lastName}
                    />
                    <div>
                      {errors.lastname && (
                        <span className="validationcolor">
                          {errors.lastname.message}
                          {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-span-6 mt-1">
                  <label className=" text-base  font-serif  mb-1 mt-3 text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Email:
                  </label>
                  <div>
                    <input
                      id="email"
                      type="email"
                      style={{ height: "40px", outline: "none" }}
                      placeholder="Email"
                      autoComplete="off"
                      {...register("email", {
                        required: "This is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      className={`input     border-gray-400 `}
                    />
                    <div>
                      {errors.email && (
                        <span className="validationcolor">
                          {errors.email.message}
                          {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-span-6 mt-1">
                  <label className="text-base mb-1 mt-3 font-serif text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Faculty Code:
                  </label>
                  <div>
                    <input
                      id="facultyCode"
                      type="text"
                      placeholder="Faculty Code"
                      style={{ height: "40px", outline: "none" }}
                      value={facultyCode}
                      {...register("facultyCode", {
                        required: "This is required",
                        minLength: {
                          value: 1,
                          message:
                            "Faculty Code must be at least 1 characters long",
                        },
                        maxLength: {
                          value: 6,
                          message:
                            "Faculty Code can be at most 6 characters long",
                        },
                      })}
                      onChange={handleFacultyCodeChange}
                      className="input"
                      autoComplete="off"
                    />
                    <div>
                      {errors.facultyCode && (
                        <span className="validationcolor">
                          {errors.facultyCode.message}
                          {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 mt-1">
                  <label className="text-base mb-1  text-primary font-serif ">
                    Phone No:
                  </label>
                  <div>
                    <input
                      id="number"
                      type="number"
                      style={{ height: "40px", outline: "none" }}
                      placeholder="Contact"
                      autoComplete="off"
                      {...register("phoneNumber", {
                        pattern: {
                          value: /^(\+\d{1,3}[- ]?)?[1-9]\d{9}$/,
                          message: "This is not a valid phone number",
                        },
                      })}
                      className="input"
                    />

                    <div>
                      {errors.phoneNumber && (
                        <span className="validationcolor">
                          {errors.phoneNumber.message}
                          {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className=" col-span-4 mt-1"
                  style={{ width: "30vw", outline: "none" }}
                >
                  <label className="text-base mb-1  text-primary font-serif ">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Center Locations:
                  </label>
                  <Select
                    isMulti
                    options={locationData}
                    value={watch("locations")}
                    {...register("locations", { required: true })}
                    onChange={(selectedOptions) => {
                      setValue("locations", selectedOptions);
                    }}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "white",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                        height: "22px",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                  <div>
                    {errors.locations && (
                      <p className="error validationcolor">
                        This is required {"*"}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-6 font-serif">
                  <label className="text-base mb-1 text-primary font-serif">
                    <span className="text-red-600 font-bold text-lg">*</span>
                    Gender:
                  </label>
                  <div>
                    <select
                      {...register("gender", { required: true })}
                      style={{ height: "40px", width: "30vw" }}
                      className="bg-white font-serif"
                      placeholder="Select Gender"
                    >
                      <option value="" disabled selected>
                        Select Gender
                      </option>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                      <option value="other">Other</option>
                    </select>
                    <div>
                      {errors.gender && errors.gender.type === "required" && (
                        <span className="validationcolor font-serif">
                          This is required {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className=" col-span-6  mt-2  font-serif ">
                  <label className="text-base mb-1 mt-3 text-primary">
                    Age:
                  </label>
                  <div>
                    <input
                      id="age"
                      type="number"
                      style={{ height: "40px", outline: "none" }}
                      placeholder="Age"
                      {...register("age", {
                        pattern: {
                          value: /^(?:0*(?:[1-9][0-9]?|1[01][0-9]|12[0-9]))$/,
                          message: "Age must be less than 130",
                        },
                      })}
                      className={`input   `}
                    />

                    <div>
                      {errors.age && (
                        <span className="validationcolor">
                          {errors.age.message}
                          {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" col-span-6 mt-2 ">
                  <label className=" text-base mb-1  text-primary font-serif ">
                    Faculty Experience:
                  </label>
                  <div>
                    <input
                      id="experience"
                      type="text"
                      style={{ height: "40px", outline: "none" }}
                      placeholder="Experience"
                      autoComplete="off"
                      // {...register("experience")}
                      {...register("experience", {
                        pattern: {
                          value: /^(?:\d{1,2}|100)(?:\sYears?)?$/,
                          message:
                            "Experience must be a numeric value less than or equal to 100 Years",
                        },
                      })}
                      className={`input   border-gray-400 `}
                    />
                    <div>
                      {errors.experience && (
                        <span className="validationcolor">
                          {errors.experience.message}
                          {"*"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 rounded-sm mt-2">
                  <label className="text-base mb-1  text-primary font-serif ">
                    Address:
                  </label>
                  <div>
                    <input
                      id="address"
                      type="text"
                      style={{ height: "40px", outline: "none" }}
                      placeholder="Address"
                      autoComplete="off"
                      {...register("address", {
                        pattern: {
                          value: /^[a-zA-Z0-9\s]+$/,
                          message:
                            "Special characters are not allowed in the address",
                        },
                      })}
                      onChange={handleAddressChange}
                      value={address}
                      className={`input border-gray-400`}
                    />
                  </div>
                </div>

                <div
                  className=" col-span-4  mt-2 "
                  style={{ width: "30vw", outline: "none" }}
                >
                  <label className="text-base mb-1 text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Subjects:
                  </label>
                  <Select
                    isMulti
                    className="  "
                    options={subjectData}
                    {...register("subject", { required: true })}
                    onChange={(selectedOptions) => {
                      setValue("subject", selectedOptions);
                    }}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "white",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                        height: "40px",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        // maxHeight: "156px",
                        overflowY: "auto",
                      }),
                    }}
                  />
                  <div>
                    {errors.subject && (
                      <p className="error validationcolor ">
                        This is required {"*"}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-2"></div>

                <div
                  className="  col-span-4 mt-2 font-serif "
                  style={{ width: "30vw" }}
                >
                  <label className="text-base mb-1 mt-3 text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Prefered Slots:
                  </label>
                  {isBatchSlotDataLoaded ? (
                    <Select
                      placeholder="Batch Slots"
                      isMulti
                      options={batchSlotData}
                      defaultValue={defaultBatchSlotValues}
                      style={{ outline: "none", border: "none" }}
                      {...register("batchSlot", { required: true })}
                      onChange={(selectedOptions) => {
                        setValue("batchSlot", selectedOptions);
                      }}
                      className="custom-select"
                      classNamePrefix="custom-select font-serif "
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "white",
                          borderRadius: 0,
                          border: "none",
                          outline: "none",
                          boxShadow: "none",
                          height: "40px",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />
                  ) : (
                    <p>Loading batch slots...</p>
                  )}
                  <div>
                    {errors.batchSlot && (
                      <p className="error validationcolor">
                        This is required {"*"}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-6 mt-2">
        <label className="font-serif text-base mb-1 mt-3 text-primary">
          <span className="text-red-600 font-bold text-lg">*</span>
          Color Code
        </label>
        <div className="flex items-center">
        <input
                  type="color"
                  {...register("colorcode", {
                    required: "This is required",
                  })}
                  value={color}
                  onChange={handleColorChange}
                  style={{ cursor: "pointer", width: "4rem", height: "3rem" }}
                />
          {/* <div
            className="w-8 h-8 border border-gray-300 rounded mr-2"
            style={{ backgroundColor: color }}
            onClick={toggleColorPicker}
          ></div>

          {displayColorPicker && (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                onClick={toggleColorPicker}
              />
              <ChromePicker  color={color} onChangeComplete={handleColorChange} />
            </div>
          )} */}
        </div>
        <div>
          {errors.colorCode && (
            <span className="validationcolor">
              {errors.colorCode.message}
              {"*"}
            </span>
          )}
        </div>
      </div>
                <div className="col-span-6 mt-2">
                  <label className="text-base mb-1 text-primary">
                    Profile Photo:
                  </label>
                  <div>
                    <input
                      type="file"
                      id="file"
                      {...register("profilePic")}
                      onChange={handleFileChange}
                      style={{ height: "40px" /* Remove outline: none */ }}
                      className="file-input input bg-white border-black "
                    />
                  </div>
                  {selectedFile && (
                    <img
                      src={selectedFile}
                      alt="Preview"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginTop: "10px",
                      }}
                      onError={(e) => {
                        e.target.src = defaultImage;
                      }}
                    />
                  )}
                </div>


              </div>
              <div className="  col-span-6 	 ml-6  flex mt-4 ">
                <div>
                  <button className="text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring focus:ring-primaryColour-300 font-medium text-sm px-5 py-2.5 me-2">
                    Create
                  </button>
                </div>
                <Link to={"/facultylist"} style={{ color: "white" }}>
                  <button className="text-gray-900   bg-gray-50 border border-gray-300 focus:outline-none hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800">
                    Cancel
                  </button>
                </Link>{" "}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FacultyCreate;
