
import React from 'react';

const RoundedProgressBar = ({ totalSessions, completedSessions, subjectColorCode }) => {
  const progress = (completedSessions / totalSessions) * 100;

  return (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>

        </div>
        <div className="text-right">
          <div className="absolute inset-0  mb-6 flex items-center justify-center font-bold text-sm text-primaryColour">
            {completedSessions}/{totalSessions}
          </div>
        </div>
      </div>
      <div className="overflow-hidden h-2 mb-4 text-xs flex bg-gray-300">
        <div
          style={{ width: `${progress}%`, backgroundColor: subjectColorCode || "green" }}
className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
        ></div>
      </div>
    </div>
  );
};

export default RoundedProgressBar;
