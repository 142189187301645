import { useContext, useEffect, useState } from "react";
import LocationContext from "../../context/LocationContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiService } from "../../Services/Services";
import Breadcrumbs from "../../breadcrumbs";

const locationgetURL = `${process.env.REACT_APP_API_URL}/getlocation?q=&limit=20&page=1&name=1&sortBy=name&sortOrder=DESC`;

const FilterHeader = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showScheduleType, setshowScheduleType] = useState(false);
  const location = useLocation();

  const [locationState, setLocationState] = useState([]);
  const {
    selectedLocation,
    setSelectedLocation,
    loginUserData,
    selectedScheduleType,
    setselectedScheduleType,
    exportLocationName, setexportLocationName
  } = useContext(LocationContext);


  const [searchParams, setSearchParams] = useSearchParams(); 
  const batchLocation = searchParams.get('location');

  const handleSelectChange = (event) => {
    setselectedScheduleType(event.target.value);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  useEffect(() => {
    setShowCalendar(
      location.pathname === "/" ||
        location.pathname === "/reports" ||
        location.pathname === "/dashboard"
    );
    setshowScheduleType(
      location.pathname === "/" || location.pathname === "/dashboard"
    );

    if (location.pathname != "/dashboard" && selectedLocation == "null") {
      setSelectedLocation("");
    }
  }, [location.pathname]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const res = await apiService.get(locationgetURL);

        if (isMounted) {
          setLocationState(res);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if(batchLocation){
        setSelectedLocation(batchLocation);
      }
      if (locationState.length > 0 && !selectedLocation) {
        setSelectedLocation(loginUserData?.location_id );
        setexportLocationName(loginUserData?.location?.name)
        // setSelectedLocation(loginUserData?.location_id || locationState[0].id);

      }
    }, 200);
  }, [locationState, selectedLocation]);

  return (
    <header className="box-border border-b s border-solid font-serif overflow-auto  shadow-lg">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="flex item-center justify-start space-x-3">
        <Breadcrumbs />
          {/* <div className="  mt-4 font-serif dropdown">
            <select
              id="countries"
              name="location_id"
              value={selectedLocation}
              onChange={handleLocationChange}
              className={`input  bg-white border border-gray-300 text-base block p-1.5 sans-serif   text-primaryColour  dark:border-gray-200 dark:placeholder-gray-400  font-bold  hover:border-blue-900 hover:border-opacity-200 `}
              style={{ width: "14rem", height: "2.2rem", borderRadius: "5px" }}
              required
            >
              <option value="">Select Location</option>
              {location.pathname === "/dashboard" && (
                <option value="null">All</option>
              )}
              {locationState.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>

          {showCalendar && (
            <div className="calendar  mt-4 font-serif">
              <Calender />
            </div>
          )}

          {showScheduleType && (
            <div className="  mt-4 font-serif dropdown">
              <div>
                <select
                  className={`input sans-serif  bg-white border border-gray-300 text-base block p-1.5 text-primaryColour  dark:border-gray-200 dark:placeholder-gray-400  font-bold  hover:border-blue-900 hover:border-opacity-200 `}
                  style={{
                    width: "18rem",
                    height: "2.2rem",
                    borderRadius: "5px",
                  }}
                  placeholder="Select Schedule Type"
                  value={selectedScheduleType}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled selected>
                    Select Schedule Type
                  </option>

                  <option value="jee/medical">Jee/Medical</option>
                  <option value="foundation">Foundation</option>
                </select>
              </div>
            </div>
          )} */}
        </div>

        <div className="text-right m-1 mr-5  flex items-center">
          <div>
            <h5 className="  text-md sans-serif ">{loginUserData?.name}</h5>
          </div>
          <img
            className="w-8 h-8 rounded-full ml-4  mb-1"
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            alt="User Avatar"
          />
        </div>
      </div>
    </header>
  );
};

export default FilterHeader;
