import PivoteTablePage from "./PivoteTables/PivoteTablePage";
import BatchCreate from "./pages/Batch/BatchCreate/BatchCreate";
import BatchEdit from "./pages/Batch/BatchEdit/BatchEdit";
import BatchList from "./pages/Batch/BatchList/BatchList";
import CreateUser from "./pages/CreateUser";
import FacultyCreate from "./pages/Faculty/FacultyCreate/FacultyCreate";
import FacultyFormEdit from "./pages/Faculty/FacultyEdit/FacultyFormEdit";
import FacultyView from "./pages/Faculty/FacultyView/FacultyView";
import FacultyList from "./pages/Faculty/Facultylist/FacultyList";
import LeaveEdit from "./pages/Leave/LeaveEdit";
import LeaveCreate from "./pages/Leave/LeaveCreate";
import LeaveList from "./pages/Leave/LeaveList";
import Logout from "./pages/Logout";
import Management from "./pages/Management/Management";
import SchedulingNewPage from "./pages/Scheduling-new/SchedulingNew";
import SchedulingPage from "./pages/Scheduling/Scheduling";
import UserEdit from "./pages/UserEdit";
import Users from "./pages/Users";
import LogHistory from "./pages/LogHistory/LogHistory";
import FacultyViewNew from "./pages/Faculty/FacultyView/FacultyViewNew";
import BatchView from "./pages/Batch/BatchView/BatchView";
import FacultySchedulingPage from "./pages/Scheduling-new/FacultyScheduling";

const routes = [
  {
    path: "/schedule-old",
    element: <SchedulingPage />,
    breadcrumb: [{ name: "Schedule", path: "/" }],
  },
  {
    path: "/",
    element: <SchedulingNewPage />,
    breadcrumb: [{ name: "Schedule", path: "/" }],
  },
  {
    path: "/faculty-view",
    element: <FacultySchedulingPage />,
    breadcrumb: [{ name: "Schedule", path: "/" }],
  },
  {
    path: "/dashboard",
    element: <PivoteTablePage />,
    // breadcrumb: [{ name: "Home", path: "/" }],
  },
  {
    path: "/batchlist",
    element: <BatchList />,
    breadcrumb: [{ name: "Schedule", path: "/" }, { name: "Batch List" }],
  },
  {
    path: "/batchcreate",
    element: <BatchCreate />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Batch List", path: "/" },
      { name: "Batch Create" },
    ],
  },
  {
    path: "/batchformedit/:id",
    element: <BatchEdit />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Batch List", path: "/" },
      { name: "Batch Edit" },
    ],
  },
  {
    path: "/batch-details/:id",
    element: <BatchView />,
    breadcrumb: [{ name: "Schedule", path: "/" },{ name: "Batch List", path: "/batchlist" },{ name: "Batch Detail" }],
  },
  {
    path: "/facultylist",
    element: <FacultyList />,
    breadcrumb: [{ name: "Schedule", path: "/" }, { name: "Faculty List" }],
  },
  {
    path: "/facultycreate",
    element: <FacultyCreate />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Faculty List", path: "/facultylist" },
      { name: "Faculty Create" },
    ],
  },
  {
    path: "/facultyformedit/:id",
    element: <FacultyFormEdit />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Faculty List", path: "/facultylist" },
      { name: "Edit Faculty Form" },
    ],
  },
  {
    path: "/facultyview/:id",
    element: <FacultyViewNew />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Faculty List", path: "/facultylist" },
      { name: "Faculty View" },
    ],
  },
  {
    path: "/facultyview-old/:id",
    element: <FacultyView />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Faculty List", path: "/facultylist" },
      { name: "Faculty View" },
    ],
  },
  {
    path: "/LeaveList",
    element: <LeaveList />,
    breadcrumb: [{ name: "Schedule", path: "/" }, { name: "Leaves" }],
  },
  {
    path: "/leavecreate",
    element: <LeaveCreate />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Leaves", path: "/LeaveList" },
      { name: "New Leave" },
    ],
  },
  {
    path: "/leaveEdit/:id",
    element: <LeaveEdit />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "Leaves", path: "/LeaveList" },
      { name: "Edit Leave" },
    ],
  },
  {
    path: "/user",
    element: <Users />,
    breadcrumb: [{ name: "Schedule", path: "/" }, { name: "User List" }],
  },
  {
    path: "/userEdit/:id",
    element: <UserEdit />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "User List", path: "/user" },
      { name: "Edit User" },
    ],
  },
  {
    path: "/createUser",
    element: <CreateUser />,
    breadcrumb: [
      { name: "Schedule", path: "/" },
      { name: "User List", path: "/user" },
      { name: "Create User" },
    ],
  },

  { path: "/logout", element: <Logout /> },
  {
    path: "/management",
    element: <Management />,
    breadcrumb: [{ name: "Management", path: "/management" }],
  },
  {
    path: "/loghistory",
    element: <LogHistory />,
    breadcrumb: [{ name: "Schedule", path: "/" }, { name: "Log history" }],
  },
];

export default routes;
