import React from "react";
import MorningIMG from "../../utils/Images/morning.png";
import AfterNoonIMG from "../../utils/Images/fullsun.png";
import { IoIosFlag } from "react-icons/io";
import SlotComponent from "./SlotComponent";

const TableBody = ({
  data,
  compactSizeTable,

  handleDrop,
  toggleMenu,
  handleTimeClick,
  getSchedulingData,
  openColumns,
  Humberger,
  setScheduleRefresh,
  setSelectedCheckboxes,
  selectedCheckboxes,
  isSwapSchedule,
  handleOpenPopup,
}) => {
  let morningSlots = [];
  let afternoonSlots = [];

  data.map((group) => {
    if(group.mornning_Slots ==true){
      morningSlots.push(group)
    }else{
      afternoonSlots.push(group)
    }
  });
  return (
    <>
      {morningSlots.map((group) => {
        return (
          <SlotComponent
            key={group.batchid}
            slotType={group.mornning_Slots == true ? "morning" : "afternoon"}
            group={group}
            compactSizeTable={compactSizeTable}
            IoIosFlag={IoIosFlag}
            Humberger={Humberger}
            AfterNoonIMG={AfterNoonIMG}
            MorningIMG={MorningIMG}
            toggleMenu={toggleMenu}
            openColumns={openColumns}
            getSchedulingData={getSchedulingData}
            setScheduleRefresh={setScheduleRefresh}
            handleDrop={handleDrop}
            handleTimeClick={handleTimeClick}
            setSelectedCheckboxes={setSelectedCheckboxes}
            selectedCheckboxes={selectedCheckboxes}
            isSwapSchedule={isSwapSchedule}
            handleOpenPopup={handleOpenPopup}
          />
        );
      })}
       {afternoonSlots.map((group) => {
        return (
          <SlotComponent
            key={group.batchid}
            slotType={group.mornning_Slots == true ? "morning" : "afternoon"}
            group={group}
            compactSizeTable={compactSizeTable}
            IoIosFlag={IoIosFlag}
            Humberger={Humberger}
            AfterNoonIMG={AfterNoonIMG}
            MorningIMG={MorningIMG}
            toggleMenu={toggleMenu}
            openColumns={openColumns}
            getSchedulingData={getSchedulingData}
            setScheduleRefresh={setScheduleRefresh}
            handleDrop={handleDrop}
            handleTimeClick={handleTimeClick}
            setSelectedCheckboxes={setSelectedCheckboxes}
            selectedCheckboxes={selectedCheckboxes}
            isSwapSchedule={isSwapSchedule}
            handleOpenPopup={handleOpenPopup}
          />
        );
      })}
    </>
  );
};

export default TableBody;
