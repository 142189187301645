import React from "react";

const Footer = () => {
  return (
    <div
      className="bg-gray-100 text-xs"
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        color: "black",
        textAlign: "center",
        padding: "10px",
        cursor: "pointer",
      }}
    >
      <a
        href="https://webassic.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
        className=" font-serif "
      >
        &copy; Developed By Webassic IT Solutions PVT. LTD.
      </a>
    </div>
  );
};

export default Footer;
