import React, { useContext } from "react";
import TableHeader from "../../components/TableHeader/TableHeader";
import CardHeader from "../../components/CardHeader/CardHeader";
import Table from "../../components/Table/Table";
import Loader from "../../components/Loader";

import LocationContext from "../../context/LocationContext";
import { Modal } from "../../components/Modal/Modal";
import { apiService } from "../../Services/Services";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { EditSchedule } from "../../components/Table/EditSchedule";

const SchedulingPage = () => {
  const {
    loading,
    facultyData, setFacultyData,
    time, setTime,
    setSelectedFaculty,
    setSelectedSubjectid,
    selectedSubjectid,
    setModalDate,
    modalDate,
    selectedLocation,

    modalOpen,
    setModalOpen,

    selectedTime,
    setSelectedTime,
    selectedFaculty,
    modalOpenEdit,
    setModalOpenEdit,
    refreshTable,

    selectedScheduleType,

    setScheduleStatusCheck,
    loaderMessage,
    setFacultyHrsUpdate,
    setSelectedSubject,
    setScheduleRefresh,
  } = useContext(LocationContext);

  let createScheudleURL = `${process.env.REACT_APP_API_URL}/createSchedule`;
  if (selectedScheduleType === "foundation") {
    createScheudleURL += "/foundation";
  }

  const postData = {
    location_id: selectedLocation,
    batch_id: localStorage.getItem("item"),
    faculty_id: selectedFaculty.value,
    subject_id: selectedSubjectid,
    slot_time: selectedTime,
    date: modalDate,
  };

  const handleCloseModal = () => {
    localStorage.removeItem("item");

    setSelectedFaculty("");
    setModalDate("");

    setModalOpen(false);
    setSelectedFaculty("");
    setModalDate("");
    setSelectedSubjectid("");
    setSelectedTime("");
    setFacultyData([])
    setTime([])
    setSelectedSubject([])
    // setValue('subject',{})
  };
  const handleEditCloseModal = () => {
    setModalOpenEdit(false);
  };

  const createScheduling = async () => {
    const url = createScheudleURL;
    const response = await apiService
      .post(url, postData)
      .then((res) => {
        setFacultyHrsUpdate(true);
        setScheduleStatusCheck(true);
        setScheduleRefresh(true);

        toast.success("Schedule Successfully Created.. ", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginBottom: "4vw",
            fontSize: "1.2em",
            width: "400px",
            padding: "10px",
          },
        });
        setSelectedFaculty("");
        setModalDate("");
        setSelectedSubjectid("");
        setSelectedTime("");
        setModalOpen(false);
      })
      .catch((err) => {
        setFacultyHrsUpdate(false);

        setModalOpen(true);
        setScheduleRefresh(false);

        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginBottom: "4vw",
            fontSize: "1.2em",
            width: "400px",
            padding: "10px",
          },
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await createScheduling();
  };

  return (
    <>
      <div className=" overflow-auto  " style={{ overflowY: "auto" }}>
        <ToastContainer />
        <div className="">
          <Modal
            isOpen={modalOpen}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
          />

          <EditSchedule isOpen={modalOpenEdit} onClose={handleEditCloseModal} />
          <div>
            {selectedScheduleType === "jee/medical" ? (
              <TableHeader breadcrumbs={[{ name: "JEE/Medical Schedule" }]} />
            ) : null}
            {selectedScheduleType === "jee" ? (
              <TableHeader breadcrumbs={[{ name: "JEE Schedule" }]} />
            ) : null}
            {selectedScheduleType === "medical" ? (
              <TableHeader breadcrumbs={[{ name: "Medical Schedule" }]} />
            ) : null}
            {selectedScheduleType === "foundation" ? (
              <TableHeader breadcrumbs={[{ name: "Foundation Schedule " }]} />
            ) : null}
          </div>
          <div className="   ">
            <CardHeader />
          </div>
        </div>
        <div className="mt-1 overflow-auto ">
          {loading && <Loader message={loaderMessage} />}
          <Table key={refreshTable} className="overflow-y-auto" />
        </div>
      </div>
    </>
  );
};

export default SchedulingPage;
