import React, { useContext, useEffect, useState, useRef } from "react";
import LocationContext from "../../context/LocationContext";
import { DateRangePicker } from "rsuite";
import { useForm } from "react-hook-form";
import { apiService } from "../../Services/Services";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import { Link, useNavigate } from "react-router-dom";
import TableHeader from "../../components/TableHeader/TableHeader";
import moment from "moment";

const Single = ({ isOpen, setIsModalOpen, leaveApiData }) => {
  const { selectedLocation } = useContext(LocationContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [slotValidation, setSlotValidation] = useState("");

  const navigate = useNavigate();

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const fetchBatchSlots = async () => {
      try {
        const response = await apiService.get(
          `${process.env.REACT_APP_API_URL}/getBatchslot`
        );
        const data = response;

        const morningId =
          data.find((slot) => slot.name === "Morning")?.id || "";
        const afternoonId =
          data.find((slot) => slot.name === "Afternoon")?.id || "";

        setOptions([
          { value: morningId, label: "Morning" },
          { value: afternoonId, label: "Afternoon" },
        ]);
      } catch (error) {
        console.error("Error fetching batch slots:", error);
      }
    };

    fetchBatchSlots();
  }, []);

  const handleOptionChange = (value) => {
    setSelectedOptions(value);
  };

  const styles = {
    width: 450,
    display: "block",
    height: "35px",

    outline: "none",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const [facultyData, setFacultyData] = useState([]);
  const facultyURL = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&sortBy=updated_at&sortOrder=DESC&location_id=`;
  const getFacutyData = async () => {
    const Response = await apiService.get(facultyURL);
    if (Response) {
      console.log("Response", Response);

      try {
        const mappingResponse = Response.map((response) => {
          const subjects = response.subject
            .map((subject) => subject.subject_name)
            .join(", ");

          // Extracting locations and joining them with commas
          const locations = response.location
            .map((location) => location.name)
            .join(", ");

          return {
            label: `${response.faculty_code} - ${response.first_name} ${response.last_name} (${subjects}) | ${locations}`,
            value: response.faculty_code,
            id: response.id,
          };
        });
        setFacultyData(mappingResponse);
      } catch (error) {
        return error;
      }
    }
  };

  const onSubmit = async (data) => {
    if (selectedOptions.length <= 0) {
      setSlotValidation(
        "Please select at least one option: 'Full Day', 'Morning', or 'Afternoon'."
      );
      return;
    }
    const facultyIds = data.faculyData?.map((item) => item.id) || [];

    const postData = {
      faculty_id: facultyIds,

      dates: data.dateRange,
      batch_slot_name: [selectedOptions],
    };

    try {
      const response = await apiService.post(
        `${process.env.REACT_APP_API_URL}/createLeave`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Record Successfully Created.. ", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
      });
      leaveApiData();
      setIsModalOpen(false);
      setSlotValidation("");
    } catch (error) {
      toast.error(error.response.data.error, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
      });
    }
  };

  useEffect(() => {
    getFacutyData();
  }, [selectedLocation]);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset the time part for accurate comparison

  const disablePastDates = (date) => {
    return date < today;
  };
  const getAllDatesBetween = (startDate, endDate) => {
    let dates = [];
    let currentDate = moment(startDate).startOf("day");
    let end = moment(endDate).endOf("day");

    while (currentDate <= end) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "day");
    }

    return dates;
  };

  const handleDateRangeChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length >= 2) {
      const dates = getAllDatesBetween(selectedOptions[0], selectedOptions[1]);

      setValue("dateRange", dates);
    } else {
      console.error("Invalid selectedDates:", selectedOptions);
      setValue("dateRange", "");
    }
  };
  const dateRangePickerContainerRef = useRef(null);

  useEffect(() => {
    if (dateRangePickerContainerRef.current) {
      const inputElement =
        dateRangePickerContainerRef.current.querySelector("input");
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, []);
  return (
    <>
      <div className="  ">
        <>
          <ToastContainer />
        </>
        <div className="  ">
          <h4 className=" text-center">Create Leave</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" mt-4  ">
              <div className="   ml-6 pt-5  ">
                <div className="  ">
                  <label className="text-base mb-1 mt-3 text-primary">
                    <span className=" text-red-600 font-bold text-lg font-serif ">
                      *
                    </span>
                    Date:
                  </label>
                  <div
                    className="relative flex-grow  "
                    ref={dateRangePickerContainerRef}
                  >
                    <DateRangePicker
                      showOneCalendar
                      autoFocus
                      format="dd/MM/yyyy" // Specify the date format here
                      placeholder="Calender"
                      {...register("dateRange", {
                        required: "This is required",
                        validate: (value) =>
                          (value && value[0] !== null && value[1] !== null) ||
                          "Please select a date range",
                      })}
                      onChange={handleDateRangeChange}
                      isoWeek
                      style={styles}
                      disabledDate={disablePastDates}
                    />
                  </div>
                  {errors.dateRange && (
                    <p className="error validationcolor">
                      {errors.dateRange.message}
                      {"*"}
                    </p>
                  )}
                </div>

                <div className="  col-span-6  mt-3 " style={{ width: "445px" }}>
                  <label className="text-base mb-1 mt-3 text-primary font-serif">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Faculty:
                  </label>
                  <Select
                    placeholder="select Faculty"
                    isMulti
                    options={facultyData}
                    closeMenuOnSelect={false} // Prevent menu from closing on select
                    hideSelectedOptions={false} // Show selected options with checkboxes
                    style={{
                      height: "35px",
                      borderRadius: "6px",
                    }}
                    {...register("faculyData", { required: true })}
                    onChange={(selectedOptions) => {
                      const formattedOptions = selectedOptions.map(
                        (option) => ({
                          ...option,
                          label: option.label.split(" | ")[0], // Remove location part
                        })
                      );

                      setValue("faculyData", formattedOptions);
                    }}
                    value={watch("faculyData")}
                    className="custom-select"
                    classNamePrefix="custom-select"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "white",
                        borderRadius: 0,

                        boxShadow: "none",
                        borderRadius: "6px",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                  <div>
                    {errors.faculyData && (
                      <p className="error validationcolor">
                        This is required {"*"}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-6 mt-3" style={{ width: "30vw" }}>
                  <div className="flex items-center">
                    <label className="text-base mb-1  text-primary font-serif">
                      <span className=" text-red-600 font-bold text-lg ">
                        *
                      </span>
                      Select Slots:
                    </label>
                  </div>

                  <div className="flex  mt-2">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        // value="fullDay"

                        checked={selectedOptions.includes("FULLDAY")}
                        onChange={() => handleOptionChange("FULLDAY")}
                        className="  border border-gray-400 rounded-full  text-primary"
                      />
                      <span className="ml-2">Full Day</span>
                    </div>
                    {options.map((option) => (
                      <div key={option.value} className="flex items-center">
                        <input
                          type="checkbox"
                          value={option.value}
                          checked={selectedOptions.includes(option.label)}
                          onChange={() => handleOptionChange(option.label)}
                          className=" text-primary border border-gray-400 rounded-full text-primary ml-4 "
                        />
                        <span className="ml-2">{option.label}</span>
                      </div>
                    ))}
                  </div>
                  <div className="mt-2">
                    <p className=" text-red-600 ">{slotValidation}</p>
                  </div>
                </div>
              </div>

              <div className="  col-span-6 	ml-6   flex mt-8 ">
                <div>
                  <button
                    className=" text-white bg-primaryColour hover:bg-primaryColour-1000  font-medium  text-sm px-5 py-2.5 me-2 font-serif "
                    onClick={handleSubmit}
                  >
                    Create
                  </button>
                </div>
                {/* <Link to={"/LeaveList"} style={{ color: "white" }}> */}
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-900  font-serif bg-gray-50 border border-gray-300 focus:outline-none hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
                >
                  Cancel
                </button>
                {/* </Link>{" "} */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Single;
