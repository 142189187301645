import React from "react";
import SubjectList from "./SubjectList";
import TimeList from "./TimeList";
import HolidayList from "./HolidayList";
import { ToastContainer } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const Management = () => {
  return (
    <>
      <>
        <ToastContainer />
      </>
      <section className="font-serif pageSize bg-white p-4 rounded-lg shadow-lg">
        <Tabs>
          <TabList className="border-b border-gray-200 flex  mb-4">
            <Tab>
              <h6 className="font-normal">Subject List</h6>
            </Tab>
            <Tab>
              <h6 className="font-normal">Holiday List</h6>
            </Tab>
            <Tab>
              <h6 className="font-normal">Time List</h6>
            </Tab>
          </TabList>

          <TabPanel>
            <SubjectList />
          </TabPanel>
          <TabPanel>
            <HolidayList />
          </TabPanel>
          <TabPanel>
            <TimeList />
          </TabPanel>
        </Tabs>
      </section>
    </>
  );
};

export default Management;
