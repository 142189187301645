import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../utils/Images/allen_logo.jpeg";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import TableHeader from "../components/TableHeader/TableHeader";
import LocationContext from "../context/LocationContext";
import Loader from "../components/Loader";
import { apiService, getApiService } from "../Services/Services";
import Select from "react-select";

const CreateUser = () => {
  const locationgetURL = `${process.env.REACT_APP_API_URL}/getlocation?q=&limit=20&page=1&name=1&sortBy=name&sortOrder=DESC`;

  const { loaderMessage, setLoaderMessage, setLoading, loading } =
    useContext(LocationContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const [locationState, setLocationState] = useState([]);
  const { selectedLocation, setSelectedLocation } = useContext(LocationContext);

  const getLocation = async () => {
    const Response = await apiService.get(locationgetURL);
    if (Response) {
      try {
        const mappingResponse = Response.map((response) => {
          return {
            label: response.name,
            value: response.name,
            id: response.id,
          };
        });
        setLocationState(mappingResponse);
      } catch (error) {
        return error;
      }
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    let forgotMessage = `Process is underway. Kindly wait for a while. Avoid refreshing the page.`;

    setLoaderMessage(<pre>{forgotMessage}</pre>);

    const postData = {
      email: data.email,
      contactNumber: data.phoneNumber,
      location_id: data.location.id,
      name: data.username,
    };

    try {
      const response = await apiService.post(
        `${process.env.REACT_APP_API_URL}/users`,
        postData
      );
      setLoading(false);

      let createUserMessage = `Process is underway. Kindly wait for a while. Avoid refreshing the page.`;

      setLoaderMessage(<pre>{createUserMessage}</pre>);
      toast.success("Record Successfully Created.. ", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.2em",
          width: "400px",
          padding: "10px",
        },
      });

      setTimeout(() => {
        navigate("/user");
      }, 3000);
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 422) {
        toast.error(`Error: ${error.response.data.errors.email}`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginBottom: "4vw",
            fontSize: "1.5em",
            width: "400px",
            padding: "10px",
          },
        });
      } else {
        setLoading(false);

        toast.error("Error: Something went wrong", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginBottom: "4vw",
            fontSize: "1.5em",
            width: "400px",
            padding: "10px",
          },
        });
      }
    }
  };

  const username = watch("username");
  const email = watch("email");
  const phoneNumber = watch("phoneNumber");
  const location = watch("location");
  useEffect(() => {
    // Find the option object with ID matching the selectedLocation
    const defaultOption = locationState.find(
      (option) => option.id === selectedLocation
    );
    if (defaultOption) {
      setValue("location", defaultOption);
    }
  }, [locationState, selectedLocation]);
  return (
    <div>
      {loading && <Loader message={loaderMessage} />}

      <>
        <ToastContainer />
      </>
      <div className="  bg-secondaryColour    cuPageHeight  ">
        <div className="flex  items-center justify-center  py-24  px-4   ">
          <div className="  border p-6 bg-white  rounded    ">
            <div className=" mt-2 ">
              <img
                className="mx-auto  rounded-full  h-16  w-16"
                src={logo}
                alt="Your Company"
              />
              <h2 className=" text-center text-2xl  tracking-tight text-gray-900 font-bold text-primary">
                Create User
              </h2>
            </div>
            <div
              className=" ml-6 mr-4 h-auto pb-6 font-serif mb-4 "
              style={{ maxHeight: "70vh" }}
            >
              <form
                className="mt-8 space-y-6"
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input type="hidden" name="remember" value="true" />
                <div className=" rounded-md   ">
                  <div className=" m-2 ">
                    <input
                      style={{ width: "600px" }}
                      type="text"
                      {...register("username", {
                        required: "field is required",
                        pattern: {
                          value: /^[A-Za-z]+$/,
                          message: "Only characters are allowed",
                        },
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length > 0) {
                          e.target.value =
                            value.charAt(0).toUpperCase() + value.slice(1);
                        }
                      }}
                      autoComplete="off"
                      className="relative text-base block w-full appearance-none   border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10  focus:outline-none "
                      placeholder="Name"
                    />
                    <div>
                      {errors.username && (
                        <span className="validationcolor">
                          {errors.username.message}
                          {" *"}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className=" m-2 ">
                    <input
                      // {...register("email", {
                      //   required: "field is required",
                      // })}
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      id="email"
                      name="email"
                      style={{ width: "600px" }}
                      type="email"
                      autoComplete="off"
                      className="relative text-base block w-full appearance-none   border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10  focus:outline-none "
                      placeholder="Email address"
                    />

                    <div>
                      {errors.email && (
                        <span className="validationcolor">
                          {errors.email.message}
                          {" *"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" m-2">
                    <input
                      style={{ width: "600px" }}
                      {...register("phoneNumber", {
                        pattern: {
                          value: /^(\+\d{1,3}[- ]?)?[1-9]\d{9}$/,
                          message: "This is not a valid phone number",
                        },
                        maxLength: {
                          value: 10,
                          message: "Phone number should not exceed 10 digits",
                        },
                      })}
                      autoComplete="off"
                      type="number"
                      className="relative text-base block w-full appearance-none rounded-none  border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10  focus:outline-none  "
                      placeholder="Phone Number"
                    />
                    <div>
                      {errors.phoneNumber && (
                        <span className="validationcolor">
                          {errors.phoneNumber.message}
                          {" *"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between m-2   ">
                    <Select
                      options={locationState}
                      value={watch("location")}
                      {...register("location", { required: true })}
                      onChange={(selectedOptions) => {
                        setValue("location", selectedOptions);
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "white",
                          borderRadius: 0,
                          outline: "none",
                          boxShadow: "none",
                          height: "30px",
                          width: "600px",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />
                  </div>
                  <div className=" ml-2">
                    {errors.location && (
                      <span className="validationcolor">
                        {errors.location.message}
                        {" *"}
                      </span>
                    )}
                  </div>
                </div>

                <div className=" m-2   ">
                  <div className=" mb-8 ">
                    <button
                      type="submit"
                      className=" group  text-base relative flex w-full justify-center  border border-transparent  bg-blue-500 py-1 px-4  font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2  "
                      // disabled={!username || !email || !location}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
