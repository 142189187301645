import axios from 'axios';
import React, { useEffect } from 'react'
import { apiService } from '../Services/Services';

const Logout = () => {
  const handleLogout = async (event) => {
    try {
      event.stopPropagation(); 
      await apiService.post(`${process.env.REACT_APP_API_URL}/logout`);
      localStorage.removeItem('access_token','id');  
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  
 
}

export default Logout