import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { apiService } from "../../Services/Services";
import Accordion from "./Accordion";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationContext from "../../context/LocationContext";

import "./management.css";
import { useContext } from "react";
import Popup from "../../components/Popup";
import TimePopup from "./TimePopup";
import moment from "moment";

const TimeList = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 400); // Adjust the delay as needed (300ms in this example)

    // Cleanup function to clear the timeout if searchTerm changes within the delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const [itemToDelete, setItemToDelete] = useState(null);

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = (id, batchCode) => {
    setItemToDelete(id);
    setErrorMessage("");
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  const handleDeleteTime = () => {
    if (itemToDelete !== null) {
      setIsOpen(false);
      apiService
        .delete(
          `${process.env.REACT_APP_API_URL}/DeleteSlotTime/${itemToDelete}`
        )
        .then(() => {
          toast.success("Time Successfully deleted.. ", toastConfig);

          timeApiData();
          setErrorMessage("");
        });
      setErrorMessage("");
    }
  };
  const timeApiData = async () => {
    if (selectedLocation) {
      const sortOrder = sortConfig.direction === "asc" ? "ASC" : "DESC";
      const sortBy = sortConfig.key ? sortConfig.key : "updated_at";
      const url = `${process.env.REACT_APP_API_URL}/getSlotTime?q=${debouncedTerm}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      try {
        const response = await apiService.get(url);
        if (response) {
          const responseData = await response;
          const { data, length } = responseData;
          setTimes(response);

          setTotalRecords(length);
        } else {
          console.error("Error fetching batch data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    }
  };

  const { selectedLocation } = useContext(LocationContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalRecords, setTotalRecords] = useState("");
  const noOfPages = Math.ceil(totalRecords / limit);
  const [Times, setTimes] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [timeGetById, setTimeGetById] = useState([]);
  const [popUpButton, setPopUpButton] = useState(false);
  const [popUpHeading, setPopUpHeading] = useState("");
  const handleLimitChange = (e) => {
    setLimit(e);
    setCurrentPage(0);
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const togglePopup = () => {
    setIsPopupOpen(true);
    setPopUpButton(false);
  };

  const cancelPopup = () => {
    setStartTime(null);
    setEndTime(null);
    setValue("Times", "");
    setErrorMessage("");
    setIsPopupOpen(false);
    setTimeGetById("");

    setPopUpButton(false);
    setPopUpHeading("Create Time");
  };

  const handleEditTime = (id) => {
    setIsPopupOpen(true);
    setTimeGetById(id);
  };

  const timeGetByIdUrl = `${process.env.REACT_APP_API_URL}/showByIDSlotTime/${timeGetById}`;

  const fetchTimeGetByData = async () => {
    try {
      const Response = await apiService.get(timeGetByIdUrl);
      if (Response) {
        const { slot_time } = Response;
        const [startTimeStr, endTimeStr] = slot_time.split("-");
        const startTime = moment(startTimeStr, "HH:mm");
        const endTime = moment(endTimeStr, "HH:mm");

        setStartTime(startTime);
        setEndTime(endTime);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getfetchData = useRef(true);

  useEffect(() => {
    timeApiData();

    getfetchData.current = false;
  }, [debouncedTerm, currentPage, sortConfig, selectedLocation, limit]);

  useEffect(() => {
    if (timeGetById) {
      fetchTimeGetByData();
    }
  }, [timeGetById]);

  useEffect(() => {
    if (timeGetById.length === 0) {
      if (Array.isArray(timeGetById) && timeGetById.length === 0) {
        setPopUpHeading("Create Time");
        setPopUpButton(false);
      }
    } else {
      setPopUpHeading("Update Time");
      setPopUpButton(true);
    }
  }, [timeGetById, popUpHeading]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };

  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data) => {
    if (!startTime || !endTime) {
      setErrorMessage("Please select both Start Time and End Time");
      return;
    }

    const formattedStartTime = moment(startTime).format("HH:mm");
    const formattedEndTime = moment(endTime).format("HH:mm");
    if (formattedStartTime >= formattedEndTime) {
      setErrorMessage("Start Time must be earlier than End Time");
      return;
    }
    const timeRange = `${formattedStartTime}-${formattedEndTime}`;

    const postData = {
      slot_time: timeRange,
    };

    if (timeGetById.length === 0) {
      try {
        const response = await apiService
          .post(`${process.env.REACT_APP_API_URL}/createSlotTime`, postData)
          .then(() => {
            setStartTime(null);
            setEndTime(null);
            setErrorMessage("");

            setTimeGetById("");
            setIsPopupOpen(false);
            toast.success("Record Successfully Created.. ", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
            timeApiData();
          })
          .catch((err) => {
            setErrorMessage("");

            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
          });
      } catch (error) {
        console.error("Error:", error.message);
        setErrorMessage("");
      }
    } else {
      try {
        const response = await apiService
          .patch(
            `${process.env.REACT_APP_API_URL}/UpdateSlotTime/${timeGetById}`,
            postData
          )

          .then(() => {
            setTimeGetById("");
            setStartTime(null);
            setEndTime(null);
            setErrorMessage("");

            setIsPopupOpen(false);
            toast.success("Record Successfully Updated.. ", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
            timeApiData();
          })
          .catch((err) => {
            setErrorMessage("");

            toast.error(err, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
          });
      } catch (error) {
        console.error("Error:", error.message);
        setErrorMessage("");
      }
    }
  };
  const accordionItems = [
    {
      title: "Times",
      content: (
        <>
          <div className="grid grid-cols-12 m-1">
            <div className="md:col-span-11 sm:col-span-6 w-full">
              <input
                value={searchTerm}
                onChange={handleChange}
                defaultValue={Times}
                style={{ width: "35vw" }}
                className="bg-white border border-gray-500 m-1 py-3 px-4 focus:outline-none mt-3 ml-5"
                placeholder="Search....."
              />
            </div>

            <div className="md:col-span-1 pl-5 relative">
              <button
                className="bg-white mt-3 hover:bg-gray-100 text-gray-800 font-serif text-2xl font-bold py-1 px-3 border border-gray-400 shadow"
                onClick={togglePopup} // Toggle the popup when the button is clicked
              >
                +
              </button>
            </div>
          </div>

          <section className="ml-6 h-full mb-8 font-serif  bg-white mr-4 ">
            <div className="acpageHeight">
              <div className="min-w-full inline-block align-middle">
                <div className="">
                  <table className="min-w-full divide-y divide-gray-400 ">
                    <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      <tr className=" bg-primaryColour text-white">
                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                          onClick={() => handleSort("subject_name")}
                        >
                          Slot Time{" "}
                          {sortConfig.key === "subject_name" ? (
                            sortConfig.direction === "asc" ? (
                              <span>▲</span>
                            ) : (
                              <span>▼</span>
                            )
                          ) : (
                            <span>▲</span>
                          )}
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-400 dark:divide-gray-700">
                      {Times.length === 0 ? (
                        <tr>
                          <td
                            colSpan="9"
                            className="text-center py-4 text-gray-800 font-serif"
                          >
                            No Time available
                          </td>
                        </tr>
                      ) : (
                        Times.map((item, i) => (
                          <tr className="hover:bg-gray-100" key={i}>
                            <td className="px-8 py-3 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
                              {item.slot_time ? item.slot_time : ""}
                            </td>

                            <td
                              className="px-8 py-3 flex whitespace-nowrap text-sm text-gray-800 font-serif text-center"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className="text-center"
                              >
                                <button
                                  className="button muted-button"
                                  onClick={() => handleEditTime(item.id)}
                                >
                                  <AiFillEdit className="text-xl mr-3 text-green-700" />
                                </button>
                              </div>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <button onClick={() => openPopup(item.id)}>
                                  <RiDeleteBin6Line className="text-red-700 text-xl mr-3" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="bg-secondaryColour mt-3" style={{ float: "right" }}>
              <div className="flex">
                <p className=" ">Items per page:</p>

                <div className="relative inline-block">
                  <button
                    className="me-3 mb-3 md:mb-0 text-balck  border       font-medium text-sm px-3 py-1 text-center inline-flex items-center  "
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    type="button"
                    style={{ border: " 2px solid balck" }}
                  >
                    {limit}
                    <svg
                      className={`w-2.5 h-2.5 ms-3 transition-transform transform ${
                        isDropdownOpen ? "rotate-180" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>

                  <div
                    className={`z-10 ${
                      isDropdownOpen ? "" : "hidden"
                    } absolute bottom-0 mb-6 bg-white divide-y divide-gray-100 rounded-lg   w-16 dark:bg-gray-700`}
                  >
                    <ul className="py-2text-base text-gray-700 dark:text-gray-200">
                      <li>
                        <button
                          onClick={() => handleLimitChange(6)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          6
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(10)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          10
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(20)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          20
                        </button>
                        bg-secondaryColour
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(50)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          50
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  pageCount={noOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                />
              </div>
            </div>
          </section>
        </>
      ),
    },
  ];

  return (
    <>
      <Popup isOpen={isOpen} onClose={closePopup}>
        <h6>Do you want to delete the time?</h6>
        <div className="button-container">
          <button
            className="text-gray-900 bg-gray-50 border border-gray-300 hover:bg-gray-100 font-medium text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
            onClick={() => setIsOpen(false)}
          >
            No
          </button>
          <button
            className="text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() => handleDeleteTime()}
          >
            Yes
          </button>
        </div>
      </Popup>
      <div className=" ml-6 mr-4 pb-6 font-serif mb-4">
        <div className="mt-6 ml-8 mr-8 bg-secondaryColour">
          <Accordion
            items={accordionItems}
            defaultOpenIndex={0}
            isPopupOpen={setIsPopupOpen}
          />
        </div>
        <div>
          {isPopupOpen && (
            <TimePopup
              errorMessage={errorMessage}
              heading={popUpHeading}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              cancelPopup={cancelPopup}
              popUpButton={popUpButton}
              handleStartTimeChange={handleStartTimeChange}
              handleEndTimeChange={handleEndTimeChange}
              startTime={startTime}
              endTime={endTime}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TimeList;
