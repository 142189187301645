import { useContext, useEffect, useRef, useState } from "react";
import LocationContext from "../../../context/LocationContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiService } from "../../../Services/Services";

const Location = () => {

    const {
        selectedLocation,
        setSelectedLocation,
        exportLocationName, setexportLocationName,
        locationState, setLocationState
    } = useContext(LocationContext);
    const location = useLocation();
    const hasRun = useRef(false);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!hasRun.current) {
                const locationgetURL = `${process.env.REACT_APP_API_URL}/getlocation?q=&limit=&page=&name=&sortBy=name&sortOrder=DESC`;
                const res = await apiService.get(locationgetURL);
                hasRun.current = true;
                setLocationState(res);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, []);
    const handleLocationChange = (e) => {
        const selectedLabel = e.target.selectedOptions[0]?.label;
        setSelectedLocation(e.target.value);
        setexportLocationName(selectedLabel)
        setSearchParams({
            ...Object.fromEntries(searchParams), // Spread the existing search params
            location: e.target.value,
        });
    };
    useEffect(() => {
        if (location.pathname === "/dashboard") {
          setSelectedLocation("null");
        }
      }, [location.pathname]);
    return (
        <div className="">
            <select
                id="countries"
                name="location_id"
                value={selectedLocation}
                className="form-select px-4 bg-white border border-black-300 rounded shadow-lg leading-4	 "
                onChange={handleLocationChange}
                style={{ width: "14rem", height: "2.25rem" }}
                required
            >
                <option value="">Select Location</option>
                {location.pathname === "/dashboard" && (
                    <option  value="null">All</option>
                )}
                 {location.pathname === "/LeaveList" && (
                    <option  value="null">All</option>
                )}
                {locationState.map((location) => (
                    <option key={location.id} value={location.id} label={location.name}>
                        {location.name}
                    </option>
                ))}
            </select>
        </div>
    );
}
export default Location;
