import React, { useState, useEffect, useMemo, useRef } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import TableHeader from "../../../components/TableHeader/TableHeader";
import "./faculty.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import LocationContext from "../../../context/LocationContext";
import { useContext } from "react";
import Popup from "../../../components/Popup";
import defaultImage from "../../../utils/Images/defaultImage.jpg";
import { apiService } from "../../../Services/Services";
import { useLocation } from "react-router-dom";
import Exports, { FacultyExports } from "../../../components/exports/exports";
import Location from "../../../components/Dropdowns/Location/Location";
import Select from "react-select";
import * as _ from "lodash";

const FacultyList = (props) => {
  const handleLimitChange = (e) => {
    setLimit(e);
    setCurrentPage(0);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [locationList, setLocationList] = useState([]);

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );

  const [isOpen, setIsOpen] = useState(false);
  const [deleteFaculyName, setDeleteFaculyName] = useState("");

  const openPopup = (id, firstName) => {
    setItemToDelete(id);
    setDeleteFaculyName(firstName);
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleDeleteFaculty = () => {
    setIsOpen(false);
    apiService
      .delete(`${process.env.REACT_APP_API_URL}/deletefaculty/${itemToDelete}`)
      .then(() => {
        toast.success("Faculty Successfully Deleted.. ", toastConfig);
        facultyApiData();
      });
  };

  const { selectedLocation, locationState } = useContext(LocationContext);
  const [selectedExportLocations, setSelectedExportLocations] = useState([]);
  const [isOpenExportSchedule, setIsOpenExportSchedule] = useState(false);

  useEffect(() => {
    const mappingResponse = locationState.map((response) => {
      return {
        label: response.name,
        value: response.id,
      };
    });
    setLocationList(mappingResponse);
  }, [locationState]);

  const handleExportLocationsChange = (selectedOptions) => {
    if (selectedOptions?.length == 0) {
      const location = _.find(locationList, { value: selectedLocation });
      setSelectedExportLocations(location ? [location] : []);
    } else {
      setSelectedExportLocations(selectedOptions);
    }
  };
  const ScheduleExporttogglePopup = () => {
    const location = _.find(locationList, { value: selectedLocation });

    setSelectedExportLocations(location ? [location] : []);
    setIsOpenExportSchedule(!isOpenExportSchedule);

  };
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalRecords, setTotalRecords] = useState("");
  const [facultyData, setFacultyData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const noOfpage = totalRecords / limit;
  const location = useLocation();
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 400); // Adjust the delay as needed (300ms in this example)

    // Cleanup function to clear the timeout if searchTerm changes within the delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedTerm) {
      // Perform your search action here with debouncedTerm
      console.log("Performing search with term:", debouncedTerm);
      // Example: fetchSearchResults(debouncedTerm);
    }
  }, [debouncedTerm]);

  const facultyApiData = async () => {
    if (selectedLocation) {
      const sortOrder = sortConfig.direction === "desc" ? "DESC" : " ASC";
      const sortBy = sortConfig.key ? sortConfig.key : "updated_at";
      const url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=${debouncedTerm}&limit=${limit}&page=${currentPage}&gender=&sortBy=${sortBy}&sortOrder=${sortOrder}&location_id=${selectedLocation}`;

      try {
        const response = await apiService.get(url);

        if (response) {
          const responseData = await response;
          const { data, total } = responseData;
          setFacultyData(data);
          setTotalRecords(total);
          localStorage.setItem("totalFacultyRecords", total);
        } else {
          console.error("Error fetching faculty data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching faculty data:", error);
      }
    }
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleRowClick = (id) => {
    navigate(`/facultyview/${id}`);
  };
  const getfetchData = useRef(true);

  useEffect(() => {
    if (!getfetchData.current) {
      facultyApiData();
      getfetchData.current = false;
    }
  }, [
    debouncedTerm,
    getfetchData,
    currentPage,
    sortConfig,
    selectedLocation,
    limit,
  ]);
  getfetchData.current = false;

  return (
    <div className="  overflow-auto ">
      <ToastContainer />
      <div class="flex justify-end items-center m-2">
        <Location />
        <input
          value={searchTerm}
          onChange={handleChange}
          class="bg-white  h-9 rounded border border-gray-500 m-1 py-2 px-2 focus:outline-none mr-2"
          placeholder="Search....."
        />
        <a href="/facultycreate">
          <button class="bg-white hover:bg-gray-100 text-gray-800 font-serif text-md font-bold py-2 px-3 border border-gray-400 shadow mr-2">
            +
          </button>
        </a>
        <FacultyExports
          exportType="Faculty"
          Select={Select}
          locationList={locationList}
          selectedExportLocations={selectedExportLocations}
          handleExportLocationsChange={handleExportLocationsChange}
          setIsOpenExportSchedule={setIsOpenExportSchedule}
          ScheduleExporttogglePopup={ScheduleExporttogglePopup}
          isOpenExportSchedule={isOpenExportSchedule}

        />
      </div>
      {/* <div class="grid grid-cols-12 m-1   ">
        <div class="md:col-span-11 sm:col-span-6 w-full  ">
          <input
            value={searchTerm}
            onChange={handleChange}
            style={{ width: "35vw" }}
            className="  bg-white border border-gray-500 m-1 py-3 px-4 focus:outline-none mt-3 ml-5"
            placeholder="Search....."
          />
        </div>
        <div class=" md:col-span-1   pl-5  ">
          <Link to="/facultycreate">
            <button className="bg-white mt-3 hover:bg-gray-100 text-gray-800 text-2xl font-bold py-1 px-3 border border-gray-400 ">
              +
            </button>
          </Link>
        </div>
      </div> */}

      <section className="ml-6 mr-2 font-serif">
        <div className=" overflow-x-auto">
          <div class=" min-w-full inline-block align-middle bg-white">
            <div className="pageHeight ">
              <table className="min-w-full divide-y divide-gray-400 font-serif rounded-md border-2">
                <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                  <tr className="bg-primaryColour text-white">
                    <th
                      scope="col"
                      className="px-2 py-3 font-serif whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Image
                    </th>
                    <th
                      scope="col"
                      className="font-serif px-8 py-3 whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Faculty Code
                    </th>
                    <th
                      scope="col"
                      className="font-serif px-8 py-3 whitespace-nowrap text-base font-bold text-primary cursor-pointer text-left"
                      onClick={() => handleSort("first_name")}
                    >
                      First Name
                      {sortConfig.key === "first_name" ? (
                        sortConfig.direction === "asc" ? (
                          <span>▲</span>
                        ) : (
                          <span>▼</span>
                        )
                      ) : (
                        <span>▲</span>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-8 py-3 font-serif whitespace-nowrap text-base font-bold text-primary cursor-pointer text-left"
                      onClick={() => handleSort("last_name")}
                    >
                      Last Name{" "}
                      {sortConfig.key === "last_name" ? (
                        sortConfig.direction === "asc" ? (
                          <span>▲</span>
                        ) : (
                          <span>▼</span>
                        )
                      ) : (
                        <span>▲</span>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-8 font-serif py-3 whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-8 font-serif py-3 whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Batches Allocation
                    </th>
                    <th
                      scope="col"
                      className="px-8 font-serif py-3 whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Batches Count
                    </th>
                    <th
                      scope="col"
                      className="px-8 font-serif py-3 whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="px-8 font-serif py-3 whitespace-nowrap text-base font-bold text-primary cursor-pointer text-left"
                      onClick={() => handleSort("experience")}
                    >
                      Experience{" "}
                      {sortConfig.key === "experience" ? (
                        sortConfig.direction === "asc" ? (
                          <span>▲</span>
                        ) : (
                          <span>▼</span>
                        )
                      ) : (
                        <span>▲</span>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-8 font-serif py-3 whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Subjects
                    </th>
                    <th
                      scope="col"
                      className="px-8 font-serif py-3 whitespace-nowrap text-base font-bold text-primary text-left"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-400">
                  {facultyData.length === 0 ? (
                    <tr>
                      <td
                        colSpan="9"
                        className="text-center py-4 text-gray-800 font-serif"
                      >
                        No faculties available
                      </td>
                    </tr>
                  ) : (
                    facultyData.map((item, i) => (
                      <tr
                        className="hover:bg-gray-100 cursor-pointer"
                        key={i}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <td className="px-4 font-serif py-3 whitespace-nowrap text-base text-gray-800 text-left">
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              `/faculty/` +
                              item.id +
                              `/image`
                            }
                            onError={(e) => {
                              e.target.src = defaultImage;
                            }}
                            className="h-8 w-8 rounded-full"
                            alt="Item Image"
                          />
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.faculty_code ? item.faculty_code : ""}
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm font-medium text-gray-800 text-left">
                          {item.first_name ? item.first_name : ""}
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.last_name ? item.last_name : ""}
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.mail ? item.mail : ""}
                        </td>

                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.batches
                            .map((batch) => batch.batch_code)
                            .filter((code) => code)
                            .join(", ")}
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.batch_count ? item.batch_count : ""}
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.phone ? item.phone : ""}
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.experience ? item.experience : ""}
                        </td>
                        <td className="px-8 font-serif py-3 whitespace-nowrap text-sm text-gray-800 text-left">
                          {item.subject.map((item) => (
                            <p key={item.subject_name}>
                              {item.subject_name ? item.subject_name : ""}
                            </p>
                          ))}
                        </td>
                        <td className="px-8 py-3 font-serif justify-center flex whitespace-nowrap text-base font-medium text-left">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <button className="button muted-button">
                              <Link to={`/facultyformedit/${item.id}`}>
                                <AiFillEdit className="text-xl mr-3 text-green-700" />
                              </Link>
                            </button>
                          </div>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <button
                              onClick={() =>
                                openPopup(
                                  item.id,
                                  item.first_name + " " + item.last_name
                                )
                              }
                            >
                              <RiDeleteBin6Line className="text-red-700 text-xl mr-3" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Popup isOpen={isOpen} onClose={closePopup} heading={deleteFaculyName}>
          <h6>Do you want to delete the faculty ?</h6>
          <div className="button-container">
            <button
              className="text-gray-900 font-serif   bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={() => setIsOpen(false)}
            >
              No
            </button>
            <button
              className=" font-serif text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium  text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={() => handleDeleteFaculty()}
            >
              Yes
            </button>
          </div>
        </Popup>
        <div className="" style={{ float: "right" }}>
          <div className="flex mt-2">
            <p className=" mt-1 ">Items per page:</p>
            <div className="relative inline-block">
              <button
                className="me-3 mb-3 md:mb-0 text-balck  border       font-medium  text-sm px-3 py-1 text-center inline-flex items-center  "
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                type="button"
                style={{ border: " 2px solid balck" }}
              >
                {limit}
                <svg
                  className={`w-2.5 h-2.5 ms-3 transition-transform transform ${
                    isDropdownOpen ? "rotate-180" : ""
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>

              <div
                className={`z-10 ${
                  isDropdownOpen ? "" : "hidden"
                } absolute bottom-0 mb-6 bg-white divide-y divide-gray-100 rounded-lg   w-16 dark:bg-gray-700`}
              >
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                  <li>
                    <button
                      onClick={() => handleLimitChange(6)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      6
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleLimitChange(10)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      10
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleLimitChange(20)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      20
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleLimitChange(50)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      50
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              pageCount={noOfpage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default FacultyList;
