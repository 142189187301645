import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import FilterHeader from "./components/FilterHeader/FilterHeader";
import SchedulingPage from "./pages/Scheduling/Scheduling";
import BatchList from "./pages/Batch/BatchList/BatchList";
import FacultyList from "./pages/Faculty/Facultylist/FacultyList";
import Login from "./pages/Login";
import BatchCreate from "./pages/Batch/BatchCreate/BatchCreate";
import BatchEdit from "./pages/Batch/BatchEdit/BatchEdit";
import FacultyCreate from "./pages/Faculty/FacultyCreate/FacultyCreate";
import FacultyView from "./pages/Faculty/FacultyView/FacultyView";
import FacultyFormEdit from "./pages/Faculty/FacultyEdit/FacultyFormEdit";
import Logout from "./pages/Logout";
import Forgot from "./pages/Forgot";
import LeaveList from "./pages/Leave/LeaveList";
import LeaveCreate from "./pages/Leave/LeaveCreate";
import LeaveEdit from "./pages/Leave/LeaveEdit";
import ResetPassword from "./pages/ResetPassword";
import CreateUser from "./pages/CreateUser";
import Users from "./pages/Users";
import CreatPassword from "./pages/CreatPassword";
import UserEdit from "./pages/UserEdit";
import PivoteTablePage from "./PivoteTables/PivoteTablePage";
import LocationContext from "./context/LocationContext";
import { apiService } from "./Services/Services";
import Footer from "./components/Footer";
import Management from "./pages/Management/Management";
import SchedulingNewPage from "./pages/Scheduling-new/SchedulingNew";
import routes from "./routes";
function App() {
  const { setLoginUserData } = useContext(LocationContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  useEffect(() => {
    const idUser = localStorage.getItem("id");
    const getUserUrl = `${process.env.REACT_APP_API_URL}/getByIdUser/${idUser}`;
    const getUserDetails = async () => {
      await apiService
        .get(getUserUrl)
        .then((res) => {
          setLoginUserData(res?.user);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (idUser) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);


  return (
    <div className="pagecolor   ">
      {isLoggedIn ? (
        <div className="grid grid-cols-12">
          <div>
            <Sidebar />
          </div>
          <div
            className="col-span-12   border-gray-400 border-solid "
            style={{ overflow: "auto" }}
          >
            <FilterHeader />
            <div
            className="ml-14 mt-2">
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/createPassword" element={<CreatPassword />} />

          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
