import React, { useContext, useEffect, useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import TableRenderers from "react-pivottable/TableRenderers";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { apiService } from "../Services/Services";
import LocationContext from "../context/LocationContext";
import TableHeader from "../components/TableHeader/TableHeader";
import "./pivot.css";
import Plot from "react-plotly.js";
import Location from "../components/Dropdowns/Location/Location";
import Calender from "../components/Dropdowns/Calender/Calender";
import ScheduleType from "../components/Dropdowns/ScheduleType/scheduleType";
const PlotlyRenderers = createPlotlyRenderers(Plot);

const PivoteTablePage = () => {
  const { selectedDate, selectedLocation, selectedScheduleType } =
    useContext(LocationContext);
  const selectedDateArray = Array.isArray(selectedDate) ? selectedDate : [];

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });

  const startDate = formattedDates[0];
  const endDate = formattedDates[1];

  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [state, setState] = useState({
    rows: ["Faculty Code", "Faculty", "Location"], // Default rows
    cols: ["Date"],
    rendererName: "Table Heatmap",
  });
  const [groupedChratState, setgroupedChratState] = useState({
    rows: ["Faculty"], // Default rows
    cols: ["Batch"], // Default columns
    rendererName: "Grouped Column Chart", // Default renderer
  });

  let streamCode = "";

  if (selectedScheduleType === "jee/medical") {
    streamCode = "j/m";
  } else if (selectedScheduleType === "jee") {
    streamCode = "j";
  } else if (selectedScheduleType === "medical") {
    streamCode = "m";
  } else if (selectedScheduleType === "foundation") {
    streamCode = "f";
  }

  const fetchData = async () => {
    try {
      setFinalData([]);
      if (selectedDate && selectedLocation === "null") {
        let url = `${
          process.env.REACT_APP_API_URL
        }/getSchedule?starting_date=${startDate}&ending_date=${endDate}&stream_code=${streamCode}&location_id=${""}`;

        // Check if selectedLocation is not null before appending location_id to the URL

        const response = await apiService.get(url);
        setData(response);
      } else if (selectedDate && selectedLocation) {
        let url = `${process.env.REACT_APP_API_URL}/getSchedule?starting_date=${startDate}&ending_date=${endDate}&stream_code=${streamCode}&location_id=${selectedLocation}`;

        // Check if selectedLocation is not null before appending location_id to the URL

        const response = await apiService.get(url);
        setData(response);
      }
    } catch (error) {
      setData([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedDate,
    selectedLocation,
    selectedScheduleType,
    startDate,
    endDate,
  ]);

  const [chartData, setChartData] = useState(null);

  const [pieChartData, setPieChartData] = useState([]);

  useEffect(() => {
    if (data) {
      const filteredData = data.map((item) => ({
        Faculty: item.faculty.first_name + " " + item.faculty.last_name,
        "Faculty Code": item.faculty.faculty_code,
        Date: item.date,
        Batch: item.batch.batch_code,
        Time: item.slot_time,
        Location: item.location.name,
        "Batch Type": item.batch.batch_types[0].name, // Assuming there's only one batch type
        Subject: item.subject.subject_name,
      }));
      setTimeout(() => {
        console.log(">>>>", filteredData);
        setFinalData(filteredData);
        generateChartData(filteredData);
        generatePieChartData(filteredData);
        setState((prevState) => ({ ...prevState })); // Preserve existing state
      }, 0);
    }
  }, [data]);

  const generateChartData = (data) => {
    const dates = [...new Set(data.map((item) => item.Date))];
    const faculties = [...new Set(data.map((item) => item.Faculty))];

    const traces = faculties.map((faculty) => {
      return {
        x: dates,
        y: dates.map(
          (date) =>
            data.filter(
              (item) => item.Date === date && item.Faculty === faculty
            ).length
        ),
        type: "bar",
        name: faculty,
      };
    });

    setChartData(traces);
  };

  // const generatePieChartData = (data) => {
  //   const batches = [...new Set(data.map((item) => item.Batch))];

  //   const pieData = batches.map((batch) => {
  //     const batchData = data.filter((item) => item.Batch === batch);
  //     const faculties = [...new Set(batchData.map((item) => item.Faculty))];
  //     const facultyCounts = faculties.map((faculty) =>
  //       batchData.filter((item) => item.Faculty === faculty).length
  //     );

  //     return {
  //       labels: faculties,
  //       values: facultyCounts,
  //       type: "pie",
  //       name: batch,
  //       title: `Distribution of Sessions for Batch ${batch}`,
  //       hoverinfo: "label+value", // Show label and value on hover
  //       textinfo: "none", // Hide text on each pie slice
  //     };
  //   });

  //   setPieChartData(pieData);
  // };

  const generatePieChartData = (data) => {
    const facultySessionCounts = {};

    data.forEach((item) => {
      const facultyName = item.Faculty;
      facultySessionCounts[facultyName] =
        (facultySessionCounts[facultyName] || 0) + 1;
    });

    const faculties = Object.keys(facultySessionCounts);
    const sessionCounts = Object.values(facultySessionCounts);

    const pieChartData = [
      {
        labels: faculties,
        values: sessionCounts,
        type: "pie",
        name: "Faculty Sessions",
        hoverinfo: "label+value", // Show label and value on hover
        textinfo: "none", // Hide text on each pie slice
      },
    ];

    setPieChartData(pieChartData);
  };
  const handleRefreash = (value) => {
    fetchData();
  };
  return (
    <>
      <div className="flex bg-white p-0.5 shadow-lg overflow-x-auto ">
        <div className="flex mt-0.5 mr-2 left-0">
          <Location />
        </div>
        <div className="flex mt-0.5  mr-2">
          <Calender />
        </div>
        <div className="flex mt-0.5">
          <ScheduleType />
        </div>
        <button
          onClick={handleRefreash}
          className="text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring focus:ring-primaryColour-300 font-medium text-sm px-5 h-8 ml-auto"
        >
          {" "}
          Refreash
        </button>
      </div>{" "}
      <div></div>
      <div
        className="custom-height1 m-4"
        // style={{height:"70vh"}}
      >
        {!data ? (
          <div
            className="  pt-40 "
            style={{
              margin: "auto",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            No Records to show table
          </div>
        ) : (
          <>
            <div className=" hidden">
              {chartData && (
                <Plot
                  data={chartData}
                  layout={{
                    title: "Faculty Schedule Over Time",
                    barmode: "grouped",
                    xaxis: { title: "Date" },
                    yaxis: { title: "Number of Classes" },
                  }}
                />
              )}

              {chartData && (
                <Plot
                  data={chartData}
                  layout={{
                    title: "Faculty Schedule Over Time",
                    barmode: "stack",
                    xaxis: { title: "Date" },
                    yaxis: { title: "Number of Classes" },
                  }}
                />
              )}
              {pieChartData.map((pieData, index) => (
                <Plot
                  key={index}
                  data={[pieData]}
                  layout={{
                    title: pieData.title,
                  }}
                />
              ))}
            </div>
            <div>
              {finalData && (
                <PivotTableUI
                  data={finalData}
                  onChange={(s) => {
                    delete s.data;
                    setState(s);
                  }}
                  renderers={Object.assign({}, TableRenderers, PlotlyRenderers)} //PlotlyRenderers
                  {...state}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PivoteTablePage;
