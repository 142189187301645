import React, { useState } from "react";
import { FaEdit, FaEllipsisV, FaTrash } from "react-icons/fa";

const CustomEventBatchView = ({
  event,
  setScheduleEditId,
  setScheduleDeleteId,
  setScheduleDeleteisOpen,
  menuOpenId,
  setMenuOpenId,
}) => {
  const [hover, setHover] = useState(false);

  const menuOpen = menuOpenId === event.id;

  const toggleMenu = (e) => {
    e.stopPropagation();
    if (menuOpen) {
      setMenuOpenId(null); // Close the menu if it's already open
    } else {
      setMenuOpenId(event.id); // Open the menu for this event
    }
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: event.isCreateLeave ? "28px" : "100%",
        cursor: event.isCreateLeave ? "pointer" : "default",
        backgroundColor:
          event.isCreateLeave && hover ? "lightblue" : "transparent",
      }}
    >
      <>
        {event.title}
        {event.hasSchedule && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <button
              onClick={toggleMenu}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                color: "black",
                fontSize: "16px",
                padding: "0",
              }}
            >
              <FaEllipsisV />
            </button>
            {menuOpen && (
              <div
                style={{
                  position: "absolute",
                  left: "-400%",
                  top: 0,
                  backgroundColor: "white",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  zIndex: 1000,
                }}
              >
                {event.hasSchedule && (
                  <>
                    <div className="flex">
                      <div
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: "blue",
                          fontSize: "14px",
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from bubbling up
                            console.log("ScheduleEDITevent.id", event.id);
                            setScheduleEditId(event.id);
                            setMenuOpenId(null); // Close the menu if it's already open
                          }}
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "blue",
                            fontSize: "12px",
                            padding: "0",
                          }}
                        >
                          <FaEdit />
                        </button>
                      </div>
                      <div
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: "blue",
                          fontSize: "14px",
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from bubbling up
                            console.log("ScheduleDelete event.id", event.id);

                            setScheduleDeleteId(event.id);
                            setScheduleDeleteisOpen(true);
                            setMenuOpenId(null); // Close the menu if it's already open

                            // handleDeleteEvent(event.id);
                          }}
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "red",
                            fontSize: "12px",
                            padding: "0",
                          }}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default CustomEventBatchView;
