// import React, { useEffect, useMemo, useRef, useState } from "react";
// import TableHeader from '../../components/TableHeader/TableHeader'
// import { Link } from 'react-router-dom';
// import { ToastContainer, toast } from "react-toastify";
// import Location from "../../components/Dropdowns/Location/Location";
// import { apiService } from "../../Services/Services";
// import { useContext } from "react";
// import LocationContext from "../../context/LocationContext";
// import Popup from "../../components/Popup";
// import ReactPaginate from "react-paginate";

// const LogHistory = () => {
//   const [searchTerm, setSearchTerm] = useState("");

//   const handleChange = (e) => {
//     setSearchTerm(e.target.value);
//   };




//   const toastConfig = useMemo(
//     () => ({
//       position: toast.POSITION.BOTTOM_CENTER,
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       style: {
//         marginBottom: "4vw",
//         fontSize: "1.2em",
//         width: "400px",
//         padding: "10px",
//       },
//     }),
//     []
//   );



//   const { selectedLocation } = useContext(LocationContext);

//   const [sortConfig, setSortConfig] = useState({
//     key: null,
//     direction: "desc",
//   });
//   const handleSort = (key) => {
//     const direction =
//       sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
//     setSortConfig({ key, direction });
//   };

//   const [currentPage, setCurrentPage] = useState(1);
//   const [limit, setLimit] = useState(20);
//   const [totalRecords, setTotalRecords] = useState("");
//   const [logHostoryData, setlogHostoryData] = useState([]);

//   const noOfPages = Math.ceil(totalRecords / limit);



//   const logHostoryApiData = async () => {
//     if (selectedLocation) {
//       // Check if selectedLocation is not empty

//       const sortOrder = sortConfig.direction === "asc" ? "ASC" : "DESC";
//       const sortBy = sortConfig.key ? sortConfig.key : "updated_at";
//       const url = `${process.env.REACT_APP_API_URL}/logs?q=${searchTerm}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&starting_date=&ending_date=&faculty_id=&location_id=${selectedLocation}`;

//       try {
//         const response = await apiService.get(url);
//         if (response) {
//           console.log("response", response)
//           const responseData = await response;
//           const { data, total } = responseData;
//           setlogHostoryData(data);

//           setTotalRecords(total);
//           localStorage.setItem("totalBatchRecords", total);
//         } else {
//           console.error("Error fetching batch data:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching batch data:", error);
//       }
//     }
//   };

//   const handlePageChange = ({ selected }) => {
//     setCurrentPage(selected + 1);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e);
//     setCurrentPage(0);
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const getfetchData = useRef(true);

//   useEffect(() => {
//     if (selectedLocation) {
//       if (!getfetchData.current) {
//         logHostoryApiData();
//         getfetchData.current = false;
//       }
//     }
//   }, [searchTerm, currentPage, sortConfig, selectedLocation, limit]);

//   getfetchData.current = false;


//   function formatDateddmmyy(dateString) {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = String(date.getFullYear()).slice(-2);
//     return `${day}-${month}-${year}`;
//   }

//   // console.log("batchData", batchData)
//   return (
//     <>
//  <div className=" overflow-auto">
//       <div class="flex justify-end items-center m-2">
//       <Location />
//         <input value={searchTerm}
//           onChange={handleChange} class="bg-white  h-9 rounded border border-gray-500 m-1 py-2 px-2 focus:outline-none mr-2" placeholder="Search....." />
//         <a href="/batchcreate">
//           <button class="bg-white hover:bg-gray-100 text-gray-800 font-serif text-md font-bold py-2 px-3 border border-gray-400 shadow mr-2">+</button>
//         </a>
//         {/* <Exports exportType="Batch" /> */}
//       </div>
//       <ToastContainer />
//       <section className="ml-6 mr-2 font-serif bg-white ">
//         <div className="overflow-x-auto">
//           <div className="min-w-full inline-block align-middle">
//             <div className="pageHeight">
//               <table className="min-w-full divide-y divide-gray-400 ">
//                 <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
//                   <tr className=" bg-primaryColour text-white">
//                     <th
//                       scope="col"
//                       className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif cursor-pointer"
//                       onClick={() => handleSort("batch_code")}
//                     >
//                       Modifie By{" "}
//                       {sortConfig.key === "batch_code" ? (
//                         sortConfig.direction === "asc" ? (
//                           <span>▲</span>
//                         ) : (
//                           <span>▼</span>
//                         )
//                       ) : (
//                         <span>▲</span>
//                       )}
//                     </th>

//                     <th
//                       scope="col"
//                       className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif "
//                     >
//                     Date
//                     </th>

//                     <th
//                       scope="col"
//                       className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif s "
//                     >
//                       {" "}
//                      Old
//                     </th>
//                     <th
//                       scope="col"
//                       className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif s "
//                     >
//                       {" "}
//                      New
//                     </th>



//                   </tr>
//                 </thead>
//                 <tbody className="divide-y divide-gray-400 dark:divide-gray-700">
//                   {/* {logHostoryData.length === 0 ? (
//                     <tr>
//                       <td
//                         colSpan="9"
//                         className="text-center py-4 text-gray-800 font-serif"
//                       >
//                         No batch available
//                       </td>
//                     </tr>
//                   ) : (
//                     logHostoryData.map((item, i) => (
//                       <tr className="hover:bg-gray-100" key={i}>
//                         <td className="px-8 py-4 whitespace-nowrap text-sm  text-gray-800  font-serif  ">
//                           {item.batch_code ? item.batch_code : ""}
//                         </td>
//                         <td className="px-16 py-4 whitespace-nowrap text-sm  text-gray-800  font-serif  text-center">
//                           {item.batch_types.map((batchtype) => (
//                             <p> {batchtype.name ? batchtype.name : null}</p>
//                           ))}
//                         </td>

//                         <td className="px-8 py-4 whitespace-nowrap text-sm text-gray-800  font-serif text-center">
//                           {item.batch_slots && item.batch_slots.length === 0
//                             ? item.slot_times_foundations.map(
//                               (slotTime, index) => (
//                                 <React.Fragment key={index}>
//                                   {slotTime.slot_times &&
//                                     slotTime.slot.includes("morning") && (
//                                       <p>Morning</p>
//                                     )}
//                                   {slotTime.slot_times &&
//                                     slotTime.slot.includes("afternoon") && (
//                                       <p>Afternoon</p>
//                                     )}
//                                 </React.Fragment>
//                               )
//                             )
//                             : item.batch_slots.map((slotTime, index) => (
//                               <React.Fragment key={index}>
//                                 {slotTime.slot_times &&
//                                   slotTime.slot.includes("morning") && (
//                                     <p>Morning</p>
//                                   )}
//                                 {slotTime.slot_times &&
//                                   slotTime.slot.includes("afternoon") && (
//                                     <p>Afternoon</p>
//                                   )}
//                               </React.Fragment>
//                             ))}
//                         </td>


//                         <td className="px-8 py-4 whitespace-nowrap text-sm text-gray-800  font-serif  text-center">
//                           {item.batch_stream.map((streamnames) => (
//                             <p>
//                               {" "}
//                               {streamnames.stream_names
//                                 ? streamnames.stream_names
//                                 : ""}
//                             </p>
//                           ))}
//                         </td>
//                         <td className="px-8 py-4 whitespace-nowrap text-sm text-gray-800  font-serif  text-center">
//                           {formatDateddmmyy(item.starting_date)
//                             ? formatDateddmmyy(item.starting_date)
//                             : ""}
//                         </td>
//                         <td className="px-8 py-4 whitespace-nowrap text-base  text-gray-800  font-serif  text-center">
//                           {item.duration ? item.duration : ""}
//                           {item.duration_type ? item.duration_type : ""}
//                         </td>
//                         <td className="px-8 py-4 whitespace-nowrap text-base  text-gray-800  font-serif  text-center">
//                           {item.selected_days && <span>Fixed</span>}
//                           {item.selected_days_count && (
//                             <span>Flexible</span>
//                           )}{" "}
//                         </td>
//                         <td className="px-8 py-4 whitespace-nowrap  text-sm text-gray-800  font-serif  text-center">
//                           {item.locations.map((locations) => (
//                             <p>{locations.name ? locations.name : ""}</p>
//                           ))}
//                         </td>


//                       </tr>
//                     ))
//                   )} */}
//                 </tbody>

//               </table>
//             </div>
//           </div>
//         </div>

//         <div className="" style={{ float: "right" }}>
//           <div className="flex mt-2">
//             <p className=" mt-1 ">Items per page:</p>

//             <div className="relative inline-block">
//               <button
//                 className="me-3 mb-3 md:mb-0 text-balck  border       font-medium text-sm px-3 py-1 text-center inline-flex items-center  "
//                 onClick={() => setIsDropdownOpen(!isDropdownOpen)}
//                 type="button"
//                 style={{ border: " 2px solid balck" }}
//               >
//                 {limit}
//                 <svg
//                   className={`w-2.5 h-2.5 ms-3 transition-transform transform ${isDropdownOpen ? "rotate-180" : ""
//                     }`}
//                   aria-hidden="true"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 10 6"
//                 >
//                   <path
//                     stroke="currentColor"
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     stroke-width="2"
//                     d="M9 5 5 1 1 5"
//                   />
//                 </svg>
//               </button>

//               <div
//                 className={`z-10 ${isDropdownOpen ? "" : "hidden"
//                   } absolute bottom-0 mb-6 bg-white divide-y divide-gray-100 rounded-lg   w-16 dark:bg-gray-700`}
//               >
//                 <ul className="py-2text-base text-gray-700 dark:text-gray-200">
//                   <li>
//                     <button
//                       onClick={() => handleLimitChange(6)}
//                       className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
//                     >
//                       6
//                     </button>
//                   </li>
//                   <li>
//                     <button
//                       onClick={() => handleLimitChange(10)}
//                       className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
//                     >
//                       10
//                     </button>
//                   </li>
//                   <li>
//                     <button
//                       onClick={() => handleLimitChange(20)}
//                       className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
//                     >
//                       20
//                     </button>
//                   </li>
//                   <li>
//                     <button
//                       onClick={() => handleLimitChange(50)}
//                       className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
//                     >
//                       50
//                     </button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//             <ReactPaginate
//               previousLabel="<"
//               nextLabel=">"
//               breakLabel="..."
//               pageCount={noOfPages}
//               marginPagesDisplayed={2}
//               pageRangeDisplayed={2}
//               onPageChange={handlePageChange}
//               containerClassName="pagination"
//               previousLinkClassName="pagination__link"
//               nextLinkClassName="pagination__link"
//               disabledClassName="pagination__link--disabled"
//               activeClassName="pagination__link--active"
//             />
//           </div>
//         </div>
//       </section>
//       </div>
//  </>
// )
// }

// export default LogHistory
import React, { useEffect, useMemo, useRef, useState } from "react";
import TableHeader from '../../components/TableHeader/TableHeader'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import Location from "../../components/Dropdowns/Location/Location";
import { apiService } from "../../Services/Services";
import { useContext } from "react";
import LocationContext from "../../context/LocationContext";
import Popup from "../../components/Popup";
import ReactPaginate from "react-paginate";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
// utils.js
import endOfYear from "date-fns/endOfYear";

export const formatDateTime = (value) => {
  const date = new Date(value);
  if (!isNaN(date)) {
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).replace(',', ''); // Remove the comma if needed
  }
  return value;
};

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfWeek(new Date(), { weekStartsOn: 1 }),
    ],    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), endOfYear(new Date())],

    // value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "All time",
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 1 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 1 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 1 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 1 }), 7),
      ];
    },
    appearance: "default",
  },
];
const LogHistory = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 400); // Adjust the delay as needed (300ms in this example)

    // Cleanup function to clear the timeout if searchTerm changes within the delay
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedTerm) {
      // Perform your search action here with debouncedTerm
      console.log("Performing search with term:", debouncedTerm);
      // Example: fetchSearchResults(debouncedTerm);
    }
  }, [debouncedTerm]);





  const { selectedLocation } = useContext(LocationContext);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalRecords, setTotalRecords] = useState("");
  const [logHostoryData, setlogHostoryData] = useState([]);

  console.log(logHostoryData)
  const noOfPages = Math.ceil(totalRecords / limit);



  const logHostoryApiData = async () => {
    if (selectedLocation && startDate && endDate) {

      // ? startDate.toISOString().split("T")[0]
      // : "";
      const formattedStartDate = startDate
  ? new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())).toISOString().split("T")[0]
  : "";

    const formattedEndDate = endDate ? endDate.toISOString().split("T")[0] : "";
      const sortOrder = sortConfig.direction === "asc" ? "ASC" : "DESC";
      const sortBy = sortConfig.key ? sortConfig.key : "updated_at";
      const url = `${process.env.REACT_APP_API_URL}/logs?search=${debouncedTerm}&limit_per_page=${limit}&page=${currentPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&starting_date=${formattedStartDate}&ending_date=${formattedEndDate}&faculty_id=&location_id=${selectedLocation}`;

      try {
        const response = await apiService.get(url);
        if (response) {
          console.log("response", response)
          const responseData = await response;
          const { data, total } = responseData;
          setlogHostoryData(data);

          setTotalRecords(total);
          localStorage.setItem("totalBatchRecords", total);
        } else {
          console.error("Error fetching batch data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleLimitChange = (e) => {
    setLimit(e);
    setCurrentPage(0);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getfetchData = useRef(true);



  getfetchData.current = false;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDateRangeChange = (selectedDates) => {
    console.log("selectedDates", selectedDates)
    if (selectedDates && selectedDates.length >= 2) {
      setStartDate(selectedDates[0]);
      setEndDate(selectedDates[1]);
    } else {
      // Handle the case where selectedDates is null or does not have at least two elements
      console.error("Invalid selectedDates:", selectedDates);
    }
  };



  useEffect(() => {
    const getCurrentWeekDates = () => {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // Get the current day of the week (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
      const startOfWeek = new Date(currentDate); // Clone the current date

      // Calculate the start of the week (Monday)
      const diff = (dayOfWeek + 6) % 7; // Adjust for Monday start of the week
      startOfWeek.setDate(currentDate.getDate() - diff);

      // Calculate the end of the week (Sunday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      return [startOfWeek, endOfWeek];
    };

    const [initialStartDate, initialEndDate] = getCurrentWeekDates();
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, []);




  useEffect(() => {
    if (selectedLocation) {
      if (!getfetchData.current) {
        logHostoryApiData();
        getfetchData.current = false;
      }
    }
  }, [debouncedTerm,
    sortConfig,
    selectedLocation,
    startDate,
    endDate,
    currentPage,
    limit,]);
  // console.log("batchData", batchData)
  return (
    <>
 <div className=" overflow-auto">

      <div class="flex justify-end items-center m-2">
      <Location />

      <DateRangePicker className=" ml-1 leave-datepicker"
          showOneCalendar
          placeholder="Calender"
          ranges={predefinedRanges}
          value={[startDate, endDate]}
          onChange={handleDateRangeChange}
          format="dd-MM-yyyy"
          isoWeek
        />
        <input value={searchTerm}
          onChange={handleChange} class="bg-white  h-9 rounded border border-gray-500 ml-1 py-2 px-2 focus:outline-none mr-2" placeholder="Search....." />
        {/* <a href="/batchcreate">
          <button class="bg-white hover:bg-gray-100 text-gray-800 font-serif text-md font-bold py-2 px-3 border border-gray-400 shadow mr-2">+</button>
        </a> */}
        {/* <Exports exportType="Batch" /> */}
      </div>
      <ToastContainer />
      <section className="ml-6 mr-2 font-serif bg-white ">
        <div className="overflow-x-auto">
          <div className="min-w-full inline-block align-middle">
            <div className="pageHeight">
              <table className="min-w-full divide-y divide-gray-400 ">
                <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                  <tr className=" bg-primaryColour text-white">
                  <th
                      scope="col"
                      className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif cursor-pointer"
                      // onClick={() => handleSort("created_at")}

                    >

                    Date
                    {/* {sortConfig.key === "created_at" ? (
                        sortConfig.direction === "asc" ? (
                          <span>▲</span>
                        ) : (
                          <span>▼</span>
                        )
                      ) : (
                        <span>▲</span>
                      )} */}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif cursor-pointer"
                    >
                      Modifie By{" "}

                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif cursor-pointer"
                    >
                      Faculty{" "}

                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif cursor-pointer"
                    >
                      Batch

                    </th>




                    <th
                      scope="col"
                      className="px-4 py-3 whitespace-nowrap  text-base font-bold text-primary  font-serif s "
                    >
                      {" "}
                     Changes
                    </th>




                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-400 dark:divide-gray-700">
                {logHostoryData.length === 0 ? (
//   <tr>
//     <td colSpan="9" className="text-center py-4 text-gray-800 font-serif">
//       No Data available
//     </td>
//   </tr>
// ) : (
//   logHostoryData.map((item, i) => (
//     <tr className="hover:bg-gray-100" key={i}>
//            <td className="px-8 py-4 whitespace-nowrap text-sm  text-gray-800  font-serif  ">
//       {new Date(item.created_at).toLocaleString()}
// </td>
//       <td className="px-16 py-4 whitespace-nowrap text-sm  text-gray-800  font-serif text-center ">
// {item.user.name}
//       </td>


//       <td className="px-16 py-4 whitespace-nowrap text-sm  text-gray-800  font-serif text-center ">
// {item.faculty.first_name +" " + item.faculty.last_name }
//       </td>
//       <td className="px-16 py-4 whitespace-nowrap text-sm  text-gray-800  font-serif text-center ">
// {item.batch.batch_code}
//       </td>


//          <td className="px-16 py-4 whitespace-nowrap text-sm text-gray-800 font-serif">
//           {/* Batch <strong>{item.batch.batch_code}</strong> for faculty <strong>{item.faculty.first_name} {item.faculty.last_name}</strong> has the following <strong>schedule</strong> changes: */}

//           {Object.keys(item.changed_values).length === 0 ? (
//   <p className="text-sm">- No changes.</p>
// ) : (
//   Object.keys(item.changed_values).map((key, changeIndex) => {
//     let oldValue = item.changed_values[key].old;
//     let newValue = item.changed_values[key].new;

//     // Remove 'id' field if present in oldValue and newValue
//     if (typeof oldValue === 'object' && 'id' in oldValue) {
//         delete oldValue.id;
//     }
//     if (typeof newValue === 'object' && 'id' in newValue) {
//         delete newValue.id;
//     }

//     // Convert objects to strings
//     // oldValue = typeof oldValue === 'object' ? JSON.stringify(oldValue) : oldValue;
//     // newValue = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue;

//     // const displayKey = key.replace(/_/g, ' ');


//     const oldValueString = typeof oldValue === 'object' && oldValue !== null
//     ? Object.values(oldValue).join(' ')
//     : oldValue;
//   const newValueString = typeof newValue === 'object' && newValue !== null
//     ? Object.values(newValue).join(' ')
//     : newValue;
//     const displayKey = key.replace(/_/g, ' ');

//     return (
//         // <p key={changeIndex} className="text-sm">
//         //     - Changed {displayKey} from
//         //     <span className="font-bold mx-1">{oldValue}</span>
//         //     to
//         //     <span className="font-bold ml-1">{newValue}</span>
//         // </p>
//         <p key={changeIndex} className="text-sm">
//         - Changed {displayKey} from
//         <span className="font-bold mx-1">{oldValueString}</span>
//         to
//         <span className="font-bold ml-1">{newValueString}</span>
//       </p>
//     );
// })
// )}

//         </td>
//     </tr>
//   ))
// )}
<tr>
  <td colSpan="9" className="text-center py-4 text-gray-800 font-serif">
    No Data available
  </td>
</tr>
) : (
  logHostoryData.map((item, i) => (
    <tr className="hover:bg-gray-100" key={i}>
      <td className="px-8 py-4 whitespace-nowrap text-sm text-gray-800 font-serif">
        {new Date(item.created_at).toLocaleString()}
      </td>
      <td className="px-16 py-4 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
        {item.user.name}
      </td>
      <td className="px-16 py-4 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
        {item.faculty.first_name + " " + item.faculty.last_name}
      </td>
      <td className="px-16 py-4 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
        {item.batch.batch_code}
      </td>
      {/* <td className="px-16 py-4 whitespace-nowrap text-sm text-gray-800 font-serif">
        {Object.keys(item.changed_values).length === 0 ? (
          <p className="text-sm">- No changes.</p>
        ) : (
          Object.keys(item.changed_values).map((key, changeIndex) => {
            let oldValue = item.changed_values[key]?.old;
            let newValue = item.changed_values[key]?.new;

            // Handle deleted_schedule separately
            if (key === 'deleted_schedule') {
              const deletedDate = new Date(item.created_at).toLocaleString();
              return (
                <p key={changeIndex} className="text-sm">
                  - Deleted schedule on <span className="font-bold ml-1">{deletedDate}</span>
                </p>
              );
            }

            // Remove 'id' field if present in oldValue and newValue
            if (typeof oldValue === 'object' && 'id' in oldValue) {
              delete oldValue.id;
            }
            if (typeof newValue === 'object' && 'id' in newValue) {
              delete newValue.id;
            }

            const oldValueString = typeof oldValue === 'object' && oldValue !== null
              ? Object.values(oldValue).join(' ')
              : oldValue;
            const newValueString = typeof newValue === 'object' && newValue !== null
              ? Object.values(newValue).join(' ')
              : newValue;
            const displayKey = key.replace(/_/g, ' ');

            return (
              <p key={changeIndex} className="text-sm">
                - Changed {displayKey} from
                <span className="font-bold mx-1">{oldValueString}</span>
                to
                <span className="font-bold ml-1">{newValueString}</span>
              </p>
            );
          })
        )}
      </td> */}
       <td className="px-16 py-4 whitespace-nowrap text-sm text-gray-800 font-serif">
      {Object.keys(item.changed_values).length === 0 ? (
        <p className="text-sm">- No changes.</p>
      ) : (
        Object.keys(item.changed_values).map((key, changeIndex) => {
          let oldValue = item.changed_values[key]?.old;
          let newValue = item.changed_values[key]?.new;

          // Handle deleted_schedule separately
          if (key === 'deleted_schedule') {
            const deletedDate = formatDateTime(item.created_at);
            return (
              <p key={changeIndex} className="text-sm">
                - Deleted schedule on <span className="font-bold ml-1">{deletedDate}</span>
              </p>
            );
          }

          // Remove 'id' field if present in oldValue and newValue
          if (typeof oldValue === 'object' && 'id' in oldValue) {
            delete oldValue.id;
          }
          if (typeof newValue === 'object' && 'id' in newValue) {
            delete newValue.id;
          }

          // Format old and new values if they are dates
          if (oldValue && !isNaN(Date.parse(oldValue))) {
            oldValue = formatDateTime(oldValue);
          }
          if (newValue && !isNaN(Date.parse(newValue))) {
            newValue = formatDateTime(newValue);
          }

          const oldValueString = typeof oldValue === 'object' && oldValue !== null
            ? Object.values(oldValue).join(' ')
            : oldValue;
          const newValueString = typeof newValue === 'object' && newValue !== null
            ? Object.values(newValue).join(' ')
            : newValue;
          const displayKey = key.replace(/_/g, ' ');

          return (
            <p key={changeIndex} className="text-sm">
              - Changed {displayKey} from
              <span className="font-bold mx-1">{oldValueString}</span>
              to
              <span className="font-bold ml-1">{newValueString}</span>
            </p>
          );
        })
      )}
    </td>
    </tr>
  ))
)}

                </tbody>

              </table>
            </div>
          </div>
        </div>

        <div className="" style={{ float: "right" }}>
          <div className="flex mt-2">
            <p className=" mt-1 ">Items per page:</p>

            <div className="relative inline-block">
              <button
                className="me-3 mb-3 md:mb-0 text-balck  border       font-medium text-sm px-3 py-1 text-center inline-flex items-center  "
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                type="button"
                style={{ border: " 2px solid balck" }}
              >
                {limit}
                <svg
                  className={`w-2.5 h-2.5 ms-3 transition-transform transform ${isDropdownOpen ? "rotate-180" : ""
                    }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>

              <div
                className={`z-10 ${isDropdownOpen ? "" : "hidden"
                  } absolute bottom-0 mb-6 bg-white divide-y divide-gray-100 rounded-lg   w-16 dark:bg-gray-700`}
              >
                <ul className="py-2text-base text-gray-700 dark:text-gray-200">
                  <li>
                    <button
                      onClick={() => handleLimitChange(6)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      6
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleLimitChange(10)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      10
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleLimitChange(20)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      20
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleLimitChange(50)}
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                    >
                      50
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              pageCount={noOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              previousLinkClassName="pagination__link"
              nextLinkClassName="pagination__link"
              disabledClassName="pagination__link--disabled"
              activeClassName="pagination__link--active"
            />
          </div>
        </div>
      </section>
      </div>
 </>
)
}

export default LogHistory