import React, { useState } from "react";

const Switch = ({ checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };
  return (
    <label className="flex items-center cursor-pointer ml-2">
      <div className="relative">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          className="sr-only"
        />
        <div
          className={`block w-8 h-4 rounded-full transition-colors duration-300 ${
            isChecked ? 'bg-green-500' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition-transform duration-300 ${
            isChecked ? 'transform translate-x-4' : ''
          }`}
        ></div>
      </div>
    </label>
  );
};

export default Switch;
