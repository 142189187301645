// import React, { useState } from 'react';
// import { FaPlus } from 'react-icons/fa';

// const CustomEvent = ({ event, handleSelectEvent }) => {
//   const [hover, setHover] = useState(false);

//   return (
//     <div
//       onMouseEnter={() => setHover(true)}
//       onMouseLeave={() => setHover(false)}
//       // onClick={() => event.isCreateLeave && handleSelectEvent(event)}
//       style={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: event.isCreateLeave ? '28px': '100%',
//         cursor: event.isCreateLeave ? 'pointer' : 'default',
//         backgroundColor: event.isCreateLeave && hover ? 'lightblue' : 'transparent'
//       }}
//     >
//       {event.isCreateLeave && hover ? 'Add Leave' : event.title}
//     </div>
//   );
// };

// export default CustomEvent;

// import React, { useState } from 'react';
// import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';

// const CustomEvent = ({ event, handleSelectEvent, handleDeleteEvent }) => {
//   const [hover, setHover] = useState(false);

//   return (
//     <div
//       onMouseEnter={() => setHover(true)}
//       onMouseLeave={() => setHover(false)}
//       // onClick={() => event.isCreateLeave && handleSelectEvent(event)}
//       style={{
//         position: 'relative',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: event.isCreateLeave ? '28px' : '100%',
//         cursor: event.isCreateLeave ? 'pointer' : 'default',
//         backgroundColor: event.isCreateLeave && hover ? 'lightblue' : 'transparent',
//         // border: event.isCreateLeave ? '1px dashed gray' : 'none'
//       }}
//     >
//       {event.isCreateLeave ? (
//          hover ? 'Add Leave' : event.title
//         // <FaPlus style={{ fontSize: '16px' }} />
//       ) : (
//         <>
//           {event.title}

//             {/* <button
//               onClick={(e) => {
//                 e.stopPropagation(); // Prevent click from bubbling up
//                 // handleDeleteEvent(event.id);
//                 console.log("event.id", event.id)
//               }}
//               style={{
//                 position: 'absolute',
//                 top: '1px',
//                 right: '1px',
//                 background: 'transparent',
//                 border: 'none',
//                 cursor: 'pointer',
//                 color: 'red',
//                 fontSize: '12px',
//                 padding: '0',
//               }}
//             >
//               <FaTrash />
//             </button> */}
//              {/* <div style={{ textAlign: 'center' }}>
//             {event.title}
//           </div> */}

//             <div
//               // style={{
//               //   position: 'absolute',
//               //   bottom: '5px',
//               //   display: 'flex',
//               //   gap: '5px',
//               //   float:"right"
//               // }}
//             >
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation(); // Prevent click from bubbling up
//                   // handleEditEvent(event.id);
//                   console.log("EDITevent.id", event.id)
//                 }}
//                 style={{
//                   background: 'transparent',
//                   border: 'none',
//                   cursor: 'pointer',
//                   color: 'blue',
//                   fontSize: '12px',
//                   padding: '0',
//                 }}
//               >
//                 <FaEdit />
//               </button>
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation(); // Prevent click from bubbling up
//                   // handleDeleteEvent(event.id);
//                   console.log("Dleteevent.id", event.id)

//                 }}
//                 style={{
//                   background: 'transparent',
//                   border: 'none',
//                   cursor: 'pointer',
//                   color: 'red',
//                   fontSize: '12px',
//                   padding: '0',
//                 }}
//               >
//                 <FaTrash />
//               </button>
//             </div>

//         </>
//       )}
//     </div>
//   );
// };

// export default CustomEvent;
import React, { useState } from "react";
import { FaEdit, FaEllipsisV, FaTrash } from "react-icons/fa";
import dayjs from "dayjs";

const CustomEvent = ({
  event,
  setLeaveId,
  setScheduleEditId,
  setScheduleDeleteId,
  setLeaveDeleteisOpen,
  setScheduleDeleteisOpen,
  menuOpenId,
  setMenuOpenId,
  setShowModal,
  setleaveEditId,
  setLeaveEditisOpen,
}) => {
  const [hover, setHover] = useState(false);

  const menuOpen =
    menuOpenId ===
    `${event.id} date ${dayjs(event.start).format("YYYY-MM-DD")}`;

  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowModal(false);

    const eventDateKey = `${event.id} date ${dayjs(event.start).format(
      "YYYY-MM-DD"
    )}`;
    if (menuOpen) {
      setMenuOpenId(null);
    } else {
      setMenuOpenId(eventDateKey);
    }
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: event.isCreateLeave ? "28px" : "100%",
        cursor: event.isCreateLeave ? "pointer" : "default",
        backgroundColor:
          event.isCreateLeave && hover ? "lightblue" : "transparent",
      }}
    >
      {event.isCreateLeave ? (
        hover ? (
          "Add Leave"
        ) : (
          event.title
        )
      ) : (
        <>
          {event.title}
          {event.hasSchedule && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <button
                onClick={toggleMenu}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "black",
                  fontSize: "16px",
                  padding: "0",
                }}
              >
                <FaEllipsisV />
              </button>
              {menuOpen && (
                <div
                  style={{
                    position: "absolute",
                    left: "-400%",
                    top: 0,
                    backgroundColor: "white",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1000,
                    display: "flex",
                  }}
                >
                  {event.hasSchedule && (
                    <>
                      <div
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: "blue",
                          fontSize: "14px",
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from bubbling up
                            setScheduleEditId(event.id);
                            setMenuOpenId(null); // Close the menu if it's already open
                          }}
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "blue",
                            fontSize: "12px",
                            padding: "0",
                          }}
                        >
                          <FaEdit />
                        </button>
                      </div>
                      <div
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: "blue",
                          fontSize: "14px",
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from bubbling up

                            setScheduleDeleteId(event.id);
                            setScheduleDeleteisOpen(true);
                            setMenuOpenId(null); // Close the menu if it's already open

                            // handleDeleteEvent(event.id);
                          }}
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "red",
                            fontSize: "12px",
                            padding: "0",
                          }}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          {event.hasLeave && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <button
                onClick={toggleMenu}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "black",
                  fontSize: "16px",
                  padding: "0",
                }}
              >
                <FaEllipsisV />
              </button>
              {menuOpen && (
                <div
                  style={{
                    position: "absolute",
                    left: "-400%",
                    top: 0,
                    backgroundColor: "white",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1000,
                    display: "flex",
                  }}
                >
                  {event.hasLeave && (
                    <>
                      <div
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: "blue",
                          fontSize: "14px",
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from bubbling up
                            setleaveEditId(event.id);
                            setLeaveEditisOpen(true);

                            setMenuOpenId(null); // Close the menu if it's already open
                          }}
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "blue",
                            fontSize: "12px",
                            padding: "0",
                          }}
                        >
                          <FaEdit />
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent click from bubbling up

                            setLeaveId(event.id);
                            setLeaveDeleteisOpen(true);
                            setMenuOpenId(null); // Close the menu if it's already open

                            // setMenuOpenId(null); // Close the menu if it's already open
                          }}
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "red",
                            fontSize: "12px",
                            padding: "5px 10px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomEvent;
