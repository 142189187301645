import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { apiService } from "../../Services/Services";
import Accordion from "./Accordion";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationContext from "../../context/LocationContext";

import "./management.css";
import { useContext } from "react";
import Popup from "../../components/Popup";

import moment from "moment";
import HolidayPopUp from "./HolidayPopUp";

const HolidayList = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm();

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [morningChecked, setMorningChecked] = useState(false);
  const [afternoonChecked, setAfternoonChecked] = useState(false);

  const [slotIdStore, setSlotIdStore] = useState([]);

  const handleCheckboxChange = (item) => {
    if (item.name === "Afternoon") {
      setAfternoonChecked((prevState) => !prevState);
    } else if (item.name === "Morning") {
      setMorningChecked((prevState) => !prevState);
    }
    setSlotIdStore((prevState) => {
      // If the id is already in the array, remove it (uncheck), otherwise add it (check)
      if (prevState.includes(item.id)) {
        return prevState.filter((id) => id !== item.id);
      } else {
        return [...prevState, item.id];
      }
    });
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 400); // Adjust the delay as needed (300ms in this example)

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const [itemToDelete, setItemToDelete] = useState(null);

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = (id, batchCode) => {
    setItemToDelete(id);

    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
    clearErrors(); // Clears all validation errors
  };
  const handleDeleteTime = () => {
    if (itemToDelete !== null) {
      setIsOpen(false);
      apiService
        .delete(`${process.env.REACT_APP_API_URL}/holidays/${itemToDelete}`)
        .then(() => {
          toast.success("Holiday Successfully deleted.. ", toastConfig);

          holidayApiData();
        });
    }
  };
  const holidayApiData = async () => {
    if (selectedLocation) {
      const sortOrder = sortConfig.direction === "asc" ? "ASC" : "DESC";
      const sortBy = sortConfig.key ? sortConfig.key : "updated_at";
      const url = `${process.env.REACT_APP_API_URL}/holidays?search=${debouncedTerm}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      try {
        const response = await apiService.get(url);
        if (response) {
          const responseData = await response;
          const { data, length } = responseData;

          setDates(response);

          setTotalRecords(length);
        } else {
          console.error("Error fetching batch data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    }
  };

  const { selectedLocation } = useContext(LocationContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalRecords, setTotalRecords] = useState("");
  const noOfPages = Math.ceil(totalRecords / limit);
  const [Dates, setDates] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [HolidayGetById, setHolidayGetById] = useState([]);
  const [popUpButton, setPopUpButton] = useState(false);
  const [popUpHeading, setPopUpHeading] = useState("");
  const handleLimitChange = (e) => {
    setLimit(e);
    setCurrentPage(0);
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const togglePopup = () => {
    setIsPopupOpen(true);
    setPopUpButton(false);
  };

  const cancelPopup = () => {
    setValue("holidayName", "");
    setValue("dateRange", "");
    clearErrors();
    setValue("locations", "");
    setValue("batchType", "");
    setSlotIdStore("");
    setValue("batchNames", "");
    setValue("morning", "");
    setValue("afternoon", "");
    setMorningChecked(false);
    setAfternoonChecked(false);
    setBatchTypeAccordingFilteredBatches("");
    setLocationfilteredBatches("");
    setIsPopupOpen(false);
    setHolidayGetById("");
    setHolidayName("");
    setPopUpButton(false);
    setPopUpHeading("Create Holidays");
  };

  const handleEditTime = (id) => {
    setIsPopupOpen(true);
    setHolidayGetById(id);
  };

  const holidayGetByIdUrl = `${process.env.REACT_APP_API_URL}/holidays/${HolidayGetById}`;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState("");

  const [HolidayName, setHolidayName] = useState("");
  const fetchHolidayGetByData = async () => {
    try {
      const Response = await apiService.get(holidayGetByIdUrl);
      if (Response) {
        setholidayGetByIdData(Response);
        if (Response.dates && Response.dates.length > 0) {
          if (Response.dates.length >= 1) {
            const startDate = Response.dates[0];
            const endDate = Response.dates[Response.dates.length - 1];

            setStartDate(startDate);
            setEndDate(endDate);
            if (Response && Response && Response.dates) {
              setHolidayName(Response.name);
              setValue("holidayName", Response.name);
            }
          } else {
            const leaveDate = Response.data.dates[0];
            setDate(leaveDate);
          }
        } else {
          console.log("No leave data available");
        }
        const { locations, batch_types, batches, batch_slots } = Response;

        if (locations) {
          if (Array.isArray(locations)) {
            const selectedLocationOptions = locationData.filter((option) =>
              locations.some((location) => location.id === option.id)
            );

            setValue("locations", selectedLocationOptions);
          } else {
            console.error("locations is not an array");
          }
        } else {
          console.error("locations is undefined");
        }
        if (batch_types) {
          if (Array.isArray(batch_types)) {
            const selectedLocationOptions = batchTypeData.filter((option) =>
              batch_types.some((batch_types) => batch_types.id === option.id)
            );

            setValue("batchType", selectedLocationOptions);
          } else {
            console.error("locations is not an array");
          }
        } else {
          console.error("batch_types is undefined");
        }
        if (batches) {
          if (Array.isArray(batch_types)) {
            const selectedLocationOptions = batchData.filter((option) =>
              batches.some((batches) => batches.id === option.value)
            );

            setValue("batchNames", selectedLocationOptions);
          } else {
            console.error("locations is not an array");
          }

          // batch_slot
        } else {
          console.error("batch_types is undefined");
        }
        if (Array.isArray(batch_slots)) {
          batch_slots.forEach((slot) => {
            if (slot.name === "Afternoon") {
              setAfternoonChecked(true);
            } else if (slot.name === "Morning") {
              setMorningChecked(true);
            }

            setSlotIdStore((prevState) => [...prevState, slot.id]); // Store IDs in state
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const batchTypeURL = `${process.env.REACT_APP_API_URL}/getBatchtype`;

  const [batchTypeData, setBatchTypeData] = useState([]);

  const locationURL = `${process.env.REACT_APP_API_URL}/getlocation?q=&limit=&page=&name=&sortBy=name&sortOrder=DESC`;
  const [locationData, setlocationData] = useState([]);

  const getLocationData = async () => {
    const Response = await apiService.get(locationURL);
    try {
      const mappingResponse = Response.map((response) => {
        return {
          label: response.name,
          value: response.name,
          id: response.id,
        };
      });
      setlocationData(mappingResponse);
    } catch (error) {
      return error;
    }
  };
  const slotDataUrl = `${process.env.REACT_APP_API_URL}/getBatchslot?q=&limit=&page=&name=&sortBy=name&sortOrder=DESC`;
  const [slotData, setSlotData] = useState([]);

  const getSlotData = async () => {
    const response = await apiService.get(slotDataUrl);
    try {
      setSlotData(response);
    } catch (error) {
      return error;
    }
  };
  const getBatchTypeData = async () => {
    const Response = await apiService.get(batchTypeURL);
    try {
      const mappingResponse = Response.map((response) => {
        return {
          label: response.name,
          value: response.name,
          id: response.id,
        };
      });
      setBatchTypeData(mappingResponse);
    } catch (error) {
      return error;
    }
  };
  const [batchData, setBatchData] = useState([]);

  const getBatchURL = `${
    process.env.REACT_APP_API_URL
  }/getbatchdata?q=&limit=&page=&sortBy=updated_at&sortOrder=DESC&location_id=${""}`;

  const getBatchData = async () => {
    try {
      const response = await apiService.get(getBatchURL);
      const mappingResponse = response.data.map((batch) => ({
        value: batch.id,
        label: batch.batch_code,
        locations: batch.locations.map((location) => ({
          id: location.id,
          name: location.name,
          location_code: location.location_code,
        })),
        types: batch.batch_types.map((batch_types) => ({
          id: batch_types.id,
          name: batch_types.name,
        })),
        batch_slots: batch.batch_slots.map((batch_slots) => ({
          id: batch_slots.id,
          slot: batch_slots.slot,
        })),
        // faculties: batch.faculties,
      }));
      setBatchData(mappingResponse);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };
  const getfetchData = useRef(true);

  const handleOnChangeBatchType = (selectedOption) => {
    setValue("batchNames", "");
    // if (HolidayGetById) {
    //   setSlotIdStore("");
    //   setValue("batchNames", "");
    // }
    const selectedLabels = selectedOption?.map((option) =>
      option.label.toLowerCase()
    );

    setValue("batchType", selectedOption);
    if (selectedOption?.lenght === 0) {
      setBatchTypeAccordingFilteredBatches("");
    }
    const dataToFilter =
      locationfilteredBatches.length > 0 ? locationfilteredBatches : batchData;

    const filteredBatchesTypeAccording = dataToFilter?.filter((batch) => {
      const batchTypeNames = batch?.types.map((types) =>
        types.name.toLowerCase()
      );

      return selectedLabels?.some((label) => batchTypeNames.includes(label));
    });

    setBatchTypeAccordingFilteredBatches(filteredBatchesTypeAccording);
  };
  const [
    BatchTypeAccordingFilteredBatches,
    setBatchTypeAccordingFilteredBatches,
  ] = useState([]);

  const handleOnChangeLocation = (selectedOption) => {
    setValue("batchNames", "");

    const selectedLabels = selectedOption?.map((option) =>
      option.label.toLowerCase()
    );

    setValue("locations", selectedOption);
    setBatchTypeAccordingFilteredBatches("");
    setValue("batchType", "");
    // if (HolidayGetById) {
    //   setSlotIdStore("");
    //   setValue("batchNames", "");
    // }
    if (selectedOption?.length === 0) {
      setValue("batchType", "");

      setLocationfilteredBatches(batchData);
    }

    const dataToFilter =
      BatchTypeAccordingFilteredBatches.length > 0
        ? BatchTypeAccordingFilteredBatches
        : batchData;

    const filteredBatches = dataToFilter?.filter((batch) => {
      const batchLocationNames = batch.locations.map((location) =>
        location.name.toLowerCase()
      );

      return selectedLabels?.some((label) =>
        batchLocationNames.includes(label)
      );
    });

    setLocationfilteredBatches(filteredBatches);
  };
  const [locationfilteredBatches, setLocationfilteredBatches] = useState([]);
  useEffect(() => {
    // Determine the data source
    const dataToFilter =
      BatchTypeAccordingFilteredBatches?.length > 0
        ? BatchTypeAccordingFilteredBatches
        : locationfilteredBatches?.length > 0
        ? locationfilteredBatches
        : batchData;

    // Filter based on morningChecked and afternoonChecked
    if (morningChecked && afternoonChecked) {
      // When both morning and afternoon are checked
      const afternoonBatches = dataToFilter.filter((batch) =>
        batch.batch_slots?.some((slot) => slot.slot === "afternoon")
      );
      const morningBatches = dataToFilter.filter((batch) =>
        batch.batch_slots?.some((slot) => slot.slot === "morning")
      );

      // Combine both morning and afternoon batches and remove duplicates
      const filteredBatches = [
        ...new Set([...morningBatches, ...afternoonBatches]),
      ];
      setValue("batchNames", filteredBatches);
    } else if (morningChecked) {
      // When only morning is checked
      const morningBatches = dataToFilter.filter((batch) =>
        batch.batch_slots?.some((slot) => slot.slot === "morning")
      );
      setValue("batchNames", morningBatches);
    } else if (afternoonChecked) {
      // When only afternoon is checked
      const afternoonBatches = dataToFilter.filter((batch) =>
        batch.batch_slots?.some((slot) => slot.slot === "afternoon")
      );
      setValue("batchNames", afternoonBatches);
    } else {
      // When neither is checked
      setValue("batchNames", []);
    }
  }, [
    morningChecked,
    afternoonChecked,
    locationfilteredBatches,
    batchData,
    BatchTypeAccordingFilteredBatches,
  ]);

  useEffect(() => {
    holidayApiData();

    getfetchData.current = false;
  }, [
    debouncedTerm,
    currentPage,
    sortConfig,
    selectedLocation,
    limit,
    HolidayGetById,
  ]);

  useEffect(() => {
    getBatchTypeData();
    getLocationData();
    getBatchData();
    getSlotData();
  }, []);
  useEffect(() => {
    if (isPopupOpen === true) {
      fetchHolidayGetByData();
    }
  }, [isPopupOpen, HolidayGetById]);
  useEffect(() => {
    if (HolidayGetById.length === 0) {
      if (Array.isArray(HolidayGetById) && HolidayGetById.length === 0) {
        setPopUpHeading("Create Holiday");
        setPopUpButton(false);
      }
    } else {
      setPopUpHeading("Update Holiday");
      setPopUpButton(true);
    }
  }, [HolidayGetById, popUpHeading]);

  const [holidayGetByIdData, setholidayGetByIdData] = useState([]);

  const formatDateToString = (dateString) => {
    const dateObject = new Date(dateString);

    if (!isNaN(dateObject.getTime())) {
      const formattedDateString = dateObject.toISOString().split("T")[0];
      return formattedDateString;
    } else {
      return "";
    }
  };

  const formattedDate = formatDateToString(date);
  const formattedStartDate = formatDateToString(startDate);
  const formattedEndDate = formatDateToString(endDate);
  useEffect(() => {
    if (HolidayGetById) {
      if (formattedDate) {
        const dateStrings = [formattedDate, formattedDate];
        const dateObjects = dateStrings.map(
          (dateString) => new Date(dateString)
        );
        console.log("dateObjects", <dateObjects></dateObjects>);
        setValue("dateRange", dateObjects);
      } else if (formattedStartDate && formattedEndDate) {
        const dateStrings = [formattedStartDate, formattedEndDate];
        const dateObjects = dateStrings.map(
          (dateString) => new Date(dateString)
        );
        setValue("dateRange", dateObjects);
      } else {
        console.error(" formattedEndDate is empty or invalid.");
      }
    }
  }, [
    formattedDate,
    formattedStartDate,
    formattedEndDate,
    holidayGetByIdData,
    HolidayGetById,
  ]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const capitalizedInput =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);

    setHolidayName(capitalizedInput);
    setValue("HolidayName", capitalizedInput);
  };

  function formatDateddmmyy(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}-${month}-${year}`;
  }
  const onSubmit = async (data) => {
    const formattedDateRange = data.dateRange.map((date) =>
      moment(date).format("YYYY-MM-DD")
    );
    const locationIds = Array.isArray(data.locations)
      ? data.locations.map((item) => item.id)
      : [];
    const batchTypeIds = Array.isArray(data.batchType)
      ? data.batchType.map((item) => item.id)
      : [];

    const batchIds = Array.isArray(data.batchNames)
      ? data.batchNames.map((item) => item.value || "")
      : [];

    const postData = {
      starting_date: formattedDateRange[0],
      ending_date: formattedDateRange[1],
      name: data.holidayName,
      location_id: locationIds,
      batch_type_id: batchTypeIds,
      batch_id: batchIds,
      batch_slot_id: slotIdStore,
    };

    if (HolidayGetById.length === 0) {
      try {
        const response = await apiService
          .post(`${process.env.REACT_APP_API_URL}/holidays`, postData)
          .then(() => {
            setValue("holidayName", []);
            setHolidayGetById("");
            setIsPopupOpen(false);
            setValue("locations", "");
            setValue("dateRange", "");
            setHolidayName("");
            setValue("batchNames", []);

            toast.success("Record Successfully Created.. ", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
            holidayApiData();
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
          });
      } catch (error) {
        console.error("Error:", error.message);
      }
    } else {
      try {
        const response = await apiService
          .put(
            `${process.env.REACT_APP_API_URL}/holidays/${HolidayGetById}`,
            postData
          )

          .then(() => {
            setHolidayGetById("");
            setValue("locations", "");
            setHolidayName("");
            setValue("dateRange", "");

            setIsPopupOpen(false);
            toast.success("Record Successfully Updated.. ", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
            holidayApiData();
          })
          .catch((err) => {
            toast.error(err, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                marginBottom: "4vw",
                fontSize: "1.2em",
                width: "400px",
                padding: "10px",
              },
            });
          });
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };
  const accordionItems = [
    {
      title: "Holidays",
      content: (
        <>
          <div className="grid grid-cols-12 m-1">
            <div className="md:col-span-11 sm:col-span-6 w-full">
              <input
                value={searchTerm}
                onChange={handleChange}
                defaultValue={Dates}
                style={{ width: "35vw" }}
                className="bg-white border border-gray-500 m-1 py-3 px-4 focus:outline-none mt-3 ml-5"
                placeholder="Search....."
              />
            </div>

            <div className="md:col-span-1 pl-5 relative">
              <button
                className="bg-white mt-3 hover:bg-gray-100 text-gray-800 font-serif text-2xl font-bold py-1 px-3 border border-gray-400 shadow"
                onClick={togglePopup}
              >
                +
              </button>
            </div>
          </div>

          <section className="ml-6 h-full mb-8 font-serif bg-white  mr-4 ">
            <div className="acpageHeight">
              <div className="min-w-full inline-block align-middle">
                <div className="">
                  <table className="min-w-full divide-y divide-gray-400 ">
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr className=" bg-primaryColour text-white">
                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                          onClick={() => handleSort("dates")}
                        >
                          Date{" "}
                          {sortConfig.key === "dates" ? (
                            sortConfig.direction === "asc" ? (
                              <span>▲</span>
                            ) : (
                              <span>▼</span>
                            )
                          ) : (
                            <span>▲</span>
                          )}
                        </th>
                        <th className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer">
                          Name
                        </th>
                        <th className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer">
                          Locations
                        </th>
                        <th className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer">
                          Batch Types
                        </th>
                        <th className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer">
                          Batches
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3  text-base font-bold text-primary  font-serif cursor-pointer"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-400 dark:divide-gray-700">
                      {Dates.length === 0 ? (
                        <tr>
                          <td
                            colSpan="9"
                            className="text-center py-4 text-gray-800 font-serif"
                          >
                            No Time available
                          </td>
                        </tr>
                      ) : (
                        Dates.map((item, i) => {
                          const displayedBatches = item.batches.slice(0, 2);
                          const remainingCount = item.batches.length - 2;
                          return (
                            <tr className="hover:bg-gray-100" key={i}>
                              <td className="px-8 py-3 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
                                {formatDateddmmyy(item.dates[0])} -{" "}
                                {formatDateddmmyy(
                                  item.dates[item.dates.length - 1]
                                )}
                              </td>
                              <td className="px-8 py-3 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
                                {item.name ? item.name : ""}
                              </td>

                              <td className="px-8 py-3 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
                                {item.locations.length > 0 ? (
                                  item.locations.map((location, index) => (
                                    <p key={index}>
                                      {location.name ? location.name : "All"}
                                    </p>
                                  ))
                                ) : (
                                  <p>All</p>
                                )}
                              </td>
                              <td className="px-8 py-3 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
                                {item.batch_types.length > 0 ? (
                                  item.batch_types.map((batchtype, index) => (
                                    <p key={index}>
                                      {batchtype.name ? batchtype.name : "All"}
                                    </p>
                                  ))
                                ) : (
                                  <p>All</p>
                                )}
                              </td>
                              <td className="px-8 py-3 whitespace-nowrap text-sm text-gray-800 font-serif text-center">
                                {displayedBatches.length > 0 ? (
                                  displayedBatches.map((batch, index) => (
                                    <p key={index}>
                                      {batch.batch_code
                                        ? batch.batch_code
                                        : "All"}
                                    </p>
                                  ))
                                ) : (
                                  <p>All</p>
                                )}

                                {remainingCount > 0 && (
                                  <p>
                                    + {remainingCount} more batch
                                    {remainingCount > 1 ? "es" : ""}
                                  </p>
                                )}
                              </td>
                              <td
                                className="px-8 py-3 flex whitespace-nowrap text-sm text-gray-800 font-serif text-center"
                                style={{ justifyContent: "center" }}
                              >
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  className="text-center"
                                >
                                  <button
                                    className="button muted-button"
                                    onClick={() => handleEditTime(item.id)}
                                  >
                                    <AiFillEdit className="text-xl mr-3 text-green-700" />
                                  </button>
                                </div>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <button onClick={() => openPopup(item.id)}>
                                    <RiDeleteBin6Line className="text-red-700 text-xl mr-3" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="bg-secondaryColour mt-3" style={{ float: "right" }}>
              <div className="flex">
                <p className=" ">Items per page:</p>

                <div className="relative inline-block">
                  <button
                    className="me-3 mb-3 md:mb-0 text-balck  border       font-medium text-sm px-3 py-1 text-center inline-flex items-center  "
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    type="button"
                    style={{ border: " 2px solid balck" }}
                  >
                    {limit}
                    <svg
                      className={`w-2.5 h-2.5 ms-3 transition-transform transform ${
                        isDropdownOpen ? "rotate-180" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>

                  <div
                    className={`z-10 ${
                      isDropdownOpen ? "" : "hidden"
                    } absolute bottom-0 mb-6 bg-white divide-y divide-gray-100 rounded-lg   w-16 dark:bg-gray-700`}
                  >
                    <ul className="py-2text-base text-gray-700 dark:text-gray-200">
                      <li>
                        <button
                          onClick={() => handleLimitChange(6)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          6
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(10)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          10
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(20)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          20
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleLimitChange(50)}
                          className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          50
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  pageCount={noOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                />
              </div>
            </div>
          </section>
        </>
      ),
    },
  ];


  return (
    <>
      <Popup isOpen={isOpen} onClose={closePopup}>
        <h6>Do you want to delete the Holiday?</h6>
        <div className="button-container">
          <button
            className="text-gray-900 bg-gray-50 border border-gray-300 hover:bg-gray-100 font-medium text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
            onClick={() => setIsOpen(false)}
          >
            No
          </button>
          <button
            className="text-white bg-primaryColour hover:bg-primaryColour-1000 focus:ring-4 focus:ring-green-300 font-medium text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() => handleDeleteTime()}
          >
            Yes
          </button>
        </div>
      </Popup>
      <div className=" ml-6 mr-4 pb-6 font-serif mb-4">
        <div className="mt-6 ml-8 mr-8  bg-secondaryColour">
          <Accordion
            items={accordionItems}
            defaultOpenIndex={0}
            isPopupOpen={setIsPopupOpen}
          />
        </div>
        <div>
          {isPopupOpen && (
            <HolidayPopUp
              morningChecked={morningChecked}
              afternoonChecked={afternoonChecked}
              // handleMorningCheckboxChange={handleMorningCheckboxChange}
              // handleAfternoonCheckboxChange={handleAfternoonCheckboxChange}
              errors={errors}
              HolidayName={HolidayName}
              onSubmit={onSubmit}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              heading={popUpHeading}
              cancelPopup={cancelPopup}
              popUpButton={popUpButton}
              locationData={locationData}
              register={register}
              watch={watch}
              setValue={setValue}
              batchTypeData={batchTypeData}
              batchData={batchData}
              handleOnChangeLocation={handleOnChangeLocation}
              handleOnChangeBatchType={handleOnChangeBatchType}
              locationfilteredBatches={locationfilteredBatches}
              BatchTypeAccordingFilteredBatches={
                BatchTypeAccordingFilteredBatches
              }
              slotData={slotData}
              slotIdStore={slotIdStore}
              handleCheckboxChange={handleCheckboxChange}
              // batchSlotCheck={batchSlotCheck}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HolidayList;
