// import React, { useState } from "react";
// import TimePicker from "rc-time-picker";
// import moment from "moment";
// import "rc-time-picker/assets/index.css";
// import { DateRangePicker } from "rsuite";
// import "rsuite/dist/rsuite.min.css"; // Ensure rsuite CSS is imported
// import './Holiday.css'
// const HolidayPopUp = ({
//   watch,
//     handleInputChange,
//     HolidayName,
//     register,
//     setValue,

//   heading,
//   handleSubmit,
//   onSubmit,
//   handleEndTimeChange,
//   cancelPopup,
//   popUpButton,
//   handleStartTimeChange,
// }) => {
//     const today = new Date();
//   today.setHours(0, 0, 0, 0)
//     const disablePastDates = (date) => {
//         return date < today;
//       };
//   return (
//     <div className="custom-popup absolute bg-white border border-gray-300 shadow-lg p-4 top-0">
//       <h2 className="text-lg font-semibold">{heading}</h2>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <section className="mt-2">
//           <hr />
//           <div className=" justify-between items-center">

//           <DateRangePicker
//                       showOneCalendar
//                       size="md"
//                       style={{width:"20vw"}}
//                       placeholder="Calender"
//                       value={watch("dateRange")}

//                       {...register("dateRange", {
//                         required: "This is required",
//                         validate: (value) =>
//                           (value && value[0] !== null && value[1] !== null) ||
//                           "Please select a date range",
//                       })}
//                       onChange={(selectedOptions) => {
//                         setValue("dateRange", selectedOptions);
//                       }}

//                     //   style={styles}
//                       disabledDate={disablePastDates}
//                     />
//             <div>
//               <input
//                 id="holidayName"
//                 type="text"
//                 style={{ height: "32px", outline: "none", width: "23rem" }}
//                 autoComplete="off"
//                 placeholder=" Holiday Name"

//                 autoFocus
//                 defaultValue={HolidayName}
//                 {...register("holidayName", {
//                   required: "This is required",
//                 })}
//                 onChange={handleInputChange}
//                 className="border border-gray-400 mt-2 mb-2"
//               />
//             </div>

//           </div>
//         </section>
//         <div className=" mt-4">
//           <button
//             className="text-gray-900 close-button bg-gray-50 border border-gray-300 hover:bg-gray-100 font-medium text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
//             onClick={cancelPopup}
//           >
//             Cancel
//           </button>
//           <button
//             type="submit"
//             className="text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2"
//           >
//             {popUpButton ? "Update" : "Create"}
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default HolidayPopUp;
import React, { useState } from "react";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css"; // Ensure rsuite CSS is imported
import "./Holiday.css";
import Select from "react-select";

const HolidayPopUp = ({
  watch,
  handleInputChange,
  HolidayName,
  register,
  setValue,
  errors,
  heading,
  handleSubmit,
  onSubmit,
  handleEndTimeChange,
  cancelPopup,
  popUpButton,
  handleStartTimeChange,
  locationData,
  batchTypeData,
  batchData,
  handleOnChangeLocation,
  handleOnChangeBatchType,
  locationfilteredBatches,
  BatchTypeAccordingFilteredBatches,
  morningChecked,
  afternoonChecked,
  handleMorningCheckboxChange,
  handleCheckboxChange,
  handleAfternoonCheckboxChange,
  slotData,
  batchSlotCheck,
  slotIdStore,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const disablePastDates = (date) => {
    return date < today;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black  bg-opacity-50" >
      <form onSubmit={handleSubmit(onSubmit)} className="relative bg-white w-full max-w-lg p-6 rounded shadow-xl">
      <h2 className="text-lg font-semibold">{heading}</h2>
        <section className="mt-2">
          <hr />
          <div className="justify-between items-center">
            <div>
              <label className="text-sm mb-1 mt-2 text-primary">
                <span className=" text-red-600 font-bold text-lg ">*</span>
                Holiday Name{" "}
              </label>
              <br />
              <input
                id="holidayName"
                type="text"
                style={{ outline: "none", width: "24vw" }}
                autoComplete="off"
                placeholder="Holiday Name"
                autoFocus
                defaultValue={HolidayName}
                value={HolidayName}
                {...register("holidayName", {
                  required: "This is required",
                })}
                onChange={handleInputChange}
                className="border border-gray-400 mt-1 mb-2 rounded-lg"
              />
              <div>
                {errors.holidayName && (
                  <span className="validationcolor">
                    {errors.holidayName.message}
                    {" *"}
                  </span>
                )}
              </div>
            </div>

            <div>
              <label className="text-sm mb-1 mt-2 text-primary">
                <span className=" text-red-600 font-bold text-lg ">*</span>
                Dates{" "}
              </label>
              <br />

              <DateRangePicker
                className="mr-2 custom-date-range-picker rounded-lg"
                showOneCalendar
                style={{ width: "24vw" }}
                placeholder="Calendar"
                value={watch("dateRange")}
                {...register("dateRange", {
                  required: "This is required",
                  validate: (value) =>
                    (value && value[0] !== null && value[1] !== null) ||
                    "Please select a date range",
                })}
                isoWeek
                onChange={(selectedOptions) => {
                  setValue("dateRange", selectedOptions);
                }}
                format="dd-MM-yyyy"
                disabledDate={disablePastDates}
              />
              <div>
                {errors.dateRange && (
                  <span className="validationcolor">
                    {errors.dateRange.message}
                    {" *"}
                  </span>
                )}
              </div>
            </div>
            <section>
              <div
                className="col-span-6 mt-2"
                style={{ width: "24vw", outline: "none" }}
              >
                <label className="text-sm mb-1 mt-3 text-primary">
                  Location:
                </label>
                <Select
                  placeholder="All"
                  isMulti
                  options={locationData}
                  value={watch("locations")}
                  {...register("locations")}
                  onChange={handleOnChangeLocation}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 9,
                      // border: "none",
                      outline: "none",
                      boxShadow: "none",
                      // height: "30px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
                <div></div>
              </div>
              <div className="  col-span-6  mt-2" style={{ width: "24vw" }}>
                <label className="text-sm mb-1 mt-2 text-primary">
                  Batch Type:
                </label>
                <Select
                  placeholder="All"
                  isMulti
                  options={batchTypeData}
                  value={watch("batchType")}
                  style={{ outline: "none", border: "none" }}
                  // {...register("batchType", { required: true })}

                  {...register("batchType")}
                  onChange={handleOnChangeBatchType}
                  className="custom-select"
                  classNamePrefix="custom-select"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 9,
                      outline: "none",
                      boxShadow: "none",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>
              {/* <div className="col-span-6 mt-2" style={{ width: "24vw" }}>
              <label className="text-sm mb-1 mt-2 text-primary">
                Time of Day:
              </label>
              <div className="flex items-center mt-1">
                <input
                  type="checkbox"
                  id="morning"
                  {...register("morning")}
                  className="mr-2"
                />
                <label htmlFor="morning" className="mr-4">
                  Morning
                </label>
                <input
                  type="checkbox"
                  id="afternoon"
                  {...register("afternoon")}
                  className="mr-2"
                />
                <label htmlFor="afternoon">Afternoon</label>
              </div>
            </div> */}

              <div className="flex items-center mt-1">
                {slotData.map((item) => (
                  <>
                    <input
                      type="checkbox"
                      //  value="sunday-to-monday"
                      //  checked={batchSlotCheck}
                      //  {...register("morning")}
                      checked={slotIdStore.includes(item.id)} // Set checked state based on slotIdStore
                      onClick={() => handleCheckboxChange(item)} // Pass a function reference that calls handleCheckboxChange
                      //  onChange={handleCheckboxChange(item.id)}
                      // disabled
                      className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                    />
                    <span className="ml-2">{item.name}</span>
                  </>
                ))}
                {/* <input
                    type="checkbox"
                    value="sunday-to-monday"
                    checked={morningChecked}
                    {...register("morning")}

                    onChange={handleMorningCheckboxChange}
                    // disabled
                    className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                  />
                  <span className="ml-2">Morning</span>

                  <input
                    type="checkbox"
                    value="sunday-to-monday"
                    checked={afternoonChecked}
                    {...register("afternoon")}

                    onChange={handleAfternoonCheckboxChange}
                    className="form-checkbox-square ml-4 h-4 w-4 text-primary border border-gray-400 rounded-none"
                  />
                  <span className="ml-2">Afternoon</span> */}
              </div>
              <div className="  col-span-6  mt-2" style={{ width: "24vw" }}>
                <label className="text-sm mb-1 mt-2 text-primary">
                  Batches:
                </label>
                <Select
                  placeholder="All"
                  isMulti
                  options={
                    BatchTypeAccordingFilteredBatches?.length > 0
                      ? BatchTypeAccordingFilteredBatches
                      : locationfilteredBatches?.length > 0
                      ? locationfilteredBatches
                      : batchData
                  }
                  value={watch("batchNames")}
                  // style={{ outline: "none", border: "none" ,}}
                  {...register("batchNames")}
                  menuPlacement="top"
                  onChange={(selectedOptions) => {
                    setValue("batchNames", selectedOptions);
                  }}
                  className="custom-select "
                  classNamePrefix="custom-select"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 9,
                      // border: "none",
                      outline: "none",
                      boxShadow: "none",
                      height: "6vw",
                      overflow: "auto",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>
            </section>
          </div>
        </section>
        <div className="mt-4">
        <button
            type="submit"
            className="text-white bg-primaryColour hover:bg-primaryColour-1000 font-medium text-sm px-5 py-2.5 me-2"
          >
            {popUpButton ? "Update" : "Create"}
          </button>
          <button
            className="text-gray-900 close-button bg-gray-50 border border-gray-300 hover:bg-gray-100 font-medium text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
            onClick={cancelPopup}
          >
            Cancel
          </button>

        </div>
      </form>
    </div>
  );
};

export default HolidayPopUp;
