import React, { useContext, useEffect } from "react";
import { DateRangePicker } from "rsuite";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import LocationContext from "../../../context/LocationContext";
import "./Calender.css";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const predefinedRanges = [
  {
    label: "This week",
    value: [startOfWeek(new Date(), { weekStartsOn: 1 }), endOfWeek(new Date(), { weekStartsOn: 1})],
    placement: "left",
  },
  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      const lastMonday = addDays(startOfWeek(start, { weekStartsOn: 1 }), -7);
      const lastSunday = endOfWeek(lastMonday, { weekStartsOn: 1 });
      return [lastMonday, lastSunday];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      const nextMonday = addDays(startOfWeek(start, { weekStartsOn: 1 }), 7);
      const nextSunday = endOfWeek(nextMonday, { weekStartsOn: 1 });
      return [nextMonday, nextSunday];
    },
    appearance: "default",
  },
];

const Calender = () => {
  const { selectedDate, setSelectedDate } = useContext(LocationContext);

  const [searchParams, setSearchParams] = useSearchParams();
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const handleDateChange = (selectedDates) => {
    if (selectedDates && selectedDates.length >= 2) {
      setSearchParams({
        ...Object.fromEntries(searchParams), // Spread the existing search params
        from: moment(selectedDates[0]).format("YYYY-MM-DD"),
        to: moment(selectedDates[1]).format("YYYY-MM-DD")
      });
      setSelectedDate(selectedDates);
    } else {
      // Handle the case where selectedDates is null or does not have at least two elements
      console.error("Invalid selectedDates:", selectedDates);
    }
  };
  useEffect(() => {
    if (!selectedDate[0]) {
      const from = searchParams.get('from');
      const to = searchParams.get('to');
      if(from && to){
        console.log(moment(from).toDate());
        setSelectedDate([moment(from).toDate(), moment(to).toDate()]);
      } else {
        const today = new Date();
        const currentDayOfWeek = today.getDay();
        const daysToSubtract = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - daysToSubtract);
        const daysToAdd = currentDayOfWeek === 0 ? 0 : 7 - currentDayOfWeek;
        const endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + daysToAdd);
        setSelectedDate([startOfWeek, endOfWeek]);
      }
    }
  }, [setSelectedDate, selectedDate, searchParams, setSearchParams]);

  return (
    <div>
      <DateRangePicker
        value={selectedDate}
        onChange={handleDateChange}
        ranges={predefinedRanges}
        showOneCalendar
        placeholder="Calendar"
        weekStart="1"
        format="dd-MM-yyyy"
        className="leading-2 border border-black rounded shadow-lg	"
        style={{ width: "14rem" }}
      />
    </div>
  );
};

export default Calender;
