import { ToastContainer, toast } from "react-toastify";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { useForm } from "react-hook-form";
import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import "../BatchCreate/BatchCreate.css";
import { FaPlus } from "react-icons/fa";

import { RiDeleteBin6Line } from "react-icons/ri";

import { Link, useNavigate } from "react-router-dom";
import { apiService } from "../../../Services/Services";
import LocationContext from "../../../context/LocationContext";

const locationURL = `${process.env.REACT_APP_API_URL}/getlocation?q=&limit=&page=&name=&sortBy=name&sortOrder=DESC`;
const getSlotTime = `${process.env.REACT_APP_API_URL}/getSlotTime`;
const batchTypeURL = `${process.env.REACT_APP_API_URL}/getBatchtype`;
const batchStreamURL = `${process.env.REACT_APP_API_URL}/getBatchStream`;

const BatchCreate = () => {
  const [fixedChecked, setFixedChecked] = useState(true);
  const [specialBatchChecked, setSpecialBatchChecked] = useState(false);
  const [flexibleChecked, setFlexibleChecked] = useState(false);

  const handleSpecialBatchChecked = () => {
    setSpecialBatchChecked(!specialBatchChecked);
  };
  const handleFixedCheckboxChange = () => {
    setFixedChecked(!fixedChecked);
    setFlexibleChecked(false);
    setValue("dayCount", {});
  };
  const handleFlexibleCheckboxChange = () => {
    setFlexibleChecked(!flexibleChecked);
    setFixedChecked(false);

    setValue("selectdays", {});
  };
  const { selectedLocation } = useContext(LocationContext);

  const [startTime, setStartTime] = useState({
    hour: "12",
    minute: "00",
    meridiem: "AM",
  });
  const [endTime, setEndTime] = useState({
    hour: "12",
    minute: "00",
    meridiem: "AM",
  });
  const [timeRanges, setTimeRanges] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTimeChange = (time, setTime) => {
    setTime(time);
  };
  // const [slotTimeError, setslotTimeError] = useState("");
  const handleAddTimeRange = () => {
    if (
      !startTime.hour ||
      !startTime.minute ||
      !startTime.meridiem ||
      !endTime.hour ||
      !endTime.minute ||
      !endTime.meridiem
    ) {
      setErrorMessage("Please select both start and end time.");
      return;
    }

    const startHour = parseInt(startTime.hour, 10);
    const startMinute = parseInt(startTime.minute, 10);
    const startMeridiem = startTime.meridiem;

    const endHour = parseInt(endTime.hour, 10);
    const endMinute = parseInt(endTime.minute, 10);
    const endMeridiem = endTime.meridiem;

    let startTotalMinutes =
      ((startHour % 12) + (startMeridiem === "PM" ? 12 : 0)) * 60 + startMinute;

    // Convert end time to total minutes from midnight
    let endTotalMinutes =
      ((endHour % 12) + (endMeridiem === "PM" ? 12 : 0)) * 60 + endMinute;

    // Adjust end time if it's before the start time (i.e., it's on the next day)
    if (endTotalMinutes < startTotalMinutes) {
      endTotalMinutes += 24 * 60; // Add 24 hours worth of minutes
    }

    if (startTotalMinutes < endTotalMinutes) {
      const newTimeRangeString = `${startTime.hour}:${startTime.minute} ${startTime.meridiem} - ${endTime.hour}:${endTime.minute} ${endTime.meridiem}`;

      const isOverlap = timeRanges.some((existingRange) => {
        const [existingStart, existingEnd] = existingRange.split(" - ");
        const [existingStartHour, existingStartMinute, existingStartMeridiem] =
          existingStart.split(/:|\s/);
        const [existingEndHour, existingEndMinute, existingEndMeridiem] =
          existingEnd.split(/:|\s/);

        // Convert existing time range to minutes since midnight
        let existingStartTotalMinutes =
          ((parseInt(existingStartHour, 10) % 12) +
            (existingStartMeridiem === "PM" ? 12 : 0)) *
            60 +
          parseInt(existingStartMinute, 10);
        let existingEndTotalMinutes =
          ((parseInt(existingEndHour, 10) % 12) +
            (existingEndMeridiem === "PM" ? 12 : 0)) *
            60 +
          parseInt(existingEndMinute, 10);

        // Adjust for midnight (12 AM)
        if (
          parseInt(existingStartHour, 10) === 12 &&
          existingStartMeridiem === "AM"
        ) {
          existingStartTotalMinutes = 0;
        }
        if (
          parseInt(existingEndHour, 10) === 12 &&
          existingEndMeridiem === "AM"
        ) {
          existingEndTotalMinutes = 0;
        }

        return (
          startTotalMinutes < existingEndTotalMinutes &&
          endTotalMinutes > existingStartTotalMinutes
        );
      });

      if (isOverlap) {
        setErrorMessage(
          "New time range overlaps with an existing range. Please select a different time range."
        );
      } else {
        setTimeRanges([...timeRanges, newTimeRangeString]);
        setErrorMessage("");
        // setslotTimeError("");
      }
    } else if (startTotalMinutes === endTotalMinutes) {
      setErrorMessage("Start time should not be equal to end time.");
    } else {
      setErrorMessage("Start time should be less than end time.");
    }
  };

  const [selectedLocationId, setSelectedLocationId] = useState("");

  const [locationData, setlocationData] = useState([]);
  const [Times, setTimes] = useState([]);
  const [batchTypeData, setBatchTypeData] = useState([]);
  const [batchStreamData, setBatchStreamData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [batchCode, setBatchCode] = useState("");
  const [facultyData, setFacultyData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  console.log("Timess", Times);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();

  const options = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  const handleOptionChange = (value) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(value)) {
        return prevSelectedOptions.filter((option) => option !== value);
      } else {
        return [...prevSelectedOptions, value];
      }
    });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase();

    setBatchCode(inputValue);
  };

  const getLocationData = async () => {
    const Response = await apiService.get(locationURL);
    try {
      const mappingResponse = Response.map((response) => {
        return {
          label: response.name,
          value: response.name,
          id: response.id,
        };
      });
      setlocationData(mappingResponse);
    } catch (error) {
      return error;
    }
  };

  const getSlotTimeData = async () => {
    const Response = await apiService.get(getSlotTime);
    try {
      const mappingResponse = Response.map((response) => {
        return {
          label: response.slot_time,
          value: response.slot_time,
          id: response.id,
        };
      });
      setTimes(mappingResponse);
    } catch (error) {
      return error;
    }
  };
  const getBatchTypeData = async () => {
    const Response = await apiService.get(batchTypeURL);
    try {
      const mappingResponse = Response.map((response) => {
        return {
          label: response.name,
          value: response.name,
          id: response.id,
        };
      });
      setBatchTypeData(mappingResponse);
    } catch (error) {
      return error;
    }
  };

  const [selectedStream, setSelectedStream] = useState({
    value: "",
    label: "",
    stream_code: "",
  });

  const getFacutyData = async () => {
    let streamCode = "";
    let url;

    if (
      selectedStream &&
      (selectedStream.stream_code === "J" || selectedStream.stream_code === "M")
    ) {
      streamCode = "j/m";

      url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&sortBy=updated_at&sortOrder=DESC&location_id=${selectedLocationId}&stream_code=${streamCode}`;
    } else if (selectedStream && selectedStream.stream_code === "F") {
      streamCode = "f";

      url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&location_id=${selectedLocationId}&sortBy=updated_at&sortOrder=ASC&stream_code=${streamCode}`;
    }

    try {
      const Response = await apiService.get(url);
      const mappingResponse = Response.map((response) => {
        return {
          label: [
            response.faculty_code +
              " - " +
              response.first_name +
              " " +
              response.last_name +
              response.subject
                .map((subject) => ` (${subject.subject_name})`)
                .join(", "),
          ],
          value: response.id,
          // value: response.faculty_code,

          id: response.id,
        };
      });
      setFacultyData(mappingResponse);
    } catch (error) {
      return error;
    }
  };

  const getBatchStreamData = async () => {
    try {
      const Response = await apiService.get(batchStreamURL);
      const mappingResponse = Response.map((response) => {
        const subjects = response.subject || [];

        const mappedSubjects = subjects.map((subject) => {
          return {
            label: subject.subject_name,
            value: subject.subject_code,
            id: subject.id,
          };
        });

        return {
          id: response.id,
          label: response.stream_names,
          value: response.stream_names,
          stream_code: response.stream_code,
          subjects: mappedSubjects,
        };
      });

      setBatchStreamData(mappingResponse);
    } catch (error) {
      console.error("Error mapping subjects:", error);
    }
  };

  useEffect(() => {
    const defaultOption = locationData.find(
      (option) => option.id === selectedLocation
    );
    if (defaultOption) {
      setValue("locations", defaultOption);
      setSelectedLocationId(defaultOption.id);
    }
  }, [locationData, selectedLocation]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedLabel = event.target.selectedOptions[0].textContent;

    const selectedStreamId = event.target.value;
    const selectedStream = batchStreamData.find(
      (option) => option.id === selectedStreamId
    );

    if (selectedStream) {
      const selectedOptions = {
        value: selectedValue,
        label: selectedLabel,
        stream_code: selectedStream["stream_code"],
      };
      setSelectedStream(selectedOptions);
      setValue("faculyData", []);
      // Extracting subjects from the selected stream with IDs
      const subjects = selectedStream.subjects.map((subject) => ({
        id: subject.id,
        label: subject.label,
      }));
      setSelectedSubjects(subjects);
    }
  };

  // ----------------------------------------------------------------------------------------

  const [sessionValues, setSessionValues] = useState({});

  const [subjectIds, setSubjectIds] = useState({});
  const [additionalRows, setAdditionalRows] = useState([]);
  const handleSessionChange = (subjectId, value, field) => {
    setSessionValues((prevSessions) => {
      const updatedSessions = { ...prevSessions };
      if (subjectId) {
        updatedSessions[subjectId] = updatedSessions[subjectId] || {};
        updatedSessions[subjectId][field] = value;
      }
      return updatedSessions;
    });
  };

  console.log("sessionValues----------------------", sessionValues);
  const handleSubjectChange = (rowId, selectedOptions) => {
    console.log("selectedOptions===========", selectedOptions);

    const newSubjectId = selectedOptions.value;
    const previousSubjectId = subjectIds[rowId];

    setSubjectIds((prev) => {
      const updated = { ...prev, [rowId]: newSubjectId };
      if (previousSubjectId && sessionValues[previousSubjectId]) {
        const updatedSessions = { ...sessionValues };
        updatedSessions[newSubjectId] = updatedSessions[previousSubjectId];
        delete updatedSessions[previousSubjectId];
        setSessionValues(updatedSessions);
      }
      return updated;
    });

    setValue(`ExtaraSubjectName${newSubjectId}`, selectedOptions);
  };

  console.log("Subjecty All", selectedSubjects);
  const addRow = () => {
    const newRowId = Date.now();
    setAdditionalRows((prev) => [
      ...prev,
      { id: newRowId, lecturePerWeek: "", totalSubject: "" },
    ]);
    setSubjectIds((prev) => ({ ...prev, [newRowId]: null }));
  };

  const removeRow = (rowId) => {
    setAdditionalRows((prev) => prev.filter((row) => row.id !== rowId));

    // Remove related session values and subject IDs
    setSessionValues((prevSessions) => {
      const updatedSessions = { ...prevSessions };
      const subjectId = subjectIds[rowId];
      if (subjectId) {
        delete updatedSessions[subjectId];
      }
      return updatedSessions;
    });

    setSubjectIds((prev) => {
      const updated = { ...prev };
      delete updated[rowId];
      return updated;
    });
  };
  const formatSessionValues = (sessionValues) => {
    return Object.keys(sessionValues).reduce((acc, subjectId) => {
      const { lecturePerWeek, totalSubject } = sessionValues[subjectId];
      acc[subjectId] = {
        subject_id: subjectId || "",
        session_per_weeks: parseInt(lecturePerWeek, 10) || 0,
        total_sessions_of_year: parseInt(totalSubject, 10) || 0,
      };
      return acc;
    }, {});
  };
  const formattedSessionValues = formatSessionValues(sessionValues);
  console.log("formattedSessionValues", formattedSessionValues);
  // --------------------------------------------------------------------------------------

  const onSubmit = async (data) => {
    const morning = [];
    const afternoon = [];
    const timeData = [];

    selectedTimes.forEach((timeInterval) => {
      const [startTime] = timeInterval.split("-");
      const [hours] = startTime.split(":");
      const startHour = parseInt(hours, 10);

      if (startHour < 12) {
        morning.push(timeInterval);
      } else {
        afternoon.push(timeInterval);
      }
    });

    // return { morning, afternoon };

    if (morning.length > 0) {
      timeData.push({
        slot: "morning",
        slot_times: morning,
      });
    }

    if (afternoon.length > 0) {
      timeData.push({
        slot: "afternoon",
        slot_times: afternoon,
      });
    }

    console.log("timeData", timeData);

    const facultyIds = data.faculyData?.map((item) => item.id) || [];

    // const SubjectSessionData = formattedSessionValues.map((subject, index) => ({
    //   // subject_id: subject.id,
    //   // session_per_weeks: parseInt(sessionValues[subject.id] || 0, 10), // parse to integer
    //   subject_id: subject.subject_id,
    //   session_per_weeks: subject.session_per_weeks,

    //   total_sessions_of_year:subject.total_sessions_of_year

    // }));

    // console.log("SubjectSessionData--------------", SubjectSessionData);
    // Convert object to array of [key, value] pairs
    const SubjectSessionData = Object.entries(formattedSessionValues).map(
      ([subject_id, details]) => ({
        subject_id: subject_id,
        session_per_weeks: details.session_per_weeks,
        total_sessions_of_year: details.total_sessions_of_year,
      })
    );

    // Now you can use map on this array
    console.log("SubjectSessionData--------------", SubjectSessionData);

    // console.log("SubjectSessionData", SubjectSessionData)

    const postData = {
      location_id: data.locations.id,
      batch_stream_id: data.batchStream,
      batch_type_id: Array.isArray(data.batchType)
        ? data.batchType.map((item) => item.id)
        : [data.batchType.id],
      ...(Object.keys(data.selectdays).length === 0
        ? { selected_days_count: parseInt(data.dayCount) }
        : { selected_days: data.selectdays }),
      batch_code: data.batchcode,
      starting_date: data.startingDate,
      duration: data.duration,
      duration_type: data.durationtype,
      faculty_id: facultyIds,
      slot: timeData,
      subject_sessions: SubjectSessionData,
      special_batch: specialBatchChecked,
    };

    console.log("postData", postData);
    apiService
      .post(`${process.env.REACT_APP_API_URL}/createbatch`, postData)
      .then(() => {
        toast.success("Record Successfully Created.. ", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginBottom: "4vw",
            fontSize: "1.2em",
            width: "400px",
            padding: "10px",
          },
        });

        // Uncomment the lines below to navigate to the batch list page after 3 seconds
        setTimeout(() => {
          navigate("/batchlist");
        }, 3000);
      })
      .catch((err) => {
        const mailErrorMessage =
          err.response.data.errors && err.response.data.errors.batch_code
            ? err.response.data.errors.batch_code[0]
            : "Unknown error";

        toast.error(mailErrorMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginBottom: "4vw",
            fontSize: "1.2em",
            width: "400px",
            padding: "10px",
          },
        });
      });
  };

  useEffect(() => {
    getLocationData();
    getSlotTimeData();
    getBatchStreamData();
    getBatchTypeData();
  }, []);

  useEffect(() => {
    getFacutyData();
  }, [selectedLocation, selectedStream, selectedLocationId]);

  useEffect(() => {
    if (fixedChecked || flexibleChecked) {
      setSelectedOptions([]);
    }
  }, [fixedChecked, flexibleChecked]);

  const [selectedTimes, setSelectedTimes] = useState([]);

  // const handleCardClick = (value) => {
  //   setSelectedTimes((prevSelected) => {
  //     if (prevSelected.includes(value)) {
  //       return prevSelected.filter((timeValue) => timeValue !== value);
  //     } else {
  //       return [...prevSelected, value];
  //     }
  //   });
  // };
  const handleCardClick = (value) => {
    setSelectedTimes((prevSelected) => {
      const newSelectedTimes = prevSelected.includes(value)
        ? prevSelected.filter((timeValue) => timeValue !== value)
        : [...prevSelected, value];

      setValue("times", newSelectedTimes);
      return newSelectedTimes;
    });
  };

  console.log("selectedTimes", selectedTimes);
  console.log("subjectIds========", subjectIds);
  // -------------------------------------------------
  const [filteredSubjectData, setFilteredSubjectData] = useState([]);

  const fetchSubjectData = async () => {
    try {
      const response = await apiService.get(
        `${process.env.REACT_APP_API_URL}/getSubject?q=&limit=20&page=1&sort_by=updated_at&sort_order=desc`
      );
      console.log("responsesubject", response);
      const options = response.data.map((item) => ({
        value: item.id,
        label: item.subject_name,
      }));
      console.log("options", options);

      console.log("selectedSubjects", selectedSubjects);
      console.log("subjectIds", subjectIds);

      // // Extract IDs from selectedSubjects

      const selectedSubjectIds = selectedSubjects
        .map((subject) => subject.id)
        .filter((id) => id); // Remove null or undefined values
      console.log("selectedSubjectIds", selectedSubjectIds);

      // Extract IDs from subjectIds
      const subjectIdValues = Object.values(subjectIds).filter((id) => id); // Remove null or undefined values
      console.log("subjectIdValues", subjectIdValues);

      // Combine both lists of IDs to be excluded
      const excludedValues = new Set([
        ...selectedSubjectIds,
        ...subjectIdValues,
      ]);
      console.log("excludedValues", excludedValues);

      // Filter out options from subjectData where ID is in excludedValues
      const filteredOptions = options.filter(
        (option) => !excludedValues.has(option.value)
      );
      console.log("filteredOptions", filteredOptions);

      setFilteredSubjectData(filteredOptions);
    } catch (error) {
      console.error("Error fetching subject data:", error);
    }
  };
  console.log("selectedSubjects---------------------------", selectedSubjects);

  // ---------------------------------------------------------------------------------
  useEffect(() => {
    fetchSubjectData();
  }, [subjectIds]);

  // const filterSubjectOptions = () => {
  //   const excludedValues = Object.values(subjectIds);
  //   const filteredOptions = subjectData.filter(option => !excludedValues.includes(option.value));
  //   setFilteredSubjectData(filteredOptions);
  // };
  return (
    <>
      <div className="  overflow-y-auto ">
        <>
          <ToastContainer />
        </>
      </div>
      <div className=" bg-secondaryColour ml-6 mr-4 h-auto pb-6 font-serif mb-4 ">
        <form onSubmit={handleSubmit(onSubmit)} className="cuPageHeight">
          <div className=" mt-1 ">
            <div className="  grid grid-cols-12 ml-6 pt-3  ">
              <div className=" col-span-6 ">
                <label className="text-sm mb-1 mt-3 text-primary">
                  {" "}
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Code:
                </label>
                <div>
                  <input
                    id="batchcode"
                    type="text"
                    className="input"
                    style={{ height: "40px", outline: "none" }}
                    autoComplete="off"
                    {...register("batchcode", {
                      required: "This is required",
                    })}
                    placeholder="Batch Code"
                    autoFocus
                    value={batchCode}
                    onChange={handleInputChange}
                  />
                  <div>
                    {errors.batchcode && (
                      <span className="validationcolor">
                        {errors.batchcode.message}
                        {" *"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className=" col-span-6 "
                style={{ width: "30vw", outline: "none" }}
              >
                <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Location:
                </label>
                <Select
                  options={locationData}
                  value={watch("locations")}
                  {...register("locations", { required: true })}
                  onChange={(selectedOptions) => {
                    setValue("locations", selectedOptions);
                    setSelectedLocationId(selectedOptions.id);
                  }}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      height: "30px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
                <div>
                  {errors.locations && (
                    <p className="error validationcolor">
                      This is required{" *"}{" "}
                    </p>
                  )}
                </div>
              </div>

              <div className="  col-span-6  mt-1" style={{ width: "30vw" }}>
                <label className="text-sm mb-1 mt-2 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Type:
                </label>
                <Select
                  placeholder="Batch Type"
                  options={batchTypeData}
                  style={{ outline: "none", border: "none" }}
                  {...register("batchType", { required: true })}
                  onChange={(selectedOptions) => {
                    setValue("batchType", selectedOptions);
                  }}
                  className="custom-select"
                  classNamePrefix="custom-select"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                      height: "32px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
                <div>
                  {errors.batchType && (
                    <p className="error validationcolor">
                      This is required {" *"}
                    </p>
                  )}
                </div>
              </div>
              <div className=" col-span-6 mt-1 ">
                <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Duration & Duration Type:
                </label>
                <div className="flex">
                  <div className="">
                    <div>
                      <input
                        id="duration"
                        defaultValue="1" // Set default value to 1
                        type="number"
                        style={{
                          height: "40px",
                          width: "16vw",
                          outline: "none",
                          paddingLeft: "8px",
                        }}
                        placeholder="Duration"
                        {...register("duration", {
                          required: "This is required",
                        })}
                        className=" "
                      />
                      <div>
                        {errors.duration && (
                          <span className="validationcolor">
                            {errors.duration.message}
                            {" *"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className=" " style={{ marginLeft: "0.1vw" }}>
                      <select
                        {...register("durationtype", { required: true })}
                        style={{
                          height: "40px",
                          width: "14vw",
                          // outline: "none",
                        }}
                        className=" bg-white  "
                        onChange={(e) => e.target.value}
                        defaultValue="Years" // Set default value to "Years"
                        placeholder="Duration"
                      >
                        <option value="" disabled selected>
                          Select Duration Type
                        </option>
                        selectedTimes
                        <option value="Days">Days</option>
                        <option value="Weeks">Weeks</option>
                        <option value="Months">Months</option>
                        <option value="Years">Years</option>
                      </select>

                      <div>
                        {errors.durationtype &&
                          errors.durationtype.type === "required" && (
                            <span className="validationcolor">
                              This is required{" *"}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-6 mt-1" style={{ width: "30vw" }}>
                <label className="text-base mb-1 text-primary font-serif">
                  <span className="text-red-600 font-bold text-lg">*</span>
                  Stream:
                </label>
                <div>
                  <select
                    {...register("batchStream", { required: true })}
                    style={{ height: "40px", width: "30vw" }}
                    className="bg-white font-serif"
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Select Stream
                    </option>
                    {batchStreamData.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        label={`${option.label} (${option.subjects
                          .map((subject) => subject.label)
                          .join(", ")})`}
                      >
                        {`${option.label} (${option.subjects
                          .map((subject) => subject.label)
                          .join(", ")})`}
                      </option>
                    ))}
                  </select>
                  <div className="">
                    {errors.batchStream && (
                      <p className="error validationcolor">
                        This is required *
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className=" col-span-6 mt-1">
                <label className="text-sm mb-1  text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Start Date:
                </label>
                <div>
                  <input
                    id="date"
                    type="date"
                    style={{ height: "40px" }}
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    placeholder="Starting Date"
                    {...register("startingDate", {
                      required: "This is required",
                    })}
                    className="input "
                  />
                  <div>
                    {errors.startingDate && (
                      <span className="validationcolor">
                        {errors.startingDate.message}
                        {" *"}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="  col-span-6 mt-1 " style={{ width: "30vw" }}>
                <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Faculty:
                </label>
                <Select
                  placeholder="select Faculty"
                  isMulti
                  options={facultyData}
                  value={watch("faculyData")}
                  style={{ outline: "none", border: "none" }}
                  {...register("faculyData", { required: true })}
                  onChange={(selectedOptions) => {
                    setValue("faculyData", selectedOptions);
                  }}
                  className="custom-select"
                  classNamePrefix="custom-select"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: 0,
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
                <div>
                  {errors.faculyData && (
                    <p className="error validationcolor">
                      This is required {" *"}
                    </p>
                  )}
                </div>
              </div>

              {/* <div className="  col-span-6 mt-1   ">
                <label className="text-sm mb-1  text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Create Slots:
                </label>
                <div className="">
                  <div className="custom-time-picker flex ">
                    <div className="flex">
                      <div>
                        <select
                          className=" "
                          value={startTime.hour}
                          onChange={(e) =>
                            handleTimeChange(
                              {
                                ...startTime,
                                hour: e.target.value.padStart(2, "0"),
                              },
                              setStartTime
                            )
                          }
                        >
                          {Array.from(Array(12), (x, index) => index + 1).map(
                            (hour) => (
                              <option
                                key={hour}
                                value={String(hour).padStart(2, "0")}
                              >
                                {String(hour).padStart(2, "0")}
                              </option>
                            )
                          )}
                        </select>
                        :
                        <select
                          value={startTime.minute}
                          onChange={(e) =>
                            handleTimeChange(
                              { ...startTime, minute: e.target.value },
                              setStartTime
                            )
                          }
                        >
                          {Array.from(Array(60), (x, index) => index).map(
                            (minute) => (
                              <option
                                key={minute}
                                value={minute < 10 ? `0${minute}` : minute}
                              >
                                {minute < 10 ? `0${minute}` : minute}
                              </option>
                            )
                          )}
                        </select>
                        <select
                          value={startTime.meridiem}
                          onChange={(e) =>
                            handleTimeChange(
                              { ...startTime, meridiem: e.target.value },
                              setStartTime
                            )
                          }
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>

                      <div className="">
                        <label>-</label>
                        <select
                          value={endTime.hour}
                          onChange={(e) =>
                            handleTimeChange(
                              {
                                ...endTime,
                                hour: e.target.value.padStart(2, "0"),
                              },
                              setEndTime
                            )
                          }
                        >
                          {Array.from(Array(12), (x, index) => index + 1).map(
                            (hour) => (
                              <option
                                key={hour}
                                value={String(hour).padStart(2, "0")}
                              >
                                {String(hour).padStart(2, "0")}
                              </option>
                            )
                          )}
                        </select>
                        :
                        <select
                          value={endTime.minute}
                          onChange={(e) =>
                            handleTimeChange(
                              { ...endTime, minute: e.target.value },
                              setEndTime
                            )
                          }
                        >
                          {Array.from(Array(60), (x, index) => index).map(
                            (minute) => (
                              <option
                                key={minute}
                                value={minute < 10 ? `0${minute}` : minute}
                              >
                                {minute < 10 ? `0${minute}` : minute}
                              </option>
                            )
                          )}
                        </select>
                        <select
                          value={endTime.meridiem}
                          onChange={(e) =>
                            handleTimeChange(
                              { ...endTime, meridiem: e.target.value },
                              setEndTime
                            )
                          }
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                      <button
                        onClick={handleAddTimeRange}
                        type="button"
                        className="bg-white hover:bg-gray-100 text-gray-800 text-1xl font-bold py-1 px-4 border border-gray-400 shadow"
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div>
                    <span className="text-red-500 ">{errorMessage}</span>
                    <p className=" text-red-500 ">{slotTimeError}</p>
                  </div>
                </div>
                <div className=" flex">
                  {timeRanges.map((range, index) => (
                    <div key={index} className="mt-2">
                      {range}
                      <button
                        className=" ml-1 "
                        type="button"
                        onClick={() => handleDeleteTimeRange(index)}
                      >
                        {" "}
                        <RiDeleteBin6Line className="text-red-700 text-sm mr-3 mt-1" />
                      </button>
                    </div>
                  ))}
                </div>
              </div> */}
              {/* <div>

               <label className="text-sm mb-1 mt-3 text-primary">
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Select Time:
                </label>
                <div className=" flex space-x-8">

 {Times.map((item) => (
        <div
          key={item.id}
          // style={{ border:"2px solid gray"}}
          className="bg-white p-2 shadow-lg w-42"

          // onClick={() => handleCardClick(item.id)}

        >
          <p>{item.label}</p>
        </div>
      ))}
                </div>
              </div> */}

              <div>
                <label className="text-sm mb-1  mt-8 text-primary">
                  <span className="text-red-600 font-bold text-lg">*</span>
                  Select Time:
                </label>
                <div className="space-y-4">
                  {Array.from({ length: Math.ceil(Times.length / 6) }).map(
                    (_, index) => (
                      <div key={index} className="flex space-x-8 mt-2">
                        {Times.slice(index * 6, index * 6 + 6).map((item) => (
                          <div
                            {...register("times", {
                              required: "This is required",
                            })}
                            key={item.id}
                            onClick={() => handleCardClick(item.value)}
                            className={`bg-white p-2 shadow-lg w-42 cursor-pointer ${
                              selectedTimes.includes(item.value)
                                ? "border-2 border-blue-500"
                                : ""
                            }`}
                          >
                            <p>{item.label}</p>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="col-span-6 mt-4" style={{ width: "20vw" }}>
                <input
                  type="checkbox"
                  value="sunday-to-monday"
                  checked={specialBatchChecked}
                  onChange={handleSpecialBatchChecked}
                  // disabled
                  className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                />
                <span className="ml-2">Is Special Batch?</span>

                <div style={{ justifyContent: "flex-end" }} className="mt-4">
                  <button
                    onClick={addRow}
                    disabled={selectedSubjects.length === 0} // Disable if no subjects are selected
                    type="button" // Corrected type attribute
                    style={{
                      // marginBottom: "1rem",
                      padding: "0.5rem 1rem",
                      // backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                      justifyContent: "flex-end",
                      backgroundColor:
                        selectedSubjects.length === 0 ? "gray" : "#007bff",
                      cursor:
                        selectedSubjects.length === 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <FaPlus />
                  </button>
                </div>

                <table
                  style={{
                    marginTop: "1rem",
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          width: "4vw",
                        }}
                      >
                        Subject
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          width: "8vw",
                        }}
                      >
                        Lecture Per Week
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          width: "8vw",
                        }}
                      >
                        Total Lectures
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedSubjects.length === 0 ? (
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            padding: "0.5rem 1rem",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Please select a stream first
                        </td>
                      </tr>
                    ) : (
                      selectedSubjects.map((subject, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {subject.label}
                          </td>
                          <td
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            <input
                              type="text"
                              className="input"
                              placeholder="Enter Number"
                              {...register(`lecturePerWeek${subject.id}`, {
                                required: "This is required",
                                min: {
                                  value: 0,
                                  message: "Must be a positive number",
                                },
                              })}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value >= 0 || value === "") {
                                  handleSessionChange(
                                    subject.id,
                                    value,
                                    "lecturePerWeek"
                                  );
                                }
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                height: "2rem",
                                width: "10vw",
                                outline: "none",
                                border: "1px solid black",
                              }}
                            />
                            {errors[`lecturePerWeek${subject.id}`] && (
                              <span className="validationcolor">
                                {errors[`lecturePerWeek${subject.id}`].message}
                              </span>
                            )}
                          </td>
                          <td
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            <input
                              type="text"
                              className="input"
                              {...register(`totalSubject${subject.id}`, {
                                required: "This is required",
                                min: {
                                  value: 0,
                                  message: "Must be a positive number",
                                },
                              })}
                              placeholder="Enter Number"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value >= 0 || value === "") {
                                  handleSessionChange(
                                    subject.id,
                                    value,
                                    "totalSubject"
                                  );
                                }
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                height: "2rem",
                                width: "10vw",
                                // outline: "none",
                                // border: "1px solid black",
                              }}
                            />
                            {errors[`totalSubject${subject.id}`] && (
                              <span className="validationcolor">
                                {errors[`totalSubject${subject.id}`].message}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    )}

                    {additionalRows.map((row) => {
                      const selectedSubjectId = subjectIds[row.id];
                      const isDisabled = !selectedSubjectId;

                      return (
                        <tr key={row.id}>
                          <td
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            <Select
                              placeholder="Select Subject"
                              options={filteredSubjectData}
                              style={{ outline: "none", border: "none" }}
                              onChange={(selectedOptions) =>
                                handleSubjectChange(row.id, selectedOptions)
                              }
                              className="custom-select"
                              classNamePrefix="custom-select"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  backgroundColor: "white",
                                  borderRadius: 0,
                                  // border: "none",
                                  // outline: "none",
                                  boxShadow: "none",
                                  width: "8vw",
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  color: "black",
                                }),
                              }}
                            />
                            {errors.facultyData && (
                              <p className="error validationcolor">
                                This is required {" *"}
                              </p>
                            )}
                          </td>
                          <td
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            <input
                              type="text"
                              className="input"
                              placeholder={
                                isDisabled
                                  ? "First Select Subject"
                                  : "Enter Number"
                              }
                              {...register(`lecturePerWeek${row.id}`, {
                                required: "This is required",
                                min: {
                                  value: 0,
                                  message: "Must be a positive number",
                                },
                              })}
                              onChange={(e) =>
                                handleSessionChange(
                                  selectedSubjectId,
                                  e.target.value,
                                  "lecturePerWeek"
                                )
                              }
                              onKeyPress={(e) =>
                                e.key === "-" && e.preventDefault()
                              }
                              style={{
                                height: "2rem",
                                width: "10vw",
                                // outline: "none",
                                // border: "1px solid black",
                                backgroundColor: isDisabled
                                  ? "#e0e0e0"
                                  : "white", // Gray background for disabled
                                cursor: isDisabled ? "not-allowed" : "auto",
                              }}
                              disabled={isDisabled}
                            />
                            {errors[`lecturePerWeek${row.id}`] && (
                              <span className="validationcolor">
                                {errors[`lecturePerWeek${row.id}`].message}
                              </span>
                            )}
                          </td>
                          <td
                            style={{
                              padding: "0.5rem 1rem",
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            <input
                              type="text"
                              className="input"
                              {...register(`totalSubject${row.id}`, {
                                required: "This is required",
                                min: {
                                  value: 0,
                                  message: "Must be a positive number",
                                },
                              })}
                              placeholder={
                                isDisabled
                                  ? "First Select Subject"
                                  : "Enter Number"
                              }
                              onChange={(e) =>
                                handleSessionChange(
                                  selectedSubjectId,
                                  e.target.value,
                                  "totalSubject"
                                )
                              }
                              onKeyPress={(e) =>
                                e.key === "-" && e.preventDefault()
                              }
                              style={{
                                height: "2rem",
                                width: "10vw",
                                // outline: "none",
                                // border: "1px solid black",
                                backgroundColor: isDisabled
                                  ? "#e0e0e0"
                                  : "white", // Gray background for disabled
                                cursor: isDisabled ? "not-allowed" : "auto",
                              }}
                              disabled={isDisabled}
                            />
                            {errors[`totalSubject${row.id}`] && (
                              <span className="validationcolor">
                                {errors[`totalSubject${row.id}`].message}
                              </span>
                            )}
                          </td>
                          <td>
                            <button
                              onClick={() => removeRow(row.id)}
                              style={{
                                padding: "0.5rem",
                                border: "none",
                                cursor: "pointer",
                              }}
                            >
                              <RiDeleteBin6Line className="text-red-700 text-xl mr-3" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-span-6 mt-1" style={{ width: "30vw" }}>
                <div className="flex items-center ">
                  <label className="text-sm mb-1  text-primary">
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Select Days:
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    value="sunday-to-monday"
                    checked={fixedChecked}
                    onChange={handleFixedCheckboxChange}
                    // disabled
                    className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                  />
                  <span className="ml-2">Fixed</span>

                  <input
                    type="checkbox"
                    value="sunday-to-monday"
                    checked={flexibleChecked}
                    onChange={handleFlexibleCheckboxChange}
                    className="form-checkbox-square ml-4 h-4 w-4 text-primary border border-gray-400 rounded-none"
                  />
                  <span className="ml-2">Flexible</span>
                </div>
                <div className=" mt-2">
                  <select
                    {...(flexibleChecked
                      ? register("dayCount", { required: "This is required" })
                      : {})}
                    style={{
                      height: "40px",
                      width: "30vw",
                      outline: "none",
                      outline: "none",
                    }}
                    value={fixedChecked ? "" : undefined}
                    className={fixedChecked ? "bg-gray-200" : "bg-white"}
                    disabled={fixedChecked || !flexibleChecked}
                    placeholder="Duration"
                  >
                    <option value="" disabled selected>
                      Select Days Count
                    </option>
                    {[1, 2, 3, 4, 5, 6, 7].map((dayCount) => (
                      <option key={dayCount} value={dayCount}>
                        {dayCount}
                      </option>
                    ))}
                  </select>
                  <div>
                    {flexibleChecked && errors.dayCount && (
                      <span className="validationcolor">
                        {errors.dayCount.message}
                        {" *"}
                      </span>
                    )}
                  </div>
                  <div></div>
                </div>

                <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-2">
                  {options.map((option) => (
                    <div key={option.value} className="flex items-center">
                      <input
                        type="checkbox"
                        value={option.value}
                        {...(fixedChecked
                          ? register("selectdays", {
                              required: "This is required",
                            })
                          : {})}
                        checked={
                          !flexibleChecked &&
                          selectedOptions.includes(option.value)
                        }
                        onChange={() => handleOptionChange(option.value)}
                        disabled={!fixedChecked || flexibleChecked}
                        className="form-checkbox-square h-4 w-4 text-primary border border-gray-400 rounded-none"
                      />
                      <span className="ml-2">{option.label}</span>
                    </div>
                  ))}
                  <div>
                    {fixedChecked && errors.selectdays && (
                      <span className="validationcolor">
                        {errors.selectdays.message}
                        {" *"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="  col-span-6  ml-6   flex  mt-4  ">
              <div>
                <button
                  className="focus:outline-none text-white bg-primaryColour hover:bg-primaryColour-1000  font-medium  text-sm px-5 py-2.5 me-2 "
                  type="submit"
                >
                  Create
                </button>
              </div>
              <Link to={"/batchlist"} style={{ color: "white" }}>
                <button
                  className="text-gray-900   bg-gray-50 border border-gray-300 focus:outline-none hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800" // style={{ height: "35px", width: "7vw" }}
                >
                  Cancel
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BatchCreate;
