import React, { createContext, useContext, useState } from "react";

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [locationState, setLocationState] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [hasSchedule, setHasSchedule] = useState(false);
  const [ScheduleStatusCheck, setScheduleStatusCheck] = useState(false);
  const[createScheduleFacultyFieldValidation, setScheduleFacultyFieldValidation]= useState('')
  const[createScheduleDateFieldValidation, setScheduleDateFieldValidation]= useState('')
  const[createScheduleBatchFieldValidation, setScheduleBatchFieldValidation]= useState('')
  const[createScheduleSlotTimeFieldValidation, setScheduleSlotTimeFieldValidation]= useState('')
  const[createScheduleSubjectFieldValidation, setScheduleSubjectFieldValidation]= useState('')

  const [facultyHrsUpdate, setFacultyHrsUpdate] = useState(false);
  const [selectedScheduleType, setselectedScheduleType] =
    useState("jee/medical");
  const [loading, setLoading] = useState(false);
  const [compactSizeTable, setCompactSize] = useState(true);
  const [selectedDate, setSelectedDate] = useState([null,null]);
  const [selectedDateUpcomingSche, setSelectedDateUpcomingSche] = useState("");
  const [selectedFacultyCalenderDate, setSelectedFacultyCalenderDate] = useState("");
  const [selectedDateLeave, setSelectedDateLeave] = useState("");
  const [modalOpen, setModalOpen] = useState();
  const [modalOpenEdit, setModalOpenEdit] = useState();
  const [refreshTable, setRefreshTable] = useState(false);
  const [getSchedulingDataState, setSchedulingDataState] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [time, setTime] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState([]);
  const [modalDate, setModalDate] = useState("");
  const [selectedSubjectid, setSelectedSubjectid] = useState("");
  const [scheduleRefresh, setScheduleRefresh] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [loginUserData, setLoginUserData] = useState([]);
  const [activeMenuStatus, setActiveMenuStatus] = useState([]);
  const [dateStatus, setDateStatus] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isSwapSchedule, setIsSwapSchedule] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [facultyData, setFacultyData] = useState([]);
  const [createScheduleData, setCreateScheduleData] = useState([]);
  const [facultyPresent, setFacultyPresent] = useState(true);
  const [exportLocationName, setexportLocationName] = useState("")
  const [leaveExportDate, setLeaveExportDate] = useState([null,null]);

  return (
    <LocationContext.Provider
      value={{
        locationState, setLocationState,
        facultyData, setFacultyData,
        subjectList, setSubjectList,
        tableData, setTableData,
        loginUserData,
         setLoginUserData,
        facultyHrsUpdate,
        setFacultyHrsUpdate,
        selectedDateUpcomingSche,
        setSelectedDateUpcomingSche,
        selectedFacultyCalenderDate, setSelectedFacultyCalenderDate,
        selectedDateLeave,
        setSelectedDateLeave,
        loadingPercentage, setLoadingPercentage,
        loaderMessage,
        activeMenuStatus, setActiveMenuStatus,
        setLoaderMessage,
        scheduleRefresh,
        setScheduleRefresh,
        refreshTable,
        ScheduleStatusCheck,
        setScheduleStatusCheck,
        dateStatus, setDateStatus,
        hasSchedule,
        setHasSchedule,
        setRefreshTable,
        compactSizeTable,
        setCompactSize,
        selectedSubjectid,
        getSchedulingDataState,
        setSchedulingDataState,
        loading,
        setLoading,
        setSelectedSubjectid,
        modalDate,
        setModalDate,
        editItemId,
        setEditItemId,
        selectedLocation,
        setSelectedLocation,
        selectedScheduleType,
        setselectedScheduleType,
        selectedDate,
        setSelectedDate,
        modalOpen,
        setModalOpen,
        modalOpenEdit,
        setModalOpenEdit,
        time,
        setTime,
        selectedTime,
        setSelectedTime,
        setSelectedSubject,
        selectedSubject,
        selectedFaculty,
        createScheduleFacultyFieldValidation, setScheduleFacultyFieldValidation,
        createScheduleDateFieldValidation, setScheduleDateFieldValidation,
        createScheduleBatchFieldValidation, setScheduleBatchFieldValidation,
        createScheduleSlotTimeFieldValidation, setScheduleSlotTimeFieldValidation,
        createScheduleSubjectFieldValidation, setScheduleSubjectFieldValidation,
        setSelectedFaculty,
        selectedCheckboxes,
        setSelectedCheckboxes,
        setIsSwapSchedule,
        isSwapSchedule,
        createScheduleData, setCreateScheduleData,
        facultyPresent, setFacultyPresent,
        exportLocationName, setexportLocationName,
        leaveExportDate, setLeaveExportDate

      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
export const useCommonFunctions = () => {
  return useContext(LocationContext);
};
export default LocationContext;
