import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full flex items-center justify-between text-left  py-2 font-semibold text-primaryColour focus:outline-none"
        onClick={toggleAccordion}
      >
        {title}
        {isOpen ? (
          <FaChevronUp className="w-5 h-5 text-primaryColour" />
        ) : (
          <FaChevronDown className="w-5 h-5 text-primaryColour" />
        )}
      </button>
      {isOpen && (
        <div className=" py-2">
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
