import { useEffect, useState, useContext, useMemo, useRef } from "react";
import LocationContext from "../../context/LocationContext";
import {
  apiService,
  getApiService,
  getDataById,
} from "../../Services/Services";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import moment from "moment";
import * as _ from "lodash";
import { toast } from "react-toastify";
import Moment from 'react-moment';
const getBatchByIDURL = `${process.env.REACT_APP_API_URL}/showBatchById`;

export const Modal = ({ isOpen, onClose, onSubmit, slotTime, schedules,swapSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const [formData, setFormData] = useState({
    faculty: "",
    batch: "",
    batchslot: "",
    subject: "",
  });
  const buttonRef = useRef(null);

  const [allFacultyData, setAllFacultyData] = useState([]);
  const [allSlotTimeData, setAllSlotTimeData] = useState([]);

  const [batchData, setBatchData] = useState([]);
  const [batchDetails, setBatchDetails] = useState([]);
  const [suggestion, setSuggestion] = useState("");

  const {
    selectedSubjectid,
    selectedFaculty,
    setSelectedFaculty,
    time,
    setTime,
    selectedTime,
    setSelectedTime,
    setSelectedSubject,
    setLoading,
    selectedSubject,
    modalDate,
    setModalDate,
    setSelectedSubjectid,
    selectedScheduleType,
    selectedLocation,
    dateStatus,
    setDateStatus,
    facultyData,
    setFacultyData,
    createScheduleData,
    setCreateScheduleData,
    createScheduleFacultyFieldValidation,
    createScheduleDateFieldValidation,
    createScheduleBatchFieldValidation,
    createScheduleSlotTimeFieldValidation,
    createScheduleSubjectFieldValidation,
    selectedDate,
    facultyPresent,
    setFacultyPresent,
  } = useContext(LocationContext);
  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const getFacultyURL = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&sortBy=updated_at&sortOrder=DESC&location_id=${selectedLocation} `;
  const getBatchURL = `${process.env.REACT_APP_API_URL}/getbatchdata?q=&limit=&page=&sortBy=updated_at&sortOrder=DESC&location_id=${selectedLocation} `;

  const fetchData = async () => {
    try {
      const [facultyResponse, batchResponse, slotTimeResponse] =
        await Promise.all([
          apiService.get(getFacultyURL),
          apiService.get(getBatchURL),
        ]);
      if (facultyResponse) {
        setFacultyPresent(true);
        setAllFacultyData(facultyResponse);
      }

      if (selectedScheduleType === "foundation") {
        const foundationBatches = batchResponse.data.filter(
          (item) => item.batch_stream[0].stream_names === "Foundation"
        );
        setBatchData(foundationBatches);
      }
      if (selectedScheduleType === "jee/medical") {
        const jeeMedicalBatches = batchResponse.data.filter(
          (item) =>
            item.batch_stream[0].stream_names === "JEE" ||
            item.batch_stream[0].stream_names === "Medical"
        );
        setBatchData(jeeMedicalBatches);
      }
      if (selectedScheduleType === "jee") {
        const jeeMedicalBatches = batchResponse.data.filter(
          (item) => item.batch_stream[0].stream_names === "JEE"
        );
        setBatchData(jeeMedicalBatches);
      }
      if (selectedScheduleType === "medical") {
        const jeeMedicalBatches = batchResponse.data.filter(
          (item) => item.batch_stream[0].stream_names === "Medical"
        );
        setBatchData(jeeMedicalBatches);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const allTimes = _.flatMap(slotTime, (slot) => slot.id).flat();
    const filteredBatchSlots = _.difference(allTimes, time);
    setAllSlotTimeData(filteredBatchSlots);
    if (createScheduleData.id) {
      const errorSchedule = schedules?.find(
        (schedule) =>
          schedule.id === createScheduleData.id && schedule.error !== null
      );
      if (errorSchedule && facultyData) {
        const suggestions = suggestFacultyOrSwap(errorSchedule);
        setSuggestion(suggestions);
      } else {
        setSuggestion("");
      }
    } else {
      setSuggestion("");
    }
  }, [batchData, time, facultyData]);
  useEffect(() => {
    if (selectedLocation) {
      fetchData();
    }
  }, [selectedLocation]);
  const fetchSingleBatch = async () => {
    try {
      const url = `${getBatchByIDURL}`;
      const id = localStorage.getItem("item");
      const response = await apiService.get(`${url}/${id}`);
      if (response) {
        setBatchDetails(response.data);
        if (
          response.data.slot_times_foundations &&
          response.data.slot_times_foundations.length > 0
        ) {
          // const responseSubjects = response.data.batch_stream[0].subject;
          // const subjectsArray = [];

          // responseSubjects.forEach((subject) => {
          //   const subjectDetails = {
          //     id: subject.id,
          //     subject_name: subject.subject_name,
          //     subject_code: subject.subject_code,
          //   };
          //   subjectsArray.push(subjectDetails);
          // });
          const slotDataM = response.data.slot_times_foundations[0].slot_times;
          const slotDataA = response.data.slot_times_foundations[1].slot_times;

          const combinedTimeSlots = [];

          if (slotDataM.length > 0) {
            combinedTimeSlots.push(...slotDataM);
          }

          if (slotDataA.length > 0) {
            combinedTimeSlots.push(...slotDataA);
          }

          setTime(combinedTimeSlots);

          // setSelectedSubject(subjectsArray);
        } else {
          const combinedTimeSlots = [];

          if (
            response.data.batch_slots &&
            response.data.batch_slots.length > 0
          ) {
            const slotDataM = response.data.batch_slots[0].slot_times;

            if (slotDataM && slotDataM.length > 0) {
              combinedTimeSlots.push(...slotDataM);
            }
          }

          if (
            response.data.batch_slots &&
            response.data.batch_slots.length > 1
          ) {
            const slotDataA = response.data.batch_slots[1].slot_times;

            if (slotDataA && slotDataA.length > 0) {
              combinedTimeSlots.push(...slotDataA);
            }
          }

          setTime(combinedTimeSlots);

          // const responseSubjects = response.data.batch_stream[0].subject;
          // const subjectsArray = [];

          // responseSubjects.forEach((subject) => {
          //   const subjectDetails = {
          //     id: subject.id,
          //     subject_name: subject.subject_name,
          //     subject_code: subject.subject_code,
          //   };
          //   subjectsArray.push(subjectDetails);
          // });

          // setSelectedSubject(subjectsArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reset();
    setValue("batch", null);
    setValue("batchslot", null);
    setBatchDetails([]);
    localStorage.removeItem("item");
    if (createScheduleData.batch_id) {
      localStorage.setItem("item", createScheduleData.batch_id);
      fetchSingleBatch();
    }
    if (createScheduleData.date) {
      setModalDate(moment(createScheduleData.date).format("YYYY-MM-DD"));
      setValue("date", modalDate);
    }
  }, [createScheduleData]);
  const [startDate, endDate] = selectedDate.map((date) => moment(date));
  function suggestFacultyOrSwap(errorSchedule) {
    const { date, slot_time, batch_id, faculty_id } = errorSchedule;

    // Get the batch and associated faculties
    const batch = _.find(batchData, { id: batch_id });
    if (!batch) {
      return null;
    }

    const faculties = batch.faculties || [];
    if (faculties.length === 0) {
      console.error(`No faculties found for batch ID ${batch_id}.`);
      return null;
    }

    // Collect all suggestions
    const suggestions = [];

    // Check for possible swaps
    const possibleSwap = suggestSwap(errorSchedule);
    if (possibleSwap) {
      suggestions.push({
        suggestionType: "Swap",
        swapWith: {
          faculty_name: `${possibleSwap.faculty.first_name} ${possibleSwap.faculty.last_name}`,
          faculty_code: possibleSwap.faculty.faculty_code,
          date: possibleSwap.date,
          slot_time: possibleSwap.slot_time,
          id: possibleSwap.id,
        },
        swapTo: {
          faculty_name: `${errorSchedule.faculty.first_name} ${errorSchedule.faculty.last_name}`,
          faculty_code: errorSchedule.faculty.faculty_code,
          date: errorSchedule.date,
          slot_time: errorSchedule.slot_time,
          id: errorSchedule.id,
        },
      });
    }

    const suitableFaculties = findSuitableFaculty(faculties, date, slot_time);
    suggestions.push(
      ...suitableFaculties.map((suitable) => ({
        suggestionType: suitable.suggestionType,
        faculty_name: `${suitable.faculty.first_name} ${suitable.faculty.last_name}`,
        faculty_code: suitable.faculty.faculty_code,
        faculty_id: suitable.faculty.id,
        subjects: suitable.faculty.subject,
      }))
    );
    return suggestions.length > 0 ? suggestions : null;
  }
  const isFacultyFree = (faculty, date, slot_time) => {
    const isSlotSpecified = slot_time !== null && slot_time !== undefined;
    const facultyHasSchedule = _.some(schedules, {
      faculty_id: faculty.id,
      date,
      ...(isSlotSpecified && { slot_time }),
    });

    return !facultyHasSchedule && isFacultyAvailable(faculty.id, date, slot_time);
  };
  // Function to find a suitable faculty for scheduling
  const findSuitableFaculty = (faculties, date, slot_time) => {
    return faculties.reduce((acc, faculty) => {
      console.log(faculty,isFacultyFree(faculty, date, slot_time));

      if (isFacultyFree(faculty, date)) {
        acc.push({
          suggestionType: "Faculty Free All Day",
          faculty,
        });
      } else if (isFacultyFree(faculty, date, slot_time)) {
        acc.push({
          suggestionType: "Faculty Free in Slot",
          faculty,
        });
      }
      return acc;
    }, []);
  };

  function suggestSwap(errorSchedule) {
    const { date, slot_time, faculty_id } = errorSchedule;

    // Step 1: Find all possible swap schedules
    const swapToSchedules = _.filter(
      findSwapSchedules(errorSchedule),
      (record) => !findSchedule(record.faculty_id, date, slot_time)
    );
    // Step 2: Filter the swapToSchedules to find suitable swapFromSchedule
    let swapFromSchedule = _.filter(swapToSchedules, (record) => {
      const isConflictWithOriginalFaculty = findSchedule(
        faculty_id,
        record.date,
        record.slot_time
      );
      const isFacultyOnLeave =  !isFacultyAvailable(faculty_id, record.date, record.slot_time)
      const isBackToBack = checkBackToBackLecture(
        faculty_id,
        record.date,
        record.slot_time
      );
      console.log(!isFacultyOnLeave,record.date,isConflictWithOriginalFaculty,isBackToBack);
      console.log(!isConflictWithOriginalFaculty && !isBackToBack && !isFacultyOnLeave);

      // is not already scheduled on the back to back as the errorSchedule date.
      return !isConflictWithOriginalFaculty && !isBackToBack && !isFacultyOnLeave;
    });
    // Step 3: If no suitable swap is found, ignore the isSameDayForBatchSchedule check
    if (swapFromSchedule.length === 0) {
      swapFromSchedule = _.filter(swapToSchedules, (record) => {
        const isConflictWithOriginalFaculty = findSchedule(
          faculty_id,
          record.date,
          record.slot_time
        );
        const isFacultyOnLeave =  !isFacultyAvailable(faculty_id, record.date, record.slot_time)
        return !isConflictWithOriginalFaculty && !isFacultyOnLeave;
      });
    }

    // Step 4: Return the first valid swapFromSchedule or null if none found
    if (swapFromSchedule.length > 0) {
      return swapFromSchedule[0];
    }
    return null; // If no solution is found
  }

  function findSwapSchedules(errorSchedule) {
    const { date, slot_time, batch_id, faculty_id, id } = errorSchedule;
    return _.filter(schedules, (record) => {
      const recordDate = moment(record.date);
      return (
        record.batch_id === batch_id &&
        record.id !== id &&
        record.faculty_id !== faculty_id &&
        recordDate.isBetween(startDate, endDate, null, "[]") &&
        isFacultyAvailable(record.faculty_id, date, slot_time)
      );
    });
  }

  function isFacultyAvailable(facultyId, date) {
      const facultyMap = _.mapValues(
        _.keyBy([...allFacultyData, ...facultyData], "id"),
        (faculty) =>
          new Set(
            _.flatMap(faculty.leave || [], (leave) =>
              leave.dates
                .split(",")
                .map((date) => moment(date).format("YYYY-MM-DD"))
            )
          )
      );
      // console.log(facultyMap);

      const facultyLeaveDates = facultyMap[facultyId];
      return (
        !facultyLeaveDates ||
        !facultyLeaveDates.has(moment(date).format("YYYY-MM-DD"))
      );
  }

  function findSchedule(faculty_id, date, slot_time) {
    return _.some(
      schedules,
      (record) =>
        record.date === date &&
        (!slot_time || record.slot_time === slot_time) &&
        record.faculty_id === faculty_id
    );
  }
  function checkBackToBackLecture(facultyId, date, slot_time) {
    const { previous, next } = getPrevNextSlotTime(slot_time);

    const hasPreviousLecture = !previous
      ? false
      : findSchedule(facultyId, date, previous);
    const hasNextLecture = !next ? false : findSchedule(facultyId, date, next);
    // Return true if there's a lecture right before or after the current slot
    return hasPreviousLecture || hasNextLecture;
  }
  function getPrevNextSlotTime(currentSlotTime) {
    const currentIndex = time.indexOf(currentSlotTime);
    if (currentIndex === -1) {
      return { previous: null, next: null }; // Slot not found in the array
    }

    const previousSlotTime = currentIndex > 0 ? time[currentIndex - 1] : null;
    const nextSlotTime =
      currentIndex < time.length - 1 ? time[currentIndex + 1] : null;

    return { previous: previousSlotTime, next: nextSlotTime };
  }
  useEffect(() => {
    setSelectedSubject([]);
    setSelectedFaculty("");
    if (batchDetails.id) {
      setValue("batch", {
        value: batchDetails.id,
        label: batchDetails.batch_code,
      });
    }
    setFacultyData(batchDetails.faculties);
  }, [batchDetails]);
  useEffect(() => {
    if (createScheduleData.faculty_id && facultyData) {
      setValue("faculty", {
        value: createScheduleData.faculty_id,
        label: createScheduleData.faculty_id,
      });
      let faculty = [...allFacultyData, ...facultyData].filter(
        (f) => f.id === createScheduleData.faculty_id
      )[0];
      console.log(faculty,[...allFacultyData, ...facultyData]);

      if (faculty) {
        handleFacltyDataChange({
          value: faculty.id,
          label: (
            <span>
              {`${faculty.faculty_code} - ${faculty.first_name} ${faculty.last_name} `}
              <span style={{ color: "red" }}>
                {`(` + createScheduleData.subject["subject_name"] + `)`}
              </span>
            </span>
          ),
          subjects: faculty.subject,
        });
      }
    }
  }, [facultyData]);
  useEffect(() => {
    if (createScheduleData.time) {
      const [start, end] = createScheduleData.time.split("-");
      let t = `${moment(start, "h:mm A").format("HH:mm")}-${moment(
        end,
        "h:mm A"
      ).format("HH:mm")}`;
      let isBatchSlotTime = slotTime.some((t1) => t1.id === t);
      if (isBatchSlotTime) {
        setValue("batchslot", { value: t, label: t });
        handleBatchSlotDataTimeChange({ value: t, label: t });
      }
    }
  }, [createScheduleData, slotTime]);
  const handleFacltyDataChange = (selectedOption) => {
    setSelectedFaculty(selectedOption);
    setSelectedSubject(selectedOption.subjects);
    let isFacultyInBatch = facultyData?.some(
      (faculty) => faculty.id === selectedOption.value
    );
    // const modifiedLabel = selectedOption.label.map((text, index) => {
    //   return index === 1 ? (
    //     <span style={{ color: "red" }}>&nbsp;{text}</span>
    //   ) : (
    //     text
    //   );
    // });
    // setValue("faculty", { value: selectedOption.value, label: modifiedLabel });

    setFacultyPresent(isFacultyInBatch);
  };
  const handleBatchDataChange = (selectedOption) => {
    setValue("batch", selectedOption);
    localStorage.setItem("item", selectedOption.value);

    fetchSingleBatch();
  };

  const handleBatchSlotDataTimeChange = (selectedOption) => {
    setValue("batchslot", selectedOption);
    if (selectedScheduleType === "foundation") {
      setSelectedTime(selectedOption.label);
    } else {
      setSelectedTime(selectedOption.label);
    }
  };

  const handleBatchSubjectDataChange = (selectedOption) => {
    setValue("subject", selectedOption);
    setSelectedSubjectid(selectedOption.value);
  };

  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    setValue("subject", null);
    setSelectedSubjectid(null);
    if (selectedSubject.length === 1) {
      setValue("subject", {
        value: selectedSubject[0].id,
        label: selectedSubject[0].subject_name,
      });
      setSelectedSubjectid(selectedSubject[0].id);
    }
  }, [selectedSubject]);

  const [minDate, setMinDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    setMinDate(`${yyyy}-${mm}-${dd}`);
  }, []);
  const handleDateChange = (event) => {
    setModalDate(event.target.value);
  };

  // useEffect(() => {
  //   if (isOpen) {
  //     fetchData();
  //   }
  // }, [isOpen]);
  if (!isOpen) return null;

  const CustomOption = ({ children, ...props }) => {
    const facultyName = children[0];
    const subjectCode = children[1];
    return (
      <components.Option {...props}>
        <span>{facultyName}</span>
        <span style={{ color: "red" }}>{subjectCode}</span>
      </components.Option>
    );
  };
  const handleSwap = async (suggestionItem,index) => {
    const url = `${process.env.REACT_APP_API_URL}/swapSchedule?schedule1=${suggestion[index]['swapTo']['id']}&schedule2=${suggestion[index]['swapWith']['id']}`;
    try {
      setLoading(true);
    await apiService.post(url).then((res) => {
        swapSubmit(res);
      })
    } catch (error) {
      toast.error(error.response.data.message, toastConfig); 
      setLoading(false);
    }
  };

  const handleChangeFaculty = (suggestionItem,index) => {
    let faculty = facultyData.filter(
      (f) => f.id === suggestionItem.faculty_id
    )[0];
    console.log(faculty,suggestionItem.faculty_id);

    if (faculty) {
      handleFacltyDataChange({
        value: faculty.id,
        label: (
          <span>
            {`${faculty.first_name} ${faculty?.last_name} `}
            <span style={{ color: "red" }}>
              {`(` + faculty.subject[0]["subject_name"] + `)`}
            </span>
          </span>
        ),
        subjects: faculty.subject,
      });
      setLoading(true);
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, 100);

    }
  };
  return (
    <form>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
        <div
          className="bg-white   border-2 border-gray-600  pb-2"
          style={{ width: "60vw" }}
        >
          <h3
            className=" text-lg py-1 bg-primaryColour text-white "
            style={{
              margin: "auto",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {createScheduleData.id ? "Update Schedule" : "Create Schedule"}
          </h3>
          {suggestion?.length > 0 && (
          <>
          <div className="flex space-x-4 flex-wrap justify-center">
              {suggestion?.length > 0 && (
                suggestion?.map((suggestionItem, index) => (
                  <div key={index} className="flex flex-col  mt-4  items-start mb-2">
                    {suggestionItem.suggestionType === "Swap" ? (
                      <>
                       <p>{suggestionItem.suggestionType}</p>
                       <button type="button"
                        className="bg-primaryColour text-white py-2 px-4 rounded-md text-md mb-2"
                        onClick={() => handleSwap(suggestionItem,index)}
                      >
                        Swap with {suggestionItem.swapWith.faculty_name} <br />(
                          {suggestionItem.swapWith.faculty_code})&nbsp;at&nbsp;  
                        <Moment format="ddd, DD MMM">
                          {suggestionItem.swapWith?.date}
                        </Moment> {" "}
                        {suggestionItem.swapWith?.slot_time}
                      </button>
                      </>
                    ) : (
                      <>
                       <p>Replace</p>
                       <button type="button"
                        className="bg-primaryColour text-white py-2 px-4 rounded-md text-md mb-2"
                        onClick={() => handleChangeFaculty(suggestionItem,index)}
                      >
                        Replace with {suggestionItem.faculty_name} <br />(
                        {suggestionItem.faculty_code})
                      </button>
                      </>

                    )}
                  </div>
                ))
              )}
          </div>
          <div class="h-3 border-b-1 border-black text-lg text-center">
            <span class="bg-white px-5">Update</span>
          </div>
          </>
          )}
          <div style={{ borderTop: "1px solid #ccc" }}>
            <div className="flex flex-wrap p-4">
              <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 md:pr-2">
                <label
                  htmlFor="dateInput"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Date
                </label>
                <input
                  {...register("date", { required: true })}
                  type="date"
                  id="dateInput"
                  value={modalDate}
                  min={minDate}
                  onChange={handleDateChange}
                  style={{ width: "28vw" }}
                  autoFocus
                  className="  px-3 py-2 border border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <div>
                  {/* {errors.date && (
                    <p className="error validationcolor">
                      This is required{" *"}{" "}
                    </p>
                  )} */}
                  <p className="text-red-600">
                    {createScheduleDateFieldValidation}
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 md:pr-2">
                <label
                  htmlFor="batchSelect"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  <span className=" text-red-600 font-bold text-lg ">*</span>
                  Batch
                </label>
                <Select
                  {...register("batch", { required: true })}
                  id="batchSelect"
                  options={batchData.map((batch) => ({
                    value: batch.id,
                    label: batch.batch_code,
                    faculties: batch.faculties,
                  }))}
                  value={watch("batch")}
                  onChange={handleBatchDataChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      width: "28vw",
                    }),
                  }}
                />
                <p className=" text-red-600 ">
                  {createScheduleBatchFieldValidation}
                </p>
                {/* {errors.batch && (
                  <p className="error validationcolor">
                    This is required{" *"}{" "}
                  </p>
                )} */}
              </div>
              <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 md:pr-2">
                <div>
                  <label
                    htmlFor="batchSlotTimeSelect"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Slot Time
                  </label>
                  <Select
                    {...register("batchslot", { required: true })}
                    id="batchSlotTimeSelect"
                    options={[
                      {
                        label: "Batch Slots",
                        options: time?.map((t, index) => ({
                          value: t,
                          label: t,
                        })),
                      },
                      {
                        label: "Other Slots",
                        options: allSlotTimeData?.map((t, index) => ({
                          value: t,
                          label: t,
                        })),
                      },
                    ]}
                    value={watch("batchslot")}
                    onChange={handleBatchSlotDataTimeChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                        width: "28vw",
                      }),
                    }}
                  />
                  {/* {errors.batchslot && (
                    <p className="error validationcolor">
                      This is required{" *"}{" "}
                    </p>
                  )} */}
                  <p className=" text-red-600 ">
                    {createScheduleSlotTimeFieldValidation}
                  </p>
                </div>
              </div>

              <div className=" md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 md:pr-2">
                <div>
                  <label
                    htmlFor="batchFacultySelect"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Faculty
                  </label>
                  {facultyData && allFacultyData && (
                    <Select
                      {...register("faculty", { required: true })}
                      id="batchFacultySelect"
                      options={[
                        {
                          label: "Batch Faculties",
                          options: facultyData?.map((faculty) => ({
                            value: faculty.id,
                            label: [
                              `
                              ${faculty.faculty_code} - ${faculty.first_name} ${faculty.last_name} `,
                              <div className="float-right">
                                {faculty.subject &&
                                  faculty.subject
                                    .map(
                                      (subject) => `(${subject.subject_name})`
                                    )
                                    .join(", ")}
                              </div>,
                            ],
                            subjects: faculty.subject,
                          })),
                        },
                        {
                          label: "Other Faculties",
                          options: allFacultyData
                            ?.filter(
                              (allFaculty) =>
                                facultyData.length !== 0 &&
                                !facultyData.some((f) => f.id === allFaculty.id)
                            )
                            ?.map((faculty) => ({
                              value: faculty.id,
                              label: [
                                `${faculty.faculty_code} - ${faculty.first_name} ${faculty.last_name} `,

                                <span className="flex-none float-right">
                                  {faculty.subject &&
                                    faculty.subject
                                      .map(
                                        (subject) => `(${subject.subject_name})`
                                      )
                                      .join(", ")}
                                </span>,
                              ],
                              subjects: faculty.subject,
                            })),
                        },
                      ]}
                      components={{ Option: CustomOption }}
                      onChange={handleFacltyDataChange}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 0,
                          width: "28vw",
                        }),
                      }}
                      value={selectedFaculty}
                    />
                  )}
                  {errors.faculty && (
                    <p className="error validationcolor">
                      This is required{" *"}{" "}
                    </p>
                  )}
                </div>
                <p className="  text-red-600 ">
                  {createScheduleFacultyFieldValidation}
                </p>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 md:pr-2">
                <div>
                  <label
                    htmlFor="batchSubjectSelect"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    <span className=" text-red-600 font-bold text-lg ">*</span>
                    Subject
                  </label>
                  <Select
                    {...register("subject", { required: true })}
                    id="batchSubjectSelect"
                    options={selectedSubject.map((subject) => ({
                      value: subject.id,
                      label: subject.subject_name,
                    }))}
                    value={watch("subject")}
                    onChange={handleBatchSubjectDataChange}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                        width: "28vw",
                      }),
                    }}
                  />
                  {/* {errors.subject && (
                    <p className="error validationcolor">
                      This is required{" *"}{" "}
                    </p>
                  )} */}
                  <p className=" text-red-600 ">
                    {createScheduleSubjectFieldValidation}
                  </p>
                </div>
              </div>
            </div>
            {facultyPresent == false && (
              <p className="text-red-500     font-sarif ml-3">
                Faculty is not assigned for this batch. Are you sure you want to
                continue ?
              </p>
            )}

          </div>
          <div className="button px-4">
            <button ref={buttonRef}
              className=" text-white bg-primaryColour hover:bg-primaryColour-1000  font-medium  text-sm px-5 py-2.5 me-2 "
              onClick={onSubmit}
            >
              {createScheduleData.id ? "Update" : "Create"}
            </button>
            <button
              className="text-gray-900   bg-gray-50 border border-gray-300  hover:bg-gray-100  font-medium  text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-600 dark:focus:ring-gray-800"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
