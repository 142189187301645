import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./CardHeader.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDrag, useDrop } from "react-dnd";
import LocationContext from "../../context/LocationContext";
import ProgressBar from "@ramonak/react-progress-bar";
import { calculateValueFromDates } from "../../Services/CommonFucntion";
import { apiService } from "../../Services/Services";
import { Link } from "react-router-dom";
import { FaGripVertical } from "react-icons/fa";
import defaultImage from "../../utils/Images/defaultImage.jpg";
import { toast } from "react-toastify";
import * as _ from 'lodash';

const CardHeader = () => {
  const [facultyCard, setFacultyCard] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [calculateHrs, setCalculateHrs] = useState("");
  const [cardLimitState, SetCrdLimitState] = useState(7);
  // const [subjectList, setSubjectList] = useState([]);

  const {
    setModalOpen,
    selectedLocation,
    selectedScheduleType,
    selectedDate,
    compactSizeTable,
    setCompactSize,
    facultyHrsUpdate,
    setFacultyHrsUpdate,
    loading,
    setLoading,
    dateStatus,
    subjectList, setSubjectList,
  } = useContext(LocationContext);

  const toastConfig = useMemo(
    () => ({
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        marginBottom: "4vw",
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          marginBottom: "4vw",
          fontSize: "1.5em",
          width: "400px",
          padding: "10px",
        },
        fontSize: "1.2em",
        width: "400px",
        padding: "10px",
      },
    }),
    []
  );
  const selectedDateArray = Array.isArray(selectedDate) ? selectedDate : [];
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const formattedDates = selectedDateArray.map((dateString) => {
    const startingDateDecoded = decodeURIComponent(dateString);
    return formatDate(startingDateDecoded);
  });

  const conversionRate = 3;

  const calculatedValue = calculateValueFromDates(
    formattedDates[0],
    formattedDates[1],
    conversionRate
  );

  const getFacultyHrs = async () => {
    const reportURL = `${process.env.REACT_APP_API_URL}/getFacultiesCount?&location_id=${selectedLocation}&starting_date=${formattedDates[0]}&ending_date=${formattedDates[1]}`;
    try {
      const res = await apiService.get(reportURL);
      setCalculateHrs(res);
    } catch (err) {
      setCalculateHrs(null);
    }
  };

  const getFacultyCard = async () => {
    if (selectedLocation) {
      // Check if selectedLocation is not empty

      let streamCode = "";

      if (selectedScheduleType === "jee/medical") {
        streamCode = "j/m";
      } else if (selectedScheduleType === "jee") {
        streamCode = "j";
      } if (selectedScheduleType === "medical") {
        streamCode = "m";
      } else if (selectedScheduleType === "foundation") {
        streamCode = "f";
      }
      const url = `${process.env.REACT_APP_API_URL}/getfacultydata?q=&limit=&page=&gender=&location_id=${selectedLocation}&sortBy=updated_at&sortOrder=ASC&stream_code=${streamCode}`;

      try {
        const res = await apiService.get(url);
        setFacultyCard(res.data);
        setTotal(res.total);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    setStartIndex(0);
    getSubjectData();
  }, [selectedScheduleType]);
  useEffect(() => {
    if (selectedLocation) {
      if (facultyHrsUpdate) {
        getFacultyHrs();
        setFacultyHrsUpdate(false);
      }

      getFacultyHrs();
    }
  }, [
    facultyHrsUpdate,
    loading,
    selectedLocation,
    formattedDates[0],
    formatDate[1],
  ]);

  useEffect(() => {
    getFacultyCard();
  }, [selectedScheduleType, selectedLocation]);

  const handleNextCard = () => {
    const nextStartIndex = startIndex + 1;
    setStartIndex(nextStartIndex);
  };

  const handlePrevCard = () => {
    const prevStartIndex = startIndex - 1;
    setStartIndex(prevStartIndex);
  };

  const renderFacultyCards = () => {
    const endIndex = Math.min(startIndex + cardLimitState, total);
    return facultyCard.slice(startIndex, endIndex).map((faculty, index) => {
      const targetFacultyId = faculty?.id || null;
      let percentage = 0;
      let getReportSessionCount = null;
      let calculatedDateRangeDifference = null;

      if (calculateHrs) {
        const filteredData = calculateHrs.Faculties.filter(
          (item) => item?.faculty_id === targetFacultyId
        );
        const totaleCountOfSessionTaken = filteredData[0]?.count || null;
        const values =
          Math.floor((totaleCountOfSessionTaken / calculatedValue) * 100) || 0;
        percentage = values;
        getReportSessionCount = totaleCountOfSessionTaken;
        calculatedDateRangeDifference = calculatedValue;
      }

      return (
        <Link
          to={`/facultyview/${faculty.id}`}
          key={faculty.id}
          style={{
            textDecoration: "none",
            color: "black",
            transition: "color 0.3s",
          }}
        >
          <Card
            key={faculty.id}
            faculty={faculty}
            facultydata={faculty}
            calculateHRs={getReportSessionCount}
            calculatedValue={calculatedDateRangeDifference}
            percentage={percentage}
            moveCard={moveCard}
            selectedScheduleType={selectedScheduleType}
          />
        </Link>
      );
    });
  };

  const handleOpenModal = () => {
    if (dateStatus) {
      toast.error(
        "Scheduling is restricted for both past dates and the current week.",
        toastConfig
      );
    } else {
      setModalOpen(true);
    }
  };
  const handleCheckboxChange = () => {
    setCompactSize(!compactSizeTable);
  };

  const moveCard = (draggedItem, targetItem) => {
    console.log("Moving card from", draggedItem, "to", targetItem);
  };

  const cardsLeft = total - (startIndex + cardLimitState);
  const subjectURL = `${process.env.REACT_APP_API_URL}/getSubject?q=&limit=&page=`;
  const getSubjectData = async () => {
    const Response = await apiService.get(subjectURL);
    if (Response) {
      try {
        let mappingResponse = [];
        if (selectedScheduleType === "jee/medical") {
          Response.data.map((response) => {
            if(_.findIndex(response.batch_stream, (ele)=> ele.stream_code === 'J' ||  ele.stream_code === 'M') >=0) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "jee") {
          Response.data.map((response) => {
            if(_.findIndex(response.batch_stream, (ele)=> ele.stream_code === 'J') >=0) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "medical") {
          Response.data.map((response) => {
            if(_.findIndex(response.batch_stream, (ele)=> ele.stream_code === 'M') >=0) {
              mappingResponse.push(response);
            }
          });
        } else if (selectedScheduleType === "foundation") {
          Response.data.map((response) => {
            if (response.batch_stream[0].stream_code == "F") {
              mappingResponse.push(response);
            }
          });
        }
        setSubjectList(mappingResponse);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <nav className="box-border border-b border-gray-400 border-solid font-serif   ml-4  overflow-auto  ">
      <div className="flex items-center  h-28 px-4">
        <button
          className="bg-white hover:bg-gray-100 text-gray-800  text-2xl font-bold py-1 px-3 border border-gray-400 shadow"
          onClick={handleOpenModal}
        >
          +
        </button>

        {startIndex === 0 ? (
          <div className="ml-2 text-2xl text-gray-400 cursor-not-allowed">
            <FaChevronLeft className="fill-current text-gray-200 " />
          </div>
        ) : (
          <div
            className="ml-2 text-2xl cursor-pointer"
            onClick={handlePrevCard}
          >
            <FaChevronLeft className="fill-current text-green-700" />
          </div>
        )}

        <section className="flex ml-2 font-serif text-primaryColour">
          {renderFacultyCards()}
        </section>

        {startIndex + cardLimitState >= total ? (
          <div className="ml-2 text-2xl text-gray-400 cursor-not-allowed ">
            <FaChevronRight className="fill-current  text-gray-200   " />
          </div>
        ) : (
          <div
            className="ml-2 text-2xl cursor-pointer"
            onClick={handleNextCard}
          >
            <FaChevronRight className="fill-current text-green-700" />
          </div>
        )}

        {cardsLeft > 0 && (
          <span className="text-sm">
            {cardsLeft}/{total}
          </span>
        )}
        <div className=" ml-auto mt-2">
          <div className=" flex">
            <label className=" flex">
              <p className="  text-base font-serif  ">Compact View:</p>
              <input
                type="checkbox"
                checked={compactSizeTable}
                onChange={handleCheckboxChange}
                style={{ width: "20px", height: "20px" }}
              />
            </label>
          </div>
          <div>
            {subjectList.map((subject, index) => (
              <div className="flex items-end mb-1">
                <div
                  className="ml-4 w-4 h-4 font-serif"
                  style={{
                    backgroundColor: subject?.subject_color_code || "white",
                  }}
                ></div>
                <span className="ml-2 text-black text-xs font-semibold font-serif">
                  {subject.subject_name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default CardHeader;

const Card = ({
  faculty,
  facultydata,
  calculatedValue,
  calculateHRs,
  percentage,
  moveCard,
  selectedScheduleType,
}) => {
  const [, ref] = useDrag({
    type: "CARD",
    item: { facultydata },
  });

  const [, drop] = useDrop({
    accept: "CARD",
    onClick: (draggedItem) => {
      if (draggedItem.facultydata !== facultydata) {
        moveCard(draggedItem.facultydata, facultydata);
        draggedItem.facultydata = facultydata;
      }
    },
  });

  const completed = percentage || 0;
  let bgColor = "#FFFF00";
  if (completed >= 70 && completed <= 100) {
    bgColor = "#008000";
  } else if (completed >= 101) {
    bgColor = "#FF0000";
  } else {
    bgColor = "#F3C92C";
  }

  let color = "";
  if (selectedScheduleType === "jee/medical") {
    color =
      faculty.subject.find((subject) =>
        subject.batch_stream.find(
          (stream) => stream.stream_code == "J" || stream.stream_code == "M"
        )
      )?.subject_color_code || "white";
  }
  if (selectedScheduleType === "jee") {
    color =
      faculty.subject.find((subject) =>
        subject.batch_stream.find(
          (stream) => stream.stream_code == "J" 
        )
      )?.subject_color_code || "white";
  }
  if (selectedScheduleType === "medical") {
    color =
      faculty.subject.find((subject) =>
        subject.batch_stream.find(
          (stream) => stream.stream_code == "M"
        )
      )?.subject_color_code || "white";
  }
  if (selectedScheduleType === "foundation") {
    color =
      faculty.subject.find((subject) =>
        subject.batch_stream.find((stream) => stream.stream_code == "F")
      )?.subject_color_code || "white";
  }

  return (
    <div
      ref={(node) => {
        ref(drop(node));
      }}
      className={`relative flex flex-col items-center justify-center m-0 mx-1 w-28 h-20 p-1 box-border border bg-white border-gray-400 tooltip`}
      key={faculty.id}
      value={faculty.id}
      style={{
        backgroundColor: color,
        cursor: "grab",
        position: "relative",
      }}
    >
      <span class="tooltiptext font-serif absolute bottom-full left-0 -mb-1/2 transform -translate-y-full">
        {faculty.first_name + " " + faculty.last_name}
      </span>

      {faculty.image_url ? (
        <img
          src={faculty.image_url}
          onError={(e) => {
            e.target.src = defaultImage;
          }}
          className="h-8 w-8 rounded-full"
          alt="Item Image"
        />
      ) : (
        <img
          src={defaultImage}
          className="h-8 w-8 rounded-full"
          alt="Default Image"
        />
      )}
      <div
        className="vertical-line font-serif"
        style={{ position: "absolute", top: "0", right: "0" }}
      >
        <FaGripVertical className=" pl-1 " />
      </div>
      <div>
        <span className="mt-1  text-xs font-semibold font-serif">
          {`${faculty.first_name
            .split(" ")[0]
            .charAt(0)
            .toUpperCase()}${faculty.last_name
            .split(" ")[0]
            .charAt(0)
            .toUpperCase()}`}
        </span>
        <span className="ml-2 text-xs font-semibold font-serif">
          ({calculateHRs ? calculateHRs : 0} / {calculatedValue})
        </span>
      </div>

      <ProgressBar
        completed={completed || 0}
        borderRadius={"1px"}
        bgColor={bgColor}
        className="w-full mb-0 "
        height={"12px"}
        customLabel="  "
      />
    </div>
  );
};
